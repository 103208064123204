import { apiSlice } from "../../../app/api/apiSlice";

export const officalInformationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addOfficalInformation: builder.mutation({
      query: (newInformation) => ({
        url: "user/information/add",
        method: "POST",
        body: newInformation,
      }),
    }),
    fetchAllowances: builder.query({
      query: () => "user/allowances",
    }),
    createAllowance: builder.mutation({
      query: (newAllowance) => ({
        url: "user/allowances",
        method: "POST",
        body: newAllowance,
      }),
    }),
    updateAllowance: builder.mutation({
      query: ({ allowanceId, updatedAllowance }) => ({
        url: `user/allowances/${allowanceId}`,
        method: "PUT",
        body: updatedAllowance,
      }),
    }),

    // Contributions endpoints
    fetchContributions: builder.query({
      query: () => "user/contributions",
    }),
    updateContributions: builder.mutation({
      query: ({ contributionId, updatedContributions }) => ({
        url: `user/contributions/${contributionId}`,
        method: "PUT",
        body: updatedContributions,
      }),
    }),
    createContribution: builder.mutation({
      query: (newContribution) => ({
        url: "user/contributions",
        method: "POST",
        body: newContribution,
      }),
    }),

    // Deductions endpoints
    fetchDeductions: builder.query({
      query: () => "user/deductions",
    }),

    updateDeductions: builder.mutation({
      query: ({ deductionId, updatedDeduction }) => ({
        url: `user/deductions/${deductionId}`,
        method: "PUT",
        body: updatedDeduction,
      }),
    }),

    createDeduction: builder.mutation({
      query: (newDeduction) => ({
        url: "user/deductions",
        method: "POST",
        body: newDeduction,
      }),
    }),

    // Taxes endpoints
    fetchTaxes: builder.query({
      query: () => "user/taxes",
    }),
    updateTax: builder.mutation({
      query: ({ taxId, updatedTax }) => ({
        url: `user/taxes/${taxId}`,
        method: "PUT",
        body: updatedTax,
      }),
    }),
    createTax: builder.mutation({
      query: (newTax) => ({
        url: "user/taxes",
        method: "POST",
        body: newTax,
      }),
    }),
  }),
});

export const {
  useAddOfficalInformationMutation,
  useFetchAllowancesQuery,
  useCreateAllowanceMutation,
  useUpdateAllowanceMutation,
  useFetchContributionsQuery,
  useUpdateContributionsMutation,
  useCreateContributionMutation,
  useFetchDeductionsQuery,
  useUpdateDeductionsMutation,
  useCreateDeductionMutation,
  useFetchTaxesQuery,
  useUpdateTaxMutation,
  useCreateTaxMutation,
} = officalInformationApiSlice;
