import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Formik } from "formik";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import Select from "react-select";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

import { useGetAllJournalEntryByYearQuery } from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";

const JournalLedger = () => {
  const [record, setRecord] = useState([]);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");

  const { data: allRecords } = useGetAllJournalEntryByYearQuery(
    {
      dateTo: dateTo,
      dateFrom: dateFrom,
    },
    { skip: !dateTo || !dateFrom }
  );

  useEffect(() => {
    if (allRecords) {
      setRecord(allRecords);
    }
  }, [allRecords]);

  const renderRow = (item) => {
    return item?.entriesData?.map((entry, subIndex) => (
      <tr key={subIndex} style={{ backgroundColor: "white" }}>
        {/* Display Date and Account only for the first subentry */}
        {subIndex === 0 && (
          <>
            <td
              rowSpan={item?.entriesData.length}
              style={{ width: "15%" }}
              className="text-center"
            >
              {new Date(item?.date).toLocaleDateString("en-GB")}{" "}
              {/* Adjusting the date format */}
            </td>
          </>
        )}
        <td
          style={{
            width: "60%",
            paddingLeft: `${subIndex == 0 ? subIndex + 3 : subIndex + 5}%`,
          }}
        >
          {entry?.Account?.name}

          {/* name */}
        </td>
        <td className="text-center">{entry?.debits}</td>
        <td className="text-center">{entry?.credits}</td>
      </tr>
    ));
  };

  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Journal_Ledger_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ color: "#009688" }}>
                Journal Ledger
              </h4>
              <button
                onClick={downloadPDF}
                style={{
                  marginBottom: "20px",
                  background: "transparent",
                  border: "none",
                  padding: 0,
                }}
              >
                <MdOutlineFileDownload
                  style={{ color: "inherit", fontSize: "20px" }}
                />
              </button>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{
                    fromDate: "",
                    toDate: "",
                  }}
                  enableReinitialize={true}
                  // validationSchema={loginSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Date From</label>
                            <input
                              type="date"
                              name="fromDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("fromDate", e.target.value);
                                setdateFrom(e.target.value);
                              }}
                              onBlur={handleBlur}
                              max={new Date()?.toISOString()?.slice(0, 10)}
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.fromDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Date To</label>
                            <input
                              type="date"
                              max={new Date()?.toISOString()?.slice(0, 10)}
                              name="toDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("toDate", e.target.value);
                                setDateTo(e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.toDate}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              {dateTo && dateFrom && (
                <div id="report-content">
                  <div>
                    <div className="row justify-content-between align-items-start mt-4">
                      <div className="col-auto">
                        <h1 className="text-left" style={{ color: "#009688" }}>
                          Aifa Services
                        </h1>
                      </div>
                      <div className="col-auto">
                        <img
                          src={aifaLogo}
                          alt="logo"
                          style={{
                            width: "130px",
                          }}
                        />
                      </div>
                    </div>
                    {dateFrom && dateTo && (
                      <div
                        className="text-center"
                        style={{
                          color: "white",
                          backgroundColor: "rgb(52, 152, 219)",
                          padding: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        <h2 style={{ color: "white" }}>
                          Journal Ledger Summary
                        </h2>
                        <p>
                          From {new Date(dateFrom).toLocaleDateString("en-GB")}{" "}
                          to {new Date(dateTo).toLocaleDateString("en-GB")}
                        </p>
                      </div>
                    )}

                    <Table
                      bordered
                      style={{ backgroundColor: "white" }}
                      className="p-3 rounded"
                    >
                      <thead
                        className="text-center"
                        style={{
                          backgroundColor: "rgb(52, 152, 219)",
                          marginTop: "10px",
                        }}
                      >
                        <tr>
                          <th style={{ color: "white" }}>Date</th>
                          <th style={{ color: "white" }}>Account</th>
                          <th style={{ color: "white" }}>Debit</th>
                          <th style={{ color: "white" }}>Credit</th>
                        </tr>
                      </thead>

                      <tbody
                        style={{
                          color: "black",
                          fontSize: "14px",
                          // fontWeight: "bold",
                        }}
                      >
                        {record?.map((item, index) => (
                          <React.Fragment key={index}>
                            {renderRow(item)}
                            {/* Add a separator row if needed */}
                            <tr>
                              <td
                                colSpan={4}
                                style={{ borderTop: "2px solid black" }}
                              ></td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JournalLedger;
