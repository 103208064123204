import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetRoleQuery,
  usePutRoleMutation,
} from "../../../features/userManagement/userManagementApiSlice";
import { notifySuccess } from "../../../helpers/Notify";
import swal from "sweetalert";
import { CSVLink } from "react-csv";

const Role = () => {
  const [search, setSearch] = useState("");
  const [filteredRoles, setFilteredRoles] = useState([]);
  const { data: roles = [], refetch } = useGetRoleQuery();
  const [putRole] = usePutRoleMutation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 20;
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setFilteredRoles(roles); // Set roles when fetched
  }, [roles]);

  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredRoles.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredRoles.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    const filtered = roles.filter((role) =>
      role.roleName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredRoles(filtered);
  };

  const handleDelete = (role) => {
    const updatedRole = {
      ...role,
      isDeleted: true,
      updatedBy: user?.userId,
    };

    putRole({ id: role.id, updatedRole })
      .unwrap()
      .then(() => {
        notifySuccess("Role deleted successfully");
        refetch();
        navigate("roles");
      })
      .catch((error) => console.error("Error deleting role", error));
  };

  const handleEdit = (role) =>
    navigate(
      `/${user?.role?.toLowerCase()}/group-permission/edit/` + role.roleId
    );
  const handleView = (role) =>
    navigate(
      `/${user?.role?.toLowerCase()}/group-permission/view/` + role.roleId
    );

  const SweetAlert = (role) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this role!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) handleDelete(role);
      else swal("Your role is safe!");
    });

  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Role Name", key: "roleName" },
    { label: "Description", key: "description" },
    { label: "Date", key: "createdAt" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredRoles.map((role, index) => ({
      index: index + 1,
      roleName: role.roleName,
      description: role.description,
      createdAt: new Date(role.createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    })),
    filename: "roles.csv",
  };

  const changeCPage = (n) => setCurrentPage(n);
  const prePage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const nextPage = () =>
    setCurrentPage((prev) => (prev < npage ? prev + 1 : prev));

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects task-table">
                <div className="tbl-caption d-flex justify-content-between align-items-center">
                  <h4 className="heading mb-0">Role Management</h4>

                  <div className="d-flex align-items-center">
                    {filteredRoles.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.7rem 1.5rem",
                        }}
                      >
                        <i className="fa-solid fa-file-excel me-2" />
                        Export Report
                      </CSVLink>
                    )}
                    <input
                      type="text"
                      name="search"
                      value={search}
                      onChange={handleSearch}
                      placeholder="Search"
                      className="form-control me-2"
                    />
                    {user?.Role?.permissions?.includes(
                      "group-permission/create"
                    ) && (
                      <Link
                        to="create"
                        className="btn btn-primary btn-sm"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.7rem 1.5rem",
                        }}
                      >
                        <i className="fa-solid fa-plus me-2"></i>
                        Add Role
                      </Link>
                    )}
                  </div>
                </div>

                <table className="table ItemsCheckboxSec dataTable no-footer mb-2">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Role Name</th>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((role, index) => (
                      <tr key={index}>
                        <td>{firstIndex + index + 1}</td>
                        <td>{role.roleName}</td>
                        <td>{role.description}</td>
                        <td>
                          {new Date(role.createdAt).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </td>
                        <td>
                          {user?.Role?.permissions?.includes(
                            "group-permission/view/:group_permissionId"
                          ) && (
                            <span
                              onClick={() => handleView(role)}
                              style={{
                                marginRight: "8px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fas fa-eye" title="View"></i>
                            </span>
                          )}
                          {user?.Role?.permissions?.includes(
                            "group-permission/edit/:group_permissionId"
                          ) &&
                            role?.roleName !== "Admin" && (
                              <span
                                onClick={() => handleEdit(role)}
                                style={{
                                  marginRight: "8px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-edit" title="Edit"></i>
                              </span>
                            )}

                          {/* {user?.Role?.permissions?.includes(
                            "group-permission/delete/:group-permissionId"
                          ) && (
                            <span
                              onClick={() => SweetAlert(role)}
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              <i className="fas fa-trash" title="Delete"></i>
                            </span>
                          )} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    Showing {firstIndex + 1} to{" "}
                    {lastIndex > filteredRoles.length
                      ? filteredRoles.length
                      : lastIndex}{" "}
                    of {filteredRoles.length} entries
                  </div>
                  <div className="pagination">
                    <Link
                      to="#"
                      onClick={prePage}
                      className={currentPage === 1 ? "disabled" : ""}
                    >
                      <i className="fa-solid fa-angle-left" />
                    </Link>
                    {number.map((n) => (
                      <Link
                        key={n}
                        to="#"
                        className={currentPage === n ? "current" : ""}
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </Link>
                    ))}
                    <Link
                      to="#"
                      onClick={nextPage}
                      className={currentPage === npage ? "disabled" : ""}
                    >
                      <i className="fa-solid fa-angle-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Role;
