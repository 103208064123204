import React, { useState } from "react";
import CalendarComponent from "./Calendar";

const SpecificDays = ({ dates, onUpdate, disabled }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const existingDate = dates.find(
      (d) =>
        new Date(d.date).toISOString().split("T")[0] ===
        date.toISOString().split("T")[0]
    );
    setTimeSlots(
      existingDate?.timeSlots?.length
        ? existingDate.timeSlots
        : [{ start: "09:00", end: "09:30" }]
    );
  };

  const handleTimeSlotChange = (index, field, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index] = {
      ...newTimeSlots[index],
      [field]: value,
    };
    setTimeSlots(newTimeSlots);
    updateDates(newTimeSlots);
  };

  const addTimeSlot = () => {
    const newTimeSlots = [...timeSlots, { start: "09:00", end: "09:30" }];
    setTimeSlots(newTimeSlots);
    updateDates(newTimeSlots);
  };

  const removeTimeSlot = (index) => {
    const newTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(newTimeSlots);
    updateDates(newTimeSlots);
  };

  const updateDates = (newTimeSlots) => {
    if (!selectedDate) return;

    const newDates = [...dates];
    const dateIndex = dates.findIndex(
      (d) =>
        new Date(d.date).toISOString().split("T")[0] ===
        selectedDate.toISOString().split("T")[0]
    );

    if (dateIndex >= 0) {
      if (newTimeSlots.length === 0) {
        newDates.splice(dateIndex, 1);
      } else {
        newDates[dateIndex] = {
          ...newDates[dateIndex],
          timeSlots: newTimeSlots,
        };
      }
    } else if (newTimeSlots.length > 0) {
      newDates.push({
        date: selectedDate,
        timeSlots: newTimeSlots,
      });
    }

    onUpdate(newDates);
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Set Your Hours For Specific Days</h5>
          <hr />

          <CalendarComponent
            selectedDate={selectedDate}
            onDateSelect={handleDateSelect}
            markedDates={dates?.map((d) => new Date(d.date))}
            disabled={disabled}
          />

          {selectedDate && (
            <div className="mt-4">
              <h6>What hours are you available?</h6>
              <div className="d-flex flex-wrap">
                {timeSlots.map((slot, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center mb-2 me-3"
                  >
                    <input
                      type="time"
                      value={slot.start}
                      onChange={(e) =>
                        handleTimeSlotChange(index, "start", e.target.value)
                      }
                      disabled={disabled}
                      className="form-control form-control-sm me-2"
                    />
                    <span className="me-2">to</span>
                    <input
                      type="time"
                      value={slot.end}
                      onChange={(e) =>
                        handleTimeSlotChange(index, "end", e.target.value)
                      }
                      disabled={disabled}
                      className="form-control form-control-sm me-2"
                    />
                    <button
                      onClick={() => removeTimeSlot(index)}
                      disabled={disabled}
                      className="btn btn-sm btn-danger"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              <button
                onClick={addTimeSlot}
                disabled={disabled}
                className="btn btn-sm btn-primary mt-2"
              >
                Add Time Slot
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecificDays;
