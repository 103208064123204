import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";
import { useGetCustomerQuery } from "../../../../../features/customer/customerApiSlice";
import { useGetEmployeesQuery } from "../../../../../features/employee/employeeApiSlice";
import {
  usePutJournalEntryMutation,
  useGetSingleJournalEntryQuery,
} from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";
import { useParams, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useGetSupplierQuery } from "../../../../../features/supplier/supplierApiSlice";

const JournalForm = () => {
  const navigate = useNavigate();
  const { journalEntryId } = useParams();

  const { data: getSingleJournalEntry, refetch } =
    useGetSingleJournalEntryQuery(journalEntryId);

  const [isView, setIsView] = useState(false);
  const [initialValues, setInitialValues] = useState({
    journalDate: "",
    journal_no: 0,
    enteries: [
      {
        id: uuidv4(),
        account: "",
        debits: "",
        credits: "",
        description: "",
        type: "",
        name: "",
      },
    ],
    files: [],
    description: "",
  });

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    if (containsView) {
      setIsView(true);
    }
    refetch();
  }, []);

  useEffect(() => {
    if (getSingleJournalEntry) {
      const entryDate = new Date(getSingleJournalEntry?.date)
        .toISOString()
        .split("T")[0];

      setInitialValues({
        journalDate: entryDate || "",
        journal_no: getSingleJournalEntry?.journal_no || 0,
        enteries: getSingleJournalEntry?.entriesData.map((entry) => ({
          id: entry.entryId,
          account: { label: entry?.Account?.name, value: entry?.Account },
          debits: entry?.debits,
          credits: entry?.credits,
          description: entry?.description,
          type: { label: entry?.type, value: entry?.type },
          name: {
            label: entry?.customer
              ? entry?.customer?.firstname
              : entry?.user
              ? entry?.user?.firstName
              : entry?.Supplier?.name,
            value: entry?.customer
              ? entry?.customer?.customerId
              : entry?.user?.userId,
          },
        })) || [
          {
            id: uuidv4(),
            account: "",
            debits: "",
            credits: "",
            description: "",
            type: "",
            name: "",
          },
        ],
        files: getSingleJournalEntry?.file || [],
        description: getSingleJournalEntry?.description || "",
      });
    }
  }, [getSingleJournalEntry]);

  const [totalDebits, setTotalDebits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);

  const { data: accounts = [] } = useGetAllAccountsQuery();
  const { data: customers = [] } = useGetCustomerQuery();
  const { data: employees = [] } = useGetEmployeesQuery();
  const { data: suppliers = [] } = useGetSupplierQuery();

  const typeOptions = [
    { value: "customer", label: "Customer" },
    { value: "employee", label: "Employee" },
    { value: "supplier", label: "Supplier" },
  ];

  const [updateJournalEntry] = usePutJournalEntryMutation();

  const validationSchema = Yup.object({
    journalDate: Yup.date().required("Required"),
    journal_no: Yup.string(),
    enteries: Yup.array().of(
      Yup.object({
        account: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
      })
    ),
  });

  const calculateTotalDebits = (enteries) => {
    const total = enteries.reduce(
      (total, item) => total + parseFloat(item.debits || 0),
      0
    );
    setTotalDebits(total);
    return total;
  };

  const calculateTotalCredits = (enteries) => {
    const total = enteries.reduce(
      (total, item) => total + parseFloat(item.credits || 0),
      0
    );
    setTotalCredits(total);
    return total;
  };

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const existingFiles = getSingleJournalEntry?.file || [];
    setFiles(existingFiles);
  }, [getSingleJournalEntry]);

  const handleFiles = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileInput = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getOptionsForName = (type) => {
    switch (type) {
      case "customer":
        return customers?.customers?.map((customer) => ({
          value: customer?.customerId,
          label: customer?.firstname,
        }));
      case "employee":
        return employees?.users?.map((employee) => ({
          value: employee?.userId,
          label: employee?.firstName,
        }));

      case "supplier":
        return suppliers.suppliers?.map((supplier) => ({
          value: supplier?.supplierId,
          label: supplier.name,
        }));
      default:
        return [];
    }
  };

  function extractFileName(jsonOrUrl) {
    if (typeof jsonOrUrl === "string") {
      if (jsonOrUrl.startsWith("{") && jsonOrUrl.endsWith("}")) {
        try {
          const { url } = JSON.parse(jsonOrUrl);
          const parts = url.split("/");
          return parts[parts.length - 1];
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return "Unknown File";
        }
      } else {
        const parts = jsonOrUrl.split("/");
        return parts[parts.length - 1];
      }
    } else if (jsonOrUrl instanceof File) {
      return jsonOrUrl.name;
    } else {
      return "Unknown File";
    }
  }

  return (
    <div
      className="container mx-3 rounded p-3 "
      style={{ backgroundColor: "white" }}
    >
      <h3 className="mb-4 pt-4">{isView ? "View" : "Edit"} Journal Entry</h3>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values) => {
          if (totalDebits !== totalCredits) {
            notifyError("Debits and Credits should be equal");
            return;
          }

          const formData = new FormData();
          formData.append("totalCredits", totalCredits);
          formData.append("totalDebits", totalDebits);
          formData.append("date", values.journalDate);
          formData.append("description", values.description);
          formData.append("isDeleted", false);
          formData.append("journal_no", values.journal_no);
          const enteriesArray = values.enteries.map((entry) => ({
            accountId: entry.account.value.accountId,
            debits: entry.debits,
            credits: entry.credits,
            description: entry.description,
            type: entry?.type?.value,
            name: entry?.name?.value,
            entryId: entry?.id,
          }));
          formData.append("enteries", JSON.stringify(enteriesArray));
          files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });
          const updatedJournalEntry = formData;
          try {
            const result = await updateJournalEntry({
              id: getSingleJournalEntry?.journalEntryId,
              updatedJournalEntry,
            });

            notifySuccess(result?.data?.message);
            navigate("journal-entries");
          } catch (error) {}
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="row mt-3 d-flex justify-content-between">
              <div className="form-group col-2">
                <label>Journal Date</label>
                <input
                  name="journalDate"
                  type="date"
                  className="form-control"
                  value={values.journalDate}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-6 ml-auto">
                <label>Journal Number</label>
                <input
                  name="journal_no"
                  type="number"
                  placeholder="Enter Journal Number"
                  className="form-control"
                  disabled={true}
                  value={values?.journal_no}
                />
              </div>
            </div>
            <FieldArray name="enteries">
              {({ push, remove }) => (
                <>
                  {/* Table for journal entries */}
                  <table className="table mt-3">
                    {/* Table head */}
                    <thead style={{ backgroundColor: "#F2F2F2" }}>
                      <tr>
                        <th>#</th>
                        <th>Account</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Description</th>
                        <th>Select Type</th>
                        <th>Name</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>

                    <tbody>
                      {values?.enteries.map((entry, index) => (
                        <tr key={entry.id}>
                          <td>{index + 1}</td>
                          <td className="w-25">
                            <Select
                              options={accounts.map((account) => ({
                                value: account,
                                label: account.name,
                              }))}
                              name={`enteries[${index}].account`}
                              value={values.enteries[index].account}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  `enteries[${index}].account`,
                                  selectedOption
                                );
                              }}
                              isDisabled={true}
                            />
                          </td>
                          <td className="" style={{ width: "150px" }}>
                            <Field
                              name={`enteries[${index}].debits`}
                              placeholder=""
                              type="number"
                              className="form-control"
                              readOnly={isView}
                            />
                            {/* Show increase/decrease text */}
                          </td>
                          <td style={{ width: "150px" }}>
                            <Field
                              name={`enteries[${index}].credits`}
                              placeholder=""
                              className="form-control"
                              type="number"
                              readOnly={isView}
                            />
                            {/* Show increase/decrease text */}
                          </td>
                          <td>
                            <Field
                              name={`enteries[${index}].description`}
                              placeholder=""
                              className="form-control"
                              readOnly={isView}
                            />
                          </td>
                          <td style={{ width: "150px" }}>
                            <Select
                              options={typeOptions}
                              name={`enteries[${index}].type`}
                              value={values?.enteries[index].type}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  `enteries[${index}].type`,
                                  selectedOption
                                );
                                setFieldValue(`enteries[${index}].name`, ""); // Reset the name field
                              }}
                              isDisabled={true}
                            />
                          </td>
                          <td style={{ width: "180px" }}>
                            <Select
                              options={getOptionsForName(entry.type.value)}
                              name={`enteries[${index}].name`}
                              value={values.enteries[index].name}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  `enteries[${index}].name`,
                                  selectedOption
                                );
                              }}
                              isDisabled={true}
                            />
                          </td>
                          {/* <td>
                            {values.enteries.length > 1 && (
                              <button
                                style={{ border: "none", fontSize: "20px" }}
                                type="button"
                                onClick={() => remove(index)}
                              >
                                <MdDelete />
                              </button>
                            )}
                          </td> */}
                        </tr>
                      ))}
                    </tbody>

                    {/* Table foot */}
                    <tfoot>
                      <tr>
                        <td></td>
                        <td className="text-end" style={{ fontWeight: "bold" }}>
                          Total
                        </td>
                        <td className="text-end" style={{ fontWeight: "bold" }}>
                          {calculateTotalDebits(values.enteries)}
                        </td>
                        <td className="text-end" style={{ fontWeight: "bold" }}>
                          {calculateTotalCredits(values.enteries)}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                  {/* Buttons for adding and clearing lines */}
                  {/* <div className="px-5">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn border btn btn-outline-success mr-2 w-10"
                        onClick={() =>
                          push({
                            id: uuidv4(),
                            account: "",
                            debits: "",
                            credits: "",
                            description: "",
                            type: "",
                            name: "",
                          })
                        }
                      >
                        Add Line
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-warning"
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          const clearedEnteries = Array.from(
                            { length: values.enteries.length },
                            (_, index) =>
                              index === 0 ? initialValues.enteries[0] : null
                          ).filter(Boolean);
                          setFieldValue("enteries", clearedEnteries);
                        }}
                      >
                        Clear Lines
                      </button>
                    </div>
                  </div> */}
                </>
              )}
            </FieldArray>
            {/* Description field */}
            <div className="form-group col-5 mt-3">
              <label>Memo</label>
              <Field
                name="description"
                as="textarea"
                placeholder="Description"
                className="form-control"
                readOnly={isView}
              />
            </div>
            {/* File upload */}
            {/* <div className="form-group mt-3">
              <label htmlFor="fileUpload" className="form-label">
                Attachments{" "}
                <small className="text-muted">(Maximum size: 20MB)</small>
              </label>
              <div
                className="border p-3 col-md-8"
                onDrop={(e) => {
                  handleDrop(e);
                  setFieldValue("files", [
                    ...files,
                    ...Array.from(e.dataTransfer.files),
                  ]);
                }}
                onDragOver={handleDragOver}
                style={{ borderStyle: "dashed", borderColor: "#ced4da" }}
              >
                <input
                  type="file"
                  id="fileUpload"
                  className="form-control-file"
                  onChange={(e) => {
                    handleFileInput(e);
                    setFieldValue("files", [
                      ...files,
                      ...Array.from(e.target.files),
                    ]);
                  }}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="fileUpload" className="d-block text-center">
                  Drag/Drop files here or click the icon
                </label>
              </div>
              <div className="mt-0">
                <button className="btn btn-link" type="button">
                  Show existing
                </button>
              </div>
            </div> */}
            {/* Display existing attachments */}
            <div>
              {files.length > 0 && (
                <div className="mt-4">
                  <h5>Existing Attachments:</h5>
                  <ul className="list-group">
                    {files.map((file, index) => {
                      const fileData = JSON.parse(file);
                      const fileName = extractFileName(file);
                      const fileUrl = fileData?.url;
                      const fileType = fileName.split(".").pop().toLowerCase();

                      return (
                        <li key={index} className="list-group-item">
                          {fileType === "jpg" ||
                          fileType === "jpeg" ||
                          fileType === "png" ||
                          fileType === "gif" ? (
                            <img
                              src={fileUrl}
                              width="200px"
                              height="300px"
                              alt={fileName}
                            />
                          ) : (
                            <a
                              href={fileUrl}
                              download={fileName}
                              target="_blank"
                            >
                              {fileName}
                            </a>
                          )}
                          {/* <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => {
                handleRemoveFile(index);
                setFieldValue(
                  "files",
                  files.filter((_, i) => i !== index)
                );
              }}
            >
              Remove
            </button> */}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
            {/* Submit button */}
            <div className="col-md-12 d-flex justify-content-end">
              {!isView && (
                <button
                  type="submit"
                  className="btn btn-success mt-3 ml-auto"
                  // disabled={isView}
                >
                  Submit
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default JournalForm;
