import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Listing from "../../Masters/listing";

import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

import {
  useGetModelsQuery,
  useCreateModelMutation,
  useDeleteModelMutation,
} from "../../../../features/Inventory/model/modelApiSlice";

const validateSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
});

export default function PropertyType() {
  const [createModel, { isLoading }] = useCreateModelMutation();
  const { data, error, refetch } = useGetModelsQuery();
  const userInfo = useSelector(selectCurrentUser);
  const [deleteModel] = useDeleteModelMutation();
  const handleDelete = async (id) => {
    const result = await deleteModel(id);
    refetch();
    notifySuccess(result?.data?.message);
  };

  const [search, setSearch] = useState("");

  const filterModels = () => {
    if (!search) return data?.model;

    return data?.model?.filter((model) => {
      const name = model?.name?.toLowerCase();
      const date = new Date(model?.createdAt).toLocaleString().toLowerCase();

      return (
        name.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase())
      );
    });
  };

  const filteredModels = filterModels();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await createModel(values);

      refetch();
      notifySuccess(result?.data?.message);
      resetForm(); // Reset form values after successful submission
    } catch (error) {
      notifyError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Model</h4>

              <div>
                <input
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  className="form-control"
                />
              </div>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="row">
                  <div className="col-md-6">
                    <Formik
                      initialValues={{ name: "" }}
                      enableReinitialize={true}
                      validationSchema={validateSchema}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className={`form-group mb-3`}>
                            <label className="text-label">Add Model</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Model"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              disabled={
                                !userInfo?.Role?.permissions?.includes(
                                  "model/create"
                                )
                              }
                            />

                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.name && errors.name}
                            </div>
                          </div>
                          <div className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={
                                isLoading ||
                                !userInfo?.Role?.permissions?.includes(
                                  "model/create"
                                )
                              }
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>

                  <div className="col-md-6">
                    <Listing
                      isCheck={"model"}
                      data={filteredModels}
                      handleDelete={handleDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
