// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import {
  usePutQoutaionMutation,
  useGetSingleQoutaionQuery,
  usePostQoutaionMutation,
} from "../../../../features/quotaion/quotaionApiSlice";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../../helpers/Notify";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import {
  useGetLeadsQuery,
  useGetInspactedLeadsQuery,
} from "../../../../features/lead/leadApiSlice";
import Select from "react-select";
import { useGetSubServicesQuery } from "../../../../features/subservice/subserviceApiSlice";
import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import Alert from "react-bootstrap/Alert";
const loginSchema = Yup.object().shape({
  leadId: Yup.string().required("Lead ID is required"),
  quotaion: Yup.string().required("Quotation is required"),
  stage: Yup.string().required("Stage is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
  discount: Yup.number().required("Discount is required"),
  salesTaxs: Yup.number().required("Sales Tax is required"),
});
const quotationStatusList = [
  { value: "Accepted", label: "Accepted" },
  { value: "Draft", label: "Draft" },
  { value: "Pending Review", label: "Pending Review" },
  { value: "Sent", label: "Sent" },
  { value: "Under Consideration", label: "Under Consideration" },

  { value: "Revise", label: "Revise" },
  { value: "Rejected", label: "Rejected" },
  { value: "Expired", label: "Expired" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "In Progress", label: "In Progress" },
  // { value: "Completed", label: "Completed" },
];

const Edit = () => {
  const [selectLead, setSelectedLead] = useState(null);
  const [discountPercentage, setDiscount] = useState(0);
  const user = localStorage.getItem("user");
  const [disableButton, setDisableButton] = useState(false);

  const { quotationId } = useParams();

  const { data: getSingleQoutaion, refetch } =
    useGetSingleQoutaionQuery(quotationId);

  const [putQoutaion, { isLoading }] = usePutQoutaionMutation();
  const [postQoutaion] = usePostQoutaionMutation();

  const [items, setItems] = useState([]);
  const [subServicesList, setSubServicesList] = useState([]);
  const [subServiceTotalDetailPrice, setSubServiceTotalDetailPrice] = useState({
    subtotal: 0,
    totalTax: 5,
    EstimatedTax: 0,
    totalDiscount: 0,
    EstimatedDiscounts: 0,
    total: 0,
    beforeDiscountSubtotal: 0,
  });

  useEffect(() => {}, [discountPercentage]);

  useEffect(() => {
    if (getSingleQoutaion?.quotation) {
      setDiscount(
        getSingleQoutaion?.quotation?.discount > 0
          ? getSingleQoutaion?.quotation?.discount
          : 0
      );
    }
  }, [getSingleQoutaion]);
  // useEffect(() => {}, [getSingleQoutaion, subServiceTotalDetailPrice]);

  const handleContentChange = (value) => {
    setContent(value);
  };
  const [content, setContent] =
    useState(`${getSingleQoutaion?.quotation?.discription}
         
  `);
  const navigate = useNavigate();

  const { data: subServices = [] } = useGetServicesQuery({
    fetchPolicy: "network-only",
  });

  const [initialValues, setInitialValues] = useState({
    quotationNo: "",
    serviceId: "",
    leadId: "",
    quotaion: "",
    stage: "",
    startDate: "",
    endDate: "",
    customerId: "",
    phone: "",
    discription: "",
    discount: "",
    discountTitle: "",
    salesTaxs: "5",
    preferred_Time: "",
    serviceDate: "",
    TRN: "",
  });
  const parseFun = () => {
    try {
      return selectLead?.serviceId?.map(JSON.parse);
    } catch (error) {
      return selectLead?.serviceId;
    }
  };
  const [isView, setIsView] = useState(null);
  const [revise, setRevise] = useState(false);

  useEffect(() => {
    refetch();
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    const containsRevise = currentPath.includes("/revise/");
    if (containsView) {
      setIsView(true);
    }
    if (containsRevise) {
      setRevise(true);
    }
    if (getSingleQoutaion) {
      // setSubServiceTotalDetailPrice(getSingleQoutaion?.pricesCalculation);
      setSubServicesList(getSingleQoutaion?.quotation?.subSeriviceList);
      setItems(getSingleQoutaion?.quotation?.subSeriviceList);
      setSelectedLead(getSingleQoutaion?.quotation?.lead);
      setContent(getSingleQoutaion?.quotation?.discription);
    }
  }, [getSingleQoutaion]);

  const handleRevise = () => {
    if (getSingleQoutaion?.quotation?.stage == "Accepted") {
      notifyWarning("Quotation Is Already Completed");
    } else {
      // navigate("quotation/revise/" + quotationId);
      window.location.href = "quotation/revise/" + quotationId;
    }
  };

  const addItem = () => {
    const newItem = { id: items.length + 1 };
    setItems([...items, newItem]);
  };

  const handleSubServiceNameChange = (selectedOption, itemId) => {
    const itemIndex = items.findIndex((item) => item.id === itemId);
    const updatedItems = [...items];

    let subService = subServices?.services?.find(
      (service) => selectedOption.value === service?.serviceId
    );

    const updatedSubService = {
      ...subService,
      label: subService?.name,
      value: subService?.serviceId,
    };

    const quantity = updatedItems[itemIndex]?.quantity || 1;
    const oneTaskQuantity = updatedItems[itemIndex]?.oneTaskQuantity || 1;

    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      subService: updatedSubService,
      startRangePrice: updatedSubService?.startRangePrice,
      endRangePrice: updatedSubService?.endRangePrice,
      totalPrice: updatedSubService?.totalPrice,
      subTotal: updatedSubService?.startRangePrice * quantity,
      oneTaskQuantity: oneTaskQuantity,
      quantity: quantity,
    };

    setItems(updatedItems);
  };

  const deleteItem = (id) => {
    // Filter out the item with the specified id
    const updatedItems = items.filter((item) => item.id !== id);

    // Reassign the IDs to be sequential starting from 1
    const reindexedItems = updatedItems.map((item, index) => ({
      ...item,
      id: index + 1,
      quantity: 1,
    }));
    setItems(reindexedItems);
  };

  useEffect(() => {
    const updatedSubServicesTotalDetailPrice = {
      subtotal: 0,
      totalTax: 0,
      EstimatedTax: 0,
      totalDiscount: 0,
      EstimatedDiscounts: 0,
      total: 0,
      beforeDiscountSubtotal: 0,
    };

    items?.forEach((subService) => {
      if (subService) {
        updatedSubServicesTotalDetailPrice.subtotal += subService.subTotal
          ? parseFloat(subService.subTotal)
          : 0;

        updatedSubServicesTotalDetailPrice.EstimatedDiscounts +=
          subService.discount ? parseFloat(subService.discount) : 0;

        updatedSubServicesTotalDetailPrice.beforeDiscountSubtotal += parseFloat(
          subService.subTotal || 0
        );
      }
    });

    updatedSubServicesTotalDetailPrice.beforeDiscountSubtotal -=
      updatedSubServicesTotalDetailPrice.EstimatedDiscounts;

    const updatedTotalDetailPrice = subServiceTotalDetailPrice;
    updatedSubServicesTotalDetailPrice.totalTax =
      updatedTotalDetailPrice?.totalTax;
    updatedSubServicesTotalDetailPrice.totalDiscount =
      updatedTotalDetailPrice?.totalDiscount;

    updatedSubServicesTotalDetailPrice.total =
      updatedSubServicesTotalDetailPrice.subtotal;

    var calculatedDiscount = 0;
    var calculatedTax = 0;
    const discount = updatedTotalDetailPrice.totalDiscount
      ? updatedTotalDetailPrice.totalDiscount
      : 0;

    const tax = updatedTotalDetailPrice.totalTax
      ? updatedTotalDetailPrice.totalTax
      : 5;
    if (updatedSubServicesTotalDetailPrice.total > 0) {
      const onePersent =
        updatedSubServicesTotalDetailPrice.beforeDiscountSubtotal / 100;
      if (discount > 0) {
        calculatedDiscount = onePersent * discount;
      }

      const withOutDiscount =
        updatedSubServicesTotalDetailPrice.beforeDiscountSubtotal -
        calculatedDiscount;

      if (tax) {
        const onePercent = withOutDiscount / 100;
        calculatedTax = onePercent * tax;

        updatedSubServicesTotalDetailPrice.total =
          withOutDiscount + calculatedTax;
      }
    }

    updatedSubServicesTotalDetailPrice.EstimatedTax = calculatedTax;

    setSubServiceTotalDetailPrice(updatedSubServicesTotalDetailPrice);
  }, [items]);

  const handleSubmitted = async (updatedQoutaion) => {
    // updatedQoutaion.serviceId = selectLead?.serviceId;
    updatedQoutaion.customerId = selectLead
      ? selectLead?.customerId
      : getSingleQoutaion?.quotation?.customerId;
    updatedQoutaion.customer = selectLead
      ? selectLead?.customer
      : getSingleQoutaion?.quotation?.customer?.firstname +
        " " +
        getSingleQoutaion?.quotation?.customer?.lastname;

    const updatedServices = items?.map((row) => {
      const discountAmount = row?.discount;

      const priceAfterDiscount = row?.subTotal - row?.discount;

      const vatAmount = parseFloat(priceAfterDiscount * 0.05).toFixed(2);

      const netPrice = parseFloat(
        parseFloat(priceAfterDiscount) + parseFloat(vatAmount)
      ).toFixed(2);

      return {
        ...row,
        discountAmount: discountAmount,
        priceAfterDiscount: priceAfterDiscount,
        vatAmount: vatAmount,
        netPrice: netPrice,
        totalPrice: priceAfterDiscount + discountAmount,
      };
    });

    updatedQoutaion.subSeriviceList = updatedServices;
    updatedQoutaion.pricesCalculation = subServiceTotalDetailPrice;
    updatedQoutaion.discription = content;
    updatedQoutaion.directQuotation = selectLead ? false : true;
    const quotation = getSingleQoutaion?.quotation;

    if (revise) {
      try {
        const updatedQoutation = JSON.parse(JSON.stringify(quotation));
        await handleAction(updatedQoutation, "Revise");

        const result = await postQoutaion(updatedQoutaion);

        notifySuccess(result?.data?.message);
        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/quotation");
      } catch (error) {
        notifyError(error.message);
      }
    } else {
      updatedQoutaion.userId =
        getSingleQoutaion?.quotation?.lead?.userId || null;
      try {
        await putQoutaion({
          id: quotation?.quotaionId,
          updatedQoutaion,
        })
          .unwrap()
          .then((response) => {
            notifySuccess("Quotation updated successfully");

            navigate(
              "/" + JSON.parse(user)?.role?.toLowerCase() + "/quotation"
            );
          })
          .catch((error) => {
            console.error("Error updating quotation", error);
            notifyError(error.message);
          });
      } catch (error) {
        notifyError(error.message);
      }
    }

    return;
  };

  const handleAction = (qoutaion, value) => {
    let updatedQoutaion = { ...qoutaion };

    updatedQoutaion.stage = value;

    updatedQoutaion.updatedBy = user?.userId;

    putQoutaion({ id: qoutaion?.quotaionId, updatedQoutaion })
      .unwrap()
      .then((response) => {
        return;
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating Quotation", error);
        notifyError(error?.message);
        return;
      });
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {isView !== null
                    ? "View Quotation"
                    : revise !== true
                    ? "Update Quotation"
                    : revise && "Revise Quotation"}
                </h4>
                {isView && getSingleQoutaion?.quotation?.stage !== "Revise" && (
                  <Button
                    onClick={() => handleRevise()}
                    style={{
                      marginRight: "8px",
                      // color: "blue",
                      cursor: "pointer",
                      right: "0px",
                    }}
                  >
                    Revise
                  </Button>
                )}
              </div>

              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      quotationNo: !revise
                        ? getSingleQoutaion?.quotation?.quotationNo
                        : getSingleQoutaion?.quotation?.quotationNo
                        ? getSingleQoutaion.quotation.quotationNo.includes("/R")
                          ? getSingleQoutaion.quotation.quotationNo.replace(
                              /(\/R)(\d+)?$/,
                              (_, p1, p2) => `${p1}${p2 ? +p2 + 1 : 1}`
                            )
                          : `${getSingleQoutaion.quotation.quotationNo}/R1`
                        : "",
                      serviceId:
                        getSingleQoutaion?.quotation?.service?.name || "",
                      leadId: getSingleQoutaion?.quotation?.leadId || "",
                      quotaion: getSingleQoutaion?.quotation?.quotaion || "",
                      stage: getSingleQoutaion?.quotation?.stage || "",
                      startDate: getSingleQoutaion?.quotation?.startDate
                        ? new Date(getSingleQoutaion.quotation.startDate)
                            .toISOString()
                            .split("T")[0]
                        : "",
                      endDate: getSingleQoutaion?.quotation?.endDate
                        ? new Date(getSingleQoutaion.quotation.endDate)
                            .toISOString()
                            .split("T")[0]
                        : "",

                      customerId:
                        getSingleQoutaion?.quotation?.customerId || "",
                      phone:
                        getSingleQoutaion?.quotation?.customer?.phone || "",
                      discription:
                        getSingleQoutaion?.quotation?.discription || "",
                      discount: getSingleQoutaion?.quotation?.discount || "",
                      discountTitle:
                        getSingleQoutaion?.quotation?.discountTitle || "",
                      salesTaxs: getSingleQoutaion?.quotation?.salesTaxs || "",
                      TRN: getSingleQoutaion?.quotation?.customer?.TRN || "",
                      preferred_Time:
                        getSingleQoutaion?.quotation?.preferred_Time || "",
                      serviceDate: getSingleQoutaion?.quotation?.serviceDate
                        ? new Date(getSingleQoutaion.quotation.serviceDate)
                            .toISOString()
                            .split("T")[0]
                        : "",
                    }}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Quotation No.
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="quotationNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quotationNo}
                                readOnly={true}
                                disabled={isView}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.startDate
                                  ? errors.startDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Quotes Start Date
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Start Date"
                                name="startDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.startDate}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startDate && errors.startDate}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.endDate
                                  ? errors.endDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Quotes Expiry Date{" "}
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="endDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endDate}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endDate && errors.endDate}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <label className="text-label">
                              Quotation Status
                            </label>

                            <Select
                              maxMenuHeight={150}
                              menuPlacement="auto"
                              id="val-quotation-status"
                              name="stage"
                              // onChange={handleChange}
                              onChange={(selectedOption) => {
                                handleChange({
                                  target: {
                                    name: "stage",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={quotationStatusList.find(
                                (option) => option.value === values.stage
                              )}
                              options={quotationStatusList}
                              isDisabled={
                                isView || revise || values.stage === "Revise"
                              }
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="stage" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="text-label">Customer</label>
                            <input
                              type="text"
                              className="form-control"
                              id="val-username1"
                              placeholder=""
                              name="customer"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                selectLead
                                  ? selectLead?.customer?.firstname +
                                    " " +
                                    selectLead?.customer?.lastname
                                  : getSingleQoutaion?.quotation?.customer
                                      ?.firstname +
                                    " " +
                                    getSingleQoutaion?.quotation?.customer
                                      ?.lastname
                              }
                              disabled={true}
                            />

                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="leadId" />
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Subject"
                                name="quotaion"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quotaion}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.quotaion && errors.quotaion}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Phone Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.TRN
                                  ? errors.TRN
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">TRN</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter TRN No"
                                name="TRN"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("TRN", data);
                                }}
                                onBlur={handleBlur}
                                value={values.TRN}
                                disabled={
                                  isView ||
                                  getSingleQoutaion?.quotation?.customer?.TRN
                                }
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.TRN && errors.TRN}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {getSingleQoutaion?.quotation?.lead?.projectId ==
                            null && (
                            <div className="form-group mb-3 col-3">
                              <label className="text-label">
                                Preferred Time
                              </label>

                              <input
                                type="time"
                                className="form-control"
                                name="preferred_Time"
                                id="time"
                                onChange={handleChange}
                                value={values?.preferred_Time}
                                disabled={!revise}
                              />

                              <div className="invalid-feedback animated fadeInUp">
                                <ErrorMessage name="stage" />
                              </div>
                            </div>
                          )}

                          {getSingleQoutaion?.quotation?.lead?.projectId ==
                            null && (
                            <div className="col-3">
                              <div
                                className={`form-group mb-3 ${
                                  values.serviceDate
                                    ? errors.serviceDate
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Service Date
                                </label>
                                <input
                                  type="Date"
                                  className="form-control"
                                  id="val-username1"
                                  placeholder="Select Date"
                                  name="serviceDate"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.serviceDate}
                                  disabled={!revise}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.serviceDate && errors.serviceDate}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-3">
                            <div
                              className={`form-group mb-3 ${
                                values.salesTaxs
                                  ? errors.salesTaxs
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">VAT Tax %</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a VAT Tax"
                                name="salesTaxs"
                                disabled={true}
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("salesTaxs", data);
                                  setSubServiceTotalDetailPrice(
                                    (prevState) => ({
                                      ...prevState,
                                      totalTax: data,
                                    })
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values.salesTaxs + " %"}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.salesTaxs && errors.salesTaxs}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Quotation Details
                              </label>

                              <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={handleContentChange}
                              />
                            </div>
                          </div>
                        </div>

                        <Alert variasnt="primary">
                          <strong>{"Service Information"}</strong>
                        </Alert>
                        <div className="row">
                          <div className="col">
                            <div className="overflow-x-auto">
                              <table className="table">
                                <thead className="thead-border">
                                  <tr>
                                    <th>Service Name</th>
                                    <th>Price</th>
                                    {getSingleQoutaion?.quotation?.lead
                                      ?.leadId &&
                                      getSingleQoutaion?.quotation?.lead
                                        ?.projectId && <th>Frequency</th>}

                                    <th>Quantity</th>
                                    <th>Discount</th>

                                    <th>SubTotal</th>

                                    {!isView && <th>Action</th>}
                                  </tr>
                                </thead>

                                <tbody>
                                  {items?.map((item) => (
                                    <>
                                      <tr key={item.id} className="item">
                                        <td style={{ width: "400px" }}>
                                          <Select
                                            options={subServices?.services
                                              ?.filter(
                                                (subservice) =>
                                                  !items.some(
                                                    (i) =>
                                                      i?.subService
                                                        ?.serviceId ===
                                                      subservice.serviceId
                                                  )
                                              ) // Filter out already selected subservices
                                              ?.map((subservice) => ({
                                                value: subservice.serviceId,
                                                label: subservice.name,
                                              }))}
                                            onChange={(selectedOption) =>
                                              handleSubServiceNameChange(
                                                selectedOption,
                                                item.id
                                              )
                                            }
                                            value={{
                                              value:
                                                item?.subService?.serviceId,
                                              label: item?.subService?.name,
                                            }} // Set the default value
                                            placeholder="Select SubService Name"
                                            isDisabled={isView}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Price"
                                            value={item?.startRangePrice} // Set the value of price from item state
                                            disabled // Disable editing of price since it's auto-filled
                                          />
                                        </td>

                                        {getSingleQoutaion?.quotation?.lead
                                          ?.leadId &&
                                          getSingleQoutaion?.quotation?.lead
                                            ?.projectId && (
                                            <td>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Frequency"
                                                value={item?.oneTaskQuantity}
                                                onChange={(event) => {
                                                  const newValue = event.target
                                                    ?.value
                                                    ? Math.abs(
                                                        Math.floor(
                                                          Number(
                                                            event.target.value
                                                          )
                                                        )
                                                      )
                                                    : "";

                                                  const updatedItem = {
                                                    ...item,
                                                    oneTaskQuantity: newValue,
                                                  };

                                                  const updatedItems =
                                                    items.map((i) =>
                                                      i.id === item.id
                                                        ? updatedItem
                                                        : i
                                                    );
                                                  setItems(updatedItems);
                                                }}
                                              />
                                            </td>
                                          )}

                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="No of Service"
                                            disabled={isView}
                                            value={item?.quantity}
                                            onChange={(event) => {
                                              const newQuantity = event.target
                                                ?.value
                                                ? Math.abs(
                                                    Math.floor(
                                                      Number(event.target.value)
                                                    )
                                                  )
                                                : "";
                                              const newSubTotal =
                                                item?.startRangePrice *
                                                newQuantity
                                                  ? item?.startRangePrice *
                                                    newQuantity
                                                  : 0;

                                              const updatedItem = {
                                                ...item,
                                                quantity: newQuantity,
                                                subTotal: newSubTotal,
                                              };

                                              const updatedItems = items.map(
                                                (i) =>
                                                  i.id === item.id
                                                    ? updatedItem
                                                    : i
                                              );
                                              setItems(updatedItems);
                                            }}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Discount"
                                            value={item?.discount}
                                            onChange={(event) => {
                                              const newDiscount = event.target
                                                ?.value
                                                ? Math.abs(
                                                    Math.floor(
                                                      Number(event.target.value)
                                                    )
                                                  )
                                                : "";

                                              const updatedItem = {
                                                ...item,
                                                discount: newDiscount,
                                              };

                                              const updatedItems = items.map(
                                                (i) =>
                                                  i.id === item.id
                                                    ? updatedItem
                                                    : i
                                              );
                                              setItems(updatedItems);
                                            }}
                                          />
                                        </td>

                                        <td>
                                          {/* {item?.subTotal} */}

                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="SubTotal"
                                            value={item?.subTotal}
                                            onChange={(event) => {
                                              const newSubtotal = event.target
                                                ?.value
                                                ? Math.abs(
                                                    Math.floor(
                                                      Number(event.target.value)
                                                    )
                                                  )
                                                : "";

                                              const updatedItem = {
                                                ...item,
                                                subTotal: newSubtotal,
                                              };

                                              const updatedItems = items.map(
                                                (i) =>
                                                  i.id === item.id
                                                    ? updatedItem
                                                    : i
                                              );
                                              setItems(updatedItems);
                                            }}
                                          />
                                        </td>
                                        {!isView && (
                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() =>
                                                deleteItem(item.id, item)
                                              }
                                            >
                                              <i className="bi bi-trash"></i>{" "}
                                              Delete
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td colspan="7">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Description"
                                            value={item?.detail}
                                            onChange={(event) => {
                                              const newDiscount =
                                                event.target?.value;

                                              const updatedItem = {
                                                ...item,
                                                detail: newDiscount,
                                              };

                                              const updatedItems = items.map(
                                                (i) =>
                                                  i.id === item.id
                                                    ? updatedItem
                                                    : i
                                              );
                                              setItems(updatedItems);
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>

                                {disableButton && (
                                  <tbody>
                                    <tr>
                                      <td colspan="5">
                                        <p
                                          style={{
                                            color: "red",
                                            fontWeight: "bold",
                                            // fontSize: "16px",
                                          }}
                                        >
                                          submit button is disabled due to error
                                          in the service qunatity and price
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                                {!isView && (
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary 1px mt-2 dotted"
                                    onClick={addItem}
                                  >
                                    <i className="fa-solid fa-plus me-2"></i>{" "}
                                    Add Item
                                  </button>
                                )}
                                <tbody
                                  class="before:block before:h-3"
                                  id="totalAmount"
                                >
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Sub Total</td>

                                    <td class="">
                                      {subServiceTotalDetailPrice?.subtotal} USD
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Discount</td>

                                    <td class="">
                                      {
                                        subServiceTotalDetailPrice?.EstimatedDiscounts
                                      }{" "}
                                      USD
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Amount</td>
                                    <td class="">
                                      {
                                        subServiceTotalDetailPrice?.beforeDiscountSubtotal
                                      }{" "}
                                      USD
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">VAT</td>
                                    <td class="">
                                      {subServiceTotalDetailPrice?.EstimatedTax?.toFixed(
                                        2
                                      )}{" "}
                                      USD
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Total Amount</td>
                                    <td class="">
                                      {" "}
                                      {subServiceTotalDetailPrice?.total?.toFixed(
                                        2
                                      )}{" "}
                                      USD
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        {isView == null && revise == false && (
                          <div className="text-right col-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting || isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        )}

                        {revise && (
                          <div className="text-right col-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting || isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
