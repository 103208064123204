import { apiSlice } from "../../../../app/api/apiSlice";

export const expenseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExpense: builder.query({
      query: () => "finance/expense",
      keepUnusedDataFor: 5,
    }),
    getExpenseByYear: builder.query({
      query: ({ year }) => `finance/expense-year?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getExpenseByFromAndTwo: builder.query({
      query: ({ from, to }) =>
        `finance/expenseByFromAndTo?from=${from}&to=${to}`,
      keepUnusedDataFor: 5,
    }),
    getIncomeByYear: builder.query({
      query: ({ year }) => `finance/income-year?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getSingleExpense: builder.query({
      query: (id) => `finance/expense/${id}`,
    }),
    postExpense: builder.mutation({
      query: (newExpense) => ({
        url: "finance/expense",
        method: "POST",
        body: newExpense,
      }),
    }),
    putExpense: builder.mutation({
      query: ({ id, updatedExpense }) => ({
        url: `finance/expense/${id}`,
        method: "PUT",
        body: updatedExpense,
      }),
    }),
  }),
});

export const {
  useGetExpenseQuery,
  useGetExpenseByYearQuery,
  useGetExpenseByFromAndTwoQuery,
  useGetIncomeByYearQuery,
  useGetSingleExpenseQuery,
  usePostExpenseMutation,
  usePutExpenseMutation,
} = expenseApiSlice;
