// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import {
  useGetSingleSubServiceQuery,
  usePutSubServiceMutation,
} from "../../../../features/subservice/subserviceApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import { useParams } from "react-router-dom";

import "react-tagsinput/react-tagsinput.css";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("SubService Name is required"),
  serviceId: Yup.object().shape({
    value: Yup.string().required("service is required"),
    label: Yup.string().required("service is required"),
  }),
  startRangePrice: Yup.number()
    .positive("Start range price must be positive")
    .nullable(),
  endRangePrice: Yup.number()
    .positive("End range price must be positive")
    .nullable(),
  totalPrice: Yup.number().positive("Total price must be positive").nullable(),
  tags: Yup.array(),
  description: Yup.string(),
});

const Edit = () => {
  const navigate = useNavigate();
  const { data: services = [] } = useGetServicesQuery();
  const { subServiceId } = useParams();
  const { data: singleService, refetch } =
    useGetSingleSubServiceQuery(subServiceId);
  const [isView, setIsView] = useState(false);
  useEffect(() => {
    refetch();
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);
  const [putSubService, { isLoading }] = usePutSubServiceMutation();

  const handleSubmitted = async (updatedService) => {
    updatedService.serviceId = updatedService?.serviceId?.value;
    try {
      const result = await putSubService({ id: subServiceId, updatedService });

      notifySuccess(result?.data?.message);
      navigate("subService");
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {isView ? "View SubService" : "Edit SubService"}
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: singleService?.subServices?.name,
                      tags: singleService?.subServices?.tags
                        ?.split(",")
                        .map((tag) => tag.trim()),
                      description: singleService?.subServices?.description,
                      serviceId: {
                        value: singleService?.subServices?.serviceId || "",
                        label: singleService?.subServices?.service?.name || "",
                      },

                      startRangePrice:
                        singleService?.subServices?.startRangePrice || "",

                      endRangePrice:
                        singleService?.subServices?.endRangePrice || "",

                      totalPrice: singleService?.subServices?.totalPrice || "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-xl-4">
                            <label className="text-label">Services</label>

                            <Select
                              options={services?.services?.map((service) => ({
                                value: service.serviceId,
                                label: service.name,
                              }))}
                              name="serviceId"
                              onChange={(selectedOption) =>
                                setFieldValue("serviceId", selectedOption)
                              }
                              onBlur={handleBlur}
                              value={values?.serviceId}
                              isDisabled={isView}
                            />

                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="serviceId" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                SubService Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a service name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.tags
                                  ? errors.tags
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Tag List</label>
                              <TagsInput
                                value={values.tags || []}
                                className={`form-control ${
                                  isView ? "bg-body" : ""
                                }`}
                                onChange={(tags) => setFieldValue("tags", tags)}
                                disabled={isView}
                              />
                              <div
                                id="val-firstname-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{
                                  display:
                                    touched.tags && errors.tags
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {errors.tags}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-xl-4">
                            <div
                              className={`form-group mb-3 ${
                                values.startRangePrice
                                  ? errors.startRangePrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Start Range Price
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-startRangePrice"
                                placeholder="Enter start range price"
                                name="startRangePrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.startRangePrice}
                                disabled={isView}
                              />
                              <div
                                id="val-startRangePrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startRangePrice &&
                                  errors.startRangePrice}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-xl-4">
                            <div
                              className={`form-group mb-3 ${
                                values.endRangePrice
                                  ? errors.endRangePrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                End Range Price
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-endRangePrice"
                                placeholder="Enter end range price"
                                name="endRangePrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endRangePrice}
                                disabled={isView}
                              />
                              <div
                                id="val-endRangePrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endRangePrice && errors.endRangePrice}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-xl-4">
                            <div
                              className={`form-group mb-3 ${
                                values.totalPrice
                                  ? errors.totalPrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Final Price</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-totalPrice"
                                placeholder="Enter Final price"
                                name="totalPrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.totalPrice}
                                disabled={isView}
                              />
                              <div
                                id="val-totalPrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.totalPrice && errors.totalPrice}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">Description</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Description"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                disabled={isView}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.description && errors.description}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!isView && (
                          <div className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting}
                            >
                              Update
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
