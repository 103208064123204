import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage, handleChange } from "formik";
import { usePostAccountMutation } from "../../../../../features/financialMangement/account/accountApiSlice";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useUpdateAccountMutation } from "../../../../../features/financialMangement/account/accountApiSlice";
import * as Yup from "yup";
import Select from "react-select";

const accountTypeOptions = [
  { label: "Assets", value: "Current assets" },
  { label: "Bank Account", value: "Bank Account" },
  { label: "Liabilities", value: "liability" },
  { label: "Equity", value: "equity" },
  { label: "Income", value: "income" },
  { label: "Expenses", value: "expense" },
  { label: "Account Receivable A/R", value: "Account Receivable A/R" },
  { label: "Cash & Equivalents", value: "Cash and equivalents" },
  { label: "Inventory", value: "Inventory" },
  { label: "Fixed Assets", value: "Fixed assets" },
  { label: "Account Payable A/P", value: "Account Payable A/P" },
  { label: "Long-term Debt", value: "Long-term debt" },
  { label: "Revenue", value: "Revenue" },
  { label: "Cost of Goods Sold", value: "Cost of goods sold" },
  { label: "Operating Expenses", value: "Operating expenses" },
  { label: "Other Income", value: "Other income" },
  { label: "Other Expenses", value: "Other expenses" },
  { label: "Credit Card", value: "Credit Card" },
  { label: "Other Current Asset", value: "Other Current Asset" },
  { label: "Other Current Liability", value: "Other Current Liability" },
  { label: "Other Asset", value: "Other Asset" },
  { label: "Other Liability", value: "Other Liability" },
];

// Current Assets
const currentAssetsOptions = [
  { label: "Office Equipment", value: "office equipment" },
  { label: "Computer Equipment", value: "computer equipment" },
  { label: "Buildings", value: "buildings" },
  { label: "Machinery", value: "machinery" },
  { label: "Goodwill", value: "goodwill" },
  { label: "Bank Account", value: "bank account" },
  { label: "Petty Cash", value: "petty cash" },
  { label: "Account Receivable", value: "account receivable" },
  { label: "Stock", value: "stock" },
];

// Liability
const liabilityOptions = [
  { label: "Credit Card", value: "credit card" },
  { label: "Accounts Payable", value: "accounts payable" },
  { label: "Income Tax Payable", value: "income tax payable" },
  { label: "Payroll Taxes", value: "payroll taxes" },
  { label: "Bank Loan", value: "bank loan" },
  { label: "Overdraft", value: "overdraft" },
];

// Equity
const equityOptions = [
  { label: "Capital", value: "capital" },
  { label: "Drawings", value: "drawings" },
  { label: "Current Year Earnings", value: "current year earnings" },
  { label: "Retained Earnings", value: "retained earnings" },
];

// Income
const incomeOptions = [
  { label: "Sales", value: "sales" },
  { label: "Consultancy", value: "consultancy" },
  { label: "Shipping", value: "shipping" },
  { label: "Other Income", value: "other income" },
];

// Expense
const expenseOptions = [
  { label: "Advertising", value: "advertising" },
  { label: "Bank Fees", value: "bank fees" },
  { label: "Payroll Expenses", value: "payroll expenses" },
  { label: "Professional Fees", value: "professional fees" },
  { label: "Consultancy", value: "consultancy" },
  { label: "Depreciation", value: "depreciation" },
  { label: "Rent", value: "rent" },
  { label: "Subscriptions", value: "subscriptions" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Travel", value: "travel" },
  { label: "Insurance", value: "insurance" },
  { label: "Utilities", value: "utilities" },
  { label: "Office Expenses", value: "office expenses" },
  { label: "Vehicle Expenses", value: "vehicle expenses" },
];

const CreateForm = (props) => {
  const [postAccount, { isLoading }] = usePostAccountMutation();
  const initialValues = {
    type: props?.initialValues?.type || "",
    detailType: props?.initialValues?.detailType || "",
    name: props?.initialValues?.name || "",
    description: props?.initialValues?.description || "",
    isSubAccount: props?.initialValues?.isSubAccount || false,
    parentAccountId: props?.initialValues?.parentAccountId || "",
    balance: props?.initialValues?.balance || "",
    asOf: props?.initialValues?.asOf
      ? new Date(props.initialValues.asOf).toISOString().split("T")[0]
      : "",
    isDeleted: props?.initialValues?.isDeleted || false,
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    detailType: Yup.string().optional("Detail Type is optional"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().optional("Description is optional"),
    isSubAccount: Yup.boolean(),
    parentAccountId: Yup.string().optional(),
    balance: Yup.string().required("Balance is required"),
    asOf: Yup.string().required("As Of date is required"),
    isDeleted: Yup.boolean().required("Is Deleted is required"),
  });
  const [updateInvoice, { isLoading: updateLoading }] =
    useUpdateAccountMutation();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            try {
              if (props.isEdit) {
                const updatedAccount = { ...values };

                updatedAccount.accountId = props.initialValues?.accountId;
                try {
                  const updateResult = updateInvoice({
                    id: props?.initialValues?.accountId,
                    updatedAccount,
                  });
                  notifySuccess("Account Updated Successfully");
                  props.onHide();
                  return;
                } catch (error) {
                  notifyError(error.message);
                  return;
                }
              }

              const result = await postAccount(values);

              notifySuccess(result?.data?.message);
              props.onHide();
            } catch (error) {
              notifyError(error.message);
            }
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="col">
                  <div className="form-group mb-3">
                    <label className="text-label">Account Type</label>
                    <Select
                      maxMenuHeight={250}
                      classNamePrefix="react-select"
                      name="type"
                      onChange={(selectedOption) => {
                        setFieldValue("type", selectedOption.value);
                        setFieldValue("detailType", "");
                      }}
                      value={accountTypeOptions.find(
                        (option) => option.value === values.type
                      )}
                      options={accountTypeOptions}
                      isDisabled={props.isView}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    disabled={props.isView}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="detailType" className="form-label">
                    Detail Type
                  </label>
                  <Select
                    classNamePrefix="react-select"
                    name="type"
                    onChange={(selectedOption) =>
                      setFieldValue("detailType", selectedOption.value)
                    }
                    value={{
                      label: values?.detailType,
                      value: values?.detailType,
                    }}
                    options={
                      values?.type == "Current assets"
                        ? currentAssetsOptions?.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          }))
                        : values?.type == "liability"
                        ? liabilityOptions?.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          }))
                        : values?.type == "equity"
                        ? equityOptions?.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          }))
                        : values?.type == "income"
                        ? incomeOptions?.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          }))
                        : values?.type == "expense"
                        ? expenseOptions?.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          }))
                        : []
                    }
                    isDisabled={
                      props.isView ||
                      ![
                        "expense",
                        "income",
                        "equity",
                        "liability",
                        "Current assets",
                      ]?.includes(values?.type)
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <Field
                    type="text"
                    id="description"
                    name="description"
                    className="form-control"
                    disabled={props.isView}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div
                  className="col-md-6  p-3"
                  style={{ backgroundColor: "#A8A8A8" }}
                >
                  <p style={{ color: "black" }}>
                    Use <b>Bank</b> accounts to track all your current activity,
                    including debit card transactions Select the appropriate
                    detail type for this account. If you're unsure, choose the
                    closest match.
                  </p>
                </div>
                <div className="col-md-6" style={{ padding: "10px" }}>
                  <div className="mb-3 form-check">
                    <Field
                      type="checkbox"
                      id="isSubAccount"
                      name="isSubAccount"
                      className="form-check-input"
                      disabled={props.isView}
                    />
                    <label htmlFor="isSubAccount" className="form-check-label">
                      Is sub-account
                    </label>
                    {values.isSubAccount && (
                      <div className="mt-2 col-md-6">
                        {/* <label htmlFor="parentAccount" className="form-label">Enter parent account</label> */}
                        <Field
                          as="select"
                          id="parentAccountId"
                          name="parentAccountId"
                          className="form-select"
                          disabled={props.isView}
                        >
                          <option value="">Select parent account</option>
                          {/* Add parent account options dynamically here */}
                          {props?.account
                            ?.filter((row) => {
                              return row?.type == values?.type
                                ? values?.type
                                : false;
                            })
                            ?.map((account) => {
                              if (
                                !account?.isSubAccount &&
                                account?.accountId !==
                                  props?.initialValues?.accountId
                              ) {
                                return (
                                  <option
                                    key={account?.accountId}
                                    value={account?.accountId}
                                  >
                                    {account?.name}
                                  </option>
                                );
                              }
                              return null;
                            })}
                        </Field>
                        <ErrorMessage
                          name="parentAccountId"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    )}
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="balance" className="form-label">
                        Balance
                      </label>
                      <Field
                        type="number"
                        id="balance"
                        name="balance"
                        className="form-control"
                        disabled={props.isView}
                      />
                      <ErrorMessage
                        name="balance"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="asOf" className="form-label">
                        As of
                      </label>
                      <Field
                        type="date"
                        id="asOf"
                        name="asOf"
                        className="form-control"
                        disabled={props.isView}
                      />
                      <ErrorMessage
                        name="asOf"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-5">
                <Button variant="secondary" onClick={props.onHide}>
                  Cancel
                </Button>
                <div>
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    disabled={isLoading || updateLoading}
                  >
                    {isLoading || updateLoading ? (
                      <>
                        <div
                          className="spinner-border text-light"
                          role="status"
                        ></div>
                      </>
                    ) : (
                      "Save and Close"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateForm;
