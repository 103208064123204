import React, { Fragment, useState, useEffect } from "react";
import { Formik } from "formik";
import Select from "react-select";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import { useGetTrailBalaceByYearQuery } from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const data = [
  { account: "Sales", debit: "", credit: "4000" },
  { account: "Wages", debit: "1000", credit: "" },
  { account: "Rent", debit: "750", credit: "" },
  { account: "Miscellaneous expenses", debit: "300", credit: "" },
  { account: "Business bank account", debit: "1000", credit: "" },
  { account: "Accounts receivable", debit: "450", credit: "" },
  { account: "Inventory", debit: "3000", credit: "" },
  { account: "Accounts payable", debit: "", credit: "500" },
  { account: "Bank loan", debit: "", credit: "1000" },
  { account: "Equity - capital contribution", debit: "", credit: "1000" },
];

function mergeEntries(entries) {
  const mergedEntries = {};

  entries.forEach((entry) => {
    const { accountId, debits, credits } = entry;

    if (!mergedEntries[accountId]) {
      mergedEntries[accountId] = { ...entry }; // Clone the entry
    } else {
      // Sum debits and credits
      mergedEntries[accountId].debits = (
        parseFloat(mergedEntries[accountId].debits || 0) +
        parseFloat(debits || 0)
      ).toString();
      mergedEntries[accountId].credits = (
        parseFloat(mergedEntries[accountId].credits || 0) +
        parseFloat(credits || 0)
      ).toString();
    }
  });

  return Object.values(mergedEntries); // Convert the object back to an array
}

const TrialBalance = () => {
  const [year, setYear] = useState("");
  const boldBlackStyle = { fontWeight: "bold", color: "black" };
  const blackTextStyle = { color: "#009688" };

  const { data: allEnteries, refetch } = useGetTrailBalaceByYearQuery(
    { year: year },
    { skip: !year }
  );

  const [records, setRecords] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  useEffect(() => {
    if (year) {
      refetch();
    }
  }, [year, refetch]);

  useEffect(() => {
    if (allEnteries) {
      const updatedData = mergeEntries(allEnteries);
      setRecords(updatedData);
      const debit = updatedData?.reduce((sum, data) => {
        return (parseFloat(sum) + parseFloat(data?.debits ?  data?.debits  : 0));
      }, 0);

      const credit = updatedData?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.credits ? data?.credits : 0 );
      }, 0);

      setTotalDebit(debit);
      setTotalCredit(credit);
    }
  }, [allEnteries]);
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Trial_Balance_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{color:"#009688"}}>Trial Balance Statement</h4>
                <button
  onClick={downloadPDF}
   style={{ marginBottom: "20px", background: "transparent", border: "none", padding: 0 }}
>
  <MdOutlineFileDownload style={{ color: "inherit" , fontSize:"20px" }} /> 
</button>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      supplierId: "",
                      year: "",
                    }}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    // onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Select Year</label>
                              <Select
                                options={[...Array(4)].map((_, i) => {
                                  const year = new Date().getFullYear() - i;
                                  return {
                                    value: year,
                                    label: year.toString(),
                                  };
                                })}
                                name="year"
                                onChange={(selectedOption) => {
                                  setFieldValue("year", selectedOption.value);
                                  setYear(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.year && touched.year && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.year}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button> */}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        {year && (
            <div className="row">
            <div className="col-lg-12">
              <div className="card">
              <div id="report-content">
                <div className="card-header">
                 
                  <div className="container">
                    <div className="text-start mb-3" style={{ padding: '10px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <h2 className="mb-1" style={blackTextStyle}>
                          Aifa Services
                        </h2>
                        <img
                          src={aifaLogo}
                          alt="logo"
                          style={{
                            width: "130px",
                          }}
                        />
                      </div>
                      <div style={{ backgroundColor: 'rgb(52, 152, 219)', color: 'white', padding: '10px', textAlign: 'center' }}>
                        <h2 className="mb-2" style={{ color: 'white' }}>
                          Trial Balance
                        </h2>
                        <h4 className="mb-3" style={{ color: 'white' }}>
                          As at Year {year}
                        </h4>
                      </div>
                    </div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ ...boldBlackStyle, color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>Accounts</th>
                          <th style={{ ...boldBlackStyle, color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>Debit</th>
                          <th style={{ ...boldBlackStyle, color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>Credit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={
                                item.account === "TOTAL"
                                  ? boldBlackStyle
                                  : blackTextStyle
                              }
                            >
                              {item.Account?.name}
                            </td>
                            <td
                              style={
                                item.debit === "TOTAL"
                                  ? boldBlackStyle
                                  : blackTextStyle
                              }
                            >
                              {item?.debits ? item?.debits : 0}
                            </td>
                            <td
                              style={
                                item.credit === "TOTAL"
                                  ? boldBlackStyle
                                  : blackTextStyle
                              }
                            >
                              {item?.credits ? item?.credits : 0}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <th style={{ ...boldBlackStyle, color: '#009688' }}>TOTAL</th>
                          <th style={{ ...boldBlackStyle, color: '#009688' }}>USD {totalDebit}</th>
                          <th style={{ ...boldBlackStyle, color: '#009688' }}>USD {totalCredit}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        )}
        
      </div>
    </Fragment>
  );
};

export default TrialBalance;
