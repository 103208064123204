import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { Formik } from "formik";
import { useGetPurchaseQuery } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
import { useGetCustomerQuery } from "../../../../../features/customer/customerApiSlice";
import { useGetAllInvoicesQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const SaleRegister = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [filterRecords, setFilterRecords] = useState([]);

  const { data: customers } = useGetCustomerQuery();
  const { data: invoices } = useGetAllInvoicesQuery();

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAR, setTotalAR] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Sales_Register_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  useEffect(() => {
    if (invoices?.invoices) {
      const filteredInvoices = invoices?.invoices?.filter((invoice) => {
        const invoiceDate = new Date(invoice?.invoiceDate);

        return (
          (!dateFrom || invoiceDate >= new Date(dateFrom)) &&
          (!dateTo || invoiceDate <= new Date(dateTo)) &&
          (!customerId || customerId == invoice?.customerId)
        );
      });

      setFilterRecords(filteredInvoices);

      const totalSum = filteredInvoices?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.totalAmount);
      }, 0);

      const totalAR = filteredInvoices?.reduce((sum, data) => {
        return (
          parseFloat(sum) +
          (parseFloat(data?.totalAmount) -
            (data?.paidAmount ? parseFloat(data?.paidAmount) : 0))
        );
      }, 0);

      const paidAmountSum = filteredInvoices?.reduce((sum, data) => {
        return (
          parseFloat(sum) +
          (data?.paidAmount ? parseFloat(data?.paidAmount) : 0)
        );
      }, 0);

      setTotalPaid(paidAmountSum);

      setTotalAR(totalAR);

      setTotalAmount(totalSum);
    }
  }, [invoices, dateFrom, dateTo, customerId]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ color: "#009688" }}>
                  Sale Register
                </h4>
                <button
                  onClick={downloadPDF}
                  style={{
                    marginRight: "10px",
                    background: "transparent",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <MdOutlineFileDownload
                    style={{ color: "inherit", fontSize: "20px" }}
                  />
                </button>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      fromDate: "",
                      toDate: "",
                    }}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Customer</label>
                              <Select
                                options={customers?.customers?.map(
                                  (customer) => ({
                                    value: customer.customerId,
                                    label: `${customer.firstname} ${customer.lastname}`,
                                  })
                                )}
                                name="customerId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "customerId",
                                    selectedOption.value
                                  );
                                  setCustomerId(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.customerId && touched.customerId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.customerId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date From</label>
                              <input
                                type="date"
                                name="fromDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("fromDate", e.target.value);
                                  setdateFrom(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.fromDate && touched.fromDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.fromDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date To</label>
                              <input
                                type="date"
                                name="toDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("toDate", e.target.value);
                                  setDateTo(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.toDate && touched.toDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.toDate}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <div id="report-content">
                    <div className="row align-items-center">
                      <div className="col-6 text-start">
                        <h1 style={{ color: "#009688" }}>Aifa Services</h1>
                      </div>
                      <div className="col-6 text-end">
                        <img
                          src={aifaLogo}
                          alt="logo"
                          style={{
                            width: "130px",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="card-header mt-2 "
                      style={{
                        backgroundColor: "rgb(52, 152, 219)",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h2
                        className="card-title"
                        style={{
                          color: "#fff",
                          marginBottom: "10px",
                          fontSize: "20px",
                        }}
                      >
                        Sales Register Report
                      </h2>
                      <div style={{ color: "#fff", fontSize: "16px" }}>
                        {`(From ${new Date(dateFrom).toLocaleDateString(
                          "en-GB"
                        )} To ${new Date(dateTo).toLocaleDateString("en-GB")})`}
                      </div>
                    </div>
                    {filterRecords && (
                      <div
                        id="task-tbl_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="empoloyeestbl2"
                          className="table ItemsCheckboxSec  no-footer mb-2 mb-sm-0 mt-3"
                        >
                          <thead>
                            <tr
                              style={{
                                backgroundColor: "rgb(52, 152, 219)",
                                color: "white",
                              }}
                            >
                              <th style={{ borderRight: "2px solid white" }}>
                                Date
                              </th>
                              <th style={{ borderRight: "2px solid white" }}>
                                Invoice Number
                              </th>
                              <th style={{ borderRight: "2px solid white" }}>
                                Customer
                              </th>
                              <th style={{ borderRight: "2px solid white" }}>
                                Total Amount
                              </th>
                              <th style={{ borderRight: "2px solid white" }}>
                                Paid Amount
                              </th>
                              <th style={{ borderRight: "2px solid white" }}>
                                A/R Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody style={{ backgroundColor: "#d9edf7" }}>
                            {filterRecords?.map((order, index) => (
                              <tr key={index}>
                                <td>
                                  {new Date(
                                    order?.invoiceDate
                                  ).toLocaleDateString("en-GB")}
                                </td>
                                <td>{order?.invoiceNo}</td>
                                <td>
                                  {order?.customer?.firstname +
                                    " " +
                                    order?.customer?.lastname}
                                </td>

                                <td>{order?.totalAmount}</td>
                                <td>
                                  {order?.paidAmount ? order?.paidAmount : 0}
                                </td>
                                <td>
                                  {" "}
                                  {parseFloat(order?.totalAmount) -
                                    (order?.paidAmount
                                      ? parseFloat(order?.paidAmount)
                                      : 0)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {filterRecords?.length > 0 && (
                            <tbody>
                              <tr
                                style={{ fontWeight: "bold", color: "#009688" }}
                              >
                                <td></td>
                                <td></td>

                                <td>Total</td>
                                <td>{totalAmount.toFixed(2)}</td>
                                <td>{totalPaid}</td>
                                <td>{totalAR.toFixed(2)}</td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SaleRegister;
