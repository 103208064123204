import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useGetAllReceivePaymentsQuery } from "../../../features/financialMangement/billing/receivePayment/receivePaymentApiSlice";
import { useGetPurchaseBySupplierIdQuery } from "../../../features/financialMangement/purchasePayment/purchasePaymentApiSlice";
function formatDate(dateString) {
  const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    // hour12: true,
  });

  return formattedDate;
}
const TransactionHistory = ({ supplierId }) => {
  const {
    data: receivePayments,
    isLoading,
    isError,
    error,
    refetch: refetchReceivePayments,
  } = useGetPurchaseBySupplierIdQuery(supplierId, {
    skip: !supplierId, // Skip the query if supplierId is not available
  });

  useEffect(() => {
    refetchReceivePayments();
  }, [receivePayments, refetchReceivePayments]);

  return (
    <div>
      <div className="table-responsive active-projects task-table">
        <div id="task-tbl_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="empoloyeestbl2"
            className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Purchase ID</th>
                <th>Payment Date</th>
                <th>Paid Amount</th>
                <th>Purchase Amount</th>
                <th>Method</th>
                <th>reference</th>
              </tr>
            </thead>
            <tbody>
              {receivePayments?.map((entry, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td> {new Date(entry?.purchase?.createdAt)?.getTime()}</td>
                  <td>
                    {entry?.paymentDate
                      ? new Date(entry?.paymentDate).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          // hour: "2-digit",
                          // minute: "2-digit",
                          // second: "2-digit",
                          // hour12: true, // This will format the time in AM/PM format
                        })
                      : "-"}
                  </td>
                  <td>{entry?.amount}</td>
                  <td>{entry?.purchase?.total}</td>
                  <td>{entry?.paymentType}</td>
                  <td>{entry?.reference}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
