import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import { useGetAllVatReportByDatePeriodQuery } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
import { useGetInvoicesByServicesQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
const SaleByServiceReport = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");

  const { data: invoices } = useGetInvoicesByServicesQuery(
    {
      dateTo: dateTo,
      dateFrom: dateFrom,
    },
    { skip: !dateTo || !dateFrom }
  );

  function generateServiceReport(invoices) {
    const report = {};
    let totalQuantity = 0;
    let totalAmount = 0;
    let totalVAT = 0;
    let totalRevenue = 0;

    // Iterate over each invoice and its sub-service list
    invoices?.forEach((invoice) => {
      invoice.subSeriviceList?.forEach((service) => {
        const serviceName = service.subService.name;
        const quantity = parseFloat(service.quantity || 0);
        const netPrice = parseFloat(service.netPrice || 0);
        const vatAmount = parseFloat(service.vatAmount || 0);
        const priceAfterDiscount = parseFloat(service.priceAfterDiscount || 0);
        const totalRevenuePrice = netPrice - vatAmount;

        // Update overall totals
        totalQuantity += quantity;
        totalAmount += priceAfterDiscount;
        totalVAT += vatAmount;
        totalRevenue += totalRevenuePrice;

        // Combine services: sum up quantity and amount for duplicate services
        if (report[serviceName]) {
          report[serviceName].quantity += quantity;
          report[serviceName].vatAmount += vatAmount;
          report[serviceName].priceAfterDiscount += priceAfterDiscount;
          report[serviceName].netPrice += netPrice;
        } else {
          // Initialize service entry
          report[serviceName] = {
            quantity: quantity,
            vatAmount: vatAmount,
            priceAfterDiscount: priceAfterDiscount,
            netPrice: netPrice,
          };
        }
      });
    });

    // Return combined report along with total quantity, amount, VAT, and revenue
    return {
      totalQuantity,
      totalVAT,
      totalPriceAfterDiscount: totalAmount,
      totalRevenue,
      services: Object.keys(report).map((serviceName) => ({
        ...report[serviceName],
        serviceName: serviceName,
      })),
    };
  }

  // Generate the service report
  const combinedReport = generateServiceReport(invoices);

  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Sale_By_Service_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title" style={{ color: " #009688" }}>
                Sale By Service Report{" "}
              </h3>
              <button
                onClick={downloadPDF}
                style={{
                  background: "transparent",
                  border: "none",
                  padding: 0,
                }}
              >
                <MdOutlineFileDownload
                  style={{ color: "inherit", fontSize: "20px" }}
                />
              </button>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{
                    fromDate: "",
                    toDate: "",
                  }}
                  enableReinitialize={true}
                  // validationSchema={loginSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Date From</label>
                            <input
                              type="date"
                              name="fromDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("fromDate", e.target.value);
                                setdateFrom(e.target.value);
                              }}
                              onBlur={handleBlur}
                              max={new Date()?.toISOString()?.slice(0, 10)}
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.fromDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Date To</label>
                            <input
                              type="date"
                              max={new Date()?.toISOString()?.slice(0, 10)}
                              name="toDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("toDate", e.target.value);
                                setDateTo(e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.toDate}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>

              {dateFrom && dateTo && (
                <div id="report-content">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h2
                      className="card-title"
                      style={{
                        color: "#fff",
                        marginBottom: "10px",
                        fontSize: "20px",
                      }}
                    >
                      Sale By Service Report
                    </h2>
                    <img
                      src={aifaLogo}
                      alt="logo"
                      style={{
                        width: "130px",
                        marginLeft: "auto",
                        marginLeft: "20px",
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card mt-3">
                        <div
                          className="card-header "
                          style={{
                            backgroundColor: "rgb(52, 152, 219)",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h2
                            className="card-title"
                            style={{
                              color: "#fff",
                              marginBottom: "10px",
                              fontSize: "20px",
                            }}
                          >
                            Sale By Service Report
                          </h2>
                          <div style={{ color: "#fff", fontSize: "16px" }}>
                            {`(From ${new Date(dateFrom).toLocaleDateString(
                              "en-GB"
                            )} To ${new Date(dateTo).toLocaleDateString(
                              "en-GB"
                            )})`}
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="basic-form">
                            <div
                              id="task-tbl_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="empoloyeestbl2"
                                className="table ItemsCheckboxSec no-footer mb-2 mb-sm-0"
                                style={{ color: "black" }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      #
                                    </th>

                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Service Name
                                    </th>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Quantity
                                    </th>

                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Amount
                                    </th>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      VAT Amount
                                    </th>

                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Revenue
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {combinedReport?.services?.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>

                                        <td>{item?.serviceName}</td>
                                        <td>{item?.quantity}</td>

                                        <td>{item?.netPrice}</td>
                                        <td>{item?.vatAmount}</td>
                                        <td>{item?.priceAfterDiscount}</td>
                                      </tr>
                                    )
                                  )}
                                  <tr>
                                    <td colSpan="1" className="text-left">
                                      <b style={{ color: "#009688" }}>Total</b>
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <b
                                        style={{ color: "#009688" }}
                                      >{`${combinedReport?.totalQuantity}`}</b>
                                    </td>
                                    <td>
                                      {" "}
                                      <b style={{ color: "#009688" }}>{`USD ${(
                                        combinedReport?.totalPriceAfterDiscount +
                                        combinedReport?.totalVAT
                                      )?.toFixed(2)}`}</b>
                                    </td>

                                    <td>
                                      {" "}
                                      <b
                                        style={{ color: "#009688" }}
                                      >{`USD ${combinedReport?.totalVAT?.toFixed(
                                        2
                                      )}`}</b>
                                    </td>

                                    <td>
                                      {" "}
                                      <b
                                        style={{ color: "#009688" }}
                                      >{`USD ${combinedReport?.totalPriceAfterDiscount?.toFixed(
                                        2
                                      )}`}</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleByServiceReport;
