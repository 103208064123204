import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { Dropdown } from "react-bootstrap";
import { IoMdEye } from "react-icons/io";
import { FaCircleDollarToSlot } from "react-icons/fa6";
import { usePutPurchaseOrderMutation } from "../../../../../features/financialMangement/purchaseOrder/purchaseOrderApiSlice";
import { useGetPurchaseQuery } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
import { RiExchangeDollarFill } from "react-icons/ri";
import CreateForm from "./addPaymentModal";
import Payments from "./viewPaymentsModal";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";

const PurchaseOrder = () => {
  const [putPurchaseOrder, { isLoading, isError, error }] =
    usePutPurchaseOrderMutation();
  const { data: purchases = [], refetch } = useGetPurchaseQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [paymentHisoryModal, setPaymentHisoryModal] = useState(false);
  const [id, setId] = useState("");
  const [data, SetData] = useState("");
  const [search, setSearch] = useState("");

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  useEffect(() => {
    refetch();
  }, [modal, paymentHisoryModal]);

  const filterPurchaseList = () => {
    return purchases.filter((purchase) => {
      const searchTerm = search.toLowerCase();
      const supplierName =
        purchase?.purchaseOrder?.Supplier?.name?.toLowerCase() || "";
      const trn = purchase?.purchaseOrder?.Supplier?.TRN?.toLowerCase() || "";
      const warehouseName =
        purchase?.purchaseOrder?.warehouse?.name?.toLowerCase() || "";
      const total = purchase?.total?.toString().toLowerCase() || "";

      return (
        supplierName.includes(searchTerm) ||
        trn.includes(searchTerm) ||
        warehouseName.includes(searchTerm) ||
        total.includes(searchTerm)
      );
    });
  };

  const filteredPurchases = filterPurchaseList();
  const records = filteredPurchases.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredPurchases.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleDelete = (purchaseOrder) => {
    let updatedPurchaseOrder = { ...purchaseOrder };
    updatedPurchaseOrder.isDeleted = true;
    updatedPurchaseOrder.updatedBy = user?.userId;

    putPurchaseOrder({
      id: updatedPurchaseOrder?.purchaseOrderId,
      updatedPurchaseOrder,
    })
      .unwrap()
      .then((response) => {
        notifySuccess("Purchase Order deleted successfully");
        refetch();
        navigate("purchase-order");
      })
      .catch((error) => {
        console.error("Error updating service", error);
      });
  };

  const handleView = (purchase) => {
    navigate("view/" + purchase?.purchaseId);
  };

  const handleAction = (order, value) => {
    const updatedPurchaseOrder = { ...order };
    updatedPurchaseOrder.orderStatus = value;
    putPurchaseOrder({
      id: updatedPurchaseOrder?.purchaseOrderId,
      updatedPurchaseOrder,
    }).then(() => {
      refetch();
    });
  };

  const SweetAlert = (purchaseOrder) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(purchaseOrder);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Supplier Name", key: "supplierName" },
    { label: "Supplier TRN", key: "supplierTRN" },
    { label: "Warehouse Name", key: "warehouseName" },
    { label: "Purchase Category", key: "purchaseCategory" },
    { label: "Total Amount (USD)", key: "totalAmount" },
    { label: "Paid Amount (USD)", key: "paidAmount" },
    { label: "Balance (USD)", key: "balance" },
    { label: "Date", key: "date" },
    { label: "Status", key: "status" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredPurchases?.map((purchase, index) => ({
      index: index + 1,
      supplierName: purchase?.purchaseOrder?.Supplier?.name || "",
      supplierTRN: purchase?.purchaseOrder?.Supplier?.TRN || "",
      warehouseName: purchase?.purchaseOrder?.warehouse?.name || "",
      purchaseCategory: purchase?.purchaseOrder?.category?.name || "",
      totalAmount: purchase?.total || "",
      paidAmount: purchase?.paidAmount || "",
      balance: purchase?.paidAmount
        ? purchase?.total - purchase?.paidAmount
        : purchase?.total,
      date: new Date(purchase?.createdAt).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
      status: purchase?.total === purchase?.paidAmount ? "Paid" : "Due",
    })),
    filename: "purchases.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Purchases</h4>

                    <div className="d-flex">
                      {filteredPurchases?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-1"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <div className="mx-2">
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search"
                          className="form-control me-2"
                        />
                      </div>
                      {user?.Role?.permissions?.includes(
                        "purchase-order/create"
                      ) && (
                        <Link
                          to={`/${user?.role?.toLowerCase()}/purchase-order/create`}
                          className="btn btn-primary light btn-sm"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Purchase
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Supplier Name</th>
                          <th>Supplier TRN</th>
                          <th>Warehouse Name</th>
                          <th>Purchase Category</th>
                          <th>Total Amount (USD)</th>
                          <th>Paid Amount (USD)</th>
                          <th>Balance (USD)</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.purchaseOrder?.Supplier?.name}</td>
                            <td>{item?.purchaseOrder?.Supplier?.TRN}</td>
                            <td>{item?.purchaseOrder?.warehouse?.name}</td>
                            <td>{item?.purchaseOrder?.category?.name}</td>
                            <td>{item?.total}</td>
                            <td>{item?.paidAmount ? item?.paidAmount : 0}</td>
                            <td>
                              {item?.paidAmount
                                ? item?.total - item?.paidAmount
                                : item?.total}
                            </td>

                            <td>
                              {new Date(item?.createdAt).toLocaleString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true, // This will format the time in AM/PM format
                                }
                              )}
                            </td>
                            <td>
                              <button
                                as="div"
                                className={`btn ${
                                  item?.total === item?.paidAmount
                                    ? "btn-success"
                                    : "btn-danger"
                                }`}
                                style={{
                                  pointerEvents: "none",
                                  cursor: "default",
                                }}
                              >
                                {item?.total === item?.paidAmount
                                  ? "Paid"
                                  : "Due"}
                              </button>
                            </td>
                            <td>
                              {/* purchase-order */}
                              {user?.Role?.permissions?.includes(
                                "purchases/view/:purchasesId"
                              ) && (
                                <>
                                  <span
                                    onClick={() => handleView(item)}
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "2px",
                                    }}
                                    title="View Purchase"
                                  >
                                    <IoMdEye />
                                  </span>

                                  <span
                                    onClick={() => {
                                      setPaymentHisoryModal(true);
                                      setId(item?.purchaseId);
                                    }}
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "2px",
                                    }}
                                    title="Payment History"
                                  >
                                    <RiExchangeDollarFill />
                                  </span>
                                </>
                              )}

                              {item.total > item.paidAmount &&
                                user?.Role?.permissions?.includes(
                                  "purchases/edit/:purchasesId"
                                ) && (
                                  <span
                                    onClick={() => {
                                      setModal(true);
                                      SetData(item);
                                    }}
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "2px",
                                    }}
                                    title="Add Payment"
                                  >
                                    <FaCircleDollarToSlot />
                                  </span>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <CreateForm
                      show={modal}
                      onHide={() => setModal(false)}
                      purchaseData={data}
                    />
                    <Payments
                      show={paymentHisoryModal}
                      onHide={() => setPaymentHisoryModal(false)}
                      purchaseId={id}
                    />

                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredPurchases.length < lastIndex
                          ? filteredPurchases.length
                          : lastIndex}{" "}
                        of {filteredPurchases.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrder;
