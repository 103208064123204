import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useGetSourceQuery } from "../../../../../features/master/source/sourceApiSlice";
import { useGetPropertyTypesQuery } from "../../../../../features/master/propertytype/propertyTypeApiSlice";
import CustomClearIndicator from "../../../Comman/MultiSelect";

import { useGetAmenitiesQuery } from "../../../../../features/master/amenities/amenitieApiSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useGetUserBySupervisorIdQuery } from "../../../../../features/auth/authApiSlice";
// import { useGetSupervisorsByServiceIdQuery } from "../../../../features/superviser/superviserApiSlice";
import { useGetSubServicesByServiceIdQuery } from "../../../../../features/subservice/subserviceApiSlice";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";

const StepOne = ({
  users,
  services,
  nextStep,
  subServices,
  values,

  setFirstStepData,
  formData,
  setFormData,
}) => {
  const user = useSelector(selectCurrentUser);
  // const [subServiceList, setSubServiceList] = useState([]);
  const { data: SourcesData } = useGetSourceQuery();
  const [serviceId, setServiceId] = useState("");
  const [supervisorId, setSupervisorId] = useState("");
  const {
    data: getAllusers = [],
    error,
    isLoading,
    refetch,
  } = useGetUserBySupervisorIdQuery(supervisorId || values?.userId);
  const { data: PropertyData } = useGetPropertyTypesQuery();
  const { data: AmenitiesData } = useGetAmenitiesQuery();
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    const containsEdit = currentPath.includes("/edit/");
    if (containsView) {
      setIsView(true);
    } else if (containsEdit) {
      setIsEdit(true);
    }
    // //       // Filter subServices based on serviceIds
    // const filteredSubServices = subServices?.filter(
    //   (subService) => subService.serviceId === values?.serviceId
    // );
    // setSubServiceList(filteredSubServices);
  }, [subServices]);

  const handleGetUser = async (userId) => {
    setSupervisorId(userId);
    refetch();
  };

  const handleService = (serviceId) => {
    setServiceId(serviceId);
  };
  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  // Define validation schema for the first step
  const stepOneValidationSchema = Yup.object().shape({
    prefix: Yup.string(),
    firstname: Yup.string(),
    lastname: Yup.string(),
    organization: Yup.string(),
    title: Yup.string(),
    leadsStatus: Yup.string().required("Service is required"),
    backlogDate:
      formData?.leadsStatus === "Pedding"
        ? Yup.date().required("Backlog date is required when status is Pedding")
        : Yup.date().nullable(),

    serviceId: Yup.string().required("Service is required"),
    leadSource: Yup.string().required("Lead Source is required"),
    userId: Yup.string().required("Supervisor is required"),
  });
  values.supervisersWithServiceId = users;

  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      validationSchema={stepOneValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setFirstStepData(values);
        nextStep();
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue, // Added setFieldValue for updating form field values
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <section>
              {/* Name Input Fields */}
              <div className="row">
                {["prefix", "firstname", "lastname"].map((fieldName, index) => (
                  <div
                    key={index}
                    className={`form-group mb-3 col-xl-4 ${
                      index !== 0 ? "" : ""
                    }`}
                  >
                    <label className="text-label">
                      {index === 0
                        ? "Status"
                        : index === 1
                        ? "First Name"
                        : "Last Name"}
                    </label>
                    {fieldName === "prefix" && (
                      <Select
                        id="val-prefix"
                        name="prefix"
                        onChange={(selectedOption) => {
                          setFieldValue("prefix", selectedOption.value);
                          setFormData({
                            ...formData,
                            prefix: selectedOption.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={{ value: values?.prefix, label: values?.prefix }}
                        options={[
                          { value: "Mr.", label: "Mr." },
                          { value: "Ms.", label: "Ms." },
                          { value: "Mrs.", label: "Mrs." },
                          { value: "Dr.", label: "Dr." },
                        ]}
                        isDisabled={isView}
                      />
                    )}

                    {(fieldName === "firstname" ||
                      fieldName === "lastname") && (
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id={`val-${fieldName}`}
                          placeholder={
                            fieldName === "firstname"
                              ? "First Name"
                              : fieldName === "lastname"
                              ? "Last Name"
                              : ""
                          }
                          name={fieldName}
                          onChange={(e) => {
                            handleChange(e); // Handle change event
                            setFormData({
                              ...formData,
                              [fieldName]: e.target.value,
                            }); // Update formData state
                          }}
                          onBlur={handleBlur}
                          value={values[fieldName]}
                          disabled={isView}
                        />
                        <div
                          id={`val-${fieldName}-error`}
                          className="invalid-feedback animated fadeInUp"
                          style={{
                            display:
                              touched[fieldName] && errors[fieldName]
                                ? "block"
                                : "none",
                          }}
                        >
                          <ErrorMessage name={fieldName} />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="row">
                {["organization", "title"].map((fieldName, index) => (
                  <div key={index} className="form-group mb-3 col-xl-6">
                    <label className="text-label">
                      {fieldName === "title"
                        ? "Company Name"
                        : capitalizeFirstLetter(fieldName)}
                    </label>
                    {fieldName === "organization" ? (
                      <Select
                        // className="form-select form-control"
                        id={`val-${fieldName}`}
                        name={fieldName}
                        onChange={(selectedOption) => {
                          setFieldValue(fieldName, selectedOption.value);
                          setFormData({
                            ...formData,
                            fieldName: selectedOption.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={{
                          value: values?.organization,
                          label: values?.organization,
                        }}
                        options={[
                          { value: "IT", label: "IT" },
                          { value: "Finance", label: "Finance" },
                          { value: "Healthcare", label: "Healthcare" },
                          { value: "Education", label: "Education" },
                          { value: "Manufacturing", label: "Manufacturing" },
                          { value: "Retail", label: "Retail" },
                          { value: "Energy", label: "Energy" },
                          { value: "Transportation", label: "Transportation" },
                          { value: "Real Estate", label: "Real Estate" },
                          { value: "Hospitality", label: "Hospitality" },
                          { value: "Agriculture", label: "Agriculture" },
                          { value: "Entertainment", label: "Entertainment" },
                          {
                            value: "Telecommunications",
                            label: "Telecommunications",
                          },
                          {
                            value: "Pharmaceuticals",
                            label: "Pharmaceuticals",
                          },
                          { value: "Automotive", label: "Automotive" },
                          {
                            value: "Aerospace and Defense",
                            label: "Aerospace and Defense",
                          },
                          { value: "Construction", label: "Construction" },
                          { value: "Consumer Goods", label: "Consumer Goods" },
                          {
                            value: "Media and Communications",
                            label: "Media and Communications",
                          },
                          { value: "Insurance", label: "Insurance" },
                          { value: "Nonprofit/NGO", label: "Nonprofit/NGO" },
                          { value: "Government", label: "Government" },
                          { value: "Legal", label: "Legal" },
                          { value: "Consulting", label: "Consulting" },
                          {
                            value: "Research and Development",
                            label: "Research and Development",
                          },
                          { value: "Others", label: "Others" },
                        ]}
                        isDisabled={isView}
                      />
                    ) : (
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id={`val-${fieldName}`}
                          placeholder={"Company Name"}
                          name={fieldName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[fieldName]}
                          disabled={isView}
                        />
                        <div
                          id={`val-${fieldName}-error`}
                          className="invalid-feedback animated fadeInUp"
                          style={{
                            display:
                              touched[fieldName] && errors[fieldName]
                                ? "block"
                                : "none",
                          }}
                        >
                          <ErrorMessage name={fieldName} />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Lead Status, Services, Lead Source, Supervisor Responsible */}
              <div className="row">
                <div className="form-group mb-3 col-xl-6">
                  <label className="text-label">Lead Status</label>

                  <Select
                    // className="form-select form-control"
                    id="val-lead-status"
                    name="leadsStatus"
                    onChange={(selectedOption) => {
                      setFieldValue("leadsStatus", selectedOption.value);
                      setFormData({
                        ...formData,
                        leadsStatus: selectedOption.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={{
                      value: values?.leadsStatus,
                      label: values?.leadsStatus,
                    }}
                    options={[
                      { value: "Accepted", label: "Accepted" },
                      { value: "Not Started", label: "Not Started" },
                      { value: "In Progress", label: "In Progress" },
                      { value: "Pending", label: "Pending" },
                      { value: "Cancel", label: "Cancel" },

                      // { value: "Completed", label: "Completed" },
                    ]}
                    isDisabled={isView}
                  />
                  {errors.leadsStatus && touched.leadsStatus && (
                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.leadsStatus}
                    </div>
                  )}
                </div>

                {values?.leadsStatus === "Pending" && (
                  <div className="form-group mb-3 col-xl-6">
                    <label className="text-label">Follow up date</label>
                    <input
                      type="date"
                      id="backlogDate"
                      name="backlogDate"
                      value={
                        values?.backlogDate
                          ? new Date(values?.backlogDate)
                              ?.toISOString()
                              ?.slice(0, 10)
                          : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                              .toISOString()
                              .slice(0, 10)
                      }
                      onChange={(e) => {
                        setFieldValue("backlogDate", new Date(e.target.value));
                        setFormData({
                          ...formData,
                          backlogDate: new Date(e.target.value),
                        });
                      }}
                      className="form-control"
                      style={{ marginLeft: "0px" }}
                      disabled={isView}
                    />
                    <div
                      id="val-backlogDate-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.backlogDate && errors.backlogDate}
                    </div>
                  </div>
                )}

                <div className="form-group mb-3 col-xl-6">
                  <label className="text-label">Lead Source</label>
                  <Select
                    // className="form-select"
                    id="val-lead-propertytype"
                    name="leadpropertytype"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "leadSource",
                          value: selectedOption.value,
                        },
                      });
                      setFormData({
                        ...formData,
                        leadSource: selectedOption.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={{
                      value: values.leadSource,
                      label: values.leadSource,
                    }}
                    options={(SourcesData?.sources || []).map((data) => ({
                      value: data.name || "",
                      label: data.name || "",
                    }))}
                    isDisabled={isView}
                  />
                  {errors.leadSource && touched.leadSource && (
                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.leadSource}
                    </div>
                  )}
                </div>

                <div className="form-group mb-3 col-xl-12">
                  <label className="text-label">Services</label>
                  <CustomClearIndicator
                    data={services}
                    type={"services"}
                    onChange={(value) => {
                      setFieldValue("serviceId", value);
                      setFormData({
                        ...formData,
                        serviceId: value,
                      });
                    }}
                    value={values?.serviceId}
                    isView={isView}
                  />
                </div>
                {user?.role == "Admin" && (
                  <div className="form-group mb-3 col-xl-6">
                    <label className="text-label">Supervisor Responsible</label>
                    <Select
                      id="val-service"
                      name="userId"
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: "userId",
                            value: selectedOption.value,
                          },
                        });
                        handleChange({
                          target: {
                            name: "userEmail",
                            value: selectedOption.email,
                          },
                        });

                        handleGetUser(selectedOption.value);
                        setFormData({
                          ...formData,
                          userId: selectedOption.value,
                        });
                        setFormData({
                          ...formData,
                          user: {
                            userEmail: selectedOption.email,
                            supervisorName: selectedOption.label,
                            userId: selectedOption?.value,
                          },
                        });
                        // setFormData({
                        //   ...formData,
                        //   supervisorName: selectedOption.label,
                        // });
                      }}
                      onBlur={handleBlur}
                      value={{
                        value: values?.userId == null ? values?.userId : "",
                        label:
                          values.userId == null
                            ? ""
                            : values?.supervisersWithServiceId?.find(
                                (supervisor) =>
                                  supervisor.userId === values.userId
                              )
                            ? `${
                                values.supervisersWithServiceId?.find(
                                  (supervisor) =>
                                    supervisor.userId === values?.userId
                                ).firstName
                              } ${
                                values.supervisersWithServiceId?.find(
                                  (supervisor) =>
                                    supervisor?.userId === values?.userId
                                )?.lastName
                              }`
                            : "",
                      }}
                      options={values?.supervisersWithServiceId?.map(
                        (supervisor) => ({
                          value: supervisor?.userId,
                          label: `${supervisor?.firstName} ${supervisor?.lastName}`,
                          email: supervisor?.email,
                        })
                      )}
                      isDisabled={isView}
                    />

                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.userId && errors.userId}
                    </div>
                  </div>
                )}
                <div className="form-group mb-3 col-xl-6">
                  <label className="text-label">Employee</label>

                  <CustomClearIndicator
                    data={getAllusers}
                    type={"employeeId"}
                    onChange={(value) => {
                      setFieldValue("employeeId", value);
                      setFormData({
                        ...formData,
                        employeeId: value,
                      });
                    }}
                    value={values?.employeeId}
                    isView={isView}
                  />

                  <div
                    id="val-phone-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors?.employeeId && errors?.employeeId}
                  </div>
                </div>
                <div className="form-group mb-3 col-xl-6">
                  <label className="text-label">Lead PropertyType</label>
                  <Select
                    // className="form-select"
                    id="val-lead-propertytype"
                    name="leadpropertytype"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "leadpropertytype",
                          value: selectedOption.value,
                        },
                      });
                      setFormData({
                        ...formData,
                        leadpropertytype: selectedOption.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={{
                      value: values.leadpropertytype,
                      label: values.leadpropertytype,
                    }}
                    options={(PropertyData?.propertyType || []).map((data) => ({
                      value: data.name || "",
                      label: data.name || "",
                    }))}
                    isDisabled={isView}
                  />
                  {errors.leadpropertytype && touched.leadpropertytype && (
                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.leadpropertytype}
                    </div>
                  )}
                </div>

                <div className="form-group mb-3 col-xl-6">
                  <label className="text-label">Lead Amenities </label>
                  <Select
                    // className="form-select"
                    id="val-lead-"
                    name="leadamenities"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "leadamenities",
                          value: selectedOption.value,
                        },
                      });
                      setFormData({
                        ...formData,
                        leadamenities: selectedOption.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={{
                      value: values.leadamenities || "",
                      label: values.leadamenities || "",
                    }}
                    options={(AmenitiesData?.amenities || []).map((data) => ({
                      value: data.name,
                      label: data.name,
                    }))}
                    isDisabled={isView}
                  />
                  {errors.leadamenities && touched.leadamenities && (
                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.leadamenities}
                    </div>
                  )}
                </div>

                <div className="form-group mb-3  col-xl-12">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={values?.isAMC || false}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: "isAMC",
                          value: event.target.checked,
                        },
                      });
                      setFormData({
                        ...formData,
                        isAMC: event.target.checked,
                      });
                    }}
                    disabled={isView || values?.agreementId}
                  />
                  <label className="form-check-label" htmlFor="send-email">
                    Is AMC?
                  </label>
                </div>
                {!isView || (isView && values?.isAMC !== null) ? (
                  <>
                    {values?.isAMC && (
                      <>
                        <div className="form-group mb-3 col-md-3">
                          <label className="text-label">
                            Number of Services
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            value={values?.numberOfService || ""}
                            onChange={(event) => {
                              let positiveNumber = Math.abs(event.target.value);
                              handleChange({
                                target: {
                                  name: "numberOfService",
                                  value: positiveNumber,
                                },
                              });
                              setFormData({
                                ...formData,
                                numberOfService: positiveNumber,
                              });
                            }}
                            disabled={isView || values?.agreementId}
                          />
                        </div>

                        <div className="form-group mb-3 col-md-3">
                          <label className="text-label">Call Out Service</label>
                          <input
                            type="number"
                            className="form-control"
                            value={values?.callOutService || ""}
                            onChange={(event) => {
                              let positiveNumber = Math.abs(event.target.value);
                              handleChange({
                                target: {
                                  name: "callOutService",
                                  value: positiveNumber,
                                },
                              });
                              setFormData({
                                ...formData,
                                callOutService: positiveNumber,
                              });
                            }}
                            disabled={isView || values?.agreementId}
                          />
                        </div>

                        <div className="col-md-3">
                          <div className={`form-group mb-3`}>
                            <label className="text-label">AMC Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Enter a Start Date"
                              name="startDate"
                              onChange={(event) => {
                                const nextYearDate = new Date(event.target?.value);
                                nextYearDate.setFullYear(
                                  nextYearDate.getFullYear() + 1
                                );

                                const nextDateString = nextYearDate
                                  .toISOString()
                                  .split("T")[0];
                                handleChange({
                                  target: {
                                    name: "startDate",
                                    value: event.target.value,
                                  },
                                });
                                handleChange({
                                  target: {
                                    name: "endDate",
                                    value: nextDateString,
                                  },
                                });
                                setFormData({
                                  ...formData,
                                  startDate: event.target.value,
                                  endDate: nextDateString,
                                });
                              }}
                              onBlur={handleBlur}
                              value={values.startDate}
                              disabled={isView || values?.agreementId}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className={`form-group mb-3`}>
                            <label className="text-label">
                              AMC Expiry Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Customer Phone Name"
                              name="endDate"
                              onChange={(event) => {
                                handleChange({
                                  target: {
                                    name: "endDate",
                                    value: event.target.value,
                                  },
                                });
                                setFormData({
                                  ...formData,
                                  endDate: event.target.value,
                                });
                              }}
                              onBlur={handleBlur}
                              value={values.endDate}
                              disabled={isView || values?.agreementId}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </div>
            </section>

            {isView ||
              (!isEdit && (
                <div className="text-end toolbar toolbar-bottom p-2">
                  <button
                    className="btn btn-primary sw-btn-next"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </button>
                </div>
              ))}
          </Form>
        );
      }}
    </Formik>
  );
};

export default StepOne;
