import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import { IMAGES } from "../../constant/theme";
import { notifyError, notifySuccess } from "../../../helpers/Notify";

import {
  useGetTaskQuery,
  usePutTaskMutation,
} from "../../../features/taskManagement/taskManagementApiSlice";
import { CSVLink } from "react-csv";

function tConvert(time) {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const Task = ({ handleTask, user }) => {
  const { data: task = [], refetch } = useGetTaskQuery();
  const [updateTask, { isLoading, isError, error, isSuccess }] =
    usePutTaskMutation();

  const [search, setSearch] = useState(""); // Search input state
  const [filteredTasks, setFilteredTasks] = useState(task);

  const [taskInProgress, setTaskInProgress] = useState(0);
  const [taskTesting, setTaskTesting] = useState(0);
  const [taskComplete, setTaskComplete] = useState(0);
  const [taskPending, setTaskPending] = useState(0);

  const cardCounter = [
    { number: taskInProgress, countText: "purple", title: "In Progress" },
    { number: taskComplete, countText: "success", title: "Complete" },
    { number: taskPending, countText: "danger", title: "Pending" },
  ];

  useEffect(() => {
    let inProgress = 0;
    let testing = 0;
    let complete = 0;
    let pending = 0;

    task?.map((task) => {
      if (task.status === "In Progress") {
        inProgress += 1;
      }
      if (task.status === "Testing") {
        testing += 1;
      }
      if (task.status === "Complete") {
        complete += 1;
      }
      if (task.status === "Pending") {
        pending += 1;
      }
    });
    setTaskInProgress(inProgress);
    setTaskTesting(testing);
    setTaskComplete(complete);
    setTaskPending(pending);
  }, [task, isSuccess, refetch]);

  // Search functionality
  useEffect(() => {
    const filtered = task.filter((t) => {
      const searchTerm = search.toLowerCase();
      const firstName = t?.quotaion?.customer?.firstname?.toLowerCase() || "";
      const lastName = t?.quotaion?.customer?.lastname?.toLowerCase() || "";
      const status = t?.status?.toLowerCase() || "";
      const startDate = t?.startDate?.toLowerCase() || "";
      const endDate = t?.endDate?.toLowerCase() || "";
      const preferredTime = t?.preferred_Time?.toLowerCase() || "";
      const priority = t?.priority?.toLowerCase() || "";

      return (
        firstName.includes(searchTerm) ||
        lastName.includes(searchTerm) ||
        status.includes(searchTerm) ||
        startDate.includes(searchTerm) ||
        endDate.includes(searchTerm) ||
        preferredTime.includes(searchTerm) ||
        priority.includes(searchTerm)
      );
    });

    setFilteredTasks(filtered);
  }, [search, task]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = filteredTasks.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredTasks.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleSelect = (task, value) => {
    if (task.status === "Complete") {
      notifyError("Task Completed");
      return;
    }
    const updatedTask = { ...task };
    updatedTask.priority = value;
    updateTask({ id: updatedTask?.taskId, updatedTask }).then(() => {
      notifySuccess("task updated successfully");
      refetch();
    });
  };

  const handleAction = (task, value) => {
    if (task.status === "Complete") {
      notifyError("Task already Completed");
      return;
    }
    const updatedTask = { ...task };
    updatedTask.status = value;
    updateTask({ id: updatedTask?.taskId, updatedTask }).then(() => {
      notifySuccess("task updated successfully");
      refetch();
    });
  };

  const [unchecked, setUnChecked] = useState(true);

  const handleChecked = (id) => {
    let temp = filteredTasks.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setFilteredTasks(temp);
  };

  const handleCheckedAll = (value) => {
    let temp = filteredTasks.map((data) => {
      return { ...data, inputchecked: value };
    });
    setFilteredTasks(temp);
    setUnChecked(!unchecked);
  };

  const employessData = (data, task) => {
    if (task?.aggrementId) {
      const names = data?.map((emp) => emp?.label);
      return names;
    }
    const employessName = data?.map((item) => JSON.parse(item));
    const labels = employessName?.map((employee) => employee.label);
    return labels;
  };

  const navigate = useNavigate();

  const handleInvoice = (task) => {
    if (task?.quotaion?.invoiceId == null) {
      navigate(
        "/" +
          user.role.toLowerCase() +
          "/invoices/create/" +
          task?.quotaion?.quotaionId
      );
      // navigate("invoices/create/" + );
    } else {
      navigate(
        "/" +
          user.role.toLowerCase() +
          "/invoices/view/" +
          task?.quotaion?.invoiceId
      );

      // navigate("invoices/view/" + task?.quotaion?.invoiceId);
    }
  };

  const handleDetail = (task) => {
    navigate("/" + user.role.toLowerCase() + "/task/view/" + task?.taskId);
  };
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Full Name", key: "fullName" },
    { label: "Status", key: "status" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Assignee Lead", key: "assigneeLead" },
    { label: "Assigned To", key: "assignedTo" },
    { label: "Preferred Time", key: "preferredTime" },
    { label: "Priority", key: "priority" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredTasks.map((task, index) => ({
      index: index + 1,
      fullName: `${task?.quotaion?.customer?.firstname || ""} ${
        task?.quotaion?.customer?.lastname || ""
      }`,
      status: task.status,
      startDate: new Date(task.startDate).toLocaleDateString(),
      endDate:
        task.status === "Complete"
          ? new Date(task.updatedAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })
          : "",
      assigneeLead: `${task?.quotaion?.lead?.user?.firstName || ""} ${
        task?.quotaion?.lead?.user?.lastName || ""
      }`,
      assignedTo: employessData(task?.quotaion?.lead?.employeeId)?.join(", "),
      preferredTime: task?.preferred_Time
        ? tConvert(task?.preferred_Time)
        : "-",
      priority: task.priority,
    })),
    filename: "taks.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row task">
                  {cardCounter.map((item, index) => (
                    <div className="col-xl-2 col-sm-4 col-6" key={index}>
                      <div className="task-summary">
                        <div className="d-flex align-items-baseline">
                          <CountUp
                            className={`mb-0 fs-28 fw-bold me-2 text-${item.countText}`}
                            end={item.number}
                            duration={"5"}
                          />
                          <h6 className="mb-0">{item.title}</h6>
                        </div>
                        <p>Tasks assigne</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div
                  className="table-responsive active-projects task-table"
                  style={{ zIndex: "500px", position: "relative" }}
                >
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Task</h4>

                    <div className="d-flex justify-content-between align-items-center">
                      {filteredTasks?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <div className="me-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Tasks"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                      <div>
                        <button
                          className="btn btn-primary light btn-sm"
                          onClick={handleTask}
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          Call Out Task
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Assignee Lead</th>
                          <th>Assigned To</th>
                          <th>Preferred Time</th>
                          <th>Priority</th>
                          <th className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((task, index) => (
                          <tr key={index}>
                            <td>
                              <span>{firstIndex + index + 1}</span>
                            </td>
                            <td>
                              <div className="products">
                                <div>
                                  <h6>
                                    {`${
                                      task?.quotaion?.customer?.firstname || ""
                                    }  ${
                                      task?.quotaion?.customer?.lastname || ""
                                    }${
                                      task?.aggrementId !== null ? " (AMC)" : ""
                                    }`}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td style={{ zIndex: "200px" }}>
                              {user?.Role?.permissions?.includes(
                                "callout-services/edit/:callOutId"
                              ) ? (
                                task?.status == "Complete" ? (
                                  <button
                                    as="div"
                                    className="py-1 px-2 rounded bg-success"
                                    style={{
                                      pointerEvents: "none",
                                      cursor: "default",
                                      border: "none",
                                      color: "white",
                                    }}
                                  >
                                    Complete
                                  </button>
                                ) : (
                                  <Dropdown className="task-dropdown-2">
                                    <Dropdown.Toggle
                                      as="div"
                                      className={task.status}
                                    >
                                      {task.status}
                                    </Dropdown.Toggle>
                                    {user?.Role?.permissions?.includes(
                                      "task/edit/:taskId"
                                    ) && (
                                      <Dropdown.Menu className="task-drop-menu">
                                        {task?.status != "In Progress" && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleAction(task, "In Progress")
                                            }
                                          >
                                            In Progress
                                          </Dropdown.Item>
                                        )}
                                        {task?.status != "Pending" && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleAction(task, "Pending")
                                            }
                                          >
                                            Pending
                                          </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleAction(task, "Complete")
                                          }
                                        >
                                          Complete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    )}
                                  </Dropdown>
                                )
                              ) : (
                                <button
                                  as="div"
                                  className={`py-1 px-2 rounded 
                                  ${
                                    task.status === "Complete"
                                      ? "bg-success text-white"
                                      : task.status === "Pending"
                                      ? "bg-warning text-dark"
                                      : task.status === "In Progress"
                                      ? "bg-info text-white"
                                      : ""
                                  }`}
                                  style={{
                                    pointerEvents: "none",
                                    cursor: "default",
                                    border: "none",
                                    color: "white",
                                  }}
                                >
                                  {task?.status}
                                </button>
                              )}
                            </td>
                            <td>
                              <span>
                                {new Date(task?.startDate).toLocaleDateString()}
                              </span>
                            </td>
                            <td>
                              {task?.status === "Complete"
                                ? new Date(task?.updatedAt).toLocaleDateString(
                                    "en-GB",
                                    {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                      hour12: true, // This will format the time in AM/PM format
                                    }
                                  )
                                : ""}
                            </td>

                            <td className="avatar-list avatar-list-stacked">
                              <div
                                key={index}
                                className="avatar-container"
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                                onMouseEnter={(e) => {
                                  const avatar =
                                    e.currentTarget.querySelector(".label");
                                  if (avatar) {
                                    avatar.style.opacity = "1";
                                  }
                                }}
                                onMouseLeave={(e) => {
                                  const avatar =
                                    e.currentTarget.querySelector(".label");
                                  if (avatar) {
                                    avatar.style.opacity = "0";
                                  }
                                }}
                              >
                                <img
                                  src={IMAGES.contact6}
                                  className="avatar avatar-md rounded-circle"
                                  alt=""
                                />
                                <div
                                  className="label"
                                  style={{
                                    position: "absolute",
                                    top: "-30px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    opacity: "0",
                                    backgroundColor: "#fff",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                                  }}
                                >
                                  {task?.user?.firstName +
                                    " " +
                                    task?.user?.lastName}
                                </div>
                              </div>
                              <div
                                className="ml-10"
                                style={{
                                  display: "inline-block",
                                  marginLeft: "15px",
                                }}
                              >
                                {" "}
                                {task?.user?.firstName +
                                  " " +
                                  task?.user?.lastName}
                              </div>
                            </td>

                            <td className="avatar-list avatar-list-stacked">
                              {employessData(task?.employee, task)?.map(
                                (label, index) => (
                                  <div
                                    key={index}
                                    className="avatar-container"
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                    onMouseEnter={(e) => {
                                      const avatar =
                                        e.currentTarget.querySelector(".label");
                                      if (avatar) {
                                        avatar.style.opacity = "1";
                                      }
                                    }}
                                    onMouseLeave={(e) => {
                                      const avatar =
                                        e.currentTarget.querySelector(".label");
                                      if (avatar) {
                                        avatar.style.opacity = "0";
                                      }
                                    }}
                                  >
                                    <img
                                      src={IMAGES.contact6}
                                      className="avatar avatar-md rounded-circle"
                                      alt=""
                                    />
                                    <div
                                      className="label"
                                      style={{
                                        position: "absolute",
                                        top: "-30px",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        opacity: "0",
                                        backgroundColor: "#fff",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        boxShadow:
                                          "0px 0px 5px rgba(0, 0, 0, 0.3)",
                                      }}
                                    >
                                      {label}
                                    </div>
                                  </div>
                                )
                              )}
                            </td>
                            <td>
                              {task?.preferred_Time
                                ? tConvert(task?.preferred_Time)
                                : "-"}
                            </td>
                            <td className="">
                              <Dropdown className="task-dropdown-2">
                                <Dropdown.Toggle
                                  as="div"
                                  className={task.select}
                                  // disabled = {true}
                                >
                                  {task.priority}
                                </Dropdown.Toggle>
                                {task?.status != "Complete" &&
                                  user?.Role?.permissions?.includes(
                                    "task/edit/:taskId"
                                  ) && (
                                    <Dropdown.Menu className="task-drop-menu">
                                      {task.priority != "High" && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleSelect(task, "High")
                                          }
                                        >
                                          High
                                        </Dropdown.Item>
                                      )}
                                      {task.priority != "Medium" && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleSelect(task, "Medium")
                                          }
                                        >
                                          Medium
                                        </Dropdown.Item>
                                      )}
                                      {task.priority != "Low" && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleSelect(task, "Low")
                                          }
                                        >
                                          Low
                                        </Dropdown.Item>
                                      )}
                                    </Dropdown.Menu>
                                  )}
                              </Dropdown>
                            </td>
                            <td className="text-end">
                              {task.status === "Complete" &&
                                user?.Role?.permissions?.includes(
                                  "invoices/create/:quotaionId"
                                ) && (
                                  <span
                                    key={index}
                                    className="badge badge-success light border-0 me-1 btn"
                                    title="Invoice"
                                    onClick={() => handleInvoice(task)}
                                  >
                                    Invoice
                                  </span>
                                )}

                              {user?.Role?.permissions?.includes(
                                "task/view/:taskId"
                              ) && (
                                <span
                                  onClick={() => handleDetail(task)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  Detail
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {firstIndex + 1} to{" "}
                        {filteredTasks.length < lastIndex
                          ? filteredTasks.length
                          : lastIndex}{" "}
                        of {filteredTasks.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
