import { apiSlice } from "../../app/api/apiSlice";

export const serviceReportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceReport: builder.query({
      query: () => "report/service-report",
      keepUnusedDataFor: 5,
    }),
    getSingleServiceReport: builder.query({
      query: (id) => `report/service-report/${id}`,
    }),
    postServiceReport: builder.mutation({
      query: (newServiceReport) => ({
        url: "report/service-report",
        method: "POST",
        body: newServiceReport,
      }),
    }),
    postServiceReportPDF:builder.mutation({
      query: (newServiceReport) => ({
        url: "report/service-report/pdf",
        method: "POST",
        body: newServiceReport,
      }),
    }),
    postServiceReportPDFDownload:builder.mutation({
      query: (newServiceReport) => ({
        url: "report/service-report/pdf/download",
        method: "POST",
        body: newServiceReport,
      }),
    }),
    putServiceReport: builder.mutation({
      query: ({ id, updatedServiceReport }) => ({
        url: `report/service-report/${id}`,
        method: "PUT",
        body: updatedServiceReport,
      }),
    }),
  }),
});

export const {
  useGetServiceReportQuery,
  useGetSingleServiceReportQuery,
  usePostServiceReportMutation,
  usePostServiceReportPDFMutation,
  usePostServiceReportPDFDownloadMutation,
  usePutServiceReportMutation,
} = serviceReportApiSlice;
