import React, { useEffect, useState } from "react";
import { FcBusinessContact, FcCalendar } from "react-icons/fc";
import { SiGooglecalendar } from "react-icons/si";
import { SiGooglemeet } from "react-icons/si";
import { CgTwilio } from "react-icons/cg";
import Card from "./Card";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
const IntegrationComponent = () => {
  const location = useLocation();

  const getRoleFromUrl = () => {
    const pathParts = location.pathname.split("/");
    return pathParts[1];
  };

  const [connectedServices, setConnectedServices] = useState(
    JSON.parse(localStorage.getItem("connectedServices")) || {}
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get("user");
    const accessToken = urlParams.get("accessToken");
    const service = localStorage.getItem("integrateFor");

    if (user && accessToken && service) {
      const parsedUser = JSON.parse(user);
      const updatedServices = {
        ...connectedServices,
        [service]: parsedUser,
      };

      localStorage.setItem(
        "connectedServices",
        JSON.stringify(updatedServices)
      );
      setConnectedServices(updatedServices);
      localStorage.setItem("accessToken", accessToken);

      window.history.replaceState(null, "", window.location.pathname);
    }
  }, []);

  const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    return parts.length >= 2 && parts[0] !== "www" ? parts[0] : parts[1];
  };

  const handleConnectClick = (url, title) => {
    if (title === "Twilio" || title === "Google Meet") {
      swal({
        title: "Under Development",
        text: `${title} feature will be added soon!`,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    const subdomain = getSubdomain();
    localStorage.setItem("integrateFor", title);

    if (subdomain) {
      url = `${url}?subdomain=${subdomain}&role=${getRoleFromUrl()}`;
    }
    window.location.href = url;
  };

  const handleDisconnect = (service) => {
    const updatedServices = { ...connectedServices };
    delete updatedServices[service];

    localStorage.setItem("connectedServices", JSON.stringify(updatedServices));
    setConnectedServices(updatedServices);
  };

  const services = [
    {
      title: "Google Calendar",
      icon: <SiGooglecalendar style={{ fontSize: "30px", color: "green" }} />,
      url: `${process.env.REACT_APP_SOCKET_URL}/auth/google`,
    },
    {
      title: "Google My Business",
      icon: <FcBusinessContact style={{ fontSize: "35px" }} />,
      url: `${process.env.REACT_APP_SOCKET_URL}/auth/google/business`,
    },
    {
      title: "Twilio",
      icon: <CgTwilio style={{ fontSize: "30px", color: "red" }} />,
      url: ``,
    },
    {
      title: "Google Meet",
      icon: <SiGooglemeet style={{ fontSize: "30px", color: "green" }} />,
      url: ``,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-body">
              <h3>Integrations</h3>
              <p className="text-muted">
                Integrate your APIs to facilitate designated services.
              </p>
              <div className="row g-3">
                {services.map((service) => (
                  <div className="col-md-6 col-12" key={service.title}>
                    <Card
                      integratedUser={connectedServices[service.title] || null}
                      handleConnectClick={() =>
                        handleConnectClick(service.url, service.title)
                      }
                      hanldeDisConnect={() => handleDisconnect(service.title)}
                      icon={service.icon}
                      title={service.title}
                      url={service.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationComponent;
