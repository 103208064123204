import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { notifySuccess } from "../../../../../helpers/Notify";

const AddDetailsModal = ({
  show,
  handleClose,
  handleSave,
  type,
  items,
  handleUpdate,
}) => {
  const [typeInput, setTypeInput] = useState("");
  const [calculationMethod, setCalculationMethod] = useState("fixed");
  const [amount, setAmount] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleSaveClick = () => {
    handleSave({ type: typeInput, calculationMethod, amount });
    setTypeInput("");
    setCalculationMethod("fixed");
    setAmount("");
    handleClose();
    notifySuccess(`${type} is created successfully`);
  };

  const handleUpdateClick = (item, isDeleted) => {
    let body = {
      type: item?.type,
      calculationMethod: item?.calculationMethod,
      amount: item?.amount,
      isDeleted: isDeleted,
    };

    let id = "";
    if (type == "Allowances") {
      id = item?.allowanceId;
    } else if (type == "Contributions") {
      id = item?.contributionId;
    } else if (type == "Deductions") {
      id = item?.deductionId;
    } else if (type == "Taxes") {
      id = item?.taxId;
    }
    if (!isDeleted) {
      id = selectedId;
    }

    handleUpdate(id, body).then(() => {
      if (isDeleted) {
        notifySuccess(`${type} is deleted successfully`);
      } else {
        notifySuccess(`${type} is updated successfully`);
        handleClose();
      }
    });
  };

  useEffect(() => {
    setIsEdit(false);
    setTypeInput("");
    setCalculationMethod("fixed");
    setAmount("");
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`Manage ${type}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{type}</h5>

        <div className="table-responsive active-projects task-table">
          <div id="task-tbl_wrapper" className="dataTables_wrapper no-footer">
            <Table
              id="empoloyeestbl2"
              className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
            >
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Calculation Method</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
           
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.type}</td>
                    <td>{item.calculationMethod}</td>
                    <td>{item.amount}</td>
                    <td>
                      <span
                        onClick={() => {
                          setIsEdit(true);
                          setAmount(item.amount);
                          setCalculationMethod(item.calculationMethod);
                          setTypeInput(item.type);
                          switch (type) {
                            case "Allowances":
                              setSelectedId(item?.allowanceId);

                              break;
                            case "Contributions":
                              setSelectedId(item?.contributionId);
                              break;
                            case "Deductions":
                              setSelectedId(item?.deductionId);

                              break;
                            case "Taxes":
                              setSelectedId(item?.taxId);

                              break;
                            default:
                              break;
                          }
                        }}
                        style={{
                          marginRight: "8px",
                          color: "green",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fas fa-edit" title="Edit"></i>
                      </span>
                      <span
                        onClick={() => handleUpdateClick(item, true)}
                        style={{ color: "red", cursor: "pointer" }}
                      >
                        <i className="fas fa-trash" title="Delete"></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <Form className="mt-3">
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter type"
              value={typeInput}
              onChange={(e) => setTypeInput(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Calculation Method</Form.Label>
            <Form.Control
              as="select"
              value={calculationMethod}
              onChange={(e) => setCalculationMethod(e.target.value)}
            >
              <option value="fixed" selected>
                Fixed
              </option>
              {/* <option value="percentage">Percentage</option> */}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn-sm" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          className="btn-sm"
          onClick={() => {
            if (isEdit) {
              handleUpdateClick(
                {
                  type: typeInput,
                  calculationMethod: calculationMethod,
                  amount: amount,
                },
                false
              );
            } else {
              handleSaveClick();
            }
          }}
        >
          {isEdit ? "Update" : "Add"} {type}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDetailsModal;
