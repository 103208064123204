// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import {
  usePutWarehouseMutation,
  useGetSingleWarehouseQuery,
} from "../../../../features/Inventory/warehouse/warehouseApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams } from "react-router-dom";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("name is required"),
  city: Yup.string().required("city is required"),
  address: Yup.string().required("address is required"),
  description: Yup.string(),
});

const Edit = () => {
  const navigate = useNavigate();
  const { warehouseId } = useParams();
  const { data: warehouse, refetch } = useGetSingleWarehouseQuery(warehouseId);

  const user = localStorage.getItem("user");

  const [isView, setIsView] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
    refetch();
  }, []);
  const [putWarehouse, { isLoading }] = usePutWarehouseMutation();
  const handleSubmitted = async (updatedWarehouse, serviceId) => {
    try {
      putWarehouse({ id: warehouseId, updatedWarehouse })
        .unwrap()
        .then((response) => {
          notifySuccess("Warehouse updated successfully");
          navigate(`/${JSON.parse(user)?.role?.toLowerCase()}/warehouse`);
        })
        .catch((error) => {
          console.error("Error updating warehouse", error);

          notifyError(error.message);
        });
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleSendData = (updatedService, serviceId) => {
    handleSubmitted(updatedService, serviceId);
    return;
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {isView ? "View" : "Edit"} Warehouse
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: warehouse?.warehouse?.name,

                      description: warehouse?.warehouse?.description,
                      warehouseId: warehouse?.warehouse?.warehouseId,
                      city: warehouse?.warehouse?.city,
                      address: warehouse?.warehouse?.address,
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSendData(values, warehouse?.warehouse?.warehouseId);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Warehouse Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Warehouse name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.city
                                  ? errors.city
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">City</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter City"
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.city}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.city && errors.city}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.city
                                  ? errors.city
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter Address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-label">Description</label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="val-prefix"
                            placeholder="Description"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.description}
                            disabled={isView}
                          />
                          <div
                            id="val-prefix-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.description && errors.description}
                          </div>
                        </div>

                        {!isView && (
                          <div className="text-right">
                            {/* <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting}
                            >
                              Update
                            </button> */}
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
