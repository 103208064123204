import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetAgreementQuery,
  usePutAgreementMutation,
} from "../../../../features/agreement/agreementApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import LargeModal from "./LargeModal";
import { Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "../../../constant/theme";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const StatusList = [
  { value: "Not Started", label: "Not Started" },
  { value: "Deferred", label: "Deferred" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Abandoned", label: "Abandoned" },
  { value: "Accepted", label: "Accepted" },
  { value: "Completed", label: "Completed" },
  { value: "In Progress", label: "In Progress" },
];

const Project = () => {
  const userInfo = useSelector(selectCurrentUser);

  const [counterBlog, setCounterBlog] = useState([
    {
      title: "Not Started",
      count: "0",
      colorstyle: "danger",
      icon: SVGICON.Canceled,
    },
    {
      title: "Completed",
      count: "0",
      colorstyle: "success",
      icon: SVGICON.Complete,
    },
    {
      title: "In Progress",
      count: "0",
      colorstyle: "primary",
      icon: SVGICON.Progress,
    },
    {
      title: "Cancelled",
      count: "7",
      colorstyle: "purple",
      icon: SVGICON.Started,
    },
  ]);
  const [search, setSearch] = useState("");
  const [updateAgreement, { isLoading, isError, error }] =
    usePutAgreementMutation();
  const { data: agreements = [], refetch } = useGetAgreementQuery();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterAgreements = () => {
    if (!search) return agreements?.agreements;

    return agreements?.agreements?.filter((agreement) => {
      const customer =
        `${agreement?.customer?.firstname} ${agreement?.customer?.lastname}`.toLowerCase();
      const services =
        `${agreement.completedTask}/${agreement.project.numberOfService}`.toLowerCase();
      const freeCallOut = `${
        agreement?.completedCallOutTask - agreement?.paidCallOuts
      }/${agreement?.project?.callOutService}`.toLowerCase();
      const paidCallOut = agreement?.paidCallOuts.toString().toLowerCase();
      const startDate = agreement?.project?.startDate.toLowerCase();
      const expiryDate = agreement?.project?.endDate.toLowerCase();
      const completedServices = `${(
        (agreement?.completedTask / agreement?.project?.numberOfService) *
        100
      ).toFixed(0)}%`.toLowerCase();
      const date = new Date(agreement?.createdAt)
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .toLowerCase();

      return (
        customer.includes(search.toLowerCase()) ||
        services.includes(search.toLowerCase()) ||
        freeCallOut.includes(search.toLowerCase()) ||
        paidCallOut.includes(search.toLowerCase()) ||
        startDate.includes(search.toLowerCase()) ||
        expiryDate.includes(search.toLowerCase()) ||
        completedServices.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase())
      );
    });
  };

  const filteredAgreements = filterAgreements();
  const records = filteredAgreements?.slice(firstIndex, lastIndex) || [];

  const npage = Math.ceil((filteredAgreements?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (agreement) => {
    let updatedAgreement = { ...agreement };
    updatedAgreement.isDeleted = true;
    updatedAgreement.updatedBy = user?.userId;

    updateAgreement({ id: updatedAgreement?.agreementId, updatedAgreement })
      .unwrap()
      .then((response) => {
        notifySuccess("Agreement deleted successfully");
        refetch();
        navigate("agreement");
      })
      .catch((error) => {
        console.error("Error updating agreement", error);
      });
  };

  useEffect(() => {
    if (agreements && agreements.agreements) {
      const updatedCounterBlog = [...counterBlog];
      updatedCounterBlog.forEach((item) => {
        item.count = 0;
      });

      agreements.agreements.forEach((project) => {
        const index = updatedCounterBlog.findIndex(
          (item) => item.title === project.stage
        );
        if (index !== -1) {
          updatedCounterBlog[index].count++;
        }
      });

      setCounterBlog(updatedCounterBlog);
    }
  }, [agreements]);

  const handleEdit = (agreement) => {
    // navigate("agreement/edit/" + agreement?.agreementId);
    navigate("edit/" + agreement?.agreementId);
  };
  const handleView = (agreement) => {
    // navigate("agreement/view/" + agreement?.agreementId);
    navigate("view/" + agreement?.agreementId);
  };

  const handleCallOut = (agreements) => {
    navigate(
      "/" +
        userInfo?.role?.toLowerCase() +
        "/callout-services/" +
        agreements?.agreementId
    );
  };

  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }

  const handleAction = (id, project, value) => {
    let updatedProject = { ...project };
    updatedProject.stage = value;
    updatedProject.updatedBy = user?.userId;

    updateAgreement({ projectId: project?.projectId, updatedProject })
      .unwrap()
      .then((response) => {
        notifySuccess("Project updated successfully");
        refetch();
        navigate("project");
      })
      .catch((error) => {
        console.error("Error updating project", error);
        notifyError(error?.message);
      });
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (agreement) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(agreement);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Customer", key: "customer" },
    { label: "Services", key: "services" },
    { label: "Free Call Out", key: "freeCallOut" },
    { label: "Paid Call Out", key: "paidCallOut" },
    { label: "Start Date", key: "startDate" },
    { label: "Expiry Date", key: "expiryDate" },
    { label: "Completed Services", key: "completedServices" },
    { label: "Date", key: "date" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: (filteredAgreements || []).map((agreement, index) => ({
      index: index + 1,
      customer: `${agreement?.customer?.firstname || ""} ${
        agreement?.customer?.lastname || ""
      }`,
      services: `${agreement?.completedTask || 0}/${
        agreement?.project?.numberOfService || 0
      }`,
      freeCallOut: `${
        agreement?.completedCallOutTask - agreement?.paidCallOuts || 0
      }/${agreement?.project?.callOutService || 0}`,
      paidCallOut: agreement?.paidCallOuts?.toString() || "0",
      startDate: new Date(agreement?.project?.startDate).toLocaleDateString(
        "en-GB",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      ),
      expiryDate: new Date(agreement?.project?.endDate).toLocaleDateString(
        "en-GB",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      ),
      completedServices: `${(
        (agreement?.completedTask / agreement?.project?.numberOfService) *
        100
      ).toFixed(0)}%`,
      date: new Date(agreement?.createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
    })),
    filename: "agreements.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {counterBlog.map((data, index) => (
            <div className="col-xl-3 col-sm-6" key={index}>
              <div className="card box-hover">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div
                      className={`icon-box icon-box-lg  rounded-circle bg-${data.colorstyle}-light `}
                    >
                      {data.icon}
                    </div>
                    <div className="total-projects2 style-2 ms-3">
                      <CountUp
                        className={`text-start project-counter count text-${data.colorstyle}`}
                        end={data.count}
                        duration="5"
                      />
                      <h6>Total {data.title}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div
                    className="tbl-caption d-flex justify-content-between align-items-center"
                    style={{ padding: "10px" }}
                  >
                    <h4 className="heading mb-0">Agreement</h4>
                    <div className="d-flex align-items-center">
                      {filteredAgreements?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{ alignItems: "center", marginTop: "1px" }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}

                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Customer</th>
                          <th>Services</th>
                          <th>Free Call Out</th>
                          <th>Paid Call Out</th>
                          <th>Start Date</th>
                          <th>Expiry Date</th>
                          <th>Completed Services</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((agreement, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {truncateText(
                                `${agreement?.customer?.firstname} ${agreement?.customer?.lastname}`,
                                15
                              )}
                            </td>
                            <td>
                              {`${agreement.completedTask}/${agreement.project.numberOfService}`}
                            </td>
                            <td>
                              {`${
                                agreement?.completedCallOutTask -
                                agreement?.paidCallOuts
                              }/${agreement?.project?.callOutService}`}
                            </td>
                            <td>{agreement?.paidCallOuts}</td>
                            <td>
                              {agreement?.project?.startDate
                                ? new Date(
                                    agreement?.project?.startDate
                                  ).toLocaleString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                : "-"}
                            </td>
                            <td>
                              {agreement?.project?.endDate
                                ? new Date(
                                    agreement?.project?.endDate
                                  ).toLocaleDateString("en-GB")
                                : "-"}
                            </td>
                            <td className="pe-0">
                              <div className="tbl-progress-box">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: `${(
                                        (agreement?.completedTask /
                                          agreement?.project?.numberOfService) *
                                        100
                                      ).toFixed(0)}%`,
                                      height: "5px",
                                      borderRadius: "8px",
                                      backgroundColor: `bg-${(
                                        (agreement?.completedTask /
                                          agreement?.project?.numberOfService) *
                                        100
                                      ).toFixed(0)}`,
                                    }}
                                  ></div>
                                </div>
                                <span className="text-primary">
                                  {`${(
                                    (agreement?.completedTask /
                                      agreement?.project?.numberOfService) *
                                    100
                                  ).toFixed(0)}%`}
                                </span>
                              </div>
                            </td>
                            <td>
                              {new Date(
                                agreement?.createdAt
                              ).toLocaleDateString("en-GB", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              })}
                            </td>
                            <td>
                              {userInfo?.Role?.permissions?.includes(
                                "callout-services/:agreementId"
                              ) && (
                                <span
                                  onClick={() => handleCallOut(agreement)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i
                                    class="bi bi-telephone"
                                    title="CallOut"
                                  ></i>
                                </span>
                              )}
                              {userInfo?.Role?.permissions?.includes(
                                "agreement/view/:agreementId"
                              ) && (
                                <span
                                  onClick={() => handleView(agreement)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}

                              {/* <span
                                    onClick={() => SweetAlert(agreement)}
                                    style={{ color: "red", cursor: "pointer" }}
                                  >
                                    <i className="fas fa-trash" title="Delete"></i>
                                  </span> */}

                              {userInfo?.Role?.permissions?.includes(
                                "agreement/edit/:agreementId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(agreement)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredAgreements?.length < lastIndex
                          ? filteredAgreements?.length
                          : lastIndex}{" "}
                        of {filteredAgreements?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
