import { apiSlice } from "../../../../app/api/apiSlice";

export const payrollApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayrolls: builder.query({
      query: () => "hrm/payrolls",
      keepUnusedDataFor: 5,
    }),
    getPayrollById: builder.query({
      query: (payrollId) => `hrm/payrolls/${payrollId}`,
      keepUnusedDataFor: 5,
    }),
    getSalarySlips: builder.query({
      query: () => `hrm/salary-slips`,
      keepUnusedDataFor: 5,
    }),
    getPayrollDetailById: builder.query({
      query: (payrollDetailId) => `hrm/payroll-detail/${payrollDetailId}`,
      keepUnusedDataFor: 5,
    }),
    updatePayrollDetailByPayroleId: builder.mutation({
      query: ({ payrollDetailId, updatedData }) => ({
        url: `hrm/payrollsDetail/${payrollDetailId}`,  // Dynamic URL with payrollDetailId
        method: "PUT",
        body: updatedData,  // Sending the updated data as the request body
      }),
    }),
    createPayroll: builder.mutation({
      query: (newPayroll) => ({
        url: "hrm/payrolls",
        method: "POST",
        body: newPayroll,
      }),
    }),
    updatePayroll: builder.mutation({
      query: ({ payrollId, updatedPayroll }) => ({
        url: `hrm/payrolls/${payrollId}`,
        method: "PUT",
        body: updatedPayroll,
      }),
    }),
    deletePayroll: builder.mutation({
      query: (payrollId) => ({
        url: `hrm/payrolls/${payrollId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPayrollsQuery,
  useGetPayrollByIdQuery,
  useGetSalarySlipsQuery,
  useGetPayrollDetailByIdQuery,
  useUpdatePayrollDetailByPayroleIdMutation,
  useCreatePayrollMutation,
  useUpdatePayrollMutation,
  useDeletePayrollMutation,
} = payrollApiSlice;
