import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";

import { SVGICON } from "../../../constant/theme";
import {
  useDeleteLeadMutation,
  useGetLeadsQuery,
  useUpdateLeadMutation,
} from "../../../../features/lead/leadApiSlice";
import Inspection from "./inspection";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifySuccess } from "../../../../helpers/Notify";
import swal from "sweetalert";
import { FcInspection } from "react-icons/fc";
import Q1 from "../../../../images/qpng01.png";
import Q2 from "../../../../images/qpng02.png";
import Q3 from "../../../../images/qpng03.png";
import newQuotationLogo from "../../../../icons/newicons/Quotation-02.svg";
import newInspectionLogo from "../../../../icons/newicons/Inspection-01.svg";

const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const leadStatusOption = [
  {
    value: "Not Started",
    label: "Not Started",
  },
  {
    value: "In Progress",
    label: "In Progress",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Cancel",
    label: "Cancel",
  },
  {
    value: "Accepted",
    label: "Accepted",
  },
  {
    value: "Completed",
    label: "Completed",
  },
];

const Lead = () => {
  const { data: leads = [], refetch } = useGetLeadsQuery({
    fetchPolicy: "network-only",
  });

  const [search, setSearch] = useState("");
  const [dateSearch, setDateSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [updateLead, { isLoading, isError, error }] = useUpdateLeadMutation();
  const [deleteLead] = useDeleteLeadMutation();
  const [filterCriteria, setFilterCriteria] = useState("all"); // Default filter is "all"
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [cardCounter, setCardCounter] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedLead, setSelectedLead] = useState(false);

  const handleQuotation = (lead) => {
    if (!lead?.quotaionId) {
      navigate(
        "/" +
          JSON.parse(user)?.role?.toLowerCase() +
          "/quotation/create/" +
          lead?.leadId
      );

      // navigate(+lead?.leadId);
    } else if (lead?.quotaionId && lead?.quotaion?.isDeleted) {
      navigate(
        "/" +
          JSON.parse(user)?.role?.toLowerCase() +
          "/quotation/create/" +
          lead?.leadId
      );
    } else if (lead?.quotaion?.stage == "Accepted") {
      navigate(
        "/" +
          JSON.parse(user)?.role?.toLowerCase() +
          "/quotation/view/" +
          lead?.quotaionId
      );
      // navigate("quotation/view/" + lead?.quotaionId);
    } else {
      navigate(
        "/" +
          JSON.parse(user)?.role?.toLowerCase() +
          "/quotation/edit/" +
          lead?.quotaionId
      );
      // navigate("quotation/edit/" + lead?.quotaionId);
    }
  };
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    // Call the function to filter leads whenever the filter criteria changes
    filterLeads();
  }, [filterCriteria, filteredLeads, leads, search, dateSearch, statusSearch]);

  const filterLeads = () => {
    if (leads && leads?.leads && Array.isArray(leads?.leads)) {
      const openCount = leads.leads.filter(
        (lead) => lead.leadsStatus === "Not Started"
      ).length;
      const inProgressCount = leads.leads.filter(
        (lead) => lead.leadsStatus === "In Progress"
      ).length;
      const pendingCount = leads.leads.filter(
        (lead) => lead.leadsStatus === "Pending"
      ).length;
      const cancelCount = leads.leads.filter(
        (lead) => lead.leadsStatus === "Cancel"
      ).length;
      const acceptedCount = leads.leads.filter(
        (lead) => lead.leadsStatus === "Accepted"
      ).length;
      const completeCount = leads.leads.filter(
        (lead) => lead.leadsStatus === "Completed"
      ).length;

      // Update the cardCounter array with the new counts
      const updatedCardCounter = [
        {
          number: openCount.toString(),
          countText: "primary",
          title: "Not Started",
        },
        {
          number: inProgressCount.toString(),
          countText: "purple",
          title: "In Progress",
        },
        {
          number: pendingCount.toString(),
          countText: "danger",
          title: "Pending",
        },
        {
          number: cancelCount.toString(),
          countText: "danger",
          title: "Cancelled",
        },
        {
          number: acceptedCount.toString(),
          countText: "success",
          title: "Accepted",
        },
        {
          number: completeCount.toString(),
          countText: "success",
          title: "Completed",
        },
      ];

      // Set the updated cardCounter
      setCardCounter(updatedCardCounter);

      // Apply the same logic for filtering leads
      let filtered = leads.leads;

      if (filterCriteria !== "all") {
        switch (filterCriteria) {
          case "today":
            filtered = filtered.filter(isToday);
            break;
          case "upcoming":
            filtered = filtered.filter(isUpcoming);
            break;
          case "oldPending":
            filtered = filtered.filter(isOldPending);
            break;
          default:
            filtered = leads.leads;
        }
      }

      if (search) {
        filtered = filtered.filter((lead) => {
          const fullName = `${lead?.customer?.firstname || ""} ${
            lead?.customer?.lastname || ""
          }`;
          const email = lead?.customer?.email || "";
          const phone = lead?.customer?.phone || "";
          const service = lead?.serviceId?.length || "";
          const leadStatus = lead?.leadsStatus || "";
          const leadSource = lead?.leadSource || "";
          const createdDate = lead?.createdAt
            ? new Date(lead?.createdAt).toLocaleDateString("en-GB")
            : "";
          const updatedDate = lead?.updatedAt
            ? new Date(lead?.updatedAt).toLocaleDateString("en-GB")
            : "";

          return (
            fullName.toLowerCase().includes(search.toLowerCase()) ||
            email.toLowerCase().includes(search.toLowerCase()) ||
            phone.toLowerCase().includes(search.toLowerCase()) ||
            service.toString().includes(search) ||
            leadStatus.toLowerCase().includes(search.toLowerCase()) ||
            leadSource.toLowerCase().includes(search.toLowerCase()) ||
            createdDate.includes(search) ||
            updatedDate.includes(search)
          );
        });
      }

      if (dateSearch) {
        filtered = filtered.filter((lead) => {
          // Convert both dates to a common format if necessary
          const leadDate = new Date(lead.createdAt).toISOString().split("T")[0];
          const searchDate = new Date(dateSearch).toISOString().split("T")[0];

          if (leadDate === searchDate) {
            const fullName = `${lead?.customer?.firstname || ""} ${
              lead?.customer?.lastname || ""
            }`;
            const email = lead?.customer?.email || "";
            const phone = lead?.customer?.phone || "";
            const service = lead?.serviceId?.length || "";
            const leadStatus = lead?.leadsStatus || "";
            const leadSource = lead?.leadSource || "";
            const createdDate = lead?.createdAt
              ? new Date(lead?.createdAt).toLocaleDateString("en-GB")
              : "";
            const updatedDate = lead?.updatedAt
              ? new Date(lead?.updatedAt).toLocaleDateString("en-GB")
              : "";
            return (
              fullName.toLowerCase().includes(search.toLowerCase()) ||
              email.toLowerCase().includes(search.toLowerCase()) ||
              phone.toLowerCase().includes(search.toLowerCase()) ||
              service.toString().includes(search) ||
              leadStatus.toLowerCase().includes(search.toLowerCase()) ||
              leadSource.toLowerCase().includes(search.toLowerCase()) ||
              createdDate.includes(search) ||
              updatedDate.includes(search)
            );
          }
        });
      }
      if (statusSearch) {
        filtered = filtered.filter((lead) => {
          if (lead?.leadsStatus === statusSearch) {
            const fullName = `${lead?.customer?.firstname || ""} ${
              lead?.customer?.lastname || ""
            }`;
            const email = lead?.customer?.email || "";
            const phone = lead?.customer?.phone || "";
            const service = lead?.serviceId?.length || "";
            const leadStatus = lead?.leadsStatus || "";
            const leadSource = lead?.leadSource || "";
            const createdDate = lead?.createdAt
              ? new Date(lead?.createdAt).toLocaleDateString("en-GB")
              : "";
            const updatedDate = lead?.updatedAt
              ? new Date(lead?.updatedAt).toLocaleDateString("en-GB")
              : "";
            return (
              fullName.toLowerCase().includes(search.toLowerCase()) ||
              email.toLowerCase().includes(search.toLowerCase()) ||
              phone.toLowerCase().includes(search.toLowerCase()) ||
              service.toString().includes(search) ||
              leadStatus.toLowerCase().includes(search.toLowerCase()) ||
              leadSource.toLowerCase().includes(search.toLowerCase()) ||
              createdDate.includes(search) ||
              updatedDate.includes(search)
            );
          }
        });
      }
      setFilteredLeads(filtered);
    }
  };

  const isToday = (lead) => {
    const today = new Date();
    const leadDate = new Date(lead.backlogDate);
    return (
      leadDate.getDate() === today.getDate() &&
      leadDate.getMonth() === today.getMonth() &&
      leadDate.getFullYear() === today.getFullYear()
    );
  };
  const colors = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];
  const isUpcoming = (lead) => {
    const today = new Date();
    const leadDate = new Date(lead.backlogDate);
    return leadDate > today && lead.status == "Pending";
  };

  const isOldPending = (lead) => {
    return lead.leadsStatus === "Pending";
  };

  const handleFilterChange = (value) => {
    setFilterCriteria(value);
  };
  const headersTitle = [
    { label: "#", key: "id" },
    { label: "Full Name", key: "fullName" },
    { label: "Email Address", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Service", key: "service" },
    { label: "Lead Status", key: "leadsStatus" },
    { label: "Lead Source", key: "key" },
    { label: "Created Date", key: "createdDate" },
    { label: "Updated Date", key: "updatedDate" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredLeads?.map((lead, leadId) => ({
      leadId: leadId + 1,
      fullName: `${lead?.customer?.prefixName || ""} ${
        lead?.customer?.firstname || ""
      } ${lead?.customer?.lastname || ""}`,
      email: lead?.customer?.email || "",
      phone: lead?.customer?.phone || "",
      service: lead?.serviceId?.key || "",
      leadsStatus: lead?.leadsStatus || "",
      leadSource: lead?.leadSource || "",
      createdDate: lead?.createdAt
        ? new Date(lead?.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })
        : "",
      updatedDate: lead?.updatedAt
        ? new Date(lead?.updatedAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })
        : "",
    })),
    filename: "lead.csv",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");

  const recordsPage = 20;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = filteredLeads?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredLeads?.length / recordsPage);
  const number = Array.from({ length: npage }, (_, i) => i + 1); // Fix pagination page numbers

  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);
  const handleSelect = (id, value) => {
    let temp = statusPriority?.map((data) => {
      if (id === data.id) {
        return { ...data, select: value };
      }
      return data;
    });
    setStatusPriority(temp);
  };
  function transformLeadData(existingData, updatedData) {
    const transformedData = {
      serviceId: updatedData.serviceId || existingData.serviceId,
      firstname: updatedData.firstname || existingData.customer.firstname,
      lastname: updatedData.lastname || existingData.customer.lastname,
      organization:
        updatedData.organization || existingData.customer.organization,
      title: updatedData.title || existingData.customer.title,
      leadsStatus: updatedData.leadsStatus || existingData.leadsStatus,
      phone: updatedData.phone || existingData.customer.phone,
      email: updatedData.email || existingData.customer.email,

      leadSource: updatedData.leadSource || existingData.leadSource,
      city: updatedData.city || existingData.customer.city,
      stateOrProvince:
        updatedData.stateOrProvince || existingData.customer.stateOrProvince,
      area: updatedData.area || existingData.customer.area,
      description: updatedData.description || existingData.description,
      tags: updatedData.tags || existingData.tags,

      webSite: updatedData.webSite || existingData.customer.webSite,
      mailingAddress: updatedData.customer?.mailingAddress,
      country: updatedData.country || existingData.customer.country,
      customerId: updatedData.customerId || existingData.customerId,
      userId: updatedData.userId,
      isDeleted: updatedData?.isDeleted,
    };

    return transformedData;
  }
  const handleAction = (lead, value) => {
    // Create a copy of the lead object
    let updatedData = { ...lead };

    // Update the leadsStatus property
    updatedData.leadsStatus = value;
    updatedData.updatedBy = user?.userId;

    // Transform the lead data as needed
    updatedData = transformLeadData(lead, updatedData);

    // Call the updateLead mutation function
    updateLead({ id: lead?.leadId, ...updatedData })
      .unwrap()
      .then((response) => {
        // Handle successful response
        notifySuccess("Lead updated successfully");
        // Refetch the leads data after the update
        refetch();
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating lead", error);
      });
  };

  const handleDelete = (lead) => {
    deleteLead({ id: lead?.leadId })
      .unwrap()
      .then((response) => {
        // Handle successful response
        notifySuccess("Lead deleted successfully");
        refetch(); // Refresh the leads list
      })
      .catch((error) => {
        // Handle error
        console.error("Error deleting lead", error);
      });
  };

  const handleEdit = (lead) => {
    navigate(
      "/" + JSON.parse(user)?.role?.toLowerCase() + "/lead/edit/" + lead?.leadId
    );
  };
  const handleView = (lead) => {
    navigate(
      "/" + JSON.parse(user)?.role?.toLowerCase() + "/lead/view/" + lead?.leadId
    );
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority?.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };
  const handleCheckedAll = (value) => {
    let temp = statusPriority?.map((data) => {
      return { ...data, inputchecked: value };
    });
    setStatusPriority(temp);
    setUnChecked(!unchecked);
  };
  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }
  const SweetAlert = (lead) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(lead);
      } else {
        swal("Your data is safe!");
      }
    });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row task">
                {cardCounter?.map((item, index) => (
                  <div className="col-xl-2 col-sm-4 col-6" key={index}>
                    <div className="task-summary">
                      <div className="d-flex align-items-baseline">
                        <CountUp
                          className={`mb-0 fs-28 fw-bold me-2 text-${item.countText}`}
                          end={item.number}
                          duration={"5"}
                        />
                        <h6 className="mb-0">{item.title}</h6>
                      </div>
                      {/* <p>Tasks assigne</p> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects task-table">
                <div className="tbl-caption d-flex justify-content-between align-items-center">
                  <h4 className="heading mb-0">Leads</h4>

                  <div className="d-flex align-items-center">
                    {filteredLeads?.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.700rem 1.50rem",
                        }}
                      >
                        <i
                          className="fa-solid fa-file-excel"
                          style={{ marginRight: "8px" }}
                        />
                        Export Report
                      </CSVLink>
                    )}
                    {/* <div className="input-group search-area">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className="input-group-text">
                        <Link to={"#"}>{SVGICON.SearchIcon}</Link>
                      </span>
                    </div> */}
                    <Dropdown
                      className="btn btn-primary light btn-sm me-2"
                      style={{
                        whiteSpace: "nowrap",
                        padding: "0.775rem 1.75rem",
                      }}
                    >
                      <Dropdown.Toggle as="div">
                        {statusSearch == ""
                          ? "All Status"
                          : statusSearch + " Status"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="task-drop-menu">
                        {statusSearch !== "" && (
                          <Dropdown.Item onClick={() => setStatusSearch("")}>
                            All Status
                          </Dropdown.Item>
                        )}
                        {leadStatusOption?.map((status, index) => (
                          <Dropdown.Item
                            onClick={() => setStatusSearch(status.value)}
                            key={index}
                          >
                            {status?.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <input
                      type="date"
                      name="dateSearch"
                      value={dateSearch}
                      onChange={(e) => setDateSearch(e.target.value)}
                      placeholder="Search"
                      className="form-control me-2"
                    />
                    <input
                      type="text"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search"
                      className="form-control me-2"
                    />

                    <Dropdown
                      className="btn btn-primary light btn-sm me-2"
                      style={{
                        whiteSpace: "nowrap",
                        padding: "0.775rem 1.75rem",
                      }}
                    >
                      <Dropdown.Toggle as="div">
                        {filterCriteria === "all"
                          ? "All Lead"
                          : filterCriteria === "today"
                          ? "Today's Follow-ups"
                          : filterCriteria === "upcoming"
                          ? "Upcoming Follow-ups"
                          : filterCriteria === "oldPending" &&
                            "Old Pending Follow-ups"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="task-drop-menu">
                        {filterCriteria != "all" && (
                          <Dropdown.Item
                            onClick={() => handleFilterChange("all")}
                          >
                            All Leads
                          </Dropdown.Item>
                        )}

                        {filterCriteria != "today" && (
                          <Dropdown.Item
                            onClick={() => handleFilterChange("today")}
                          >
                            Today's Follow-ups
                          </Dropdown.Item>
                        )}
                        {filterCriteria != "upcoming" && (
                          <Dropdown.Item
                            onClick={() => handleFilterChange("upcoming")}
                          >
                            Upcoming Follow-ups
                          </Dropdown.Item>
                        )}

                        {filterCriteria != "oldPending" && (
                          <Dropdown.Item
                            onClick={() => handleFilterChange("oldPending")}
                          >
                            Old Pending Follow-ups
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <CSVLink
                      {...csvlink}
                      className="btn btn-primary light btn-sm me-2"
                      style={{
                        whiteSpace: "nowrap",
                        padding: "0.775rem 1.75rem",
                      }}
                    >
                      <i className="fa-solid fa-file-excel" /> Export Report
                    </CSVLink> */}

                    {JSON.parse(user)?.Role?.permissions?.includes(
                      "lead/create"
                    ) && (
                      <Dropdown
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.775rem 1.75rem",
                        }}
                      >
                        <Dropdown.Toggle as="div">
                          {" "}
                          <i className="fa-solid fa-plus me-2"></i>Add Lead
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="task-drop-menu">
                          <Dropdown.Item
                          // onClick={() => handleFilterChange("today")}
                          >
                            <Link to={"create"}>
                              <i className="fa-solid fa-plus me-2"></i>Add Lead
                              with New Customers
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item
                          // onClick={() => handleChange("all")}
                          >
                            <Link to={"create#existingCustomer"}>
                              <i className="fa-solid fa-plus me-2"></i> Add Lead
                              with Existing Customers
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
                <div
                  id="task-tbl_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="empoloyeestbl2"
                    className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Full Name</th>
                        <th>Email Address</th>
                        <th>Phone</th>
                        <th>Service</th>
                        <th>Lead Status</th>
                        <th>Lead Source</th>
                        <th>Action</th>
                        <th>Created Date</th>
                        <th>Updated Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records?.map((lead, index) => (
                        // <tr key={index} >
                        <tr
                          key={index}
                          className={
                            lead?.userId == null ? "table-warning" : ""
                          }
                        >
                          {" "}
                          <td>{index + 1}</td>
                          <td>{`${lead?.customer?.firstname || ""} ${
                            lead?.customer?.lastname || ""
                          }`}</td>
                          <td>{lead?.customer?.email || ""}</td>
                          <td>{lead?.customer?.phone || ""}</td>
                          <td>
                            {lead?.projectId
                              ? `${lead?.serviceId?.length || ""} (AMC)`
                              : lead?.serviceId?.length || ""}
                          </td>
                          <td>
                            <Dropdown className="task-dropdown-2">
                              <Dropdown.Toggle as="div">
                                {lead?.leadsStatus}
                              </Dropdown.Toggle>
                              {/* <Dropdown.Menu className="task-drop-menu">
                                {leadStatusOption
                                  ?.filter?.((data) => {
                                    return data?.value != lead?.leadsStatus;
                                  })
                                  ?.map((item) => (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleAction(lead, item?.value)
                                      }
                                    >
                                      {item?.value}
                                    </Dropdown.Item>
                                  ))}
                              </Dropdown.Menu> */}
                            </Dropdown>
                          </td>
                          <td>
                            {capitalizeFirstLetter(lead?.leadSource || "")}
                          </td>
                          <td>
                            {lead?.inspectionId &&
                              !lead?.quotaionId &&
                              JSON.parse(user)?.Role?.permissions?.includes(
                                "quotation/create"
                              ) && (
                                <img
                                  src={newQuotationLogo}
                                  alt="Quotation"
                                  title="Quotation"
                                  onClick={() => handleQuotation(lead)}
                                  width={45}
                                  style={{ cursor: "pointer" }}
                                ></img>
                              )}

                            {lead?.inspectionId &&
                              lead?.quotaionId &&
                              JSON.parse(user)?.Role?.permissions?.includes(
                                "quotation/edit/:quotationId"
                              ) && (
                                <img
                                  src={newQuotationLogo}
                                  alt="Quotation"
                                  title="Quotation"
                                  onClick={() => handleQuotation(lead)}
                                  width={45}
                                  style={{ cursor: "pointer" }}
                                ></img>
                              )}

                            {JSON.parse(user)?.Role?.permissions?.includes(
                              "lead/view/:leadId"
                            ) && (
                              <span
                                onClick={() => handleView(lead)}
                                style={{
                                  marginRight: "8px",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-eye" title="View"></i>
                              </span>
                            )}

                            {lead?.quotaion?.stage != "Accepted" &&
                              lead?.leadsStatus != "Completed" &&
                              JSON.parse(user)?.Role?.permissions?.includes(
                                "lead/edit/:leadId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(lead)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}
                            {/* Delete icon */}

                            {lead?.quotaion?.stage !== "Accepted" &&
                              JSON.parse(user)?.role?.toLowerCase() ==
                                "admin" && (
                                <span
                                  onClick={() => SweetAlert(lead)}
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Delete"
                                  ></i>
                                </span>
                              )}

                            {lead?.inspectionId ? (
                              <img
                                src={newInspectionLogo}
                                alt="Not Inspection"
                                title="Inspection"
                                onClick={() => {
                                  setModalShow(true);
                                  refetch();
                                  setSelectedLead(lead);
                                }}
                                width={45}
                                style={{ cursor: "pointer" }}
                              ></img>
                            ) : lead?.userId != null ? (
                              <img
                                src={Q2}
                                alt="Inspection"
                                title="Inspection"
                                onClick={() => {
                                  setModalShow(true);
                                  refetch();
                                  setSelectedLead(lead);
                                }}
                                width={45}
                                style={{ cursor: "pointer" }}
                              ></img>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {lead?.createdAt
                              ? new Date(lead?.createdAt).toLocaleString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true, // This will format the time in AM/PM format
                                  }
                                )
                              : ""}
                          </td>
                          <td>
                            {lead?.updatedAt
                              ? new Date(lead?.updatedAt).toLocaleString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true, // This will format the time in AM/PM format
                                  }
                                )
                              : ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {firstIndex + 1} to{" "}
                      {Math.min(filteredLeads?.length, lastIndex)} of{" "}
                      {filteredLeads?.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers justify-content-center"
                      id="example2_paginate"
                    >
                      <Link
                        className={`paginate_button previous ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number?.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            }`}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className={`paginate_button next ${
                          currentPage === npage ? "disabled" : ""
                        }`}
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <Inspection
          show={modalShow}
          data={selectedLead}
          refetch={refetch}
          onHide={() => {
            setModalShow(false);
          }}
        />
      )}
    </div>
  );
};

export default Lead;
