import React from "react";
import WeeklySchedule from "./WeeklySchedule";

const AvailabilityComponent = () => {
  return (
    <div className="container-fluid">
      <WeeklySchedule />
    </div>
  );
};

export default AvailabilityComponent;
