import React, { useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';


const App = () => {
  const [formData, setFormData] = useState({
    name: '',
    receiptId: 0,
    price1: 0,
    price2: 0,
  });

  const handleChange = ({ target: { value, name }}) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const createAndDownloadPdf = () => {
    axios.post('http://localhost:8080/api/create-pdf', formData)
      .then(() => axios.get('http://localhost:8080/api/fetch-pdf', { responseType: 'blob' }))
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

        saveAs(pdfBlob, 'newPdf.pdf');
      })
  };

  return (
    <div >
      <input type="text" placeholder="Name" name="name" onChange={handleChange}/>
      <input type="number" placeholder="Receipt ID" name="receiptId" onChange={handleChange} />
      <input type="number" placeholder="Price 1" name="price1" onChange={handleChange} />
      <input type="number" placeholder="Price 2" name="price2" onChange={handleChange} />
      <button onClick={createAndDownloadPdf}>Download PDF</button>
    </div>
  );
};

export default App;
