import { SVGICON, IMAGES } from "../../constant/theme";

export const MenuList = [
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.DashBoardSVG,
    to: "",
    access: ["admin", "supervisor"],
  },

  //CRM
  {
    title: "CRM",
    classsChange: "menu-title",
    // access: ["admin", "supervisor"],
  },

  {
    title: "Lead",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.LeadSvg,
    to: "lead",
    // access: ["admin", "supervisor"],
  },

  {
    title: "Customer",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.CustomerSvg,
    to: "customer",
    // access: ["admin"],
  },

  {
    title: "AMC",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Projects,
    to: "amc",
    access: ["admin"],
  },
  {
    title: "Agreement",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Agreements,
    to: "agreement",
    access: ["admin", "supervisor"],
  },

  {
    title: "Inventory",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Inventory,
    access: ["admin"],
    content: [
      {
        title: "Warehouse",
        to: "warehouse",
        access: ["admin"],
      },
      {
        title: "Stock List",
        to: "stocklist",
        access: ["admin"],
      },
      {
        title: "Category",
        to: "category",
        access: ["admin"],
      },

      {
        title: "Brand",
        to: "brand",
        access: ["admin"],
      },
      {
        title: "Model",
        to: "model",
        access: ["admin"],
      },
      {
        title: "Product",
        to: "product",
        access: ["admin"],
      },
    ],
  },

  {
    title: "Purchase Order",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Inventory,
    access: ["admin"],
    content: [
      {
        title: "List Purchase Order",
        to: "purchase-order",
        access: ["admin"],
      },
      {
        title: "Receive Shipping",
        to: "receive-shipping",
        access: ["admin"],
      },

      {
        title: "List Purchases",
        to: "purchases",
        access: ["admin"],
      },
    ],
  },
  {
    title: "Supplier",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Suppliers,
    to: "supplier",
    access: ["admin"],
  },
  {
    title: "Masters",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Masters,
    access: ["admin"],
    content: [
      {
        title: "Parent Services",
        classsChange: "mm-collapse",
        iconStyle: SVGICON.TaskIcon,
        to: "parent-services",
        access: ["admin"],
      },
      {
        title: "Services",
        classsChange: "mm-collapse",
        iconStyle: SVGICON.TaskIcon,
        to: "service",
        access: ["admin"],
      },

      {
        title: "Source",
        to: "source",
        access: ["admin"],
      },
      {
        title: "Property Type",
        to: "propertytype",
        access: ["admin"],
      },
      {
        title: "Amenities",
        to: "amenities",
        access: ["admin"],
      },
    ],
  },

  {
    title: "CHAT BOT",
    classsChange: "menu-title",
  },
  {
    title: "ChatBOT",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Agreements,
    to: "chatbot",
    access: ["admin", "supervisor"],
  },
  {
    title: "Configuration",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Agreements,
    to: "configuration",
    access: ["admin", "supervisor"],
    content: [
      {
        title: "Configuration",
        to: "configuration",
        access: ["admin"],
      },
      {
        title: "Integration",
        to: "integration",
        access: ["admin"],
      },
      {
        title: "Availability",
        to: "availability",
        access: ["admin"],
      },
    ],
  },
  {
    title: "All Conversations",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Agreements,
    to: "conversations",
    access: ["admin", "supervisor"],
  },
  {
    title: "Appointments",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Agreements,
    to: "appointments",
    access: ["admin", "supervisor"],
  },
  {
    title: "Agents",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Agreements,
    to: "agents",
    access: ["admin", "supervisor"],
  },

  //Task Mangement
  {
    title: "Task Mangement",
    classsChange: "menu-title",
    access: ["admin", "supervisor"],
  },

  {
    title: "Tasks",
    iconStyle: SVGICON.Tasks,
    to: "task",
    access: ["admin", "supervisor"],
  },

  {
    title: "Board",
    iconStyle: SVGICON.Board,
    to: "board",
    access: ["admin", "supervisor"],
  },

  //Financial management
  {
    title: "Financial Mangement",
    classsChange: "menu-title",
    access: ["admin"],
  },

  {
    title: "Chart of Accounts",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.ChartOfAccounts,
    to: "accounts",
    access: ["admin"],
    // content: [
    //   {
    //     title: "Charts of Accounts",
    //     to: "accounts",
    //     access: ["admin"],
    //   },
    // ],
  },
  {
    title: "Billings",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Billings,
    access: ["admin"],
    content: [
      {
        title: "Invoices",
        to: "invoices",
        access: ["admin"],
      },
      {
        title: "Quotation",
        to: "quotation",
        access: ["admin"],
      },
      {
        title: "Receive Payment",
        to: "receive-payment",
        access: ["admin"],
      },
    ],
  },
  {
    title: "Transactions",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Transactions,
    access: ["admin"],
    content: [
      {
        title: "Account Transactions",
        to: "account-transactions",
        access: ["admin"],
      },
      {
        title: "Journal Entries",
        to: "journal-entries",
        access: ["admin"],
      },
      // {
      //   title: "Ledger Entries",
      //   to:"task",
      // },
    ],
  },
  // {
  //   title: "Budgeting",
  //   classsChange: "mm-collapse",
  //   iconStyle: SVGICON.Budgeting,
  //   access: ["admin"],
  //   content: [
  //     {
  //       title: "Budgets",
  //       to: "budget",
  //       access: ["admin"],
  //     },
  //   ],
  // },
  // {
  //   title: "Financial Periods",
  //   classsChange: "mm-collapse",
  //   iconStyle: SVGICON.FinancialPeriods,
  //   access: ["admin"],
  //   to: "",
  //   // content: [
  //   //   {
  //   //     title: "Budgets",
  //   //     to:"task",
  //   //   },
  //   // ],
  // },
  // {
  //   title: "Expenses",
  //   classsChange: "mm-collapse",
  //   iconStyle: SVGICON.Expenses,
  //   access: ["admin"],
  //   content: [
  //     {
  //       title: "Expenses",
  //       to: "expense",
  //       access: ["admin"],
  //     },
  //     // {
  //     //   title: "Expense Categories",
  //     //   to: "expense-category",
  //     // },
  //   ],
  // },
  // {
  //   title: "Incomes ",
  //   classsChange: "mm-collapse",
  //   iconStyle: SVGICON.Incomes,
  //   access: ["admin"],
  //   content: [
  //     {
  //       title: "Incomes",
  //       to: "income",
  //     },
  //     {
  //       title: "Income Categories",
  //       to: "Dashboard-light",
  //       access: ["admin"],
  //     },
  //   ],
  // },
  {
    title: "Reports",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Reports,
    access: ["admin"],
    content: [
      // {
      //   title: "Financial Statements",
      //   to: "financialstatement",
      //   access: ["admin"],
      // },
      // {
      //   title: "Financial Reports",
      //   to: "Dashboard-light",
      //   access: ["admin"],
      // },
      {
        title: "Service Reports",
        to: "service-report",
        access: ["admin"],
      },
      {
        title: "Purchase by Vendor",
        to: "vendor/purchase-summary",
        access: ["admin"],
      },
      {
        title: "AR Aging Summary",
        to: "invoice/aging",
        access: ["admin"],
      },
      {
        title: "Purchase Register",
        to: "purchase/register",
        access: ["admin"],
      },
      {
        title: "Sales Register",
        to: "sales/register",
        access: ["admin"],
      },
      {
        title: "Sales Register By Category",
        to: "sales/register/category",
        access: ["admin"],
      },
      {
        title: "Accounts Payable Summary",
        to: "payable/summary",
        access: ["admin"],
      },
      {
        title: "Account Receivables",
        to: "receiveable/summary",
        access: ["admin"],
      },
      {
        title: "Customer Wise Sale Summary",
        to: "customerwise/sale",
        access: ["admin"],
      },
      {
        title: "AP Aging Summary",
        to: "payable/aging/summary",
        access: ["admin"],
      },
      {
        title: "Job By Team Report",
        to: "job/team",
        access: ["admin"],
      },
      {
        title: "Sale By Team Report",
        to: "sale/team",
        access: ["admin"],
      },
      {
        title: "Sale By Service Report",
        to: "sale/service",
        access: ["admin"],
      },

      {
        title: "Profit And  Loss Statement",
        to: "profit-loss/statement",
        access: ["admin"],
      },
      {
        title: "Balance Sheet",
        to: "balance-sheets/sheet",
        access: ["admin"],
      },
      {
        title: "Trial Balance",
        to: "trial-balance/sheet",
        access: ["admin"],
      },
      {
        title: "Cash Flow Statement",
        to: "cash-flow/statement",
        access: ["admin"],
      },
      {
        title: "General Ledger",
        to: "general-ledger/ledger",
        access: ["admin"],
      },
      {
        title: "Journal Ledger",
        to: "journal-ledger",
        access: ["admin"],
      },
      {
        title: "Expenses By Category",
        to: "expenses/category",
        access: ["admin"],
      },
      {
        title: "Actual Vs Budget",
        to: "actual-budget/sheet",
        access: ["admin"],
      },
      {
        title: "VAT Audit Report",
        to: "audit-report/VAT",
        access: ["admin"],
      },
      {
        title: "VAT Return",
        to: "return-report/VAT",
        access: ["admin"],
      },
      // {
      //   title: "MTD Vat Return",
      //   to: "MTD-return/Vat",
      //   access: ["admin"],
      // },
      {
        title: "Movement of Equity",
        to: "movement-of-equity",
        access: ["admin"],
      },

      {
        title: "Bank Reconciallation Statement",
        to: "bank-reconcialliation/statement",
        access: ["admin"],
      },
      {
        title: "Recurring Invoice Details",
        to: "recurring-invoice/details",
        access: ["admin"],
      },
    ],
  },
  // {
  //   title: "Settings",
  //   classsChange: "mm-collapse",
  //   iconStyle: SVGICON.Settings,
  //   access: ["admin"],
  //   content: [
  //     {
  //       title: "Financial Configuration",
  //       to: "settings",
  //       access: ["admin"],
  //     },
  //     {
  //       title: "Currencies",
  //       to: "currencies",
  //       access: ["admin"],
  //     },
  //   ],
  // },
  // {
  //   title: "Tax Management   ",
  //   classsChange: "mm-collapse",
  //   iconStyle: SVGICON.TaxManagement,
  //   access: ["admin"],
  //   content: [
  //     {
  //       title: "Tax Configurations",
  //       to: "tax",
  //       access: ["admin"],
  //     },
  //   ],
  // },
  //HRM
  {
    title: "HRM",
    classsChange: "menu-title",
    access: ["admin", "supervisor"],
  },

  {
    title: "Payroll",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.PayRoll,
    access: ["admin"],
    content: [
      {
        title: "Payroll",
        to: "payroll",
        access: ["admin"],
      },
      {
        title: "Employee Attendance",
        to: "attendence-sheet",
        access: ["admin"],
      },
      {
        title: "Leaves",
        to: "leaves",
        access: ["admin"],
      },

      {
        title: "Salary Slips",
        to: "salary-slips",
        access: ["admin", "supervisor"],
      },

      {
        title: "Salary",
        to: "salary",
        access: ["admin"],
      },
      // {
      //   title: "Shift",
      //   to: "shiftType",
      //   access: ["admin"],
      // },
    ],
  },

  {
    title: "Shift",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.LeadSvg,
    to: "shiftType",
    // access: ["admin", "supervisor"],
  },

  {
    title: "Loans",
    to: "loans",
    // access: ["admin"],
    iconStyle: SVGICON.PayRoll,
    // content: [
    //   {
    //     title: "Create Loan",
    //     to: "loans/create",
    //     access: ["admin"],
    //   },
    //   {
    //     title: "Loan List",
    //     to: "loans",
    //     access: ["admin"],
    //   },
    //   {
    //     title: "Loan view",
    //     to: "loans/view/:loansId",
    //     access: ["admin"],
    //   },
    // ],
  },
  {
    title: "Leave Management",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.LeaveManagement,
    access: ["admin", "supervisor"],
    content: [
      {
        title: "All Leaves",
        to: "leaves",
        access: ["admin", "supervisor"],
      },
      {
        title: "Create Leaves",
        to: "leave/create",
        access: ["admin", "supervisor"],
      },
      {
        title: "Leave Types",
        to: "leaves-type",
        access: ["admin"],
      },
      {
        title: "Leave Balance",
        to: "leave-balance",
        access: ["admin"],
      },
    ],
  },
  {
    title: "Attendance",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Attendance,
    access: ["admin", "supervisor"],
    content: [
      {
        title: "Add Attendence",
        to: "attendence/add",
        access: ["admin"],
      },
      {
        title: "Today's Attendence",
        to: "today-attendence",
        access: ["admin", "supervisor"],
      },
      {
        title: "Attendence Sheet",
        to: "attendence-sheet",
        access: ["admin", "supervisor"],
      },
    ],
  },

  {
    title: "Holiday",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Holiday,
    to: "holidays",
    access: ["admin"],
  },
  {
    title: "Employee",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Employee,
    to: "employee",
    access: ["admin", "supervisor"],
  },

  //User management

  {
    title: "User management",
    classsChange: "menu-title",
    access: ["admin"],
  },
  {
    title: "Group Permission",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Users,
    to: "group-permission",
    access: ["admin"],
  },
];
