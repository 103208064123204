import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import Select from "react-select";

const StepThree = ({
  prevStep,

  values,
  setTherdStepData,
  handleSendData,
  formData,
  setFormData,
  isLoading,
}) => {
  // Define validation schema for the third step
  const { leadId } = useParams();
  const navigate = useNavigate();

  const [isView, setIsView] = useState(false);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);
  const stepThreeValidationSchema = Yup.object().shape({
    mailingAddress: Yup.string().required(),
    // city: Yup.string().optional(),
    // stateOrProvince: Yup.string().optional(),
    // area: Yup.string().optional(),
    // country: Yup.string().optional(),
    // customerTRN: Yup.string().optional(),
    // permitDetails: Yup.string().max(255, "less than 255 letters"),
  });
  const emirates = [
    { label: "Abu Dhabi", value: "Abu Dhabi" },
    { label: "Dubai", value: "Dubai" },
    { label: "Sharjah", value: "Sharjah" },
    { label: "Umm Al Qaiwain", value: "Umm Al Qaiwain" },
    { label: "Fujairah", value: "Fujairah" },
    { label: "Ajman", value: "Ajman" },
    { label: "Ras Al Khaimah", value: "Ras Al Khaimah" },
  ];

  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      validationSchema={stepThreeValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isView) {
          navigate("lead");
        } else {
          handleSendData(values);
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <section>
            <div className="row">
              <div className="form-group mb-3 col-xl-12">
                <label className="text-label">Address</label>
                <div className="input-group">
                  <Field
                    as="textarea"
                    className={`form-control ${
                      touched.mailingAddress && errors.mailingAddress
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Address"
                    name="mailingAddress"
                    onChange={(e) => {
                      handleChange(e); // Handle change event
                      setFormData({
                        ...formData,
                        mailingAddress: e.target.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={values.mailingAddress}
                    disabled={isView}
                  />
                  <div className="invalid-feedback animated fadeInUp">
                    <ErrorMessage name="address" />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mt-2 col-xl-6">
                <label className="text-label">City</label>
                <div className="input-group">
               
                  <Select
                    maxMenuHeight={240}
                    classNamePrefix="form-control"
                    name="city"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "city",
                          value: selectedOption.value,
                        },
                      });
                      setFormData({ ...formData, city: selectedOption.value });
                    }}
                    onBlur={handleBlur}
                    value={emirates.find(
                      (option) => option.value === values.city
                    )}
                    isDisabled={isView}
                    options={emirates}
                    menuPlacement="bottom"
                    placeholder="Select City"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                    }}
                  />
                  <div className="invalid-feedback animated fadeInUp">
                    <ErrorMessage name="city" />
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 mt-2 col-xl-6">
                <label className="text-label">State/Province</label>
                <div className="input-group">
                  <Field
                    type="text"
                    className={`form-control ${
                      touched.stateOrProvince && errors.stateOrProvince
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="State/Province"
                    name="stateOrProvince"
                    onChange={(e) => {
                      handleChange(e); // Handle change event
                      setFormData({
                        ...formData,
                        stateOrProvince: e.target.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={values.stateOrProvince}
                    disabled={isView}
                  />
                  <div className="invalid-feedback animated fadeInUp">
                    <ErrorMessage name="stateOrProvince" />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mt-2 col-xl-6">
                <label className="text-label">Area</label>
                <div className="input-group">
                  <Field
                    type="text"
                    className={`form-control ${
                      touched.area && errors.area ? "is-invalid" : ""
                    }`}
                    placeholder="Area"
                    name="area"
                    onChange={(e) => {
                      handleChange(e); // Handle change event
                      setFormData({ ...formData, area: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.area}
                    disabled={isView}
                  />
                  <div className="invalid-feedback animated fadeInUp">
                    <ErrorMessage name="area" />
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 mt-2 col-xl-6">
                <label className="text-label">Country</label>
                <div className="input-group">
                  <Field
                    type="text"
                    className={`form-control ${
                      touched.country && errors.country ? "is-invalid" : ""
                    }`}
                    placeholder="Country"
                    name="country"
                    onChange={(e) => {
                      handleChange(e); // Handle change event
                      setFormData({ ...formData, country: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.country}
                    disabled={isView}
                  />
                  <div className="invalid-feedback animated fadeInUp">
                    <ErrorMessage name="country" />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mt-2 col-xl-6">
                <label className="text-label">Customer TRN</label>
                <div className="input-group">
                  <Field
                    type="text"
                    className={`form-control ${
                      touched.customerTRN && errors.customerTRN
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Customer TRN"
                    name="TRN"
                    onChange={(e) => {
                      handleChange(e);
                      setFormData({ ...formData, TRN: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.TRN}
                    disabled={isView}
                  />
                  <div className="invalid-feedback animated fadeInUp">
                    <ErrorMessage name="customerTRN" />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mt-2 col-xl-3">
                <label className="text-label">Appointment Time</label>
                <div className="input-group">
                  <input
                    type="time"
                    className={`form-control`}
                    placeholder="Appointment Time"
                    name="appointmentTime"
                    onChange={(e) => {
                      handleChange(e);
                      setFormData({
                        ...formData,
                        appointmentTime: e.target.value,
                      });

                      setTherdStepData({
                        ...values,
                        appointmentTime: e.target.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={formData.appointmentTime}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display:
                        touched.appointmentTime && errors.appointmentTime
                          ? "block"
                          : "none",
                    }}
                  ></div>
                </div>
              </div>
              <div className="form-group mb-3 mt-2 col-xl-3">
                <label className="text-label">Appointment Date</label>
                <div className="input-group">
                  <input
                    type="date"
                    className={`form-control`}
                    placeholder="Appointment Date"
                    name="appointmentDate"
                    onChange={(e) => {
                      handleChange(e);
                      setFormData({
                        ...formData,
                        appointmentDate: e.target.value,
                      });

                      setTherdStepData({
                        ...values,
                        appointmentDate: e.target.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={
                      formData.appointmentDate
                        ? formData.appointmentDate?.slice(0, 10)
                        : ""
                    }
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display:
                        touched.appointmentDate && errors.appointmentDate
                          ? "block"
                          : "none",
                    }}
                  ></div>
                </div>
              </div>

              <div className="form-group mb-3 mt-2 col-xl-12">
                <div className="form-check">
                  <Field
                    type="checkbox"
                    className="form-check-input"
                    id="permitRequired"
                    name="permitRequired"
                    checked={values?.permitRequired} // Set the checked attribute based on the value of permitRequired field
                    onChange={(e) => {
                      // handleChange(e); // Handle change event
                      setFieldValue("permitRequired", e.target.checked);
                      setFormData({
                        ...formData,
                        permitRequired: e.target.checked,
                      }); // Set permitRequired based on checkbox checked state
                    }}
                    disabled={isView || values?.agreementId}
                  />

                  <label className="form-check-label" htmlFor="permitRequired">
                    Permit Required
                  </label>
                </div>

                {/* Conditionally render the textarea if permit is required */}
                {values?.permitRequired && (
                  <>
                    <div className="mt-2">
                      <label className="text-label">Permit Details</label>
                      <textarea
                        className={`form-control ${
                          touched.permitDetails && errors.permitDetails
                            ? "is-invalid"
                            : ""
                        }`}
                        rows="4"
                        placeholder="Enter permit details"
                        name="permitDetails"
                        // onChange={(e) => {
                        //   // handleChange(e);
                        //   setFormData({
                        //     ...formData,
                        //     permitDetails: e.target.checked,
                        //   });
                        // }}
                        onChange={(e) => {
                          handleChange(e);
                          setFormData({
                            ...formData,
                            permitDetails: e.target.value,
                          });

                          setTherdStepData({
                            ...values,
                            permitDetails: e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={formData.permitDetails}
                        disabled={isView}
                      />
                      <div className="invalid-feedback animated fadeInUp">
                        <ErrorMessage name="permitDetails" />
                      </div>
                    </div>
                    <div className="mt-2">
                      {values?.permitImg && (
                        <label className="text-label">Permit Image</label>
                      )}

                      <input
                        type="file"
                        className={`form-control ${
                          touched.permitImg && errors.permitImg
                            ? "is-invalid"
                            : ""
                        }`}
                        name="permitImg"
                        onChange={(e) => {
                          const file = e.target.files[0];

                          setFieldValue("permitImg", file);
                          setFormData({
                            ...formData,
                            permitImg: file,
                          });
                          e.target.value = null;
                        }}
                        onBlur={handleBlur}
                        disabled={isView}
                      />

                      {/* {values?.permitImg && (
                        <div className="mt-2">
                          <div
                            className="mt-2"
                            style={{
                              maxWidth: "350px",
                              marginBottom: "10px",
                              position: "relative",
                            }}
                          >
                            {typeof values.permitImg === "string" ||
                            values.permitImg.type.startsWith("image/") ? (
                              <img
                                src={
                                  typeof values?.permitImg === "string"
                                    ? values?.permitImg
                                    : URL.createObjectURL(values?.permitImg)
                                }
                                alt="Permit Preview"
                                className="mx-1 img-thumbnail"
                              />
                            ) : (
                              <div className="file-name">
                                <span>{values?.permitImg.name}</span>
                              </div>
                            )}
                            <span
                              onClick={() => {
                                setFieldValue("permitImg", "");
                                setFormData({
                                  ...formData,
                                  permitImg: "",
                                });
                              }}
                              style={{
                                position: "absolute",
                                right: "5px",
                                top: "10px",
                              }}
                              className="btn btn-danger"
                            >
                              <i className="fas fa-trash" title="Delete"></i>
                            </span>
                          </div>
                        </div>
                      )} */}
                      {values?.permitImg && (
                        <div className="mt-2">
                          <div
                            className="d-flex align-items-center" // Added flexbox classes for alignment
                            style={{
                              maxWidth: "350px",
                              marginBottom: "10px",
                              position: "relative",
                              gap: "10px", // Optional: Adds spacing between buttons
                            }}
                          >
                            {typeof values.permitImg === "string" ? (
                              values.permitImg.match(
                                /\.(jpeg|jpg|gif|png)$/i
                              ) ? (
                                <img
                                  src={values?.permitImg}
                                  alt="Permit Preview"
                                  className="mx-1 img-thumbnail"
                                  style={{ maxWidth: "100%" }}
                                />
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    window.open(values.permitImg, "_blank")
                                  }
                                  className="btn btn-primary"
                                >
                                  Permit Preview
                                </button>
                              )
                            ) : values.permitImg.type.startsWith("image/") ? (
                              <img
                                src={URL.createObjectURL(values?.permitImg)}
                                alt="Permit Preview"
                                className="mx-1 img-thumbnail"
                                style={{ maxWidth: "100%" }}
                              />
                            ) : (
                              <div className="file-name">
                                <span>{values?.permitImg.name}</span>
                              </div>
                            )}
                            <span
                              onClick={() => {
                                setFieldValue("permitImg", "");
                                setFormData({
                                  ...formData,
                                  permitImg: "",
                                });
                              }}
                              className="btn btn-danger"
                            >
                              <i className="fas fa-trash" title="Delete"></i>
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="invalid-feedback animated fadeInUp">
                        <ErrorMessage name="permitImg" />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {(values?.inspectionCharge ||
                values?.inspectionCharge ||
                values.issue ||
                values.commonRoomOption ||
                values.additionalCharges ||
                values.charges ||
                values?.outsideVillaDropdown ||
                values?.aCInstallationCharge ||
                values.haveACUnitOption ||
                values.acUnit ||
                values.time ||
                values.date) && (
                <div>
                  <Alert variant="primary">
                    <strong>{"Inquery Information"}</strong>
                  </Alert>
                  <div className="row">
                    {values?.inspectionCharge && (
                      <div className="form-group mb-3 mt-2 col-xl-12">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="inspectionCharge"
                            name="inspectionCharge"
                            checked={values?.inspectionCharge} // Set the checked attribute based on the value of permitRequired field
                            onChange={(e) => {
                              // handleChange(e); // Handle change event
                              setFieldValue(
                                "inspectionCharge",
                                e.target.checked
                              );
                              setFormData({
                                ...formData,
                                permitRequired: e.target.checked,
                              }); // Set permitRequired based on checkbox checked state
                            }}
                            disabled={isView || values?.inspectionCharge}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="permitRequired"
                          >
                            Inspection Charge
                          </label>
                        </div>
                      </div>
                    )}

                    {values.issue && (
                      <div className="form-group mb-3 mt-2 col-xl-6">
                        <label className="text-label">Issue</label>
                        <div className="input-group">
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.issue && errors.issue ? "is-invalid" : ""
                            }`}
                            placeholder="Issue"
                            name="issue"
                            onChange={(e) => {
                              handleChange(e); // Handle change event
                              setFormData({
                                ...formData,
                                issue: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.issue}
                            disabled={isView}
                          />
                          <div className="invalid-feedback animated fadeInUp">
                            <ErrorMessage name="issue" />
                          </div>
                        </div>
                      </div>
                    )}

                    {values.commonRoomOption && (
                      <div className="form-group mb-3 mt-2 col-xl-6">
                        <label className="text-label">Common Room Option</label>
                        <div className="input-group">
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.commonRoomOption &&
                              errors.commonRoomOption
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Common Room Option"
                            name="commonRoomOption"
                            onChange={(e) => {
                              handleChange(e); // Handle change event
                              setFormData({
                                ...formData,
                                commonRoomOption: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.commonRoomOption}
                            disabled={isView}
                          />
                          <div className="invalid-feedback animated fadeInUp">
                            <ErrorMessage name="commonRoomOption" />
                          </div>
                        </div>
                      </div>
                    )}

                    {values.additionalCharges && (
                      <div className="form-group mb-3 mt-2 col-xl-6">
                        <label className="text-label">
                          {" "}
                          Additional Charges
                        </label>
                        <div className="input-group">
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.additionalCharges &&
                              errors.additionalCharges
                                ? "is-invalid"
                                : ""
                            }`}
                            name="additionalCharges"
                            onChange={(e) => {
                              handleChange(e); // Handle change event
                              setFormData({
                                ...formData,
                                additionalCharges: e.target.checked,
                              });
                            }}
                            onBlur={handleBlur}
                            checked={values.additionalCharges}
                            disabled={isView}
                          />
                          <div className="invalid-feedback animated fadeInUp">
                            <ErrorMessage name="additionalCharges" />
                          </div>
                        </div>
                      </div>
                    )}

                    {values.charges && (
                      <div className="form-group mb-3 mt-2 col-xl-6">
                        <label className="text-label">Service Charges</label>
                        <div className="input-group">
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.charges && errors.charges
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Charges"
                            name="charges"
                            onChange={(e) => {
                              handleChange(e); // Handle change event
                              setFormData({
                                ...formData,
                                charges: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.charges}
                            disabled={isView}
                          />
                          <div className="invalid-feedback animated fadeInUp">
                            <ErrorMessage name="charges" />
                          </div>
                        </div>
                      </div>
                    )}

                    {values?.outsideVillaDropdown && (
                      <div className="form-group mb-3 mt-2 col-xl-12">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="outsideVillaDropdown"
                            name="outsideVillaDropdown"
                            checked={values?.outsideVillaDropdown} // Set the checked attribute based on the value of permitRequired field
                            onChange={(e) => {
                              // handleChange(e); // Handle change event
                              setFieldValue(
                                "outsideVillaDropdown",
                                e.target.checked
                              );
                              setFormData({
                                ...formData,
                                outsideVillaDropdown: e.target.checked,
                              }); // Set permitRequired based on checkbox checked state
                            }}
                            disabled={isView}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="outsideVillaDropdown"
                          >
                            Is Out Side Villa
                          </label>
                        </div>
                      </div>
                    )}

                    {values?.aCInstallationCharge && (
                      <div className="form-group mb-3 mt-2 col-xl-12">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="aCInstallationCharge"
                            name="aCInstallationCharge"
                            checked={values?.aCInstallationCharge} // Set the checked attribute based on the value of permitRequired field
                            onChange={(e) => {
                              // handleChange(e); // Handle change event
                              setFieldValue(
                                "aCInstallationCharge",
                                e.target.checked
                              );
                              setFormData({
                                ...formData,
                                aCInstallationCharge: e.target.checked,
                              }); // Set permitRequired based on checkbox checked state
                            }}
                            disabled={isView}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="permitRequired"
                          >
                            AC Installation Charge
                          </label>
                        </div>
                      </div>
                    )}

                    {values.haveACUnitOption && (
                      <div className="form-group mb-3 mt-2 col-xl-6">
                        <label className="text-label">
                          Have AC Unit Option
                        </label>
                        <div className="input-group">
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.haveACUnitOption &&
                              errors.haveACUnitOption
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Have AC Unit Option"
                            name="haveACUnitOption"
                            onChange={(e) => {
                              handleChange(e); // Handle change event
                              setFormData({
                                ...formData,
                                haveACUnitOption: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.haveACUnitOption}
                            disabled={isView}
                          />
                          <div className="invalid-feedback animated fadeInUp">
                            <ErrorMessage name="haveACUnitOption" />
                          </div>
                        </div>
                      </div>
                    )}

                    {values.acUnit && (
                      <div className="form-group mb-3 mt-2 col-xl-6">
                        <label className="text-label">AC Unit</label>
                        <div className="input-group">
                          <Field
                            type="number"
                            className={`form-control ${
                              touched.acUnit && errors.acUnit
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="AC Unit"
                            name="acUnit"
                            onChange={(e) => {
                              handleChange(e); // Handle change event
                              setFormData({
                                ...formData,
                                acUnit: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.acUnit}
                            disabled={isView}
                          />
                          <div className="invalid-feedback animated fadeInUp">
                            <ErrorMessage name="acUnit" />
                          </div>
                        </div>
                      </div>
                    )}

                    {values.date && (
                      <div className="form-group mb-3 mt-2 col-xl-6">
                        <label className="text-label">Date</label>
                        <div className="input-group">
                          <Field
                            type="date"
                            className={`form-control ${
                              touched.date && errors.date ? "is-invalid" : ""
                            }`}
                            name="date"
                            onChange={(e) => {
                              handleChange(e); // Handle change event
                              setFormData({
                                ...formData,
                                date: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.date ? values.date.slice(0, 10) : ""} // Format date string to "YYYY-MM-DD"
                            disabled={isView}
                          />
                          <div className="invalid-feedback animated fadeInUp">
                            <ErrorMessage name="date" />
                          </div>
                        </div>
                      </div>
                    )}

                    {values.time
                      ? values.time
                      : values.startTime && (
                          <div className="form-group mb-3 mt-2 col-xl-6">
                            <label className="text-label">Start Time</label>
                            <div className="input-group">
                              <Field
                                type="time"
                                className={`form-control ${
                                  touched.time && errors.time
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Time"
                                name="startTime"
                                onChange={(e) => {
                                  handleChange(e); // Handle change event
                                  setFormData({
                                    ...formData,
                                    startTime: e.target.value,
                                  });
                                }}
                                onBlur={handleBlur}
                                value={
                                  values.time ? values.time : values.startTime
                                }
                                disabled={isView}
                              />
                              <div className="invalid-feedback animated fadeInUp">
                                <ErrorMessage name="time" />
                              </div>
                            </div>
                          </div>
                        )}

                    {values.endTime && (
                      <div className="form-group mb-3 mt-2 col-xl-6">
                        <label className="text-label">End Time</label>
                        <div className="input-group">
                          <Field
                            type="time"
                            className={`form-control ${
                              touched.time && errors.time ? "is-invalid" : ""
                            }`}
                            placeholder="End Time"
                            name="endTime"
                            onChange={(e) => {
                              handleChange(e); // Handle change event
                              setFormData({
                                ...formData,
                                endTime: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.endTime || ""}
                            disabled={isView}
                          />
                          <div className="invalid-feedback animated fadeInUp">
                            <ErrorMessage name="time" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>

          {/* Navigation Buttons */}
          {!isView && (
            <div className="text-end toolbar toolbar-bottom p-2">
              <button
                className="btn btn-primary sw-btn-next ms-1"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : !leadId ? (
                  "Save"
                ) : isView ? (
                  "Finish"
                ) : (
                  "Update"
                )}
              </button>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default StepThree;
