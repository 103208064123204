import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import {
  useCreateShiftMutation,
  useUpdateShiftMutation,
} from "../../../../../features/hrm/payroll/shift/shiftApiSlice";

const ShiftModal = (props) => {
  const [createShift] = useCreateShiftMutation();
  const [updateShift] = useUpdateShiftMutation();
  const handleSubmit = async (values, { resetForm }) => {
    try {
      let { hours, minutes } = calculateWorkingHours(
        values.startTime,
        values.endTime
      );
      if (
        values.excludeBreak &&
        values.breakStart !== "" &&
        values.breakEnd !== ""
      ) {
        const { hours: breakHours, minutes: breakMinutes } =
          calculateWorkingHours(values.breakStart, values.breakEnd);
        hours -= breakHours;
        minutes -= breakMinutes;

        if (minutes < 0) {
          hours -= 1;
          minutes += 60;
        }
      }
      if (
        values.startTime !== "" &&
        values.endTime !== "" &&
        values.noOfDays != ""
      ) {
        let { hours, minutes } = calculateWorkingHours(
          values.startTime,
          values.endTime
        );
        if (
          values.excludeBreak &&
          values.breakStart !== "" &&
          values.breakEnd !== ""
        ) {
          const { hours: breakHours, minutes: breakMinutes } =
            calculateWorkingHours(values.breakStart, values.breakEnd);
          hours -= breakHours;
          minutes -= breakMinutes;

          if (minutes < 0) {
            hours -= 1;
            minutes += 60;
          }
        }
        const { thresholdMaxHours, thresholdMaxMinutes } = calculateThreshold(
          hours,
          minutes,
          values?.noOfDays
        );
        values.thresholdMaxMinutes = thresholdMaxMinutes;
        values.thresholdMaxHours = thresholdMaxHours;
      }

      values.workingHours = hours;
      values.workingMinutes = minutes;

      if (props?.data == null) {
        try {
          const sanitizedValues = Object.fromEntries(
            Object.entries(values).map(([key, value]) => [
              key,
              value === "" ? null : value,
            ])
          );
          const result = await createShift(sanitizedValues);
          if (result.data.message == "Shift created successfully.") {
            notifySuccess(result?.data?.message);
            props.refetch();
            props.onHide();
          } else {
            notifyError(result?.data?.message);
          }
        } catch (error) {
          notifyError(error?.message);
        }
      } else {
        try {
          const result = await updateShift({
            shiftId: props?.data?.shiftId,
            updatedShift: values,
          });
          notifySuccess(result?.data?.message);
          props.refetch();
          props.onHide();
        } catch (err) {
          console.error("Failed to update shift: ", err);
        }
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    shiftName: Yup.string().required("Shift name is required"),
    flexibleShift: Yup.boolean(),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    breakStart: Yup.string().nullable(), // Optional
    breakEnd: Yup.string().nullable(), // Optional
    excludeBreak: Yup.boolean().nullable(), // Optional
    startTimeThreshold: Yup.number().nullable(), // Optional
    endTimeThreshold: Yup.number().nullable(), // Optional
    overtimeStart: Yup.string().nullable(), // Optional
    maxOvertime: Yup.string().nullable(), // Optional
    noOfDays: Yup.number().nullable(), // Optional
    // nightShift: Yup.boolean(),
    // workingDays: Yup.array()
    //   .of(Yup.string())
    //   .required("At least one working day is required"),
  });

  const workingDayOptions = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  function calculateWorkingHours(startTimeStr, endTimeStr) {
    // Convert to Date objects
    const [startHours, startMinutes] = startTimeStr.split(":").map(Number);
    const [endHours, endMinutes] = endTimeStr.split(":").map(Number);

    const startTime = new Date();
    startTime.setHours(startHours, startMinutes, 0, 0);

    const endTime = new Date();
    endTime.setHours(endHours, endMinutes, 0, 0);

    // Calculate the difference in milliseconds
    const timeDiff = endTime - startTime;

    // Convert the difference to hours and minutes
    const totalMinutes = timeDiff / (1000 * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }
  const calculateThreshold = (hours, minutes, noOfDays) => {
    // Calculate total minutes
    const totalMinutes = minutes * noOfDays;
    const totalHours = hours * noOfDays;

    // Convert total minutes to hours and minutes
    const additionalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    // Calculate final threshold hours and minutes
    const thresholdMaxHours = totalHours + additionalHours;
    const thresholdMaxMinutes = remainingMinutes;

    return { thresholdMaxHours, thresholdMaxMinutes };
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.data == null ? "Add Shift" : "Edit Shift"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            shiftName: props?.data?.shiftName || "",
            flexibleShift: props?.data?.flexibleShift || true,
            nightShift: props?.data?.nightShift || false,
            startTime: props?.data?.startTime || "",
            endTime: props?.data?.endTime || "",
            breakStart: props?.data?.breakStart || "",
            breakEnd: props?.data?.breakEnd || "",
            excludeBreak: props?.data?.excludeBreak || false,
            startTimeThreshold: props?.data?.startTimeThreshold || 0,
            endTimeThreshold: props?.data?.endTimeThreshold || 0,
            overtimeStart: props?.data?.overtimeStart || "",
            maxOvertime: props?.data?.maxOvertime || "",
            noOfDays: props?.data?.noOfDays || 22,
            // workingDays: props?.data?.workingDays || [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <label className="text-label">Shift Name</label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Enter shift name"
                      name="shiftName"
                    />
                    <ErrorMessage
                      name="shiftName"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
                <hr />
                <h3>Attendance Setting</h3>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label" style={{ margin: "3px" }}>
                      {" "}
                      Working Hour:
                      {values.startTime !== "" && values.endTime !== ""
                        ? (() => {
                            let { hours, minutes } = calculateWorkingHours(
                              values.startTime,
                              values.endTime
                            );
                            if (
                              values.excludeBreak &&
                              values.breakStart !== "" &&
                              values.breakEnd !== ""
                            ) {
                              const {
                                hours: breakHours,
                                minutes: breakMinutes,
                              } = calculateWorkingHours(
                                values.breakStart,
                                values.breakEnd
                              );
                              hours -= breakHours;
                              minutes -= breakMinutes;

                              if (minutes < 0) {
                                hours -= 1;
                                minutes += 60;
                              }
                            }

                            // setFieldValue("workingHours", hours);
                            // setFieldValue("workingMinutes", minutes);
                            return ` ${hours < 10 ? "0" + hours : hours}:${
                              minutes < 10 ? "0" + minutes : minutes
                            }`;
                          })()
                        : ""}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label" style={{ margin: "3px" }}>
                      {" "}
                      Working Hour Threshold:
                      {values.startTime !== "" &&
                      values.endTime !== "" &&
                      values.noOfDays != ""
                        ? (() => {
                            let { hours, minutes } = calculateWorkingHours(
                              values.startTime,
                              values.endTime
                            );
                            if (
                              values.excludeBreak &&
                              values.breakStart !== "" &&
                              values.breakEnd !== ""
                            ) {
                              const {
                                hours: breakHours,
                                minutes: breakMinutes,
                              } = calculateWorkingHours(
                                values.breakStart,
                                values.breakEnd
                              );
                              hours -= breakHours;
                              minutes -= breakMinutes;

                              if (minutes < 0) {
                                hours -= 1;
                                minutes += 60;
                              }
                            }
                            const { thresholdMaxHours, thresholdMaxMinutes } =
                              calculateThreshold(
                                hours,
                                minutes,
                                values?.noOfDays
                              );

                            return ` ${
                              thresholdMaxHours < 10
                                ? "0" + thresholdMaxHours
                                : thresholdMaxHours
                            }:${
                              thresholdMaxMinutes < 10
                                ? "0" + thresholdMaxMinutes
                                : thresholdMaxMinutes
                            }`;
                          })()
                        : ""}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <Field
                      type="checkbox"
                      name="flexibleShift"
                      className="form-check-input"
                      checked={true}
                      disabled
                    />
                    <label className="text-label" style={{ margin: "3px" }}>
                      {" "}
                      Flexible Shift
                    </label>
                    <ErrorMessage
                      name="flexibleShift"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <div className="form-group mb-3">
                    <Field
                      type="checkbox"
                      name="nightShift"
                      className="form-check-input"
                    />
                    <label className="text-label" style={{ margin: "3px" }}>
                      {" "}
                      Night Shift
                    </label>
                    <ErrorMessage
                      name="nightShift"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">
                      Start Time* (e.g. 09:00 AM)
                    </label>
                    <Field
                      type="time"
                      className="form-control"
                      name="startTime"
                    />

                    <ErrorMessage
                      name="startTime"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">
                      End Time* (e.g. 09:00 PM)
                    </label>
                    <Field
                      type="time"
                      className="form-control"
                      name="endTime"
                    />
                    <ErrorMessage
                      name="endTime"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label className="text-label" style={{ margin: "3px" }}>
                    Break Working Hour:
                    {values.breakStart !== "" && values.breakEnd !== ""
                      ? (() => {
                          const { hours, minutes } = calculateWorkingHours(
                            values.breakStart,
                            values.breakEnd
                          );

                          return ` ${hours < 10 ? "0" + hours : hours}:${
                            minutes < 10 ? "0" + minutes : minutes
                          }`;
                        })()
                      : ""}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">
                      Break Start (e.g. 01:00 PM)
                    </label>
                    <Field
                      type="time"
                      className="form-control"
                      name="breakStart"
                    />
                    <ErrorMessage
                      name="breakStart"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">
                      Break End (e.g. 02:00 PM)
                    </label>
                    <Field
                      type="time"
                      className="form-control"
                      name="breakEnd"
                    />
                    <ErrorMessage
                      name="breakEnd"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mb-3">
                <Field
                  type="checkbox"
                  name="excludeBreak"
                  className="form-check-input"
                />
                <label className="text-label" style={{ margin: "3px" }}>
                  Break Time Exclude from working hours
                </label>

                <ErrorMessage
                  name="excludeBreak"
                  component="div"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                />
              </div>
              {values?.flexibleShift == false && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="text-label">
                          Start Time Threshold
                        </label>
                        <Field
                          type="number"
                          className="form-control"
                          name="startTimeThreshold"
                        />
                        <ErrorMessage
                          name="startTimeThreshold"
                          component="div"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="text-label">End Time Threshold</label>
                        <Field
                          type="number"
                          className="form-control"
                          name="endTimeThreshold"
                        />
                        <ErrorMessage
                          name="endTimeThreshold"
                          component="div"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="text-label">
                          Overtime Start Time
                        </label>
                        <Field
                          type="time"
                          className="form-control"
                          name="overtimeStart"
                        />
                        <ErrorMessage
                          name="overtimeStart"
                          component="div"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="text-label">Max Overtime</label>
                        <Field
                          type="time"
                          className="form-control"
                          name="maxOvertime"
                        />
                        <ErrorMessage
                          name="maxOvertime"
                          component="div"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <label className="text-label">
                      Consider Shift as a Number of days
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      name="noOfDays"
                    />
                    <ErrorMessage
                      name="noOfDays"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="form-group mb-3">
                <label className="text-label">Working Days</label>
                <Select
                  isMulti
                  options={workingDayOptions}
                  name="workingDays"
                  onChange={(selectedOptions) =>
                    setFieldValue(
                      "workingDays",
                      selectedOptions.map((option) => option.value)
                    )
                  }
                  value={workingDayOptions.filter((option) =>
                    values.workingDays.includes(option.value)
                  )}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="workingDays"
                  component="div"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                />
              </div> */}

              <div className="d-flex justify-content-between mt-5">
                <Button variant="secondary" onClick={props.onHide}>
                  Cancel
                </Button>
                <Button type="submit" variant="success">
                  {props?.data == null ? "Add Shift" : "Update Shift"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ShiftModal;
