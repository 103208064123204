import React from "react";
import { Modal, Button } from "react-bootstrap";

const LeaveDetailsModal = ({ show, handleClose, leaveData }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Leave Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h4>{leaveData.user?.firstName + " " + leaveData.user?.lastName}</h4>
        </div>
        <div className="mt-3">
          <p>
            <strong>Type:</strong> {leaveData?.LeaveType?.leaveName}
          </p>
          <p>
            <strong>No of Days:</strong> {leaveData.numberOfDays}
          </p>
          <p>
            <strong>From:</strong>{" "}
            {new Date(leaveData.fromDate).toLocaleDateString()}
          </p>
          <p>
            <strong>To:</strong>{" "}
            {new Date(leaveData.toDate).toLocaleDateString()}
          </p>
          <p>
            <strong>Status:</strong> {leaveData.status}
          </p>
          <p>
            <strong>Reason:</strong> {leaveData.reason}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeaveDetailsModal;
