import React, { useState, useEffect } from "react";
import { useFormik, Formik } from "formik";
import * as yup from "yup";
import Listing from "../listing";
import swal from "sweetalert";
import {
  useGetSourceQuery,
  usePostSourceMutation,
  useDeleteSourceMutation,
} from "../../../../features/master/source/sourceApiSlice";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const validateSchema = yup.object().shape({
  name: yup
    .string()
    .required("Source is required")
    .min(2, "Must be minimum 2 letters")
    .max(100, "Must be less than 100 letters"),
});

export default function PropertyType() {
  const userInfo = useSelector(selectCurrentUser);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [postSource, { isLoading }] = usePostSourceMutation();
  const { data, error, refetch } = useGetSourceQuery();
  const [deleteSource] = useDeleteSourceMutation();

  useEffect(() => {
    if (data?.sources) {
      setFilteredData(data.sources);
    }
  }, [data]);

  const handleDelete = async (id) => {
    const result = await deleteSource(id);
    refetch();
    notifySuccess(result?.data?.message);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await postSource(values);
      refetch();
      notifySuccess(result?.data?.message);
      resetForm();
    } catch (error) {
      notifyError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const SweetAlert = (id) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(id);
      } else {
        swal("Your data is safe!");
      }
    });

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const filtered = data?.sources.filter((source) =>
      source.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Source</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="row">
                  <div className="col-md-6">
                    <Formik
                      initialValues={{ name: "" }}
                      enableReinitialize={true}
                      validationSchema={validateSchema}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className={`form-group mb-3`}>
                            <label className="text-label">Add Source</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Source"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              disabled={
                                !userInfo?.Role?.permissions?.includes(
                                  "source/create"
                                )
                              }
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.name && touched.name && errors.name}
                            </div>
                          </div>
                          <div className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>

                    <Listing
                      isCheck={"source"}
                      data={filteredData}
                      handleDelete={SweetAlert}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
