import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetShiftsQuery,
  useDeleteShiftMutation,
} from "../../../../../features/hrm/payroll/shift/shiftApiSlice";

import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import ShiftModal from "./create";
function tConvert(time) {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const Index = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { data: shifts = [], refetch } = useGetShiftsQuery();
  const [deleteShift] = useDeleteShiftMutation();

  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedShift, setSelectedShift] = useState(null);

  // Constants for pagination
  const recordsPerPage = 13;
  const filteredShifts = shifts?.filter((shift) =>
    shift?.shiftName?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const npage = Math.ceil(filteredShifts?.length / recordsPerPage);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredShifts?.slice(firstIndex, lastIndex);
  const pageNumbers = [...Array(npage + 1).keys()].slice(1);

  // Pagination Functions
  const prePage = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  const nextPage = () => currentPage < npage && setCurrentPage(currentPage + 1);
  const changePage = (pageNumber) => setCurrentPage(pageNumber);

  // Handle Delete
  const handleDelete = async (shift) => {
    try {
      const result = await deleteShift(shift?.shiftId);
      notifySuccess(result?.data?.message);
      refetch();
    } catch (err) {
      console.error("Failed to delete shift: ", err);
      notifyError("Failed to delete shift.");
    }
  };

  // Handle Edit
  const handleEdit = (shift) => {
    setSelectedShift(shift);
    setModal(true);
  };

  // SweetAlert Confirmation
  const SweetAlert = (shift) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(shift);
      }
    });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Shift Types</h4>
                    <div className="d-flex">
                      <div className="mx-4">
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search"
                          className="form-control me-2"
                        />
                      </div>
                      {user?.Role?.permissions?.includes(
                        "shiftType/create"
                      ) && (
                        <Link
                          onClick={() => setModal(true)}
                          className="btn btn-primary light btn-sm me-2"
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Shift
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Shift Name</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Working Hour(HH:MM)</th>
                          <th>Working Hour Threshold Time(M)</th>
                          <th>Flexible Shift</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((shift, index) => (
                          <tr key={index}>
                            <td>{firstIndex + index + 1}</td>
                            <td>{shift.shiftName}</td>
                            <td>{tConvert(shift?.startTime)}</td>
                            <td>{tConvert(shift?.endTime)}</td>
                            <td>
                              {shift?.workingHours < 10
                                ? "0" + shift?.workingHours
                                : shift?.workingHours}
                              :
                              {shift?.workingMinutes < 10
                                ? "0" + shift?.workingMinutes
                                : shift?.workingMinutes}
                            </td>
                            <td>
                              {shift?.thresholdMaxHours}:
                              {shift?.thresholdMaxMinutes}
                            </td>
                            <td>{shift.flexibleShift ? "Yes" : "No"}</td>

                            <td>
                              {user?.Role?.permissions?.includes(
                                "shiftType/edit/:shiftTypeId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(shift)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}
                              {/* {user?.Role?.permissions?.includes(
                                "shift/delete/shiftId"
                              ) && (
                                <span
                                  onClick={() => SweetAlert(shift)}
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Delete"
                                  ></i>
                                </span>
                              )} */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {firstIndex + 1} to{" "}
                        {lastIndex > filteredShifts.length
                          ? filteredShifts.length
                          : lastIndex}{" "}
                        of {filteredShifts.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {pageNumbers.map((n) => (
                            <Link
                              key={n}
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              onClick={() => changePage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShiftModal
          show={modal}
          onHide={() => {
            setModal(false);
            setSelectedShift(null);
          }}
          data={selectedShift}
          refetch={refetch}
        />
      </div>
    </>
  );
};

export default Index;
