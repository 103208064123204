// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";

import {
  usePostInvoiceMutation,
  useGetSalesPersonQuery,
} from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { useGetQoutationByAcceptedQuery } from "../../../../../features/quotaion/quotaionApiSlice";

import { useGetStockListQuery } from "../../../../../features/Inventory/stocklist/stockListApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import { useGetServicesQuery } from "../../../../../features/service/serviceApiSlice";
// import { useGetSubServicesQuery } from "../../../../../features/subservice/subserviceApiSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useGetSingleQoutaionQuery } from "../../../../../features/quotaion/quotaionApiSlice";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";

const quotationStatusList = [
  { value: "Draft", label: "Draft" },
  { value: "Pending Review", label: "Pending Review" },
  { value: "Sent", label: "Sent" },
  { value: "Under Consideration", label: "Under Consideration" },
  { value: "Accepted", label: "Accepted" },
  { value: "Rejected", label: "Rejected" },
  { value: "Expired", label: "Expired" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "In Progress", label: "In Progress" },
  { value: "Completed", label: "Completed" },
];

function tConvert(time) {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
const loginSchema = Yup.object().shape({
  leadId: Yup.string().optional("Lead ID is required"),
  invoiceNo: Yup.string().required("Invoice No. is required"),
  invoiceDate: Yup.date().required("Invoice Date is required"),
  invoiceDue: Yup.date().required("Invoice Due is required"),
  // paymentStatus: Yup.object().required("Payment Status is required"),
  // serviceId: Yup.string().required("Service ID is required"),
  // customerId: Yup.string().required("Customer ID is required"),
  phone: Yup.string().required("Phone is required"),
  discription: Yup.string(),
  email: Yup.string().email("Invalid email").optional("email is reuired"),
  address: Yup.string(),
  TRN: Yup.string().optional(),
});

const Create = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectLead, setSelectedLead] = useState(null);
  const { quotaionId } = useParams();
  const [directinvoice, setDirectInvoice] = useState(false);
  const { data: accounts = [] } = useGetAllAccountsQuery();

  const { data: getSingleQuotation, refetch } = useGetSingleQoutaionQuery(
    quotaionId,
    {
      skip: !quotaionId, // Skip the query if quotationId is not provided
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch, getSingleQuotation]);

  useEffect(() => {
    if (quotaionId !== undefined) {
      setDirectInvoice(true);
      // refetch();
      setSelectedLead(getSingleQuotation?.quotation);
      setInitialValues({
        leadId: getSingleQuotation?.quotation.leadId || "",
        invoiceNo: generateInvoiceNumber(),
        invoiceDate: "",
        invoiceDue: "",
        // paymentStatus: "",
        serviceId: "",
        customerId: getSingleQuotation?.quotation.customerId || "",
        phone: getSingleQuotation?.quotation.customer.phone || "",
        description: "",
        email: getSingleQuotation?.quotation.customer.email || "",
        address: getSingleQuotation?.quotation.customer.mailingAddress || "",
        TRN: getSingleQuotation?.quotation.customer?.TRN || "",
        sendMail: false,
        quotaionId: {
          label: `${getSingleQuotation?.quotation.customer?.firstname} ${getSingleQuotation?.quotation.customer?.lastname} ${getSingleQuotation?.quotation?.quotationNo}`,
          value: getSingleQuotation?.quotation?.quotaionId,
        },
        salesAccountId: "",
        receiveableAccountId: "",
      });
    }
  }, [getSingleQuotation, quotaionId]);

  const [subServicesList, setSubServicesList] = useState([]);
  const [itemss, setItemss] = useState([]);
  const [productTotalDetailPrice, setProductTotalDetailPrice] = useState({
    subtotal: 0,
    totalTax: 0,
    totalDiscount: 0,
    amountBeforeVat: 0,
    total: 0,
    grandTotal: 0,
  });
  const [initialValues, setInitialValues] = useState({
    leadId: "",
    invoiceNo: generateInvoiceNumber(),
    invoiceDate: "",
    invoiceDue: "",
    // paymentStatus: "",
    serviceId: "",
    customerId: "",
    phone: "",
    description: "",
    email: "",
    TRN: "",
    address: "",
    sendMail: false,
    paidAmount: 0,
    salesAccountId: "",
    receiveableAccountId: "",
  });
  const { data: subServices = [] } = useGetServicesQuery({
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const filteredSubServicesOptions = subServices?.services?.filter(
      (subservice) => selectLead?.serviceId === subservice?.serviceId
    );

    setItemss(selectLead?.subSeriviceList);
  }, [selectLead]);
  const [items, setItems] = useState([]);

  const [isUpdated, setIsUpdated] = useState(false);
  const navigate = useNavigate();

  const { data: qoutaions = [] } = useGetQoutationByAcceptedQuery();
  const { data: users = [] } = useGetSalesPersonQuery();
  const [content, setContent] = useState(`
  <head>

      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 20px;
          }
          .container {
              width: 80%;
              margin: auto;
             
              padding: 20px;
          }
         
         
        
          .section {
              margin-bottom: 20px;
          }
          .section h2 {
            
              padding-bottom: 5px;
          }
          .customer-details,
          .scope-of-work,
          .exclude-scope,
          .payment,
          .terms-conditions,
          .bank-details,
          .thank-you-note {
              margin-bottom: 20px;
          }
          .scope-table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
          }
          .scope-table th,
          .scope-table td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
          }
      </style>
  </head>
  <body>
      <div class="container">
        
         
          <div class="section scope-of-work">
              <h2>Scope of Work</h2>
              <table class="scope-table">
              
              </table>
          </div>
          
          <div class="section exclude-scope">
              <h2>Exclude Scope</h2>
              <p>[Details]</p>
          </div>
          
          <div class="section payment">
              <h2>Payment</h2>
              <p>[Payment Terms]</p>
          </div>
          
          <div class="section terms-conditions">
              <h2>General Terms & Conditions</h2>
              <p>[Terms & Conditions]</p>
          </div>
          
          <div class="section bank-details">
              <h2>Bank Details</h2>
              <p>[Bank Name]</p>
              <p>[Account Number]</p>
              <p>[IFSC Code]</p>
          </div>
          
          <div class="section thank-you-note">
              <h2>Thank You Note</h2>
              <p>[Thank You Message]</p>
          </div>
      </div>
  </body>
  </html>
  
`);
  const { data: products = [], refetch: productRefetch } =
    useGetStockListQuery();
  const [postInvoice, { isLoading }] = usePostInvoiceMutation();

  useEffect(() => {
    const updatedProductTotalDetailPrice = {
      subtotal: 0,
      totalTax: 0,
      totalDiscount: 0,
      amountBeforeVat: 0,
      total: 0,
      grandTotal: 0,
    };
    // itemss?.forEach((item) => {
    //   if (item?.subTotal) {
    //     updatedProductTotalDetailPrice.subtotal += parseFloat(item.subTotal);
    //   }
    // });

    itemss?.forEach((subService) => {
      if (subService && subService?.subTotal > 0) {
        updatedProductTotalDetailPrice.subtotal += parseFloat(
          subService.subTotal || 0
        );

        updatedProductTotalDetailPrice.totalDiscount += subService.discount
          ? parseFloat(subService.discount)
          : 0;
      }
    });

    var calculatedDiscount = 0;
    var calculatedTax = 0;
    const discount = selectLead?.discount ? selectLead?.discount : 0;

    const tax = selectLead?.salesTaxs ? selectLead?.salesTaxs : 0;

    if (updatedProductTotalDetailPrice.subtotal > 0) {
      const onePersent = updatedProductTotalDetailPrice.subtotal / 100;
      if (discount > 0) {
        calculatedDiscount = onePersent * discount;
      }

      const onePercentWithoutDiscount =
        (updatedProductTotalDetailPrice.subtotal -
          updatedProductTotalDetailPrice.totalDiscount) /
        100;
      if (tax) {
        calculatedTax = onePercentWithoutDiscount * tax;
      }
    }

    // updatedProductTotalDetailPrice.totalDiscount =
    //   calculatedDiscount?.toFixed(2);

    updatedProductTotalDetailPrice.totalTax = calculatedTax; //ok

    updatedProductTotalDetailPrice.total =
      updatedProductTotalDetailPrice.subtotal -
      updatedProductTotalDetailPrice.totalDiscount;

    updatedProductTotalDetailPrice.amountBeforeVat +=
      updatedProductTotalDetailPrice.total;

    updatedProductTotalDetailPrice.total =
      updatedProductTotalDetailPrice.total + calculatedTax;

    updatedProductTotalDetailPrice.grandTotal =
      updatedProductTotalDetailPrice.total -
      (selectLead?.lead?.inspection?.cost
        ? parseFloat(selectLead?.lead?.inspection?.cost)
        : 0);

    items.forEach((product) => {
      if (product?.product && product.price > 0) {
        // var subtotal = product?.total * product.productQuantity;
        updatedProductTotalDetailPrice.subtotal +=
          product?.price * product?.productQuantity; //* product.productQuantity

        updatedProductTotalDetailPrice.totalTax += product?.taxes;
        updatedProductTotalDetailPrice.totalDiscount += product.discount;
        updatedProductTotalDetailPrice.amountBeforeVat +=
          product?.priceBeforeVat * product?.productQuantity;
        updatedProductTotalDetailPrice.total +=
          product?.priceBeforeVat * product?.productQuantity + product.taxes;
      }
    });

    setProductTotalDetailPrice(updatedProductTotalDetailPrice);
  }, [items, itemss, selectLead]); // Ensure productPercentage is included as a dependency if it's used in the calculation

  const handleContentChange = (value) => {
    setContent(value);
  };
  const handleSubmitted = async (values) => {
    values.discount = selectLead?.discount;

    // return
    values.notes = content;
    values.description = content;
    values.inspection = selectLead?.lead?.inspection;
    values.customer = selectLead?.customer;
    // if (directinvoice) {
    values.quotaionId = quotaionId;
    // }
    // values.paymentStatus = values?.paymentStatus?.value;
    values.subTotal = productTotalDetailPrice?.subtotal;
    values.grandTotal = productTotalDetailPrice?.grandTotal?.toFixed();
    values.estimatedTax = productTotalDetailPrice?.totalTax?.toFixed(2);
    values.itemDiscounts = parseFloat(
      productTotalDetailPrice?.totalDiscount
    )?.toFixed(2);
    values.totalAmount = parseFloat(
      productTotalDetailPrice?.total -
        parseFloat(values?.inspection?.cost > 0 ? values?.inspection?.cost : 0)
    )?.toFixed();
    const extractedData = items.map((item) => {
      if (item?.product) {
        return {
          productId: item.product.productId,
          productName: item.product.product.name,
          productQuantity: item.productQuantity,
          warehouseId: item.product.warehouseId,
          categoryId: item.product.product.categoryId,
          // productName: item.product.name,
          id: item.id,
          price: item.price,
          discount: item.discount,
          taxes: item.taxes,
          total: item.total,
          priceBeforeVat: item?.priceBeforeVat,
          ...item,
        };
      }
    });

    values.productIds = extractedData;

    const updatedServices = itemss?.map((row) => {
      const discountAmount = row?.discount;

      const priceAfterDiscount = row?.subTotal - row?.discount;

      const vatAmount = parseFloat(priceAfterDiscount * 0.05).toFixed(2);

      const netPrice = parseFloat(
        parseFloat(priceAfterDiscount) + parseFloat(vatAmount)
      ).toFixed(2);

      return {
        ...row,
        discountAmount: discountAmount,
        priceAfterDiscount: priceAfterDiscount,
        vatAmount: vatAmount,
        netPrice: netPrice,
        totalPrice: priceAfterDiscount + discountAmount,
      };
    });
    values.subSeriviceList = updatedServices;

    values.receiveableAccountId = values?.receiveableAccountId?.value;
    values.salesAccountId = values.salesAccountId?.value;

    try {
      const result = await postInvoice(values);

      notifySuccess(result?.data?.message);
      // navigate("invoices");
      navigate("/" + user?.role?.toLowerCase() + "/invoices");
    } catch (error) {
      notifyError(error.message);
    }
  };

  function generateInvoiceNumber() {
    const randomNumber = Math.floor(Math.random() * 100); // Generate an 8-digit random number

    const currentDate = new Date()
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "");

    // Combine the prefix, current date, and random number
    const invoiceNumber = `#${"PW"}${currentDate}${randomNumber}`;

    return invoiceNumber;
  }

  const handleSubServiceNameChange = (selectedOption, itemId) => {
    const itemIndex = itemss.findIndex((item) => item.id === itemId);

    const updatedItems = [...itemss];

    const subService = subServices?.services?.find(
      (subService) => selectedOption.value === subService?.serviceId
    );
    const updatedSubService = {
      ...subService,
      label: subService?.name,
      value: subService?.serviceId,
    };
    updatedItems[itemIndex].subService = updatedSubService;

    updatedItems[itemIndex].startRangePrice =
      updatedSubService?.startRangePrice;
    updatedItems[itemIndex].endRangePrice = updatedSubService?.endRangePrice; // Discount amount
    updatedItems[itemIndex].totalPrice = updatedSubService?.totalPrice; // Tax amount
    updatedItems[itemIndex].quantity = 1;
    updatedItems[itemIndex].subTotal = updatedSubService?.startRangePrice;
    updatedItems[itemIndex].discount = 0;
    updatedItems[itemIndex].detail = "";
    setItemss(updatedItems);
  };

  const handleProductNameChange = (selectedOption, itemId) => {
    setIsUpdated(true);
    const itemIndex = items.findIndex((item) => item.id === itemId);
    const updatedProductTotalDetailPrice = productTotalDetailPrice;

    // Create a copy of the items array
    const updatedItems = [...items];

    const product = products?.stocklist?.find(
      (product) =>
        selectedOption.value.productId === product?.productId &&
        selectedOption.value.warehouseId === product?.warehouseId
    );
    updatedItems[itemIndex].product = product;

    const productPercentage = product?.product?.price / 100;

    let productDiscount = 0;

    if (
      product?.product?.discountType === "percentage" &&
      productPercentage * product?.product?.discountes > 0
    ) {
      productDiscount = parseInt(
        productPercentage * product?.product?.discountes
      );
    }
    if (
      product?.product?.discountType === "fix" &&
      productPercentage * product?.product?.discountes > 0
    ) {
      productDiscount = parseInt(product?.product?.discountes);
    }

    let priceAfterDiscount = product?.product?.price - productDiscount;

    let tax = priceAfterDiscount * 0.05;

    // let totalAmount =
    //   product?.product?.price -
    //   productPercentage * product?.product?.discountes;
    // totalAmount += productPercentage * product?.product?.taxes;

    // Update the item's properties with the calculated values
    updatedItems[itemIndex].price = product?.product?.price;
    updatedItems[itemIndex].discount = productDiscount;

    updatedItems[itemIndex].taxes = tax; // Tax amount
    updatedItems[itemIndex].priceBeforeVat = priceAfterDiscount;
    updatedItems[itemIndex].total = priceAfterDiscount + tax;
    updatedItems[itemIndex].productQuantity = 1;
    // updatedItems[itemIndex].categoryId = product?.product?.categoryId

    setItems(updatedItems);
  };

  const deleteItems = (id, product) => {
    const updatedItems = itemss?.filter((item) => item.id !== id);

    const reindexedItems = updatedItems.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setItemss(reindexedItems);
  };

  // Function to handle item deletion
  const deleteItem = (id, product) => {
    if (product.productQuantity !== 0) {
      const updatedProductTotalDetailPrice = productTotalDetailPrice;

      updatedProductTotalDetailPrice.subtotal -=
        parseFloat(product.price) * product.productQuantity;
      updatedProductTotalDetailPrice.totalDiscount -= product?.discount;
      updatedProductTotalDetailPrice.totalTax -= product?.taxes;

      updatedProductTotalDetailPrice.total -= product?.total;
      setProductTotalDetailPrice(updatedProductTotalDetailPrice);
    }
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  // Function to add a new item
  const addItem = () => {
    const newItem = { id: items.length + 1 };
    setItems([...items, newItem]);
  };
  // Function to add a new item
  const addItems = () => {
    const newItems = { id: itemss?.length + 1 };
    setItemss([...itemss, newItems]);
  };

  const handlePlusProduct = (id, product) => {
    // return
    const itemIndex = items.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedItems = [...items];

      // Increase the product quantity by 1
      if (
        updatedItems[itemIndex].productQuantity + 1 >
        product?.product?.quantity
      ) {
        notifyError("Product stock empty");
        return;
      }

      const discountPerProduct =
        updatedItems[itemIndex].discount /
        updatedItems[itemIndex].productQuantity;

      const taxPerProduct =
        updatedItems[itemIndex].taxes / updatedItems[itemIndex].productQuantity;

      const totalPerProduct =
        updatedItems[itemIndex].total / updatedItems[itemIndex].productQuantity;

      updatedItems[itemIndex].productQuantity += 1;
      updatedItems[itemIndex].discount =
        discountPerProduct * updatedItems[itemIndex].productQuantity;
      updatedItems[itemIndex].taxes =
        taxPerProduct * updatedItems[itemIndex].productQuantity;

      updatedItems[itemIndex].total =
        totalPerProduct * updatedItems[itemIndex].productQuantity;
      product = product?.product;

      setItems(updatedItems);
    }
  };

  const handleMinusProduct = (id, products) => {
    const itemIndex = items.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedItems = [...items];

      if (updatedItems[itemIndex].productQuantity > 0) {
        if (updatedItems[itemIndex].productQuantity == 1) {
          return;
        }

        const discountPerProduct =
          updatedItems[itemIndex].discount /
          updatedItems[itemIndex].productQuantity;

        const taxPerProduct =
          updatedItems[itemIndex].taxes /
          updatedItems[itemIndex].productQuantity;

        const totalPerProduct =
          updatedItems[itemIndex].total /
          updatedItems[itemIndex].productQuantity;
        updatedItems[itemIndex].productQuantity -= 1;

        updatedItems[itemIndex].discount =
          discountPerProduct * updatedItems[itemIndex].productQuantity;
        updatedItems[itemIndex].taxes =
          taxPerProduct * updatedItems[itemIndex].productQuantity;

        updatedItems[itemIndex].total =
          totalPerProduct * updatedItems[itemIndex].productQuantity;

        setItems(updatedItems);
      }
    }
  };
  const paymentStatusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
    { value: "Overdue", label: "Overdue" },
    // Add more options as needed
  ];

  const updateProductPrice = (productDetail, value, index) => {
    setItems((prevProducts) =>
      prevProducts.map((product, idx) => {
        if (idx === index) {
          const productPercentage = value ? value / 100 : 0;
          let productDiscount = 0;

          if (
            productDetail?.product?.product?.discountType === "percentage" &&
            productPercentage * productDetail?.product?.product?.discountes > 0
          ) {
            productDiscount = parseInt(
              productPercentage * productDetail?.product?.product?.discountes
            );
          }
          if (
            productDetail?.product?.product?.discountType === "fix" &&
            productPercentage * productDetail?.product?.product?.discountes > 0
          ) {
            productDiscount = parseInt(
              productDetail?.product?.product?.discountes
            );
          }

          let priceAfterDiscount = parseFloat(
            value ? value - productDiscount : 0
          ).toFixed(2);

          priceAfterDiscount = parseFloat(priceAfterDiscount); // Convert back to number

          let tax = parseFloat((priceAfterDiscount * 0.05).toFixed(2));

          let totalAmount = parseFloat((priceAfterDiscount + tax).toFixed(2));

          return {
            ...product,
            price: value,
            discount: productDiscount,
            priceBeforeVat: priceAfterDiscount,
            taxes: tax,
            total: totalAmount,
          };
        }
        return product;
      })
    );
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Invoice</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice No.</label>
                              <input
                                type="text"
                                className="form-control"
                                name="invoiceNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceNo}
                                readOnly={true}
                              />
                            </div>
                          </div>

                          {/* Invoice Date */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="invoiceDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceDate}
                                // required
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.invoiceDate && errors.invoiceDate}
                              </div>
                            </div>
                          </div>

                          {/* Invoice Due */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice Due</label>
                              <input
                                type="date"
                                className="form-control"
                                name="invoiceDue"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceDue}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.invoiceDue && errors.invoiceDue}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <label className="text-label">Customer</label>

                            <Select
                              id="val-service"
                              name="quotaionId"
                              onChange={(selectedOption) => {
                                const selectedLead = qoutaions?.quotaions.find(
                                  (qoutaion) =>
                                    qoutaion.quotaionId === selectedOption.value
                                );
                                setSelectedLead(selectedLead);
                                setFieldValue(
                                  "phone",
                                  selectedLead?.customer?.phone || ""
                                );

                                setFieldValue(
                                  "email",
                                  selectedLead?.customer?.email || ""
                                );
                                setFieldValue(
                                  "TRN",
                                  selectedLead?.customer?.TRN || ""
                                );

                                setFieldValue(
                                  "address",
                                  selectedLead?.customer?.mailingAddress || ""
                                );
                                setFieldValue(
                                  "serviceId",
                                  selectedLead?.service?.serviceId || ""
                                );
                                setFieldValue(
                                  "customerId",
                                  selectedLead?.customerId || ""
                                );
                                setFieldValue(
                                  "leadId",
                                  selectedLead?.leadId || ""
                                );
                                setFieldValue(
                                  "subSeriviceList",
                                  selectedLead?.subSeriviceList || []
                                );
                                handleChange({
                                  target: {
                                    name: "quotaionId",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={{
                                value: values.quotaionId,
                                label: selectLead
                                  ? `${selectLead?.customer?.firstname} ${selectLead?.customer?.lastname} - ${selectLead?.quotationNo}`
                                  : "",
                              }}
                              options={qoutaions?.quotaions?.map(
                                (qoutaion) => ({
                                  value: qoutaion.quotaionId,
                                  label: `${qoutaion?.customer?.firstname} ${qoutaion?.customer?.lastname} -  ${qoutaion?.quotationNo}`,
                                })
                              )}
                              isDisabled={true}
                            ></Select>
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="quotaionId" />
                            </div>
                          </div>

                          {/* Contact Us */}
                          <div className="col-4">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Contact us </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Contact US"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          {/* Email */}
                          <div className="col-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div
                              className={`form-group mb-3 ${
                                values.TRN
                                  ? errors.TRN
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">TRN</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="TRN No"
                                name="TRN"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("TRN", data);
                                }}
                                onBlur={handleBlur}
                                value={values.TRN}
                                disabled={
                                  getSingleQuotation?.quotation.customer?.TRN
                                }
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.TRN && errors.TRN}
                              </div>
                            </div>
                          </div>

                          <div className="col-4">
                            <label className="text-label">Sales Account</label>
                            <Select
                              name="salesAccountId"
                              onChange={(selectedOption) => {
                                setFieldValue("salesAccountId", selectedOption); // Pass the whole object
                              }}
                              onBlur={handleBlur}
                              value={
                                values?.salesAccountId
                                  ? {
                                      label: values.salesAccountId?.label,
                                      value: values.salesAccountId?.value,
                                    }
                                  : null
                              }
                              options={accounts
                                ?.filter(
                                  (account) =>
                                    account?.type === "income" &&
                                    account?.detailType === "sales"
                                )
                                ?.map((account) => ({
                                  value: account?.accountId,
                                  label: account?.name,
                                }))}
                              classNamePrefix="react-select" //
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.salesAccountId && errors.salesAccountId}
                            </div>
                          </div>
                          <div className="col-4">
                            <label className="text-label">
                              Receivable Account
                            </label>
                            <Select
                              name="receiveableAccountId"
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "receiveableAccountId",
                                  selectedOption
                                ); // Pass the whole object
                              }}
                              onBlur={handleBlur}
                              value={
                                values.receiveableAccountId
                                  ? {
                                      label: values.receiveableAccountId?.label,
                                      value: values.receiveableAccountId?.value,
                                    }
                                  : null
                              } // Ensure value is properly set
                              options={accounts
                                ?.filter(
                                  (account) =>
                                    account?.type === "Account Receivable A/R"
                                )
                                ?.map((account) => ({
                                  value: account?.accountId,
                                  label: account?.name,
                                }))}
                              classNamePrefix="react-select" // Optional: For better styling
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.receiveableAccountId &&
                                errors.receiveableAccountId}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <label className="text-label">Sales Person</label>
                            <Select
                              name="salesPersonUserId"
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "salesPersonUserId",
                                  selectedOption
                                );
                              }}
                              onBlur={handleBlur}
                              value={
                                values?.salesPersonUserId
                                  ? {
                                      label: values.salesPersonUserId?.label,
                                      value: values.salesPersonUserId?.value,
                                    }
                                  : null
                              }
                              options={users?.users?.map((user) => ({
                                value: user?.userId,
                                label: `${user?.firstName} ${user?.lastName}`,
                              }))}
                              classNamePrefix="react-select" //
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.salesPersonUserId &&
                                errors.salesPersonUserId}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Address</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div class="">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={values.sendMail}
                              id="flexCheckChecked"
                              name="sendMail"
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              Are you sure you want to send an email?
                            </label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="text-label">
                              Invoice Details
                            </label>

                            <ReactQuill
                              theme="snow"
                              value={content}
                              onChange={handleContentChange}
                            />
                          </div>
                        </div>

                        {selectLead?.leadId && (
                          <React.Fragment>
                            <Alert variant="primary">
                              <strong>{"Inspection Information"}</strong>
                            </Alert>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Inspector Name</th>
                                  <th scope="col">Role</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      selectLead?.lead?.inspection?.user
                                        ?.firstName
                                    }
                                  </td>
                                  <td>
                                    {selectLead?.lead?.inspection?.user?.role}
                                  </td>
                                  <td>
                                    {selectLead?.lead?.inspection
                                      ?.inspectionDate
                                      ? new Date(
                                          selectLead?.lead?.inspection?.inspectionDate
                                        ).toLocaleDateString()
                                      : ""}
                                  </td>
                                  <td>
                                    {tConvert(
                                      selectLead?.lead?.inspection
                                        ?.inspectionTime
                                    )}
                                  </td>
                                  <td>
                                    {
                                      selectLead?.lead?.inspection
                                        ?.inspectionType
                                    }
                                  </td>
                                  <td>
                                    {selectLead?.lead?.inspection?.cost == ""
                                      ? 0
                                      : selectLead?.lead?.inspection?.cost}{" "}
                                    USD
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </React.Fragment>
                        )}
                        <Alert variant="primary">
                          <strong>{"Services Information"}</strong>
                        </Alert>
                        <div className="row">
                          <div className="col">
                            <div className="overflow-x-auto">
                              <table className="table">
                                <thead className="thead-border">
                                  <tr>
                                    <th>Service Name</th>
                                    <th>Price </th>
                                    <th>Quantity</th>
                                    <th>Discount</th>
                                    <th>SubTotal</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {itemss &&
                                    itemss?.map((item, index) => (
                                      <>
                                        <tr key={item.id} className="item">
                                          <td style={{ width: "300px" }}>
                                            <Select
                                              options={subServices?.services
                                                ?.filter(
                                                  (subservice) =>
                                                    !itemss.some(
                                                      (i) =>
                                                        i?.subService
                                                          ?.serviceId ===
                                                        subservice.serviceId
                                                    )
                                                )
                                                ?.map((subservice) => ({
                                                  value: subservice.serviceId,
                                                  label: subservice.name,
                                                }))}
                                              onChange={(selectedOption) =>
                                                handleSubServiceNameChange(
                                                  selectedOption,
                                                  item.id
                                                )
                                              }
                                              value={{
                                                value:
                                                  item?.subService?.serviceId,
                                                label: item?.subService?.name,
                                              }}
                                              placeholder="Select SubService Name"
                                              isDisabled={
                                                index <
                                                selectLead?.subSeriviceList
                                                  ?.length
                                              }
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="Start Range Price"
                                              value={item?.startRangePrice} // Set the value of price from item state
                                              disabled // Disable editing of price since it's auto-filled
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="No of Service"
                                              value={item?.quantity}
                                              onChange={(event) => {
                                                const newQuantity =
                                                  event.target.value;
                                                const newSubTotal =
                                                  item?.startRangePrice *
                                                  newQuantity;

                                                const updatedItem = {
                                                  ...item,
                                                  quantity: newQuantity,
                                                  subTotal: newSubTotal,
                                                };

                                                const updatedItems = itemss.map(
                                                  (i) =>
                                                    i.id === item.id
                                                      ? updatedItem
                                                      : i
                                                );
                                                setItemss(updatedItems);
                                              }}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="discount"
                                              value={item?.discount}
                                              onChange={(event) => {
                                                const newDiscount = event.target
                                                  ?.value
                                                  ? Math.abs(
                                                      Math.floor(
                                                        Number(
                                                          event.target.value
                                                        )
                                                      )
                                                    )
                                                  : "";

                                                const updatedItem = {
                                                  ...item,
                                                  discount: newDiscount,
                                                };

                                                const updatedItems = itemss.map(
                                                  (i) =>
                                                    i.id === item.id
                                                      ? updatedItem
                                                      : i
                                                );
                                                setItemss(updatedItems);
                                              }}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="sub total"
                                              value={item?.subTotal}
                                              onChange={(event) => {
                                                const newSubTotal =
                                                  event.target.value;

                                                const updatedItem = {
                                                  ...item,

                                                  subTotal: newSubTotal,
                                                };

                                                const updatedItems = itemss.map(
                                                  (i) =>
                                                    i.id === item.id
                                                      ? updatedItem
                                                      : i
                                                );
                                                setItemss(updatedItems);
                                              }}
                                            />
                                          </td>

                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() =>
                                                deleteItems(item.id, item)
                                              }
                                              disabled={
                                                index <
                                                selectLead?.subSeriviceList
                                                  ?.length
                                              }
                                            >
                                              <i className="bi bi-trash"></i>{" "}
                                              Delete
                                            </button>
                                          </td>
                                        </tr>
                                        <tr className="item">
                                          <td colSpan={"7"}>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Description"
                                              value={item?.detail}
                                              onChange={(event) => {
                                                const newDiscount =
                                                  event.target?.value;

                                                const updatedItem = {
                                                  ...item,
                                                  detail: newDiscount,
                                                };

                                                const updatedItems = itemss.map(
                                                  (i) =>
                                                    i.id === item.id
                                                      ? updatedItem
                                                      : i
                                                );
                                                setItemss(updatedItems);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>

                                <button
                                  type="button"
                                  class="btn btn-outline-primary 1px mt-2 dotted"
                                  onClick={addItems}
                                >
                                  <i className="fa-solid fa-plus me-2"></i> Add
                                  Service
                                </button>
                              </table>
                            </div>
                          </div>
                        </div>
                        <Alert variant="primary">
                          <strong>{"Product Information"}</strong>
                        </Alert>
                        <div className="row">
                          <div className="col">
                            <div className="overflow-x-auto">
                              <table className="table">
                                <thead className="thead-border">
                                  <tr>
                                    <th>Item Name</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                    <th>Discount Amount</th>
                                    <th>Final Unit Price</th>
                                    <th>Tax Amount</th>
                                    <th>Total Amount</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items.map((item, index) => (
                                    <tr key={item.id} className="item">
                                      <td style={{ width: "300px" }}>
                                        <Select
                                          options={products?.stocklist
                                            ?.filter(
                                              (product) =>
                                                !items.some(
                                                  (i) =>
                                                    i.product?.productId ===
                                                      product?.productId &&
                                                    i.product?.warehouseId ===
                                                      product?.warehouseId
                                                )
                                            ) // Filter out already selected products
                                            .map((product) => ({
                                              value: product,
                                              label: `${product?.product?.name} (${product?.warehouse?.name})`,
                                            }))}
                                          onChange={(selectedOption) =>
                                            handleProductNameChange(
                                              selectedOption,
                                              item.id
                                            )
                                          }
                                          placeholder="Select Product Name"
                                        />
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <button
                                            className="btn btn-outline-primary"
                                            onClick={(event) => {
                                              event.preventDefault(); // Prevent form submission
                                              handleMinusProduct(item.id, item);
                                            }}
                                            disabled={!item?.product}
                                          >
                                            -
                                          </button>
                                          <span className="mx-3">
                                            {item.productQuantity}
                                          </span>

                                          <button
                                            className="btn btn-outline-primary"
                                            onClick={(event) => {
                                              event.preventDefault(); // Prevent form submission

                                              handlePlusProduct(item.id, item);
                                            }}
                                            disabled={!item?.product}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Price"
                                          value={item.price} // Set the value of price from item state
                                          // disabled // Disable editing of price since it's auto-filled
                                          style={{ width: "100px" }}
                                          onChange={(e) => {
                                            handleChange(e);

                                            const total = parseInt(
                                              e.target.value
                                            );

                                            updateProductPrice(
                                              item,
                                              total,
                                              index
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Discount"
                                          value={item?.discount} // Set the value of discount from item state
                                          disabled // Disable editing of discount since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Tax"
                                          value={item.priceBeforeVat} // Set the value of taxes from item state
                                          disabled // Disable editing of taxes since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Tax"
                                          value={item.taxes} // Set the value of taxes from item state
                                          disabled // Disable editing of taxes since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Total"
                                          value={item.total} // Set the value of total from item state
                                          disabled // Disable editing of total since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            deleteItem(item.id, item)
                                          }
                                        >
                                          <i className="bi bi-trash"></i> Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <button
                                  type="button"
                                  class="btn btn-outline-primary 1px mt-2 dotted"
                                  onClick={addItem}
                                >
                                  <i className="fa-solid fa-plus me-2"></i> Add
                                  Item
                                </button>
                                <tbody
                                  class="before:block before:h-3"
                                  id="totalAmount"
                                >
                                  <tr>
                                    <td
                                      colspan="6"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Sub Total</td>
                                    <td class="">
                                      {productTotalDetailPrice?.subtotal} USD
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="6"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class=""> Discount</td>
                                    <td class="">
                                      {productTotalDetailPrice?.totalDiscount}{" "}
                                      USD
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="6"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">VATable Amount</td>
                                    <td class="">
                                      {(productTotalDetailPrice?.amountBeforeVat).toFixed(
                                        2
                                      )}
                                      USD
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="6"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">VAT</td>
                                    <td class="">
                                      {(productTotalDetailPrice?.totalTax).toFixed(
                                        2
                                      )}{" "}
                                      USD
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="6"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Total Amount</td>
                                    <td class="">
                                      {" "}
                                      {productTotalDetailPrice?.total
                                        ? parseFloat(
                                            productTotalDetailPrice?.total
                                          ).toFixed()
                                        : 0}{" "}
                                      USD
                                    </td>
                                  </tr>

                                  {selectLead?.leadId &&
                                    selectLead?.lead?.inspection?.cost > 0 && (
                                      <>
                                        <tr>
                                          <td
                                            colspan="5"
                                            class="border-bottom-0"
                                          ></td>
                                          <td class="">Inspection Amount</td>
                                          <td class="">
                                            {" "}
                                            {
                                              selectLead?.lead?.inspection?.cost
                                            }{" "}
                                            USD
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            colspan="5"
                                            class="border-bottom-0"
                                          ></td>
                                          <td class="">Remaining Amount</td>
                                          <td class="">
                                            {" "}
                                            {productTotalDetailPrice?.total
                                              ? parseFloat(
                                                  productTotalDetailPrice?.total -
                                                    (selectLead?.lead
                                                      ?.inspection?.cost > 0
                                                      ? selectLead?.lead
                                                          ?.inspection?.cost
                                                      : 0)
                                                ).toFixed()
                                              : 0}{" "}
                                            USD
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <br />

                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
