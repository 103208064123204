import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetWarehouseQuery,
  usePutWarehouseMutation,
} from "../../../../features/Inventory/warehouse/warehouseApiSlice";

import { useGetStockListQuery } from "../../../../features/Inventory/stocklist/stockListApiSlice";

import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";

import { IMAGES } from "../../../constant/theme";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const StockList = () => {
  const [putWarehouse, { isLoading, isError, error }] =
    usePutWarehouseMutation();
  const { data: warehouse = [], refetch } = useGetStockListQuery();
  useEffect(() => {
    refetch();
  }, []);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterStockList = () => {
    if (!search) return warehouse?.stocklist;

    return warehouse?.stocklist?.filter((stocklist) => {
      const productName = stocklist?.product?.name?.toLowerCase();
      const category = stocklist?.product?.category?.name?.toLowerCase();
      const brand = stocklist?.product?.brand?.name?.toLowerCase();
      const model = stocklist?.product?.model?.name?.toLowerCase();
      const warehouseName = stocklist?.warehouse?.name?.toLowerCase();
      const supplier = stocklist?.product?.Supplier?.name?.toLowerCase();
      const quantity = stocklist?.quantity?.toString().toLowerCase();

      return (
        productName.includes(search.toLowerCase()) ||
        category.includes(search.toLowerCase()) ||
        brand.includes(search.toLowerCase()) ||
        model.includes(search.toLowerCase()) ||
        warehouseName.includes(search.toLowerCase()) ||
        supplier.includes(search.toLowerCase()) ||
        quantity.includes(search.toLowerCase())
      );
    });
  };

  const filteredStockList = filterStockList();
  const records = filteredStockList?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredStockList?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (warehouse) => {
    let updatedWarehouse = { ...warehouse };
    updatedWarehouse.isDeleted = true;
    updatedWarehouse.updatedBy = user?.userId;

    putWarehouse({ id: updatedWarehouse?.warehouseId, updatedWarehouse })
      .unwrap()
      .then((response) => {
        notifySuccess("Brand deleted successfully");
        refetch();
        navigate("warehouse");
      })
      .catch((error) => {
        console.error("Error updating service", error);
      });
  };

  const handleEdit = (warehouse) => {
    navigate("warehouse/edit/" + warehouse?.warehouseId);
  };

  const handleView = (warehouse) => {
    navigate("warehouse/view/" + warehouse?.warehouseId);
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (service) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(service);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Product Name", key: "productName" },
    { label: "Category", key: "category" },
    { label: "Brand", key: "brand" },
    { label: "Model", key: "model" },
    { label: "Warehouse", key: "warehouse" },
    { label: "Supplier", key: "supplier" },
    { label: "Quantity", key: "quantity" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredStockList?.map((stocklist, index) => ({
      index: index + 1,
      productName: stocklist?.product?.name,
      category: stocklist?.product?.category?.name,
      brand: stocklist?.product?.brand?.name,
      model: stocklist?.product?.model?.name,
      warehouse: stocklist?.warehouse?.name,
      supplier: stocklist?.product?.Supplier?.name,
      quantity: stocklist?.quantity,
    })),
    filename: "stocklist_data.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">StockList</h4>

                    <div className="d-flex align-items-center">
                      {filteredStockList?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />
                      {JSON.parse(user)?.Role?.permissions?.includes(
                        "stocklist/create" || "stocklist/edit/:stocklistId"
                      ) && (
                        <Link
                          to={`/${JSON.parse(
                            user
                          )?.role?.toLowerCase()}/warehouse/manage`}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Manage
                          StockList
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product Name</th>
                          <th>Category</th>
                          <th>Brand</th>
                          <th>Model</th>
                          <th>Warehouse</th>
                          <th>Supplier</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((stocklist, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{stocklist?.product?.name}</td>
                            <td>{stocklist?.product?.category?.name}</td>
                            <td>{stocklist?.product?.brand?.name}</td>
                            <td>{stocklist?.product?.model?.name}</td>
                            <td>{stocklist?.warehouse?.name}</td>
                            <td>{stocklist?.product?.Supplier?.name}</td>
                            <td>{stocklist?.quantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredStockList?.length < lastIndex
                          ? filteredStockList?.length
                          : lastIndex}{" "}
                        of {filteredStockList?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockList;
