import React from "react";

function Card({
  integratedUser,
  handleConnectClick,
  hanldeDisConnect,
  icon,
  title,
  url,
}) {
  return (
    <div className="card shadow-sm h-100">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0 d-flex align-items-center">
          {icon}
          <span className="ms-2" style={{ fontSize: "20px" }}>
            {title}
          </span>
        </h5>
        {integratedUser ? (
          <button className="btn btn-primary btn-sm" disabled>
            Connected
          </button>
        ) : (
          <button
            className="btn btn-info btn-sm"
            onClick={() => handleConnectClick(url, title)}
          >
            Connect
          </button>
        )}
      </div>
      <div className="card-body">
        <p className="mb-1 fw-bold">Connected Account</p>
        {integratedUser ? (
          <div className="d-flex justify-content-between align-items-center">
            <span>{integratedUser.email}</span>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={hanldeDisConnect}
            >
              Disconnect
            </button>
          </div>
        ) : (
          <p className="text-muted">No account connected yet.</p>
        )}
      </div>
    </div>
  );
}

export default Card;
