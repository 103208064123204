import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";

import { Dropdown } from "react-bootstrap";

import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";

import {
  useGetPurchaseOrderQuery,
  usePutPurchaseOrderMutation,
} from "../../../../../features/financialMangement/purchaseOrder/purchaseOrderApiSlice";

const headersTitle = [
  { label: "Employee ID", key: "id" },
  { label: "Invoice", key: "invid" },
  { label: "Status", key: "status" },
  { label: "Name", key: "title" },
  { label: "Start Date", key: "startdate" },
  { label: "End Date", key: "enddate" },
  { label: "Priority", key: "select" },
];

const PurchaseOrder = () => {
  const [putPurchaseOrder, { isLoading, isError, error }] =
    usePutPurchaseOrderMutation();
  const { data: purchaseOrder = [], refetch } = useGetPurchaseOrderQuery();

  const [isPurchases, setIsPurchases] = useState(false);
  const [search, setSearch] = useState("");

  const location = useLocation();

  const filterPurchaseOrderList = () => {
    return purchaseOrder.filter((order) => {
      const searchTerm = search.toLowerCase();
      const supplierName = order?.Supplier?.name?.toLowerCase() || "";
      const trn = order?.Supplier?.TRN?.toLowerCase() || "";
      const warehouseName = order?.warehouse?.name?.toLowerCase() || "";
      const total = order?.total?.toString().toLowerCase() || "";
      const date = getDateTimeFromCreatedAt(order?.createdAt).toLowerCase();

      return (
        supplierName.includes(searchTerm) ||
        trn.includes(searchTerm) ||
        warehouseName.includes(searchTerm) ||
        total.includes(searchTerm) ||
        date.includes(searchTerm)
      );
    });
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const containsPurchases = currentPath.includes("/purchases");

    if (containsPurchases) {
      setIsPurchases(true);
    } else {
      setIsPurchases(false);
    }

    refetch();
  }, [location, refetch]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  // const user = localStorage.getItem("user");
  const user = useSelector(selectCurrentUser);

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const filteredOrders = filterPurchaseOrderList();
  const records = filteredOrders.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredOrders.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (purchaseOrder) => {
    // Create a copy of the service object
    let updatedPurchaseOrder = { ...purchaseOrder };

    // Update the servicesStatus property
    updatedPurchaseOrder.isDeleted = true;
    updatedPurchaseOrder.updatedBy = user?.userId;

    // Call the updateservice mutation function
    putPurchaseOrder({
      id: updatedPurchaseOrder?.purchaseOrderId,
      updatedPurchaseOrder,
    })
      .unwrap()
      .then((response) => {
        // Handle successful response
        notifySuccess("Purchase Order deleted successfully");
        // Refetch the services data after the update
        refetch();

        // navigate("purchase-order");
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating service", error);
      });
  };
  const handleEdit = (purchaseOrder) => {
    navigate("edit/" + purchaseOrder?.purchaseOrderId);
  };
  const handleView = (purchaseOrder) => {
    navigate("view/" + purchaseOrder?.purchaseOrderId);
  };

  function getDateTimeFromCreatedAt(createdAtString) {
    const dateObject = new Date(createdAtString);

    const date = dateObject.toLocaleDateString();
    const time = dateObject.toLocaleTimeString();

    return `${date}-${time}`;
  }

  const handleAction = (order, value) => {
    const updatedPurchaseOrder = { ...order };
    updatedPurchaseOrder.orderStatus = value;
    putPurchaseOrder({
      id: updatedPurchaseOrder?.purchaseOrderId,
      updatedPurchaseOrder,
    }).then(() => {
      refetch();
    });
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (purchaseOrder) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(purchaseOrder);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Supplier Name", key: "Supplier.name" },
    { label: "TRN", key: "Supplier.TRN" },
    { label: "Warehouse Name", key: "warehouse.name" },
    { label: "Category", key: "category.name" },
    { label: "Total Amount (USD)", key: "total" },
    { label: "Date/Time", key: "createdAtFormatted" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredOrders.map((order, index) => ({
      index: index + 1,
      "Supplier.name": order.Supplier?.name || "",
      "Supplier.TRN": order.Supplier?.TRN || "",
      "warehouse.name": order.warehouse?.name || "",
      "category.name": order.category?.name || "",
      total: order.subtotal || "",
      createdAtFormatted: new Date(order.createdAt).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
    })),
    filename: "purchase-order.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">
                      {" "}
                      {isPurchases ? "Purchases" : "Purchase Order"}
                    </h4>

                    <div className="d-flex">
                      {filteredOrders?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-1"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <div className="mx-2">
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search"
                          className="form-control"
                        />
                      </div>

                      <div>
                        {/* <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                        >
                          <i className="fa-solid fa-file-excel" /> Export Report
                        </CSVLink> */}
                        {user?.Role?.permissions?.includes(
                          "purchase-order/create"
                        ) && (
                          <Link
                            to={"create"}
                            className="btn btn-primary light btn-sm me-2"
                            style={{
                              whiteSpace: "nowrap",
                              padding: "0.700rem 1.50rem",
                            }}
                          >
                            <i className="fa-solid fa-plus me-2"></i>Add
                            Purchase
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Supplier Name</th>
                          <th>TRN</th>
                          <th>Warehouse Name</th>
                          <th>Category</th>
                          <th>Total Amount (USD)</th>
                          <th>Date/Time</th>

                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {records
                          ?.filter((order) => {
                            if (isPurchases) {
                              return order?.orderStatus == "Complete";
                            } else {
                              return order?.orderStatus !== "Complete";
                            }
                          })
                          ?.map((purchaseOrder, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{purchaseOrder?.Supplier?.name}</td>
                              <td>{purchaseOrder?.Supplier?.TRN}</td>
                              <td>{purchaseOrder?.warehouse?.name}</td>
                              <td>{purchaseOrder?.category?.name}</td>
                              <td>{purchaseOrder?.total}</td>
                              <td>
                                {new Date(
                                  purchaseOrder?.createdAt
                                ).toLocaleString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true, // This will format the time in AM/PM format
                                })}
                                {/* {getDateTimeFromCreatedAt(
                                  purchaseOrder?.createdAt
                                )} */}
                              </td>

                              {/* <td>{purchaseOrder?.orderStatus}</td>
                               */}

                              <td>
                                {/* View icon */}
                                {user?.Role?.permissions?.includes(
                                  "purchase-order/view/:purchase_orderId"
                                ) && (
                                  <span
                                    onClick={() => handleView(purchaseOrder)}
                                    style={{
                                      marginRight: "8px",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="fas fa-eye" title="View"></i>
                                  </span>
                                )}
                                {/* Edit icon */}
                                {user?.Role?.permissions?.includes(
                                  "purchase-order/edit/:purchase_orderId"
                                ) && (
                                  <span
                                    onClick={() => handleEdit(purchaseOrder)}
                                    style={{
                                      marginRight: "8px",
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="fas fa-edit" title="Edit"></i>
                                  </span>
                                )}
                                {/* Delete icon */}
                                {!isPurchases && user?.role == "Admin" && (
                                  <span
                                    onClick={() => SweetAlert(purchaseOrder)}
                                    style={{ color: "red", cursor: "pointer" }}
                                  >
                                    <i
                                      className="fas fa-trash"
                                      title="Delete"
                                    ></i>
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {isPurchases
                          ? filteredOrders.filter(
                              (item) => item.orderStatus == "Complete"
                            )?.length
                          : filteredOrders.filter(
                              (item) => item.orderStatus !== "Complete"
                            )?.length < lastIndex
                          ? isPurchases
                            ? filteredOrders.filter(
                                (item) => item.orderStatus == "Complete"
                              )?.length
                            : filteredOrders.filter(
                                (item) => item.orderStatus !== "Complete"
                              )?.length
                          : lastIndex}{" "}
                        of{" "}
                        {isPurchases
                          ? filteredOrders.filter(
                              (item) => item.orderStatus == "Complete"
                            )?.length
                          : filteredOrders.filter(
                              (item) => item.orderStatus !== "Complete"
                            )?.length}{" "}
                        entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrder;
