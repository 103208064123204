import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Select from "react-select";
import { usePostReceivePaymentMutation } from "../../../../../features/financialMangement/billing/receivePayment/receivePaymentApiSlice";
import { useGetCustomerQuery } from "../../../../../features/customer/customerApiSlice";
import {
  useGetAllInvoicesQuery,
  useGetAllUnpaidInvoicesQuery,
  useGetAllUnpaidInvoicesByCustomerIdQuery,
} from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetSingleCustomerQuery } from "../../../../../features/customer/customerApiSlice";

const validationSchema = Yup.object().shape({
  paymentDate: Yup.date().required("Payment date is required"),
  email: Yup.string().email("Invalid email format"),
  sendLater: Yup.boolean(),
  paymentMethod: Yup.string().required("payment method is required"),
  referenceNo: Yup.string(),
  accountId: Yup.string().required("accoun is required"),
  amountReceived: Yup.number().min(0, "Amount received cannot be negative"),
  memo: Yup.string(),
});

function Create() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [files, setFiles] = useState([]);
  const customerId = searchParams.get("customerId");

  const [totalAmountReceived, SetTotalAmountRecived] = useState(0);
  const [totalCredit, SetTotalCredit] = useState(0);

  const [selectCustomer, setSelectedCustomer] = useState(null);
  const [finalInvoices, setFinalInvoices] = useState([]);

  const [postReceivePayment, { isLoading }] = usePostReceivePaymentMutation();
  const { data: customers } = useGetCustomerQuery();

  const { data: invoices } = useGetAllUnpaidInvoicesQuery();

  const { data: accounts } = useGetAllAccountsQuery();

  const { data: singleCustomer, refetch } = useGetSingleCustomerQuery(
    customerId,
    {
      skip: !customerId, // Skip query execution if customerId doesn't exist);
    }
  );

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    refetch();

    if (customerId) {
      setSelectedCustomer(customerId);
    }
  }, [customerId, singleCustomer]);

  useEffect(() => {
    if (invoices?.invoices && selectCustomer) {
      const filterInvoices = invoices?.invoices
        ?.filter((item) => {
          return item.customerId == selectCustomer;
        })
        .map((invoice) => ({
          ...invoice,
          amount: 0,
        }));
      setFinalInvoices(filterInvoices);
    }
  }, [selectCustomer, customerId, singleCustomer]);

  useEffect(() => {
    const sum = finalInvoices?.reduce((total, invoice) => {
      return (
        (invoice?.amount > 0 ? Math.floor(Number(invoice?.amount)) : 0) + total
      );
    }, 0);

    SetTotalAmountRecived(sum);

    const credit = finalInvoices?.reduce((totalCredit, invoice) => {
      // Parse fields as floats and handle default values if fields are missing
      const grandTotal = parseFloat(invoice?.totalAmount) || 0;
      const paidAmount = parseFloat(invoice?.paidAmount) || 0;
      const amount = Math.floor(Number(invoice?.amount)) || 0;

      // Calculate total
      const totalCalculation =
        grandTotal - paidAmount - (amount > 0 ? amount : 0);

      return totalCredit + totalCalculation;
    }, 0);

    SetTotalCredit(credit);
  }, [finalInvoices, singleCustomer]);

  useEffect(() => {}, [finalInvoices, singleCustomer]);

  const handleFiles = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileInput = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const createReceivePayment = async (values) => {
    try {
      await postReceivePayment(values);
    } catch (error) {
      console.error("Failed to create receive payment", error);
    }
  };

  const handleSubmit = async (values) => {
    values.updatedinvoice = finalInvoices?.map((invoice) => {
      const newinvoice = {
        invoiceId: "",
        amount: 0,
        invoiceNo: invoice?.invoiceNo,
        TRN: "",
        totalAmount: "",
        paidAmount: "",
      };

      newinvoice.invoiceId = invoice?.invoiceId;
      newinvoice.amount = Math.floor(Number(invoice?.amount));
      newinvoice.TRN = invoice?.TRN;
      newinvoice.totalAmount = invoice?.totalAmount;
      newinvoice.paidAmount = invoice?.paidAmount;
      newinvoice.receiveableAccountId = invoice?.receiveableAccountId;

      return newinvoice;
    });

    const formData = new FormData();
    formData.append("customerName", values.customerId?.label);
    formData.append(
      "customerId",
      customerId
        ? values.customerId?.value
        : values.customerId?.value?.customerId
    );
    formData.append("email", values?.email);
    formData.append("sendLater", values?.sendLater);
    formData.append("paymentDate", values?.paymentDate);
    formData.append("paymentMethod", values?.paymentMethod);
    formData.append("referenceNo", values?.referenceNo);
    formData.append("accountId", values?.accountId);
    formData.append("amountReceived", totalAmountReceived);
    formData.append("memo", values?.memo);
    formData.append("bankName", values?.bankName);
    formData.append("pdcDate", values?.pdcDate);
    formData.append("checkNo", values?.checkNo);

    values?.updatedinvoice.forEach((invoice, index) => {
      formData.append(`updatedInvoice[${index}]`, JSON.stringify(invoice));
    });

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    try {
      createReceivePayment(formData)
        .then((result) => {
          notifySuccess("payment receive successfully");
          navigate("/" + user?.role?.toLowerCase() + "/receive-payment");
        })
        .catch((error) => {
          console.error("Error:", error); // Log any errors here
        });
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div
      className="mx-3 px-4 py-3 rounded"
      style={{ backgroundColor: "white" }}
    >
      <h2 className="mb-4">Receive Payment</h2>
      <Formik
        initialValues={{
          customerId: singleCustomer
            ? {
                label:
                  singleCustomer?.customers?.firstname +
                  " " +
                  singleCustomer?.customers?.lastname,
                value: singleCustomer?.customers?.customerId,
              }
            : "",
          email: singleCustomer ? singleCustomer?.customers?.email : "",
          sendLater: false,
          paymentDate: "",
          paymentMethod: "",
          referenceNo: "",
          accountId: "",
          // amountReceived: totalAmountReceived || 0,
          memo: "",
          checkNo: "",
          bankName: "",
          pdcDate: "",
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue, handleBlur }) => (
          <Form>
            <div className="row d-flex justify-content-between">
              <div className="row col-md-8">
                <div className="form-group col-md-4">
                  <label htmlFor="customer">Customer</label>
                  <Select
                    options={customers?.customers?.map((item) => ({
                      label: `${item?.firstname} ${item?.lastname}`,
                      value: item,
                      customer: item,
                    }))}
                    name="customerId"
                    value={values?.customerId}
                    onChange={(selectedOption) => {
                      setFieldValue("customerId", selectedOption);

                      setFieldValue("email", selectedOption?.customer?.email);

                      setSelectedCustomer(selectedOption?.value?.customerId);
                    }}
                    onBlur={handleBlur}
                    isDisabled={customerId}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="email">Email</label>
                  <div className="input-group">
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Separate emails with a comma"
                      value={values.email}
                      disabled
                    />
                    <div className="">
                      <button className="btn btn-outline-primary" type="button">
                        Cc/Bcc
                      </button>
                    </div>
                  </div>
                  <div className="form-check mt-2">
                    <Field
                      type="checkbox"
                      name="sendLater"
                      className="form-check-input"
                      id="sendLater"
                      checked={values.sendLater}
                    />
                    <label className="form-check-label" htmlFor="sendLater">
                      Send later
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group col-md-4">
                <label className="ml-auto text-left">Amount Received</label>
                <div
                  className="text-left"
                  style={{
                    fontSize: "xx-large",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {totalAmountReceived}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-3">
                <label htmlFor="paymentDate">Payment Date</label>
                <Field
                  type="date"
                  name="paymentDate"
                  className="form-control"
                  id="paymentDate"
                  value={values.paymentDate}
                  // required
                />
                <ErrorMessage
                  name="paymentDate"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row mt-3 d-flex justify-content-between">
              <div className="row col-md-8">
                <div className="form-group col-md-4">
                  <label htmlFor="paymentMethod">Payment Method</label>
                  <Select
                    options={[
                      { value: "Cash", label: "Cash" },
                      { value: "Cheque", label: "Cheque" },
                      { value: "Bank", label: "Bank" },
                    ]}
                    name="paymentMethod"
                    onChange={(selectedOption) => {
                      setFieldValue("paymentMethod", selectedOption.value);
                      setFieldValue("accountId", "");
                    }}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="paymentMethod"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="referenceNo">Reference No.</label>
                  <Field
                    type="text"
                    name="referenceNo"
                    className="form-control"
                    id="referenceNo"
                    placeholder="Reference number"
                    value={values.referenceNo}
                  />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="accountId">Deposit to</label>
                  <Select
                    options={accounts
                      ?.filter((account) => {
                        if (
                          values.paymentMethod == "Bank" ||
                          values.paymentMethod == "Cheque"
                        ) {
                          return account?.type == "Bank Account";
                        } else {
                          return account?.type == "Cash and equivalents";
                        }
                      })
                      ?.map((bank) => ({
                        value: bank?.accountId,
                        label: bank?.name,
                      }))}
                    name="accountId"
                    onChange={(selectedOption) =>
                      setFieldValue("accountId", selectedOption.value)
                    }
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="accountId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label className="d-block">Amount Received</label>
                <input
                  type="number"
                  name="amountReceived"
                  className="form-control mt-2"
                  placeholder="Amount received"
                  value={totalAmountReceived}
                  disabled={true}
                />
              </div>
            </div>
            {values.paymentMethod === "Cheque" && (
              <div className="row mt-3 ">
                <div className="form-group col-md-4">
                  <label htmlFor="referenceNo">Cheque No.</label>
                  <Field
                    type="text"
                    name="checkNo"
                    className="form-control"
                    id="checkNo"
                    placeholder="check no"
                    value={values.checkNo}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="referenceNo">Bank Name</label>
                  <Field
                    type="text"
                    name="bankName"
                    className="form-control"
                    id="bankName"
                    placeholder="enter bank name"
                    value={values.bankName}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="referenceNo">PDC Date</label>
                  <Field
                    type="date"
                    name="pdcDate"
                    className="form-control"
                    id="pdcDate"
                    value={values.pdcDate}
                  />
                </div>
              </div>
            )}

            {finalInvoices?.length > 0 && (
              <h3 className="mt-5">Outstanding Transactions</h3>
            )}

            {selectCustomer && finalInvoices?.length > 0 ? (
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th></th>
                    <th>invoiceNo</th>
                    <th>Due Date</th>
                    <th>Original Amount</th>
                    <th>Paid Amount</th>

                    <th>Open Balance</th>
                    <th>Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Assuming you have an array of transactions, replace this with your actual data */}
                  {finalInvoices?.map((invoice, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            const data =
                              parseFloat(invoice?.totalAmount) -
                              invoice?.paidAmount;

                            setFinalInvoices((prevInvoice) => {
                              const updatedInvoice = [...prevInvoice];
                              updatedInvoice[index] = {
                                ...updatedInvoice[index],
                                amount: e.target.checked ? data : 0,
                              };
                              return updatedInvoice;
                            });
                          }}
                        />
                      </td>
                      <td>{invoice?.invoiceNo}</td>
                      <td>
                        {new Date(invoice?.invoiceDue).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      <td>{invoice?.totalAmount}</td>
                      <td>{invoice?.paidAmount}</td>
                      <td>
                        {parseFloat(invoice?.totalAmount) - invoice?.paidAmount}
                      </td>
                      <td>
                        <Field
                          type="number"
                          className="form-control"
                          value={invoice?.amount}
                          inputmode="numeric"
                          min={0}
                          onChange={(e) => {
                            const number = e.target?.value
                              ? Math.floor(Number(e.target.value))
                              : 0;

                            setFinalInvoices((prevInvoice) => {
                              const updatedInvoice = [...prevInvoice];
                              updatedInvoice[index] = {
                                ...updatedInvoice[index],
                                amount: e.target.value,
                              };
                              return updatedInvoice;
                            });
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tbody>
                  <td colSpan="5"></td>

                  <td>Total Received</td>
                  <td className="text-end">{totalAmountReceived}</td>
                </tbody>
                <tbody>
                  <td colSpan="5"></td>

                  <td>Total Credit</td>
                  <td className="text-end">{totalCredit}</td>
                </tbody>
              </table>
            ) : selectCustomer != null ? (
              <p
                className="p-4 bg-success mt-4 rounded"
                style={{ color: "black" }}
              >
                No Record Found Against this Customer
              </p>
            ) : null}

            <div className="row mt-3">
              <div className="form-group col-md-4">
                <label htmlFor="memo">Memo</label>
                <Field
                  as="textarea"
                  name="memo"
                  className="form-control"
                  id="memo"
                  rows="2"
                  value={values.memo}
                />
              </div>
            </div>

            <div className="form-group mt-3">
              <label htmlFor="fileUpload" className="form-label">
                Attachments{" "}
                <small className="text-muted">(Maximum size: 20MB)</small>
              </label>
              <div
                className="border p-3 col-md-8"
                onDrop={(e) => {
                  handleDrop(e);
                  setFieldValue("files", [
                    ...files,
                    ...Array.from(e.dataTransfer.files),
                  ]);
                }}
                onDragOver={handleDragOver}
                style={{ borderStyle: "dashed", borderColor: "#ced4da" }}
              >
                <input
                  type="file"
                  id="fileUpload"
                  className="form-control-file"
                  onChange={(e) => {
                    handleFileInput(e);
                    setFieldValue("files", [
                      ...files,
                      ...Array.from(e.target.files),
                    ]);
                    // e.target.value = null;
                  }}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="fileUpload" className="d-block text-center">
                  Drag/Drop files here or click the icon
                </label>
              </div>
              {files.length > 0 && (
                <div className="mt-0">
                  <button className="btn btn-link" type="button">
                    Show existing
                  </button>
                </div>
              )}
            </div>
            <div>
              {files.length > 0 && (
                <div className="mt-4">
                  <h5>Uploaded Files:</h5>
                  <div className="d-flex flex-wrap">
                    {files.map((file, index) => (
                      <div
                        key={index}
                        className="mt-2 position-relative"
                        style={{
                          maxWidth: "150px",
                          maxHeight: "150px",
                          marginRight: "10px",
                          marginBottom: "10px",
                          position: "relative",
                        }}
                      >
                        {/* Image preview */}
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          className="img-thumbnail"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                        {/* Remove button positioned at the top-right corner */}
                        <button
                          type="button"
                          onClick={() => {
                            handleRemoveFile(index);
                            setFieldValue(
                              "files",
                              files.filter((_, i) => i !== index)
                            );
                          }}
                          className="btn btn-danger btn-sm"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            padding: "5px 10px",
                          }}
                        >
                          <i className="fas fa-trash" title="Delete"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div
              className=" mt-3 row mx-2 px-2 rounded"
              style={{ backgroundColor: "#71797E" }}
            >
              <div className="col-md-12 btn-group">
                <div className="btn-group my-3">
                  <button
                    type="button"
                    className="btn btn-secondary border rounded"
                    style={{ backgroundColor: "#71797E" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="reset"
                    className="btn btn-secondary border mx-2 px-3 rounded"
                    style={{ backgroundColor: "#71797E" }}
                  >
                    Clear
                  </button>
                </div>
                <button type="submit" className="btn">
                  Print
                </button>
                <div className="py-3">
                  <button
                    type="submit"
                    className="btn btn-primary border bg-success"
                    disabled={isLoading || finalInvoices?.length < 1}
                  >
                    Save and close
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Create;
