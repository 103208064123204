// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import { usePostQoutaionByCustomerMutation } from "../../../../features/quotaion/quotaionApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useGetCategoriesQuery } from "../../../../features/Inventory/category/categoryApiSlice";
import { useGetCustomerQuery } from "../../../../features/customer/customerApiSlice";
const quotationStatusList = [
  { value: "Draft", label: "Draft" },
  { value: "Pending Review", label: "Pending Review" },
  { value: "Sent", label: "Sent" },
  { value: "Under Consideration", label: "Under Consideration" },
  // { value: "Accepted", label: "Accepted" },
  // { value: "Revise", label: "Revise" },
  // { value: "Rejected", label: "Rejected" },
  // { value: "Expired", label: "Expired" },
  // { value: "Cancelled", label: "Cancelled" },
  { value: "In Progress", label: "In Progress" },
  // { value: "Completed", label: "Completed" },
];

function generateQuotationNumber() {
  // Generate a random number (you can use any method to generate a unique number)
  const randomNumber = Math.floor(Math.random() * 100); // Generate an 8-digit random number

  // Get the current date and format it (e.g., YYMMDD)
  const currentDate = new Date()
    .toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "");

  // Combine the prefix, current date, and random number
  const quotationNumber = `#${"PW"}${currentDate}${randomNumber}`;

  return quotationNumber;
}
const loginSchema = Yup.object().shape({
  // leadId: Yup.string().required("Lead ID is required"),
  customerId: Yup.string().required("Customer is required"),
  quotaion: Yup.string().required("Subject is required"),
  stage: Yup.string().required("Stage is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
  discount: Yup.number().optional("Discount is required"),
  salesTaxs: Yup.number().required("Sales Tax is required"),
});

const Create = () => {
  const user = localStorage.getItem("user");

  const [trnDisable, setTrnDisable] = useState(false);

  const [items, setItems] = useState([]);

  const [subServiceTotalDetailPrice, setSubServiceTotalDetailPrice] = useState({
    subtotal: 0,
    totalTax: 5,
    EstimatedTax: 0,
    totalDiscount: 0,
    EstimatedDiscounts: 0,
    total: 0,
    grandTotal: 0,
    inspectionPrice: 0,
    beforeDiscountSubtotal: 0,
  });

  const [content, setContent] = useState(`
  <head>

      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 20px;
          }
          .container {
              width: 80%;
              margin: auto;
             
              padding: 20px;
          }
         
         
        
          .section {
              margin-bottom: 20px;
          }
          .section h2 {
            
              padding-bottom: 5px;
          }
          .customer-details,
          .scope-of-work,
          .exclude-scope,
          .payment,
          .terms-conditions,
          .bank-details,
          .thank-you-note {
              margin-bottom: 20px;
          }
          .scope-table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
          }
          .scope-table th,
          .scope-table td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
          }
      </style>
  </head>
  <body>
      <div class="container">
        
         
          <div class="section scope-of-work">
              <h2>Scope of Work</h2>
              <table class="scope-table">
                  <tr>
                      <th>Sr #</th>
                      <th>Frequency</th>
                      <th>Description</th>
                      <th>Qty</th>
                      <th>Amount</th>
                  </tr>
                  <tr>
                      <td>[1]</td>
                      <td>[Frequency]</td>
                      <td>[Description]</td>
                      <td>[Qty]</td>
                      <td>[Amount]</td>
                  </tr>
                  <!-- More rows as needed -->
              </table>
          </div>
          
          <div class="section exclude-scope">
              <h2>Exclude Scope</h2>
              <p>[Details]</p>
          </div>
          
          <div class="section payment">
              <h2>Payment</h2>
              <p>[Payment Terms]</p>
          </div>
          
          <div class="section terms-conditions">
              <h2>General Terms & Conditions</h2>
              <p>[Terms & Conditions]</p>
          </div>
          
          <div class="section bank-details">
              <h2>Bank Details</h2>
              <p>[Bank Name]</p>
              <p>[Account Number]</p>
              <p>[IFSC Code]</p>
          </div>
          
          <div class="section thank-you-note">
              <h2>Thank You Note</h2>
              <p>[Thank You Message]</p>
          </div>
      </div>
  </body>
  </html>
  
`);

  const handleContentChange = (value) => {
    setContent(value);
  };
  const navigate = useNavigate();
  const { data: customers = [] } = useGetCustomerQuery({
    fetchPolicy: "network-only",
  });
  const { data: subServices = [] } = useGetServicesQuery({
    fetchPolicy: "network-only",
  });

  const [postQoutaion, { isLoading }] = usePostQoutaionByCustomerMutation();

  const [initialValues, setInitialValues] = useState({
    quotationNo: generateQuotationNumber(),
    serviceId: "",

    quotaion: "",
    stage: "",
    startDate: "",
    endDate: "",
    customerId: "",
    phone: "",
    discription: "",
    discount: "",
    discountTitle: "",
    salesTaxs: "5",
    sendEmails: false,
    preferred_Time: "",
    TRN: "",
    serviceDate: "",
  });

  const addItem = () => {
    const newItem = { id: items.length + 1, quantity: 1 };
    setItems([...items, newItem]);
  };

  const handleSubServiceNameChange = (selectedOption, itemId) => {
    const itemIndex = items.findIndex((item) => item.id === itemId);
    const updatedItems = [...items];

    let subService = subServices?.services?.find(
      (service) => selectedOption.value === service?.serviceId
    );

    const updatedSubService = {
      ...subService,
      label: subService?.name,
      value: subService?.serviceId,
    };

    const quantity = updatedItems[itemIndex].quantity || 1;

    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      subService: updatedSubService,
      startRangePrice: updatedSubService?.startRangePrice,
      endRangePrice: updatedSubService?.endRangePrice,
      totalPrice: updatedSubService?.totalPrice * quantity,
      subTotal: updatedSubService?.startRangePrice * quantity,
      discount: 0,
      detail: "",
    };

    setItems(updatedItems);
  };

  const deleteItem = (id) => {
    // Filter out the item with the specified id
    const updatedItems = items.filter((item) => item.id !== id);

    // Reassign the IDs to be sequential starting from 1
    const reindexedItems = updatedItems.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setItems(reindexedItems);
  };

  useEffect(() => {
    const updatedSubServicesTotalDetailPrice = {
      subtotal: 0,
      totalTax: 0,
      EstimatedTax: 0,
      totalDiscount: 0,
      EstimatedDiscounts: 0,
      total: 0,
      beforeDiscountSubtotal: 0,
    };

    items?.forEach((subService) => {
      if (subService) {
        updatedSubServicesTotalDetailPrice.subtotal += subService.subTotal
          ? parseFloat(subService.subTotal)
          : 0;

        updatedSubServicesTotalDetailPrice.EstimatedDiscounts +=
          subService.discount ? parseFloat(subService.discount) : 0;

        updatedSubServicesTotalDetailPrice.beforeDiscountSubtotal +=
          parseFloat(subService.subTotal || 0) -
          updatedSubServicesTotalDetailPrice.EstimatedDiscounts;
      }
    });

    const updatedTotalDetailPrice = subServiceTotalDetailPrice;
    updatedSubServicesTotalDetailPrice.totalTax =
      updatedTotalDetailPrice?.totalTax;
    updatedSubServicesTotalDetailPrice.totalDiscount =
      updatedTotalDetailPrice?.totalDiscount;

    updatedSubServicesTotalDetailPrice.total =
      updatedSubServicesTotalDetailPrice.subtotal;

    var calculatedDiscount = 0;
    var calculatedTax = 0;
    const discount = updatedTotalDetailPrice.totalDiscount
      ? updatedTotalDetailPrice.totalDiscount
      : 0;

    const tax = updatedTotalDetailPrice.totalTax
      ? updatedTotalDetailPrice.totalTax
      : 5;
    if (updatedSubServicesTotalDetailPrice.total > 0) {
      const onePersent =
        updatedSubServicesTotalDetailPrice.beforeDiscountSubtotal / 100;
      if (discount > 0) {
        calculatedDiscount = onePersent * discount;
      }

      const withOutDiscount =
        updatedSubServicesTotalDetailPrice.beforeDiscountSubtotal -
        calculatedDiscount;

      if (tax) {
        const onePercent = withOutDiscount / 100;
        calculatedTax = onePercent * tax;

        updatedSubServicesTotalDetailPrice.total =
          withOutDiscount + calculatedTax;
      }
    }

    updatedSubServicesTotalDetailPrice.EstimatedTax = calculatedTax;

    setSubServiceTotalDetailPrice(updatedSubServicesTotalDetailPrice);
  }, [items]);

  const handleSubmitted = async (values) => {
    values.discription = content;

    const updatedServices = items?.map((row) => {
      const discountAmount = row?.discount;

      const priceAfterDiscount = row?.subTotal - row?.discount;

      const vatAmount = parseFloat(priceAfterDiscount * 0.05).toFixed(2);

      const netPrice = parseFloat(
        parseFloat(priceAfterDiscount) + parseFloat(vatAmount)
      ).toFixed(2);

      return {
        ...row,
        discountAmount: discountAmount,
        priceAfterDiscount: priceAfterDiscount,
        vatAmount: vatAmount,
        netPrice: netPrice,
        totalPrice: priceAfterDiscount + discountAmount,
      };
    });

    values.subSeriviceList = updatedServices;

    values.pricesCalculation = subServiceTotalDetailPrice;

    try {
      const result = await postQoutaion(values);

      notifySuccess(result?.data?.message);
      navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/quotation");
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Quotation By Customer</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Quotation No.
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="quotationNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quotationNo}
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.startDate
                                  ? errors.startDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Quotes Start Date
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Start Date"
                                name="startDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.startDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startDate && errors.startDate}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.endDate
                                  ? errors.endDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Quotes Expiry Date{" "}
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="endDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endDate && errors.endDate}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <label className="text-label">
                              Quotation Status
                            </label>

                            <Select
                              id="val-quotation-status"
                              name="stage"
                              // onChange={handleChange}
                              onChange={(selectedOption) => {
                                handleChange({
                                  target: {
                                    name: "stage",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={quotationStatusList.find(
                                (option) => option.value === values.stage
                              )}
                              options={quotationStatusList}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="stage" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="text-label">Customer</label>

                            <Select
                              id="val-service"
                              name="customerId"
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "phone",
                                  selectedOption?.phone || ""
                                );
                                if (selectedOption?.TRN?.length > 0) {
                                  setTrnDisable(true);
                                } else {
                                  setTrnDisable(false);
                                }

                                setFieldValue("TRN", selectedOption?.TRN || "");
                                handleChange({
                                  target: {
                                    name: "customerId",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              options={customers?.customers?.map(
                                (customer) => ({
                                  value: customer?.customerId,
                                  label: `${customer?.firstname} ${customer?.lastname}`,
                                  phone: customer?.phone,
                                  TRN: customer?.TRN,
                                })
                              )}
                              required
                            ></Select>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Subject"
                                name="quotaion"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quotaion}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.quotaion && errors.quotaion}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Phone Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.TRN
                                  ? errors.TRN
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">TRN</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter TRN No"
                                name="TRN"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("TRN", data);
                                }}
                                onBlur={handleBlur}
                                value={values?.TRN}
                                disabled={trnDisable}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.TRN && errors.TRN}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-3">
                            <div
                              className={`form-group mb-3 ${
                                values.discount
                                  ? errors.discount
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Discount %</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-username1"
                                placeholder="Discount"
                                name="discount"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("discount", data);
                                  setSubServiceTotalDetailPrice(
                                    (prevState) => ({
                                      ...prevState,
                                      totalDiscount: data,
                                    })
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values.discount}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discount && errors.discount}
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="col-3">
                            <div
                              className={`form-group mb-3 ${
                                values.discountTitle
                                  ? errors.discountTitle
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Discount Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Discount Title"
                                name="discountTitle"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("discountTitle", data);
                                }}
                                onBlur={handleBlur}
                                value={values.discountTitle}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountTitle && errors.discountTitle}
                              </div>
                            </div>
                          </div> */}

                          <div className="form-group mb-3 col-3">
                            <label className="text-label">Preferred Time</label>

                            <input
                              type="time"
                              className="form-control"
                              name="preferred_Time"
                              id="time"
                              onChange={handleChange}
                              value={values?.preferred_Time}
                            />
                          </div>

                          <div className="col-3">
                            <div
                              className={`form-group mb-3 ${
                                values.serviceDate
                                  ? errors.serviceDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Service Date</label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Select Date"
                                name="serviceDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.serviceDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.serviceDate && errors.serviceDate}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div
                            className={`form-group mb-3 ${
                              values.salesTaxs
                                ? errors.salesTaxs
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">VAT Tax %</label>
                            <input
                              type="text"
                              className="form-control"
                              id="val-username1"
                              placeholder="Enter a VAT Tax"
                              name="salesTaxs"
                              disabled={true}
                              onChange={(e) => {
                                const data = e.target.value;
                                setFieldValue("salesTaxs", data);
                                setSubServiceTotalDetailPrice((prevState) => ({
                                  ...prevState,
                                  totalTax: data,
                                }));
                              }}
                              onBlur={handleBlur}
                              value={values.salesTaxs}
                            />

                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.salesTaxs && errors.salesTaxs}
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3 mt-3 col-xl-12">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="send-email"
                              name="sendEmails"
                              onChange={(e) => {
                                setFieldValue("sendEmails", e.target.checked);
                              }}
                              onBlur={handleBlur}
                              checked={values.sendEmails}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="send-email"
                            >
                              Are you sure you want to send an email?
                            </label>
                          </div>
                          {errors.sendEmails && touched.sendEmails && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.sendEmails}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Quotation Details
                              </label>

                              <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={handleContentChange}
                              />
                            </div>
                          </div>
                        </div>

                        <Alert variasnt="primary">
                          <strong>{"Service Information"}</strong>
                        </Alert>
                        <div className="row">
                          <div className="col">
                            <div className="overflow-x-auto">
                              <table className="table">
                                <thead className="thead-border">
                                  <tr>
                                    <th>Service Name</th>
                                    <th>Price</th>

                                    <th>Quantity</th>
                                    <th>Discount</th>
                                    <th>SubTotal</th>
                                    <th>Description</th>

                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items?.map((item) => (
                                    <tr key={item.id} className="item">
                                      <td style={{ width: "300px" }}>
                                        <Select
                                          options={subServices?.services
                                            ?.filter(
                                              (subservice) =>
                                                !items.some(
                                                  (i) =>
                                                    i?.subService?.serviceId ===
                                                    subservice.serviceId
                                                )
                                            ) // Filter out already selected subservices
                                            ?.map((subservice) => ({
                                              value: subservice.serviceId,
                                              label: subservice.name,
                                            }))}
                                          onChange={(selectedOption) =>
                                            handleSubServiceNameChange(
                                              selectedOption,
                                              item.id
                                            )
                                          }
                                          value={{
                                            value: item?.subService?.serviceId,
                                            label: item?.subService?.name,
                                          }} // Set the default value
                                          placeholder="Select SubService Name"
                                        />
                                      </td>

                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Start Range Price"
                                          value={item?.startRangePrice} // Set the value of price from item state
                                          disabled // Disable editing of price since it's auto-filled
                                        />
                                      </td>

                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="No of Service"
                                          value={item?.quantity}
                                          onChange={(event) => {
                                            const newQuantity =
                                              event.target.value;
                                            const newSubTotal =
                                              item?.startRangePrice *
                                              newQuantity;

                                            const updatedItem = {
                                              ...item,
                                              quantity: newQuantity,
                                              subTotal: newSubTotal,
                                            };

                                            const updatedItems = items.map(
                                              (i) =>
                                                i.id === item.id
                                                  ? updatedItem
                                                  : i
                                            );
                                            setItems(updatedItems);
                                          }}
                                        />
                                      </td>

                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="discount"
                                          value={item?.discount}
                                          onChange={(event) => {
                                            const newDiscount = event.target
                                              ?.value
                                              ? Math.abs(
                                                  Math.floor(
                                                    Number(event.target.value)
                                                  )
                                                )
                                              : "";

                                            const updatedItem = {
                                              ...item,
                                              discount: newDiscount,
                                            };

                                            const updatedItems = items.map(
                                              (i) =>
                                                i.id === item.id
                                                  ? updatedItem
                                                  : i
                                            );
                                            setItems(updatedItems);
                                          }}
                                        />
                                      </td>

                                      <td>
                                        {/* {item?.subTotal} */}

                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="subtotal"
                                          value={item?.subTotal}
                                          onChange={(event) => {
                                            const newSubtotal =
                                              event.target.value;

                                            const updatedItem = {
                                              ...item,
                                              subTotal: newSubtotal,
                                            };

                                            const updatedItems = items.map(
                                              (i) =>
                                                i.id === item.id
                                                  ? updatedItem
                                                  : i
                                            );
                                            setItems(updatedItems);
                                          }}
                                        />
                                      </td>

                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="enter detail"
                                          value={item?.detail}
                                          onChange={(event) => {
                                            const newDiscount =
                                              event.target?.value;

                                            const updatedItem = {
                                              ...item,
                                              detail: newDiscount,
                                            };

                                            const updatedItems = items.map(
                                              (i) =>
                                                i.id === item.id
                                                  ? updatedItem
                                                  : i
                                            );
                                            setItems(updatedItems);
                                          }}
                                        />
                                      </td>

                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            deleteItem(item.id, item)
                                          }
                                        >
                                          <i className="bi bi-trash"></i> Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <button
                                  type="button"
                                  class="btn btn-outline-primary 1px mt-2 dotted"
                                  onClick={addItem}
                                >
                                  <i className="fa-solid fa-plus me-2"></i> Add
                                  Item
                                </button>
                                <tbody
                                  class="before:block before:h-3"
                                  id="totalAmount"
                                >
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Sub Total</td>

                                    <td class="">
                                      {subServiceTotalDetailPrice?.subtotal} USD
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Discount</td>
                                    <td class="">
                                      {subServiceTotalDetailPrice?.EstimatedDiscounts
                                        ? (subServiceTotalDetailPrice?.EstimatedDiscounts).toFixed(
                                            2
                                          )
                                        : 0}{" "}
                                      USD
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Amount</td>

                                    <td class="">
                                      {
                                        subServiceTotalDetailPrice?.beforeDiscountSubtotal
                                      }{" "}
                                      USD
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">VAT</td>
                                    <td class="">
                                      {subServiceTotalDetailPrice?.EstimatedTax?.toFixed(
                                        2
                                      )
                                        ? subServiceTotalDetailPrice.EstimatedTax?.toFixed(
                                            2
                                          )
                                        : ""}
                                      USD
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Total Amount</td>
                                    <td class="">
                                      {" "}
                                      {subServiceTotalDetailPrice?.total?.toFixed(
                                        2
                                      )}{" "}
                                      USD
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className=" d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
