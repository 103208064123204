import React, { useState, useEffect } from "react";
import Header from "../../parts/Header/Header";
import Footer from "../../parts/Footer/Footer";
import styles from "./styles";
import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BsTelephoneFill } from "react-icons/bs";
import { io } from "socket.io-client";
// const socket = io("http://localhost:8080");
const socket = io("https://api.beyondaio.com");
function WelcomePage({ nextPage }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("us");

  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get("https://geolocation-db.com/json/");
        const countryCode = response.data.country_code.toLowerCase();
        setDefaultCountry(countryCode);
      } catch (error) {
        console.error("Error fetching country code:", error);
      }
    };

    fetchCountryCode();
  }, []);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!name || !email || !phone) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill in all required fields: Name, Email, and Contact Number.",
      });
      return;
    }

    // if (!isValidEmail(email)) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Invalid Email",
    //     text: "Please enter a valid email address.",
    //   });
    //   return;
    // }

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/user`,
        {
          name,
          email,
          phoneNumber: phone,
          phone,
          role: "user",
        }
      );

      const chatUser = response.data;

      socket.emit("newChat", { data: chatUser });
      localStorage.removeItem("chatUser");
      localStorage.setItem("chatUser", JSON.stringify(chatUser.data));

      localStorage.setItem(
        "conversationID",
        chatUser.data.conversation.conversationId
      );
      nextPage();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error processing your request. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-between px-2 py-4"
      style={styles.container}
    >
      <Header />

      <div className="text-center">
        <h1
          className="display-5 fw-bold px-3 text-[#212121] mt-2 mb-2 text-start"
          style={styles.header}
        >
          How can we help you today?{" "}
          <span role="img" aria-label="wave">
            👋
          </span>
        </h1>
      </div>

      <div className="card p-4 shadow-sm border-0" style={styles.card}>
        <div className="d-flex align-items-start">
          <div>
            <div style={{ display: "flex" }}>
              <img
                src="/assets/images/agent.png"
                alt="AI Assistant"
                className="me-3"
                style={styles.img}
              />
              <div>
                <h2
                  className="h6 fw-bold text-[AIO Beyond] mb-0"
                  style={styles.title}
                >
                  AIO Beyond
                </h2>
                <p
                  className="text-muted text-[#212121] mb-2"
                  style={styles.textMuted}
                >
                  Let me know if you have any questions!
                </p>
              </div>
            </div>

            <div
              style={{
                ...styles.inputgrp,
                borderColor: isNameFocused ? "#2DFFCC" : "transparent",
              }}
            >
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={styles.input}
                onFocus={() => setIsNameFocused(true)}
                onBlur={() => setIsNameFocused(false)}
                autoFocus={true}
              />
              <FaUser />
            </div>

            <div
              style={{
                ...styles.inputgrp,
                borderColor: isEmailFocused ? "#2DFFCC" : "transparent",
              }}
            >
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.input}
                onFocus={() => setIsEmailFocused(true)}
                onBlur={() => setIsEmailFocused(false)}
              />
              <MdEmail />
            </div>

            <div
              style={{
                ...styles.inputgrp,
                padding: "5px 12px",
                borderColor: isPhoneFocused ? "#2DFFCC" : "transparent",
              }}
            >
              <PhoneInput
                country={defaultCountry}
                value={phone}
                onChange={(value) => setPhone(value)}
                onFocus={() => setIsPhoneFocused(true)}
                onBlur={() => setIsPhoneFocused(false)}
                inputStyle={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                }}
              />{" "}
              <BsTelephoneFill />
            </div>
          </div>
        </div>

        <button
          onClick={handleSubmit}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="btn w-100 mt-3 text-white"
          style={{
            ...styles.button,
            backgroundColor: isHovered ? "#1f3c3c" : "#0A2424",
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Starting..." : "Let's Start"}
        </button>
      </div>

      <div style={{ marginTop: "5px" }}>
        <Footer />
      </div>
    </div>
  );
}

export default WelcomePage;
