// Create.jsx
import React, { Fragment, useEffect, useState } from "react";

import { Formik, Field, ErrorMessage } from "formik";

import "react-datepicker/dist/react-datepicker.css";

import CustomClearIndicator from "../../Comman/MultiSelect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

import {
  useGetSingleAgreementQuery,
  usePutAgreementMutation,
} from "../../../../features/agreement/agreementApiSlice";

import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

function tConvert(time) {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const loginSchema = Yup.object().shape({
  serviceId: Yup.string().required("Service ID is required"),
  leadId: Yup.string().required("Lead ID is required"),
  quotationId: Yup.string().required("Quotation ID is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
  customerId: Yup.string().required("Customer ID is required"),
  userId: Yup.string().required("User ID is required"),

  address: Yup.string().required("Address is required"),
  period: Yup.string().required("Period is required"),
  projectId: Yup.string().required("Project ID is required"),

  firstServiceDate: Yup.date()
    .required("First Service Date is required")
    .min(
      Yup.ref("startDate"),
      "First Service Date must be after or equal to Start Date"
    )
    .max(
      Yup.ref("endDate"),
      "First Service Date must be before or equal to End Date"
    ),
  secondServiceDate: Yup.date()
    .required("Second Service Date is required")
    .min(
      Yup.ref("startDate"),
      "Second Service Date must be after or equal to Start Date"
    )
    .max(
      Yup.ref("endDate"),
      "Second Service Date must be before or equal to End Date"
    ),
  thirdServiceDate: Yup.date()
    .required("Third Service Date is required")
    .min(
      Yup.ref("startDate"),
      "Third Service Date must be after or equal to Start Date"
    )
    .max(
      Yup.ref("endDate"),
      "Third Service Date must be before or equal to End Date"
    ),
  fourthServiceDate: Yup.date()
    .required("Fourth Service Date is required")
    .min(
      Yup.ref("startDate"),
      "Fourth Service Date must be after or equal to Start Date"
    )
    .max(
      Yup.ref("endDate"),
      "Fourth Service Date must be before or equal to End Date"
    ),

  preferred_Time: Yup.string().required("Preferred Time is required"),
  discount: Yup.number().positive("Discount must be a positive number"),
  taxes: Yup.number().positive("Taxes must be a positive number"),
  PayAbleAmount: Yup.number().positive(
    "Payable Amount must be a positive number"
  ),
  totalAmounts: Yup.number()
    .positive("Total Amounts must be a positive number")
    .required("Total Amounts is required"),
  location: Yup.string().required("Location is required"),
  remarks: Yup.string().required("Remarks is required"),
});

const Create = () => {
  const userInfo = useSelector(selectCurrentUser);
  const { agreementId } = useParams();
  const { data: agreement, refetch } = useGetSingleAgreementQuery(agreementId);

  const [putAgreement, { isLoading }] = usePutAgreementMutation();

  const location = useLocation();

  const [isView, setIsView] = useState(false);

  useEffect(() => {
    refetch();
  }, [agreement, refetch]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);
  const [amc, setAmc] = useState("");
  const [content, setContent] = useState("");
  const [initialData, setInitialDate] = useState({
    serviceId: "",
    leadId: "",
    quotationId: "",
    startDate: "",
    endDate: "",
    customerId: "",
    userId: "",
    phone: "",
    serviceName: "",
    userName: "",
    customerName: "",
    AMCStartDate: "",
    address: "",
    period: "",
    projectId: "",
    firstServiceDate: "",
    secondServiceDate: "",
    thirdServiceDate: "",
    fourthServiceDate: "",
    preferred_Time: "",
    discount: "",
    taxes: "",
    PayAbleAmount: "",
    totalAmounts: "",
    location: "",
    remarks: "",
    subject: "",
  });

  const [selectLead, setSelectedLead] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const amcValue = params.get("amc");
    setAmc(amcValue);
  }, [location]);

  const handleSubmitted = async (values) => {
    try {
      const updatedAgreement = {
        remarks: null,
      };

      updatedAgreement.remarks = content;

      const result = await putAgreement({
        id: agreement?.agreementId,
        updatedAgreement,
      });

      notifySuccess(result?.data?.message);
      navigate("/" + userInfo?.role?.toLowerCase() + "/agreement");
    } catch (error) {
      notifyError(error.message);
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or null

    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const getShortMonthName = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or null

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return months[monthIndex] + " " + year;
  };

  useEffect(() => {
    if (agreement) {
      const selectedLead = agreement; // Assuming project is the selected project object

      setInitialDate({
        serviceId:
          selectedLead?.lead?.serviceId?.map((service) =>
            JSON.parse(service)
          ) || [],
        startDate: selectedLead?.startDate || "",
        endDate: selectedLead?.endDate || "",
        customerId: selectedLead?.customer?.customerId || "",
        frequency: selectedLead?.project?.frequency || "",

        preferred_Time: tConvert(selectedLead?.preferred_Time) || "",

        location:
          { label: selectedLead?.location, value: selectedLead?.location } ||
          "",
        employeeId:
          selectedLead?.lead?.employeeId?.map((employee) =>
            JSON.parse(employee)
          ) || [],
        suggestedPrice:
          selectedLead?.quotaion?.pricesCalculation?.total + " USD" || "",
        finalPrice: selectedLead?.totalAmounts || "",
        subserviceIds:
          selectedLead?.lead?.subserviceIds?.map((subservice) =>
            JSON.parse(subservice)
          ) || [],

        subject: selectedLead?.subject,

        phone: selectedLead?.customer?.phone || "",
        serviceName: selectedLead?.service?.name || "",
        userName: `${selectedLead?.user?.firstName || ""} ${
          selectedLead?.user?.lastName || ""
        }`,
        customerName: `${selectedLead?.customer?.prefixName || ""} ${
          selectedLead?.customer?.firstname || ""
        } ${selectedLead?.customer?.lastname || ""}`,
        AMCStartDate: formatDate(selectedLead?.project?.startDate) || "",
        address: selectedLead?.customer?.mailingAddress || "",
        period: `${getShortMonthName(
          selectedLead?.startDate
        )} - ${getShortMonthName(selectedLead?.project?.endDate)}`,
        projectId: selectedLead?.projectId || "",
        numberOfService: selectedLead?.project?.numberOfService,
        inputServicesDate: agreement?.servicesDates || "",
      });

      if (selectedLead?.remarks) {
        setContent(selectedLead?.remarks);
      }
    }
  }, [agreement]);

  const handleContentChange = (value) => {
    setContent(value);
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {" "}
                  {isView ? "View Agreement" : "Edit Agreement"}
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialData}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setValues,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-xl-3">
                            <label className="text-label">Customer</label>
                            <input
                              type="text"
                              className="form-control"
                              id="val-username1"
                              placeholder=""
                              name="customerName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.customerName}
                              disabled={true}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="customerName" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Phone Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Supervise Project{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Supervise Project"
                                name="userName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.userName}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.userName && errors.userName}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <label className="text-label">Employee </label>
                            <CustomClearIndicator
                              data={values?.employeeId || []}
                              type={"employeeId"}
                              value={values?.employeeId}
                              isView={true}
                            />
                          </div>

                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Frequency</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a frequency"
                                name="frequency"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.frequency}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.frequency && errors.frequency}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Number of Services
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Number of Services"
                                name="numberOfService"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.numberOfService}
                                disabled={true}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.numberOfService &&
                                  errors.numberOfService}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.AMCStartDate
                                  ? errors.AMCStartDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                AMC Start Date
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder=""
                                name="AMCStartDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.AMCStartDate}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.AMCStartDate && errors.AMCStartDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.period
                                  ? errors.period
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Period </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder=""
                                name="period"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.period}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.period && errors.period}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="text-label">Services</label>
                            <CustomClearIndicator
                              data={values?.serviceId || []}
                              type={"subservices"}
                              value={values?.serviceId}
                              isView={true}
                            />
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Final Price</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Final Price"
                                name="finalPrice"
                                // onChange={handleChange}

                                onBlur={handleBlur}
                                // value={values.finalPrice}
                                value={values.finalPrice}
                                disabled={true}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.suggestedPrice && errors.suggestedPrice}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="AMC AC Preventive Maintenance Agreement"
                                name="subject"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.subject}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.subject && errors.subject}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Preferred Time
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder=""
                                name="preferred_Time"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.preferred_Time}
                                disabled={true}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.preferred_Time && errors.preferred_Time}
                              </div>
                            </div>
                          </div>

                          {values?.inputServicesDate?.map(
                            (serviceDate, index) => (
                              <div className="col-md-3" key={index}>
                                <div className={`form-group mb-3`}>
                                  <label className="text-label">
                                    Service Date {index + 1}
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id={`val-serviceDate${index}`}
                                    placeholder=""
                                    name={`serviceDate${index}`}
                                    value={serviceDate}
                                    disabled={true}
                                  />
                                  <div
                                    id={`val-serviceDate${index}-error`}
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors[`serviceDate${index}`]}
                                  </div>
                                </div>
                              </div>
                            )
                          )}

                          <div className="form-group mb-3 col-xl-3">
                            <label className="text-label">Location</label>

                            <Select
                              id="val-project-status"
                              name="location"
                              // onChange={handleChange}

                              onBlur={handleBlur}
                              value={values?.location}
                              isDisabled={true}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="location" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.address
                                  ? errors.address
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Customer Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder=""
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 mt-3 col-xl-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="send-email"
                                name="sendEmail"
                                onChange={(e) => {
                                  setFieldValue("sendEmail", e.target.checked);
                                }}
                                onBlur={handleBlur}
                                checked={values.sendEmail || false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="send-email"
                              >
                                Are you sure you want to send an email?
                              </label>
                            </div>
                            {errors.sendEmail && touched.sendEmail && (
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.sendEmail}
                              </div>
                            )}
                          </div>

                          <div className="form-group my-3">
                            <div
                              className="form-group my-3"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              <label className="text-label">
                                Remarks Details
                              </label>

                              <ReactQuill
                                readOnly={isView}
                                theme="snow"
                                value={content}
                                onChange={handleContentChange}
                              />
                            </div>
                          </div>
                        </div>

                        {!isView && (
                          <div className="d-flex justify-content-end mt-3">
                            {/* <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting || isLoading}
                            >
                              Submit
                            </button> */}
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
