import { apiSlice } from "../../app/api/apiSlice";

export const inspectionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInspection: builder.query({
      query: (leadId) => `inspection/${leadId}`,
      keepUnusedDataFor: 5,
    }),
    createPdfInspection: builder.mutation({
      query: (inspectionData) => ({
        url: `inspection/pdf`,
        method: "POST",
        body: inspectionData, // Pass the inspection data directly
      }),
    }),
    createPdfDownloadInspection: builder.mutation({
      query: (inspectionData) => ({
        url: `inspection/pdf/downlaod`,
        method: "POST",
        body: inspectionData, // Pass the inspection data directly
      }),
    }),
    createInspection: builder.mutation({
      query: ({ leadId, newInspection }) => ({
        url: `inspection/${leadId}`,
        method: "POST",
        body: newInspection,
      }),
    }),

    updateInspection: builder.mutation({
      query: ({ inspectionId, updatedInspection }) => ({
        url: `inspection/${inspectionId}`,
        method: "PUT",
        body: updatedInspection,
      }),
    }),
    updateImageInspection: builder.mutation({
      query: ({ inspectionId, updatedInspection }) => ({
        url: `inspectionImages/${inspectionId}`,
        method: "PUT",
        body: updatedInspection,
      }),
    }),
  }),
});

export const {
  useGetInspectionQuery,
  useCreatePdfInspectionMutation,
  useCreatePdfDownloadInspectionMutation,
  useCreateInspectionMutation,
  useUpdateInspectionMutation,
  useUpdateImageInspectionMutation,
} = inspectionApiSlice;
