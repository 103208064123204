import React, { Fragment, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  usePostWarehouseMutation,
  useGetWarehouseQuery,
} from "../../../../features/Inventory/warehouse/warehouseApiSlice";
import { useGetWarehouseProductsQuery } from "../../../../features/Inventory/product/warehouseProductsApiSlice";
import { usePostStockListMutation } from "../../../../features/Inventory/stocklist/stockListApiSlice";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const loginSchema = Yup.object().shape({});

const Manage = () => {
  const userInfo = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const [selectFrom, setSelectFrom] = useState("");
  const [selectTo, setSelectTO] = useState("");

  const { data: warehouse = [], refetch: refetchWarehouse } =
    useGetWarehouseQuery({
      fetchPolicy: "network-only",
    });

  const { data: warehouseProduct = [], refetch: refetchWarehouseProduct } =
    useGetWarehouseProductsQuery(selectFrom);

  const [postStockList, { isLoading }] = usePostStockListMutation();

  const handleSubmitted = async (values) => {
    try {
      const products = warehouseProduct?.products?.map((product, index) => ({
        product_quantity: product.quantity,
        transfer_quantity: values[`transferValue${index}`],
        productId: product?.product?.productId, // Ensure to include productid
        productName: product?.product?.name,
        checkbox: values[`checkbox${index}`],
      }));

      const inValidProduct = products.find(
        (product) => product.transfer_quantity > product.product_quantity
      );

      if (inValidProduct) {
        notifyError(
          `Transfer quantity exceeds available quantity for product: ${inValidProduct.productName}`
        );
        return;
      }

      const filterRows = products?.filter((product) => {
        return product?.checkbox == true && product?.transfer_quantity > 0;
      });

      const newValue = {
        warehouseIdFrom: selectFrom,
        warehouseIdTo: selectTo,
        products: filterRows,
      };

      if (newValue?.products?.length <= 0) {
        notifyError("Select the Product & Quantity");
        return;
      }

      // return

      const result = await postStockList(newValue);
      if (result?.error) {
        notifyError(result?.error?.data?.message);
        return;
      }

      notifySuccess(result?.data?.message);
      navigate(`/${userInfo?.role?.toLowerCase()}/stocklist`);

      refetchWarehouseProduct();
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Warehouse</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      warehouseIdFrom: "",
                      warehouseIdTo: "",
                      products: [],
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">From</label>
                              <Select
                                options={warehouse?.warehouse?.map(
                                  (warehouse) => ({
                                    value: warehouse.warehouseId,
                                    label: warehouse.name,
                                  })
                                )}
                                name="warehouseIdFrom"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "warehouseIdFrom",
                                    selectedOption.value
                                  );
                                  setSelectFrom(selectedOption.value);
                                  refetchWarehouseProduct(selectFrom);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.warehouseIdFrom &&
                                touched.warehouseIdFrom && (
                                  <div className="invalid-feedback animated fadeInUp">
                                    {errors.warehouseIdFrom}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">To</label>
                              <Select
                                options={warehouse?.warehouse
                                  ?.filter((data) => {
                                    return data?.warehouseId != selectFrom;
                                  })
                                  ?.map((warehouse) => ({
                                    value: warehouse.warehouseId,
                                    label: warehouse.name,
                                  }))}
                                name="warehouseIdTo"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "warehouseIdTo",
                                    selectedOption.value
                                  );
                                  setSelectTO(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.warehouseIdTo &&
                                touched.warehouseIdTo && (
                                  <div className="invalid-feedback animated fadeInUp">
                                    {errors.warehouseIdTo}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        {values.warehouseIdFrom &&
                          values.warehouseIdTo &&
                          values.warehouseIdFrom !== values.warehouseIdTo && (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label className="text-label">Products</label>
                                  <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Product Name</th>
                                        {/* <th>Product ID</th> */}
                                        <th>Brand</th>
                                        <th>Model</th>
                                        {/* <th>Supplier</th> */}
                                        <th>Quantity</th>
                                        <th>Quantity To Move</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {warehouseProduct?.products?.map(
                                        (product, index) => (
                                          <tr key={index}>
                                            <td>
                                              <input
                                                type="checkbox"
                                                name={`checkbox${index}`}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `checkbox${index}`,
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            </td>

                                            <td>{product?.product?.name}</td>
                                            {/* <td>{product?.product?.productId}</td> */}
                                            <td>
                                              {" "}
                                              {product?.product?.brand?.name}
                                            </td>
                                            <td>
                                              {product?.product?.model?.name}
                                            </td>
                                            {/* <td></td> */}

                                            <td>{product.quantity}</td>
                                            <td>
                                              <input
                                                className="form-control"
                                                type="number"
                                                placeholder="Enter number to transfer"
                                                name={`transferValue${index}`}
                                                onChange={handleChange}
                                                min={0}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  {errors.warehouseIdFrom && (
                                    <div className="invalid-feedback animated fadeInUp">
                                      {errors.warehouseIdFrom}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="text-right">
                          {/* <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Transfer Stock
                          </button> */}
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Transfer Stock"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Manage;
