import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Dropdown } from "react-bootstrap";

import {
  useGetCustomerQuery,
  usePutCustomerMutation,
} from "../../../../features/customer/customerApiSlice";

import CountUp from "react-countup";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";

import { IMAGES } from "../../../constant/theme";
import { CSVLink } from "react-csv";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const Customer = () => {
  const [putCustomer, { isLoading, isError, error }] = usePutCustomerMutation();
  const { data: customers = [], refetch } = useGetCustomerQuery();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");

  useEffect(() => {
    refetch();
  }, [customers, refetch]);

  const [search, setSearch] = useState("");

  const recordsPage = 20;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterCustomers = () => {
    if (!search) return customers?.customers || [];

    return customers?.customers?.filter((customer) => {
      const fullName =
        `${customer.firstname} ${customer.lastname}`.toLowerCase();
      const email = customer.email.toLowerCase();
      const phone = customer?.phone?.toLowerCase();
      const status = customer?.status ? "inactive" : "Active";
      // const city = customer.city.toLowerCase();
      // const date = new Date(customer.createdAt).toLocaleString().toLowerCase();

      return (
        fullName.includes(search.toLowerCase()) ||
        email.includes(search.toLowerCase()) ||
        phone.includes(search.toLowerCase()) ||
        status.includes(search.toLowerCase())
        // city.includes(search.toLowerCase())
        // date.includes(search.toLowerCase())
      );
    });
  };

  const filteredCustomers = filterCustomers();
  const records = (filteredCustomers || []).slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredCustomers?.length || 0) / recordsPage);

  // Show only 5 page numbers at a time
  const [visiblePages, setVisiblePages] = useState(4);

  const pagesToShow = (currentPage) => {
    const start = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    const end = Math.min(npage, start + visiblePages - 1);

    return [...Array(end - start + 1).keys()].map((i) => i + start);
  };

  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleEdit = (customer) => {
    navigate("edit/" + customer?.customerId);
  };

  const handleView = (customer) => {
    navigate("view/" + customer?.customerId);
  };

  const handleLedger = (customer) => {
    navigate("ledger/" + customer?.customerId);
  };
  const handleAction = (customer, value) => {
    const updatedCustomer = { ...customer };
    updatedCustomer.status = value;
    putCustomer({ id: updatedCustomer?.customerId, updatedCustomer }).then(
      () => {
        refetch();
      }
    );
  };

  const handleDelete = async (customer) => {
    try {
      let updatedCustomer = { ...customer };
      updatedCustomer.isDeleted = true;
      updatedCustomer.updatedBy = user?.userId;
      updatedCustomer.deleteBy = user?.userId;

      await putCustomer({ id: customer?.customerId, updatedCustomer }).unwrap();

      notifySuccess(`Customer Deleted Successfully`);
      refetch();
    } catch (error) {
      notifyError("Error While deleting customer");
    }
  };

  const SweetAlert = (customer) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(customer);
      } else {
        swal("Your data is safe!");
      }
    });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Customers</h4>

                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      {JSON.parse(user)?.Role?.permissions?.includes(
                        "customer/create"
                      ) && (
                        <Link
                          to={"create"}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Customer
                        </Link>
                      )}
                    </div>
                  </div>

                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>TRN No</th>
                          <th>Phone</th>

                          <th>Status</th>
                          {/* <th>Area</th> */}
                          <th>City</th>

                          <th>Date</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((customer, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{`${customer.firstname} ${customer.lastname}`}</td>
                            <td>{customer.email}</td>
                            <td>{customer?.TRN}</td>
                            <td>{customer.phone}</td>
                            {/* <td>{customer.status ? "inactive" : "Active"}</td> */}

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  as="div"
                                  className={`btn ${
                                    customer?.status
                                      ? "btn-danger"
                                      : "btn-success"
                                  }`}
                                >
                                  {customer?.status ? "Inactive" : "Active"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="task-drop-menu">
                                  {customer?.status && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleAction(customer, false)
                                      }
                                    >
                                      Active
                                    </Dropdown.Item>
                                  )}

                                  {!customer.status && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleAction(customer, true)
                                      }
                                    >
                                      Inactive
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            {/* <td>{customer?.area}</td> */}

                            <td>{customer.city}</td>
                            <td>
                              {new Date(customer.createdAt).toLocaleString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                }
                              )}
                            </td>
                            <td className="text-end">
                              {JSON.parse(user)?.Role?.permissions?.includes(
                                "customer/view/:customerId"
                              ) && (
                                <span
                                  onClick={() => handleView(customer)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}

                              {JSON.parse(user)?.Role?.permissions?.includes(
                                "customer/edit/:customerId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(customer)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}

                              {JSON.parse(user)?.Role?.permissions?.includes(
                                "customer/ledger/:customerId"
                              ) && (
                                <span
                                  onClick={() => handleLedger(customer)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  Customer Ledger
                                </span>
                              )}
                              {JSON.parse(user)?.role == "Admin" && (
                                <span
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => SweetAlert(customer)}
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Delete"
                                  ></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredCustomers?.length < lastIndex
                          ? filteredCustomers?.length
                          : lastIndex}{" "}
                        of {filteredCustomers?.length} entries
                      </div>

                      <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                        <button
                          className="paginate_button previous"
                          onClick={prePage}
                          disabled={currentPage === 1}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </button>

                        <span>
                          {pagesToShow(currentPage).map((n, i) => (
                            <button
                              key={i}
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              onClick={() => setCurrentPage(n)}
                            >
                              {n}
                            </button>
                          ))}
                        </span>

                        <button
                          className="paginate_button next"
                          onClick={nextPage}
                          disabled={currentPage === npage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
