import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  useGetServicesQuery,
  usePutServiceMutation,
} from "../../../../features/service/serviceApiSlice";
import { notifySuccess } from "../../../../helpers/Notify";
import swal from "sweetalert";
import { CSVLink } from "react-csv";
import { IMAGES } from "../../../constant/theme";

const Service = () => {
  const [search, setSearch] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);
  const [putService] = usePutServiceMutation();
  const { data: service = [], refetch } = useGetServicesQuery();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (service?.services) {
      setFilteredServices(service.services);
    }
  }, [service]);

  useEffect(() => {
    refetch();
  }, []);

  const recordsPage = 20;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = filteredServices.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredServices.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const filtered = service.services.filter((service) =>
      service.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredServices(filtered);
  };

  const handleDelete = (service) => {
    const updatedService = {
      ...service,
      isDeleted: true,
      updatedBy: user?.userId,
    };

    putService({ id: service?.serviceId, updatedService })
      .unwrap()
      .then((response) => {
        notifySuccess("Service deleted successfully");
        refetch();
        // navigate("service");
      })
      .catch((error) => {
        console.error("Error updating service", error);
      });
  };

  const handleEdit = (service) => {
    navigate("edit/" + service?.serviceId);
  };

  const handleView = (service) => {
    navigate("view/" + service?.serviceId);
  };

  const SweetAlert = (service) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(service);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Service Name", key: "name" },
    { label: "Parent Service", key: "parentServiceName" },
    { label: "Description", key: "description" },
    { label: "Date", key: "createdAt" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredServices.map((service, index) => ({
      index: index + 1,
      name: service.name,
      parentServiceName: service.parentServiceName,
      description: service.description,
      createdAt: new Date(service.createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    })),
    filename: "services.csv",
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Services </h4>

                    <div className="d-flex align-items-center">
                      {filteredServices?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={handleSearch}
                        placeholder="Search"
                        className="form-control me-2" // Margin-end for spacing
                      />

                      {user?.Role?.permissions?.includes("service/create") && (
                        <Link
                          to="create"
                          className="btn btn-primary btn-sm " // Adjusted classes for button styling
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Service
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Service Name</th>
                          <th>Parent Service</th>
                          <th>Price</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((service, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{service?.name}</td>
                            <td>{service?.parentServiceName}</td>
                            <td>{service?.startRangePrice}</td>
                            <td>
                              {new Date(service?.createdAt).toLocaleString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true, // This will format the time in AM/PM format
                                }
                              )}
                            </td>
                            <td>
                              {user?.Role?.permissions?.includes(
                                "service/view/:serviceId"
                              ) && (
                                <span
                                  onClick={() => handleView(service)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}

                              {user?.Role?.permissions?.includes(
                                "service/edit/:serviceId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(service)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}

                              {user?.role == "Admin" && (
                                <span
                                  onClick={() => SweetAlert(service)}
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Delete"
                                  ></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredServices.length < lastIndex
                          ? filteredServices.length
                          : lastIndex}{" "}
                        of {filteredServices.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
