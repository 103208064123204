import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { usePostPurchasePaymentMutation } from "../../../../../features/financialMangement/purchasePayment/purchasePaymentApiSlice";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";

const CreateForm = (props) => {
  const { data: accounts = [] } = useGetAllAccountsQuery();
  const [postPurchasePayment, { isLoading }] = usePostPurchasePaymentMutation();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      values.purchaseId = props?.purchaseData?.purchaseId;
      values.supplierAccountId = props?.purchaseData?.supplierAccountId;
      values.supplierId = props?.purchaseData?.purchaseOrder?.supplierId;

      if (
        values?.amount >
        props?.purchaseData?.total - props?.purchaseData?.paidAmount
          ? props?.purchaseData?.total
          : 0
      ) {
        notifyError(
          `value should be less than or equal to ${
            props?.purchaseData?.total - props?.purchaseData?.paidAmount
          }`
        );

        return;
      }

      const result = await postPurchasePayment(values);
      notifySuccess(result?.data?.message);

      props.onHide();
    } catch (error) {
      notifyError(error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive")
      .max(
        props?.purchaseData?.total - props?.purchaseData?.paidAmount,
        `Amount should be less than or equal to ${
          props?.purchaseData?.total - props?.purchaseData?.paidAmount
        }`
      ),
    paymentType: Yup.string().required("Payment type is required"),
    paymentDate: Yup.date().required("Payment date is required").nullable(),
    accountId: Yup.string().required("payment account is required"),
  });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Payment{" "}
          <span className="bg-success px-3 py-1 rounded">
            ({props?.purchaseData?.total - props?.purchaseData?.paidAmount} USD
            is Payable ){" "}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            amount: 0,
            paymentType: "",
            paymentDate: "",
            reference: "",
            accountId: "",
            memo: "",
            checkNo: "",
            bankName: "",
            pdcDate: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Amount</label>
                    <Field
                      type="number"
                      className="form-control"
                      placeholder="Enter amount"
                      name="amount"
                    />
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Payment Date</label>
                    <Field
                      type="date"
                      className="form-control"
                      placeholder="Select date"
                      name="paymentDate"
                    />
                    <ErrorMessage
                      name="paymentDate"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Payment Type</label>
                    <Select
                      options={[
                        { value: "Cash", label: "Cash" },
                        { value: "Cheque", label: "Cheque" },
                        { value: "Bank", label: "Bank" },
                      ]}
                      name="paymentType"
                      onChange={(selectedOption) => {
                        setFieldValue("paymentType", selectedOption.value);
                        setFieldValue("accountId", "");
                      }}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="paymentType"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Payment From</label>
                    <Select
                      options={accounts
                        ?.filter((account) => {
                          if (
                            values.paymentType == "Bank" ||
                            values.paymentType == "Cheque"
                          ) {
                            return account?.type == "Bank Account";
                          } else {
                            return account?.type == "Cash and equivalents";
                          }
                          // return account?.type == "Bank Account";
                        })
                        ?.map((account) => ({
                          value: account?.accountId,
                          label: account?.name,
                        }))}
                      name="accountId"
                      onChange={(selectedOption) =>
                        setFieldValue("accountId", selectedOption.value)
                      }
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="accountId"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Reference</label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Enter reference"
                      name="reference"
                    />
                    <ErrorMessage
                      name="reference"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
                {values.paymentType === "Cheque" && (
                  <>
                    <div className="form-group col-md-6">
                      <label htmlFor="referenceNo">Cheque No.</label>
                      <Field
                        type="text"
                        name="checkNo"
                        className="form-control"
                        id="checkNo"
                        placeholder="check no"
                        value={values.checkNo}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="referenceNo">Bank Name</label>
                      <Field
                        type="text"
                        name="bankName"
                        className="form-control"
                        id="bankName"
                        placeholder="enter bank name"
                        value={values.bankName}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="referenceNo">PDC Date</label>
                      <Field
                        type="date"
                        name="pdcDate"
                        className="form-control"
                        id="pdcDate"
                        value={values.pdcDate}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row mt-3">
                <div className="form-group col-md-12">
                  <label htmlFor="memo">Memo</label>
                  <Field
                    as="textarea"
                    name="memo"
                    className="form-control"
                    id="memo"
                    rows="2"
                    value={values.memo}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-5">
                <Button variant="secondary" onClick={props.onHide}>
                  Cancel
                </Button>
                {/* <Button type="submit" variant="success">
                  Save and Close
                </Button> */}
                <button
                  type="submit"
                  className="btn btn-primary me-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <div
                        className="spinner-border text-light"
                        role="status"
                      ></div>
                    </>
                  ) : (
                    "Save and Close"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateForm;
