import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useGetEquityByDatesPeriodQuery } from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

function EquityStatement() {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [records, setRecords] = useState([]);
  const [changesSum, setChangesSum] = useState(0);

  const { data: allRecords } = useGetEquityByDatesPeriodQuery(
    {
      dateTo: dateTo,
      dateFrom: dateFrom,
    },
    { skip: !dateTo || !dateFrom }
  );

  function mergeEntries(entries) {
    const mergedEntries = {};

    entries.forEach((entry) => {
      const { accountId, debits, credits } = entry;

      if (!mergedEntries[accountId]) {
        mergedEntries[accountId] = { ...entry }; // Clone the entry
      } else {
        // Sum debits and credits
        mergedEntries[accountId].debits = (
          parseFloat(mergedEntries[accountId].debits || 0) +
          parseFloat(debits || 0)
        ).toString();
        mergedEntries[accountId].credits = (
          parseFloat(mergedEntries[accountId].credits || 0) +
          parseFloat(credits || 0)
        ).toString();
      }
    });

    return Object.values(mergedEntries); // Convert the object back to an array
  }

  useEffect(() => {
    if (allRecords?.equity) {
      const data = mergeEntries(allRecords?.equity);

      const sum = data?.reduce((acc, item) => {
        const credits = parseFloat(item?.credits) || 0;
        const debits = parseFloat(item?.debits) || 0;
        return acc + (credits - debits);
      }, 0); // Initialize `acc` as 0

      setChangesSum(sum);

      setRecords(data);
    }
  }, [allRecords]);
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Movement_of_Equity_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{color:"#009688"}}>Movement Of Equity </h4>
              <button
  onClick={downloadPDF}
   style={{ marginBottom: "20px", background: "transparent", border: "none", padding: 0 }}
>
  <MdOutlineFileDownload style={{ color: "inherit" , fontSize:"20px" }} /> 
</button>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{
                    fromDate: "",
                    toDate: "",
                  }}
                  enableReinitialize={true}
                  // validationSchema={loginSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Date From</label>
                            <input
                              type="date"
                              name="fromDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("fromDate", e.target.value);
                                setdateFrom(e.target.value);
                              }}
                              onBlur={handleBlur}
                              //   max={new Date()?.toISOString()?.slice(0, 10)}
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.fromDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Date To</label>
                            <input
                              type="date"
                              //   max={new Date()?.toISOString()?.slice(0, 10)}
                              name="toDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("toDate", e.target.value);
                                setDateTo(e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.toDate}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>

              {dateFrom && dateTo && (
                <div id="report-content">
                <div className="container">
                  <div className="row">
                    <div className="col-6 text-start">
                      <h1 style={{ color: '#009688' }}>Aifa Services</h1> {/* Updated color */}
                    </div>
                    <div className="col-6 text-end">
                      <img
                        src={aifaLogo}
                        alt="logo"
                        style={{
                          width: "130px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center" style={{ backgroundColor: 'rgb(52 152 219)', color: 'white', padding: '10px' }}>
                      <h3 style={{ color: 'white' }}>Movement of Equity</h3>
                      <p>
                        From{" "}
                        <b>
                          {" "}
                          {`${new Date(dateFrom).toLocaleDateString("en-GB")} `}
                        </b>
                        To{" "}
                        <b>
                          {" "}
                          {`${new Date(dateTo).toLocaleDateString("en-GB")}`}
                        </b>
                      </p>
                    </div>
                  </div>

                  <div className="row mt-4" style={{ color: "black" }}>
                    <div className="col-6">
                      <p className="text-muted text-black">ACCOUNT</p>
                    </div>
                    <div className="col-6 text-end f-bold">
                      <p style={{ color: "black" }}>TOTAL</p>
                    </div>
                  </div>
                  <div className="row" style={{ backgroundColor: '#e6f3ff' }}>
                    <div className="col-6" style={{ color: "black" }}>
                      <p>Opening Balance</p>
                    </div>
                    <div className="col-6 text-end">
                      <p>  {allRecords?.openingBalances}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h4 style={{ color: " #009688" }}>
                        Changes in Equity
                      </h4>
                    </div>
                  </div>

                  {records?.length > 0 ? (
                    records?.map((item, index) => (
                      <div
                        className="row"
                        key={index}
                        style={{ color: "black" }}
                      >
                        <div className="col-6">
                          <p style={item.bold ? { fontWeight: "bold" } : {}}>
                            {item.Account?.name}
                          </p>
                        </div>
                        <div className="col-6 text-end">
                          <p style={item.bold ? { fontWeight: "bold" } : {}}>
                            {parseFloat(item?.credits ? item?.credits : 0) -
                              parseFloat(item?.debits ? item?.debits : 0)}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Record Found</p>
                  )}

                  <div className="row mb-0" style={{color: " #009688", fontWeight: "bold"}}>
                    <div className="col-6" style={{ color: "black" }}>
                      <p style={{ color: " #009688" }}>Net Changes in Equity</p>
                    </div>
                    <div className="col-6 text-end" style={{ color: " #009688" }}>
                      <p>  {changesSum}</p>
                    </div>
                  </div>
                  <hr className="my-0"/>

                  <div className="row mb-0" style={{color: " #009688", fontWeight: "bold"}}>
                    <div className="col-6" style={{ color: "black" }}>
                      <p style={{ color: " #009688" }}>Closing Balance</p>
                    </div>
                    <div className="col-6 text-end" style={{ color: " #009688" }}>
                      <p>  {allRecords?.openingBalances - changesSum}</p>
                    </div>
                  </div>
                </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquityStatement;
