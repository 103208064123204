import React from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  useGetEmployeeBySuperviserIdQuery,
  useGetSupervisersQuery,
} from "../../../features/superviser/superviserApiSlice";
import Select from "react-select";
import { usePutTaskMutation } from "../../../features/taskManagement/taskManagementApiSlice";
import { notifyError, notifySuccess } from "../../../helpers/Notify";
import { usePutCallOutTaskMutation } from "../../../features/callOutTask/callOutTaskApiSlice";

const loginSchema = Yup.object().shape({
  startDate: Yup.date().required("date is required"),
  supervisorId: Yup.object().required("Supervisor is required"),
  employee: Yup.array()
    .min(1, "select atleast 1 employee")
    .required("employee is required"),
  preferred_Time: Yup.string().required("time is required"),
});
const TaskDetailModal = ({ show, onHide, task, type }) => {
  const [updateTask, { isLoading }] = usePutTaskMutation();

  const [updateCalloutTask, { isLoading: calloutLoading }] =
    usePutCallOutTaskMutation();

  const { data: supervisers = [] } = useGetSupervisersQuery({
    fetchPolicy: "network-only",
  });

  const { data: employee = [] } = useGetEmployeeBySuperviserIdQuery({
    fetchPolicy: "network-only",
  });

  const handleSubmitted = async (values) => {
    values.employee =
      type == "task" && !task?.aggrementId
        ? values?.employee?.map((data) => JSON.stringify(data))
        : values?.employee;

    values.supervisor = values?.supervisorId?.value;
    if (type == "callout") {
      values.preferredTime = values?.preferred_Time;
    }

    if (type == "callout" && task?.type == "Paid") {
      values.price = values.price;
    } else {
      delete values.price;
    }

    try {
      if (type == "task") {
        await updateTask({ id: task?.taskId, updatedTask: values }).unwrap();
        notifySuccess("task updated successfully");
        onHide();
      } else if (type == "callout") {
        updateCalloutTask({
          callOutTaskId: task.callOutTaskId,
          updatedTask: values,
        }).unwrap();

        notifySuccess("task updated successfully");
        onHide();
      }
    } catch (error) {
      notifyError("error while updating task");
    }
  };
  return (
    <Modal show={show} onHide={onHide} dialogClassName="custom-modal-width">
      <Modal.Header closeButton>
        <Modal.Title>Task Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="basic-form">
            <Formik
              initialValues={{
                employee:
                  (task?.employee && type == "callout") || task?.aggrementId
                    ? task?.employee
                    : task?.employee && type == "task"
                    ? task?.employee?.map((data) => JSON.parse(data))
                    : [],
                startDate:
                  type == "callout"
                    ? new Date(task?.startDate)?.toISOString()?.split("T")[0]
                    : task?.startDate || "",
                supervisorId:
                  {
                    value: task?.supervisor,
                    label: task?.user?.firstName + " " + task?.user?.lastName,
                  } || "",
                preferred_Time:
                  type == "callout"
                    ? task?.preferredTime
                    : task?.preferred_Time || "",
                price:
                  type == "callout" && task?.type == "Paid" ? task?.price : 0,
              }}
              enableReinitialize={true}
              validationSchema={loginSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmitted(values);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className={`form-group mb-3 ${
                          values.startDate
                            ? errors.startDate
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="val-username1"
                          placeholder="Enter Address"
                          name="startDate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.startDate}
                        />
                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.startDate && errors.startDate}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div
                        className={`form-group mb-3 ${
                          values.preferred_Time
                            ? errors.preferred_Time
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Preferred Time</label>
                        <input
                          type="time"
                          className="form-control"
                          id="val-username1"
                          placeholder="Enter Address"
                          name="preferred_Time"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.preferred_Time}
                        />
                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.preferred_Time && errors.preferred_Time}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div
                        className={`form-group mb-3 ${
                          values.supervisorId
                            ? errors.supervisorId
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Supervisor</label>
                        <Select
                          id="val-service"
                          name="supervisorId"
                          onChange={(selectedOption) => {
                            setFieldValue("supervisorId", selectedOption);
                            setFieldValue("employee", "");
                          }}
                          onBlur={handleBlur}
                          value={values?.supervisorId}
                          options={supervisers?.users
                            ?.filter((user) => {
                              return (
                                user?.userId != values?.supervisorId?.value
                              );
                            })
                            ?.map((supervisor) => ({
                              value: supervisor?.userId,
                              label: `${supervisor.firstName} ${supervisor.lastName}`,
                            }))}
                        />

                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.supervisorId && errors.supervisorId}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div
                        className={`form-group mb-3 ${
                          values.employee
                            ? errors.employee
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Employee</label>
                        <Select
                          id="val-service"
                          name="employee"
                          onChange={(selectedOption) => {
                            setFieldValue("employee", selectedOption);
                          }}
                          onBlur={handleBlur}
                          value={values?.employee}
                          options={employee?.users
                            ?.filter(
                              (user) =>
                                user?.supervisorId ==
                                values?.supervisorId?.value
                            )
                            ?.map((employee) => ({
                              value: employee?.userId,
                              label: `${employee.firstName} ${employee.lastName}`,
                            }))}
                          isMulti
                        />
                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.employee && errors.employee}
                        </div>
                      </div>
                    </div>

                    {type == "callout" && task?.type == "Paid" && (
                      <div className="col-md-12">
                        <div
                          className={`form-group mb-3 ${
                            values.price
                              ? errors.price
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Price</label>
                          <input
                            id="val-service"
                            type="number"
                            className="form-control"
                            required
                            min={1}
                            name="price"
                            onChange={(e) => {
                              setFieldValue("price", e.target?.value);
                            }}
                            onBlur={handleBlur}
                            value={values?.price}
                          />
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.price && errors.price}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="text-right">
                    <button
                      type="submit"
                      className="btn btn-primary me-2"
                      disabled={isSubmitting || isLoading || calloutLoading}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TaskDetailModal;
