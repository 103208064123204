import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleInvoiceQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AifaLogo from "./AIFA-Logo-2.png";

const View = () => {
  const { invoiceId } = useParams();
  const { data: singleCallOutInvoices } = useGetSingleInvoiceQuery(invoiceId);
  const calibriBase64 = "YOUR_BASE64_ENCODED_CALIBRI_FONT_STRING";
  const [loader, setLoader] = useState(false);

  const handleDownloadPDF = () => {
    const input = document.getElementById("quotation-content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addFileToVFS("calibri.ttf", calibriBase64);
      pdf.addFont("calibri.ttf", "calibri", "normal");
      pdf.setFont("calibri");
      pdf.setFontSize(12);

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("invoice.pdf");
    });
  };

  function convertHeadingsToParagraphs(htmlContent) {
    // Create a temporary DOM element to manipulate the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Select all heading tags (h1, h2, h3, etc.)
    const headings = tempDiv.querySelectorAll("h1, h2, h3, h4, h5, h6");

    headings.forEach((heading) => {
      // Create a new <p> element
      const p = document.createElement("p");

      // Copy the content of the heading into the <p> element
      p.innerHTML = heading.innerHTML;

      // Apply the bold style to the <p> tag
      p.style.fontWeight = "bold";

      // Remove extra line spacing by setting margin and padding to 0
      p.style.margin = "0";
      p.style.padding = "0";

      // Replace the heading with the <p> tag
      heading.replaceWith(p);
    });

    // Return the updated HTML as a string
    return tempDiv.innerHTML;
  }

  return (
    <div id="quotation">
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger" onClick={handleDownloadPDF}>
          Print
        </button>
      </div>
      <div
        id="quotation-content"
        className="m-3 rounded quotation d-flex flex-column justify-content-between "
      >
        <div className="row justify-content-center">
          <div className="">
            <div className="card shadow-sm" style={{ width: "100%" }}>
              <div className="card-body p-4">
                <div className="row mb-4">
                  {/* Invoice Details */}
                  <div className="col-md-6">
                    <h3
                      className="mb-3"
                      style={{ color: "#009688", fontSize: "1.75rem" }}
                    >
                      Tax Invoice
                    </h3>
                    <p className="mb-2">
                      <strong>Invoice No:</strong>{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          paddingLeft: "10px",
                        }}
                      >
                        {singleCallOutInvoices?.invoiceNo}
                      </span>
                    </p>
                    <p className="mb-2">
                      <strong>Invoice Date:</strong>{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          paddingLeft: "10px",
                        }}
                      >
                        {new Date(
                          singleCallOutInvoices?.invoiceDate
                        )?.toLocaleDateString("en-GB")}
                      </span>
                    </p>
                    <p className="mb-2">
                      <strong>Due Date:</strong>{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          paddingLeft: "10px",
                        }}
                      >
                        {new Date(
                          singleCallOutInvoices?.invoiceDue
                        )?.toLocaleDateString("en-GB")}
                      </span>
                    </p>
                  </div>
                  {/* Logo on the right */}
                  <div className="col-md-6 text-md-end">
                    <img
                      src={AifaLogo}
                      alt="AIFA Logo"
                      className="img-fluid mb-3"
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                </div>

                <div className="row mb-4" style={{ display: "flex" }}>
                  <div className="col-md-6" style={{ flex: "1" }}>
                    <div
                      className="p-3"
                      style={{ backgroundColor: "#d9edf7", height: "100%" }}
                    >
                      <h5 style={{ color: "#009688", fontSize: "20px" }}>
                        Billed By
                      </h5>
                      <p
                        className="mb-1"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        AIFA Environmental Services & Pest Control LLC
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        Del Ani Building, Office# FFA03, Al Quoz
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        Dubai, United Arab Emirates (UAE)
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>VAT Number:</strong>{" "}
                        <span style={{ color: "#000" }}>100242271300003</span>
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>Phone:</strong>{" "}
                        <span style={{ color: "#000" }}>+971 4 834 7261</span>
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>Website:</strong>{" "}
                        <span style={{ color: "#000" }}>aifaservices.ae</span>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6" style={{ flex: "1" }}>
                    <div
                      className="p-3"
                      style={{ backgroundColor: "#d9edf7", height: "100%" }}
                    >
                      <h5 style={{ color: "#009688", fontSize: "20px" }}>
                        Billed To
                      </h5>
                      <p
                        className="mb-1"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        {singleCallOutInvoices?.customer?.firstname +
                          " " +
                          singleCallOutInvoices?.customer?.lastname}
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        {singleCallOutInvoices?.customer?.mailingAddress}
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>VAT Number:</strong>{" "}
                        <span style={{ color: "#000" }}>
                          {singleCallOutInvoices?.customer?.TRN}
                        </span>
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>Phone:</strong>{" "}
                        <span style={{ color: "#000" }}>
                          {singleCallOutInvoices?.customer?.phone}
                        </span>
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>Email:</strong>{" "}
                        <span style={{ color: "#000" }}>
                          {singleCallOutInvoices?.customer?.email}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="table-responsive mb-4">
                  <table
                    className="table table-bordered"
                    style={{ borderCollapse: "collapse" }}
                  >
                    <thead
                      style={{ backgroundColor: "#3498db", color: "#ffffff" }}
                    >
                      <tr>
                        <th>#</th>
                        <th>Service</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#d9edf7" }}>
                      <tr>
                        <td>1</td> {/* Row number */}
                        <td>
                          {singleCallOutInvoices?.callOutTaskId
                            ? "Callout Service"
                            : "Inspection"}
                        </td>
                        <td>{singleCallOutInvoices?.subTotal}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="p-3" style={{ backgroundColor: "#d9edf7" }}>
                      <h5 style={{ color: "#009688", fontSize: "20px" }}>
                        Bank Details
                      </h5>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>Account Name:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          AIFA Environmental Services & Pest Control LLC
                        </span>
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>Account Number:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          0332415463001
                        </span>
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>IBAN:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          AE230400000332415463001
                        </span>
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>SWIFT Code:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          NBADAEAA
                        </span>
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>Bank:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          FAB BANK
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    style={{ fontSize: "18px", color: "black" }}
                  >
                    <div className="d-flex justify-content-between mb-2">
                      <span>Sub Total</span>
                      <span>USD {singleCallOutInvoices?.subTotal}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>{`Discount`}</span>
                      {/* // (${invoices?.quotaion?.discount}%) */}
                      <span>
                        USD{" "}
                        {singleCallOutInvoices?.itemDiscounts
                          ? parseFloat(
                              singleCallOutInvoices?.itemDiscounts
                            ).toFixed(2)
                          : "0"}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Amount</span>
                      <span>USD {singleCallOutInvoices?.subTotal}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>VAT</span>
                      <span>
                        USD{" "}
                        {parseFloat(
                          singleCallOutInvoices?.estimatedTax
                        ).toFixed(2)}
                      </span>
                    </div>
                    <hr
                      style={{ borderTop: "3px solid black", margin: "10px 0" }}
                    />
                    <div className="d-flex justify-content-between text-black mb-2">
                      <strong>Total (USD)</strong>
                      <strong>USD {singleCallOutInvoices?.totalAmount}</strong>
                    </div>
                    <hr
                      style={{ borderTop: "3px solid black", margin: "10px 0" }}
                    />
                  </div>
                </div>

                <div>
                  <h4 style={{ color: "black", fontWeight: "bold" }}>Note:</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: convertHeadingsToParagraphs(
                        singleCallOutInvoices?.description
                      ),
                    }}
                    style={{ color: "black" }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
