// Create.jsx
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { usePostCurrencyMutation } from "../../../../../features/financialMangement/settings/currency/currencyApiSlice";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  code: Yup.string().required("Code is required"),
  symbol: Yup.string().required("Symbol is required"),
});

const Create = () => {
  const navigate = useNavigate();

  const [postCurrency, { isLoading }] = usePostCurrencyMutation();
  // If you're using putServiceMutation, make sure to uncomment the line below
  // const { mutate: putService } = usePutServiceMutation();

  const handleSubmitted = async (values) => {
    try {
      const result = await postCurrency(values);

      // Handle success, access result.data
      notifySuccess(result?.data?.message);
      navigate("currencies");
    } catch (error) {
      notifyError(error.message);
      // Handle error, access error.data
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Currency</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{ name: "", code: "", symbol: "" }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter currency name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-4">
                            <label className="text-label">Currency Code</label>
                            <input
                              type="text"
                              className="form-control"
                              id="val-prefix"
                              placeholder="Enter currency code"
                              name="code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.code}
                            />
                            <div
                              id="val-prefix-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.code && errors.code}
                            </div>
                          </div>

                          <div className="form-group col-md-4">
                            <label className="text-label">
                              Currency Symbol
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="val-prefix"
                              placeholder="Enter currency symbol"
                              name="symbol"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.symbol}
                            />
                            <div
                              id="val-prefix-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.symbol && errors.symbol}
                            </div>
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
