import React, { useEffect, useState } from "react";
import AifaLogo from "./AIFA-Logo-2.png";
import RightSide from "../../../../../images/RightSide.png";
import LeftSide from "../../../../../images/LedfSide.png";
import BG from "../../../../../images/aifaBGimage.png";
import Signature from "../../../../../images/signature.png";
import Stamp from "../../../../../images/stamp.jpg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";
import { useGetSingleInvoiceQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { MdOutlineFileDownload } from "react-icons/md";

const InvoiceView = () => {
  const { invoicesId } = useParams();
  const [isPDFDownloadCreating, setIsPDFDownloadCreating] = useState(false);

  const { data: invoices, refetch } = useGetSingleInvoiceQuery(invoicesId);

  const [records, setRecords] = useState([]);
  const [withoutTaxValue, setWithoutTaxValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (invoices) {
      const products = invoices?.productIds?.map((product) => {
        const data = JSON.parse(product);

        return data;
      });

      const services = invoices?.subSeriviceList;

      if (products?.length > 0) {
        const productService = services.concat(products);

        setRecords(productService);
      } else {
        setRecords(services);
      }

      const inspectionWithoutTax = (
        invoices?.lead?.inspection?.cost * 0.95
      ).toFixed(2);

      const withoutTax = records?.reduce((sum, record) => {
        if (record.subService) {
          return parseFloat(sum) + parseFloat(record.subTotal || 0);
        } else {
          // For product records
          return parseFloat(sum) + parseFloat(record.total * 0.95 || 0);
        }
      }, 0);

      setWithoutTaxValue(withoutTax);

      const withTax = records?.reduce((sum, record) => {
        if (record.subService) {
          // For service records
          return (
            parseFloat(sum) +
            parseFloat(record.subTotal + record.subTotal * 0.05 || 0)
          );
        } else {
          // For product records
          return parseFloat(sum) + parseFloat(record.total || 0);
        }
      }, 0);

      setTotalAmount(withTax);

      const quantitySum = records?.reduce((sum, record) => {
        if (record.subService) {
          return sum + parseInt(record.quantity || 1);
        } else {
          // For product records
          return sum + parseInt(record.productQuantity || 1);
        }
      }, 1); // set 1 due to the one qunatity for inspection

      setQuantity(quantitySum);
    }
  }, [invoices, totalAmount, withoutTaxValue, quantity]);

  const calibriBase64 = "YOUR_BASE64_ENCODED_CALIBRI_FONT_STRING";

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  const handleDownloadPDF = () => {
    setIsPDFDownloadCreating(true);
    const input = document.getElementById("quotation-content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addFileToVFS("calibri.ttf", calibriBase64);
      pdf.addFont("calibri.ttf", "calibri", "normal");
      pdf.setFont("calibri");
      pdf.setFontSize(12);

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("invoice.pdf");
    });
    setIsPDFDownloadCreating(false);
  };

  function convertHeadingsToParagraphs(htmlContent) {
    // Create a temporary DOM element to manipulate the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Select all heading tags (h1, h2, h3, etc.)
    const headings = tempDiv.querySelectorAll("h1, h2, h3, h4, h5, h6");

    headings.forEach((heading) => {
      // Create a new <p> element
      const p = document.createElement("p");

      // Copy the content of the heading into the <p> element
      p.innerHTML = heading.innerHTML;

      // Apply the bold style to the <p> tag
      p.style.fontWeight = "bold";

      // Remove extra line spacing by setting margin and padding to 0
      p.style.margin = "0";
      p.style.padding = "0";

      // Replace the heading with the <p> tag
      heading.replaceWith(p);
    });

    // Return the updated HTML as a string
    return tempDiv.innerHTML;
  }

  return (
    <div id="quotation">
      <div className="d-flex justify-content-end">
        <button
          style={{
            background: "transparent",
            border: "1px solid #007bff",
            color: "#007bff",
            borderRadius: "4px",
            padding: "5px 10px",
            cursor: "pointer",
          }}
          title="Download PDF"
          disabled={isPDFDownloadCreating}
          onClick={handleDownloadPDF}
        >
          {isPDFDownloadCreating ? (
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          ) : (
            <>
              <MdOutlineFileDownload
                style={{
                  color: "inherit",
                  fontSize: "20px",
                  marginRight: "5px",
                }}
              />
              Download PDF
            </>
          )}
        </button>
      </div>
      <div
        id="quotation-content"
        className="m-3 rounded quotation d-flex flex-column justify-content-between "
      >
        <div className="row justify-content-center">
          <div className="">
            <div className="card shadow-sm" style={{ width: "100%" }}>
              <div className="card-body p-4">
                <div className="row mb-4">
                  {/* Invoice Details */}
                  <div className="col-md-6">
                    {/* Increased font size for "Tax Invoice" */}
                    <h3
                      className="mb-3"
                      style={{ color: "#009688", fontSize: "1.75rem" }}
                    >
                      Tax Invoice
                    </h3>
                    <p className="mb-2">
                      <strong>Invoice No:</strong>{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          paddingLeft: "10px",
                        }}
                      >
                        {invoices?.invoiceNo}
                      </span>
                    </p>
                    <p className="mb-2">
                      <strong>Invoice Date:</strong>{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          paddingLeft: "10px",
                        }}
                      >
                        {formatDate(invoices?.invoiceDate)}
                      </span>
                    </p>
                    <p className="mb-2">
                      <strong>Due Date:</strong>{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          paddingLeft: "10px",
                        }}
                      >
                        {formatDate(invoices?.invoiceDue)}
                      </span>
                    </p>
                  </div>
                  {/* Logo on the right */}
                  <div className="col-md-6 text-md-end">
                    <img
                      src={AifaLogo}
                      alt="AIFA Logo"
                      className="img-fluid mb-3"
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                </div>

                <div className="row mb-4" style={{ display: "flex" }}>
                  <div className="col-md-6" style={{ flex: "1" }}>
                    <div
                      className="p-3"
                      style={{ backgroundColor: "#d9edf7", height: "100%" }}
                    >
                      <h5 style={{ color: "#009688", fontSize: "20px" }}>
                        Billed By
                      </h5>
                      <p
                        className="mb-1"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        AIFA Environmental Services & Pest Control LLC
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        Del Ani Building, Office# FFA03, Al Quoz
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        Dubai, United Arab Emirates (UAE)
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>VAT Number:</strong>{" "}
                        <span style={{ color: "#000" }}>100242271300003</span>
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>Phone:</strong>{" "}
                        <span style={{ color: "#000" }}>+971 4 834 7261</span>
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>Website:</strong>{" "}
                        <span style={{ color: "#000" }}>aifaservices.ae</span>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6" style={{ flex: "1" }}>
                    <div
                      className="p-3"
                      style={{ backgroundColor: "#d9edf7", height: "100%" }}
                    >
                      <h5 style={{ color: "#009688", fontSize: "20px" }}>
                        Billed To
                      </h5>
                      <p
                        className="mb-1"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        {invoices?.customer?.firstname +
                          " " +
                          invoices?.customer?.lastname}
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        {invoices?.customer?.mailingAddress}
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>VAT Number:</strong>{" "}
                        <span style={{ color: "#000" }}>
                          {invoices?.customer?.TRN}
                        </span>
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>Phone:</strong>{" "}
                        <span style={{ color: "#000" }}>
                          {invoices?.customer?.phone}
                        </span>
                      </p>
                      <p className="mb-1" style={{ color: "black" }}>
                        <strong>Email:</strong>{" "}
                        <span style={{ color: "#000" }}>
                          {invoices?.customer?.email}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="table-responsive mb-4">
                  <table
                    className="table table-bordered"
                    style={{ borderCollapse: "collapse" }}
                  >
                    <thead
                      style={{ backgroundColor: "#3498db", color: "#ffffff" }}
                    >
                      <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>VAT Rate</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Discount</th>
                        <th>Amount</th>
                        <th>VAT</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#d9edf7" }}>
                      {records?.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td> {/* Row number */}
                          <td>
                            {row?.subService
                              ? row?.subService?.name
                              : row?.productName}
                          </td>
                          <td>5%</td>
                          <td>
                            {row?.subService
                              ? row?.quantity
                              : row?.productQuantity}
                          </td>
                          <td>
                            USD{" "}
                            {parseFloat(
                              row?.subService
                                ? row?.subTotal
                                : row?.price * row?.productQuantity
                            ).toFixed(2)}
                          </td>
                          <td>
                            USD{" "}
                            {parseFloat(
                              row?.subService
                                ? row?.discountAmount
                                : row?.discount
                            ).toFixed(2)}
                          </td>
                          <td>
                            USD{" "}
                            {parseFloat(
                              row?.subService
                                ? row?.priceAfterDiscount
                                : row?.priceBeforeVat * row?.productQuantity
                            ).toFixed(2)}
                          </td>
                          <td>
                            USD{" "}
                            {parseFloat(
                              row?.subService ? row?.vatAmount : row?.taxes
                            ).toFixed(2)}
                          </td>
                          <td>
                            USD{" "}
                            {parseFloat(
                              row?.subService ? row?.netPrice : row?.total
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="p-3" style={{ backgroundColor: "#d9edf7" }}>
                      <h5 style={{ color: "#009688", fontSize: "20px" }}>
                        Bank Details
                      </h5>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>Account Name:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          AIFA Environmental Services & Pest Control LLC
                        </span>
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>Account Number:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          0332415463001
                        </span>
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>IBAN:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          AE230400000332415463001
                        </span>
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>SWIFT Code:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          NBADAEAA
                        </span>
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "black", marginBottom: "10px" }}
                      >
                        <strong>Bank:</strong>
                        <span style={{ color: "#000", marginLeft: "10px" }}>
                          FAB BANK
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    style={{ fontSize: "18px", color: "black" }}
                  >
                    <div className="d-flex justify-content-between mb-2">
                      <span>Sub Total</span>
                      <span>USD {invoices?.subTotal}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>{`Discount`}</span>

                      <span>
                        USD {parseFloat(invoices?.itemDiscounts).toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Amount</span>
                      <span>
                        USD{" "}
                        {(
                          parseFloat(invoices?.subTotal) -
                          parseFloat(invoices?.itemDiscounts)
                        ).toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>VAT</span>
                      <span>
                        USD {parseFloat(invoices?.estimatedTax).toFixed(2)}
                      </span>
                    </div>
                    <hr
                      style={{ borderTop: "3px solid black", margin: "10px 0" }}
                    />
                    <div className="d-flex justify-content-between text-black mb-2">
                      <strong>Total (USD)</strong>
                      <strong>
                        USD{" "}
                        {parseFloat(invoices?.totalAmount) +
                          parseFloat(
                            invoices?.lead?.inspection?.cost > 0
                              ? invoices?.lead?.inspection?.cost
                              : 0
                          )}
                      </strong>
                    </div>
                    <hr
                      style={{ borderTop: "3px solid black", margin: "10px 0" }}
                    />
                    <div className="d-flex justify-content-between mb-2">
                      <span>Inspection Charges:</span>
                      <span>
                        USD{" "}
                        {invoices?.lead?.inspection?.cost > 0
                          ? invoices?.lead?.inspection?.cost
                          : 0}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Remaining Amount (USD):</span>
                      <span>USD {invoices?.totalAmount}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <h4 style={{ color: "black", fontWeight: "bold" }}>Note:</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: convertHeadingsToParagraphs(
                        invoices?.description
                      ),
                    }}
                    style={{ color: "black" }}
                  ></p>
                </div>

                {/* <div className="row">
                  <div className="col-12">
                    <h5
                      className=""
                      style={{
                        fontSize: "1.5rem",
                        color: "#009688",
                        marginBottom: "1rem",
                        textAlign: "left",
                      }}
                    >
                      Terms & Conditions
                    </h5>
                    <ol style={{ color: "#009688" }}>
                      {" "}
                      
                      <li style={{ color: "black" }}>
                        1. Payment has to be made 100% advance for AC Units &
                        70% for material
                      </li>
                      <li style={{ color: "black" }}>
                        2. Payment should have been made under the name of AIFA
                        Environmental Services & Pest Control LLC
                      </li>
                    </ol>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;
