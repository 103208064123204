import React, { useState } from "react";
import "./style.css";
import { styles } from "./style";
import { dateFormat } from "../../../helpers/DateFormat";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { SiGooglegemini } from "react-icons/si";
const UserProfile = ({
  activeConversation,
  setIsProfile,
  activeTab,
  setActiveTab,
}) => {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(false);
  const [isBtn, setIsBtn] = useState(false);

  const fetchConversationMessages = async () => {
    setPreLoading(true);
    setIsBtn(false);
    const activeConversationID = activeConversation?.conversationId;
    if (activeConversationID) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chatbot/summary/${activeConversationID}`
        );

        if (response?.data?.summary?.summary) {
          setSummary(response?.data?.summary?.summary);
        } else {
          setSummary(null);
          setIsBtn(true);
        }

        setPreLoading(false);
        setIsBtn(true);
      } catch (error) {
        setPreLoading(false);
        setSummary(null);
        setIsBtn(true);

        console.error("Error fetching messages:", error);
      }
    }
  };

  const createSummary = async () => {
    try {
      setLoading(true);
      const activeConversationID = activeConversation?.conversationId;

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/summary`,
        { conversationId: activeConversationID }
      );
      setSummary(response?.data?.summary);
      setLoading(false);
      setIsBtn(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="_user_profile">
      <span
        onClick={() => setIsProfile(false)}
        style={{
          color: "gray",
          position: "absolute",
          top: "0",
          right: "0",
          margin: "5px 7px",
          cursor: "pointer",
          fontSize: "20px",
        }}
      >
        <RxCross2 />
      </span>
      <div style={styles.left}>
        <img
          src={activeConversation?.members[0]?.user?.profilePicture}
          alt="Avatar"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginRight: "10px",
          }}
        />
        <div style={{ flexGrow: 1 }}>
          <h6 style={{ margin: "0" }}>
            {activeConversation?.members[0]?.user?.name}
          </h6>
          <small style={{ color: "#888" }}>
            {dateFormat(activeConversation?.createdAt)}
          </small>
        </div>
      </div>
      <div className="tabs">
        <button
          className={`tab ${activeTab === "details" ? "active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`tab ${activeTab === "summary" ? "active" : ""}`}
          onClick={() => {
            fetchConversationMessages();
            setActiveTab("summary");
          }}
        >
          Summary
        </button>
      </div>
      <div className="tab-content">
        {activeTab === "details" && (
          <>
            <p>
              <strong>Name: </strong>
              {activeConversation?.members[0]?.user?.name}
            </p>
            <p>
              <strong>Email: </strong>
              {activeConversation?.members[0]?.user?.email}
            </p>
            <p>
              <strong>Role: </strong>{" "}
              {activeConversation?.members[0]?.user?.role}
            </p>
          </>
        )}
        {activeTab === "summary" && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>Summary</h3>
              {isBtn && (
                <span className="tab-summary-btn" onClick={createSummary}>
                  {summary ? "Regenerate" : "Generate"}
                  <SiGooglegemini
                    style={{ marginLeft: "3px", fontSize: "20px" }}
                    className={loading ? "spin" : ""}
                  />
                </span>
              )}
            </div>

            {!loading && !preLoading ? (
              <p style={{ marginTop: "10px" }}>
                {summary ? summary : "Summary is not generated yet"}
              </p>
            ) : (
              <>
                <p className="text-center" style={{ marginTop: "10px" }}>
                  {preLoading ? "Loading summary..." : "Generating summary..."}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
