import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import swal from "sweetalert";
import {
  useGetLoansQuery,
  useUpdateLoanMutation,
} from "../../../../features/hrm/loan/loanApiSlice";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { CSVLink } from "react-csv";

const LoanList = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const { data: loans, refetch } = useGetLoansQuery();
  const [putLoan, { isLoading }] = useUpdateLoanMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const recordsPage = 13;

  const filterLoans = () => {
    if (!search) return loans?.loans || [];

    return loans?.loans?.filter((loan) => {
      const loanAmount = loan?.loanAmount?.toLowerCase();
      const loanType = loan?.loanType?.toLowerCase();
      const status = loan?.status?.toLowerCase();
      const date = new Date(loan.loanTakenDate).toLocaleString().toLowerCase();

      return (
        loanAmount.includes(search.toLowerCase()) ||
        loanType.includes(search.toLowerCase()) ||
        status.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase())
      );
    });
  };

  const filteredLoans = filterLoans();
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = filteredLoans?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredLoans?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleView = (loan) => {
    navigate("/" + user?.role?.toLowerCase() + "/loans/view/" + loan?.loanId);
  };

  const handleEdit = (loan) => {
    navigate("/" + user?.role?.toLowerCase() + "/loans/edit/" + loan?.loanId);
  };

  const handleSelect = (loan, value) => {
    const updatedLoan = { status: value };

    putLoan({ loanId: loan?.loanId, updatedLoan }).then(() => {
      refetch();
    });
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  }
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Employee Name", key: "employeeName" },
    { label: "Loan Amount", key: "loanAmount" },
    { label: "Loan Type", key: "loanType" },
    { label: "Recovery Method", key: "recoveryMethod" },
    { label: "Status", key: "status" },
    { label: "Loan Taken Date", key: "loanTakenDate" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredLoans.map((loan, index) => ({
      index: index + 1,
      employeeName: `${loan?.user?.firstName} ${loan?.user?.lastName}`,
      loanAmount: loan?.loanAmount,
      loanType: loan?.loanType,
      recoveryMethod: loan?.loanRecoveryMethod,
      status: loan?.status,
      loanTakenDate: new Date(loan?.loanTakenDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    })),
    filename: "Loans.csv",
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects task-table">
                <div className="tbl-caption d-flex justify-content-between align-items-center">
                  <h4 className="heading mb-0">Loans Request</h4>

                  <div className="d-flex align-items-center">
                    {filteredLoans?.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2 "
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.700rem 1.50rem",
                        }}
                      >
                        <i
                          className="fa-solid fa-file-excel"
                          style={{ alignItems: "center", marginTop: "7px" }}
                        />{" "}
                        Export Report
                      </CSVLink>
                    )}
                    <input
                      type="text"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search"
                      className="form-control me-2"
                    />
                    {user?.Role?.permissions?.includes("loans/create") && (
                      <Link
                        to={"create"}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.700rem 1.50rem",
                        }}
                      >
                        <i className="fa-solid fa-plus me-2"></i>Add Loan
                      </Link>
                    )}
                  </div>
                </div>
                <div
                  id="task-tbl_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="empoloyeestbl2"
                    className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Employee Name</th>
                        <th>Loan Amount</th>
                        <th>Loan Type</th>
                        <th>Recovery Method</th>
                        <th>Status</th>
                        <th>Loan Taken Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records?.map((loan, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{`${loan?.user?.firstName} ${loan?.user?.lastName}`}</td>
                          <td>{loan?.loanAmount}</td>
                          <td>{loan?.loanType}</td>
                          <td>{loan?.loanRecoveryMethod}</td>
                          {/* <td>{loan?.status}</td> */}

                          <td className="">
                            <Dropdown className="task-dropdown-2">
                              <Dropdown.Toggle
                                as="div"
                                className={loan?.status}
                              >
                                {loan?.status}
                              </Dropdown.Toggle>
                              {loan?.status == "Pending" &&
                                user?.Role?.permissions?.includes(
                                  "loans/edit/:loansId"
                                ) && (
                                  <Dropdown.Menu className="task-drop-menu">
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(loan, "Approved")
                                      }
                                    >
                                      Approved
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(loan, "Rejected")
                                      }
                                    >
                                      Rejected
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item
                                onClick={() => handleSelect(loan, "Pending")}
                                >
                                  Pending
                                </Dropdown.Item> */}
                                  </Dropdown.Menu>
                                )}
                            </Dropdown>
                          </td>
                          <td>
                            {loan?.loanTakenDate
                              ? new Date(
                                  loan?.loanTakenDate
                                ).toLocaleDateString()
                              : null}
                          </td>
                          <td>
                            {user?.Role?.permissions?.includes(
                              "loans/view/:loansId"
                            ) && (
                              <span
                                onClick={() => handleView(loan)}
                                style={{
                                  marginRight: "8px",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-eye" title="View"></i>
                              </span>
                            )}

                            {loan?.status != "Approved" &&
                              user?.Role?.permissions?.includes(
                                "loans/edit/:loansId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(loan)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {lastIndex - recordsPage + 1} to{" "}
                      {filteredLoans?.length < lastIndex
                        ? filteredLoans?.length
                        : lastIndex}{" "}
                      of {filteredLoans?.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers justify-content-center"
                      id="example2_paginate"
                    >
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            } `}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanList;
