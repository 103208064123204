import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useUpdateAccountMutation } from "../../../../../features/financialMangement/account/accountApiSlice";
import {
  useGetJournalEntryQuery,
  usePutJournalEntryMutation,
} from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";

const Journal = () => {
  const [updateJournalEntry, { isLoading, isError, error }] =
    usePutJournalEntryMutation();
  const { data: journalEntries = [], refetch } = useGetJournalEntryQuery();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  useEffect(() => {
    refetch();
  }, [refetch, journalEntries]);

  const filterJournalEntries = () => {
    if (!search) return journalEntries;

    return journalEntries.filter((entry) => {
      const journalNo = entry?.journal_no?.toString().toLowerCase();
      const date = entry?.date?.toString().toLowerCase();
      const description = entry?.description?.toString().toLowerCase();

      return (
        journalNo.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase()) ||
        description.includes(search.toLowerCase())
      );
    });
  };

  const filteredJournalEntries = filterJournalEntries();
  const records = filteredJournalEntries.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredJournalEntries?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleDelete = (entry) => {
    let updatedJournalEntry = { ...entry };
    updatedJournalEntry.isDeleted = true;

    updateJournalEntry({
      id: updatedJournalEntry?.journalEntryId,
      updatedJournalEntry,
    })
      .unwrap()
      .then((response) => {
        notifySuccess("Journal Entry deleted successfully");
        refetch();
      })
      .catch((error) => {
        console.error("Error updating Journal Entry", error);
      });
  };

  const handleEdit = (entry) => {
    navigate("journal-entries/edit/" + entry?.journalEntryId);
  };

  const handleView = (entry) => {
    navigate(
      "/" +
        user?.role?.toLowerCase() +
        "/journal-entries/view/" +
        entry?.journalEntryId
    );
  };

  const SweetAlert = (entry) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(entry);
      } else {
        swal("Your data is safe!");
      }
    });

  function isoToCustomDate(isoTimestamp) {
    const date = new Date(isoTimestamp);
    const day = date.getUTCDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  }
  const headersTitle = [
    { label: "Journal No", key: "journal_no" },
    { label: "Date", key: "date" },
    { label: "Total Credits", key: "totalCredits" },
    { label: "Total Debits", key: "totalDebits" },
    { label: "Description", key: "description" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredJournalEntries.map((entry) => ({
      journal_no: entry?.journal_no,
      date: new Date(entry?.date).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      totalCredits: entry?.totalCredits,
      totalDebits: entry?.totalDebits,
      description: entry?.description,
    })),
    filename: "JournalEntries.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Journal Entries</h4>

                    <div className="d-flex align-items-center">
                      {filteredJournalEntries?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2 "
                          style={{
                            marginLeft: "",
                            height: "38px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{ alignItems: "center", marginTop: "7px" }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />
                      {user?.Role?.permissions?.includes(
                        "journal-entries/create"
                      ) && (
                        <Link
                          to={"create"}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Entry
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>Journal No:</th>
                          <th>Date</th>
                          <th>Total Credits</th>
                          <th>Total Debits</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((entry, index) => (
                          <tr key={index}>
                            <td>{entry?.journal_no}</td>
                            <td>
                              {new Date(entry?.date).toLocaleDateString(
                                "en-GB"
                              )}
                            </td>
                            <td>{entry?.totalCredits}</td>
                            <td>{entry?.totalDebits}</td>
                            <td>{entry?.description}</td>
                            <td>
                              {user?.Role?.permissions?.includes(
                                "journal-entries/view/:journal_entriesId"
                              ) && (
                                <span
                                  onClick={() => handleView(entry)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}
                              {/* <span
                                onClick={() => handleEdit(entry)}
                                style={{
                                  marginRight: "8px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-edit" title="Edit"></i>
                              </span> */}
                              {/* <span
                                onClick={() => SweetAlert(entry)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredJournalEntries.length < lastIndex
                          ? filteredJournalEntries.length
                          : lastIndex}{" "}
                        of {filteredJournalEntries?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Journal;
