import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Stepper, Step } from "react-form-stepper";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import {
  useGetSupervisersQuery,
  useGetEmployeeBySuperviserIdQuery,
} from "../../../../features/superviser/superviserApiSlice";
import { usePostLeadMutation } from "../../../../features/lead/leadApiSlice";
import { useGetCustomerQuery } from "../../../../features/customer/customerApiSlice";
import Select from "react-select";
import { useGetSourceQuery } from "../../../../features/master/source/sourceApiSlice";
import { useGetPropertyTypesQuery } from "../../../../features/master/propertytype/propertyTypeApiSlice";
import { useGetAmenitiesQuery } from "../../../../features/master/amenities/amenitieApiSlice";
import { GiCancel } from "react-icons/gi";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

import CustomClearIndicator from "../../Comman/MultiSelect";
const Create = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const user = useSelector(selectCurrentUser);
  const [options, setOptions] = useState([]);
  const [customErrors, setCustomErrors] = useState([]);
  const [image, setImage] = useState();
  const hashValue = window.location.hash.substr(1);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const { data: customersList } = useGetCustomerQuery();
  const { data: AmenitiesData } = useGetAmenitiesQuery();
  const { data: PropertyData } = useGetPropertyTypesQuery();
  const handleAMCChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].isAMC = e.target.checked;
    setServicesList(updatedServices);
  };
  useEffect(() => {
    if (customersList?.customers) {
      const customerData = customersList?.customers?.map((customer) => ({
        value: customer.customerId,
        label: `${customer.firstname} ${customer.lastname} - Phone: ${customer.phone} - Address: ${customer.mailingAddress}`,
      }));

      setOptions(customerData);
    }
  }, [hashValue && customersList?.customers]);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [exist, setExist] = useState(false);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    const containsEdit = currentPath.includes("/edit/");
    const containExisting = currentPath.includes("existingCustomer");

    if (containsView) {
      setIsView(true);
    } else if (containsEdit) {
      setIsEdit(true);
    } else if (containExisting) {
      setExist(true);
    }
  }, []);
  const [servicesList, setServicesList] = useState([
    {
      serviceId: [],

      supervisorId: [],
      employeeData: [],
      userId: [],
      employeeId: [],
      isAMC: false,
      frequency: "",
      numberOfService: 0,
      callOutService: 0,
      startDate: "",
      endDate: "",
    },
  ]);
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);
  const { data: SourcesData } = useGetSourceQuery();

  const navigate = useNavigate();
  const [goSteps, setGoSteps] = useState(0);
  const [postLead, { isLoading }] = usePostLeadMutation();
  const { data: services = [] } = useGetServicesQuery({
    fetchPolicy: "network-only",
  });

  const { data: supervisers = [] } = useGetSupervisersQuery({
    fetchPolicy: "network-only",
  });

  const { data: employee = [] } = useGetEmployeeBySuperviserIdQuery({
    fetchPolicy: "network-only",
  });
  const handleserviceChange = (value, index) => {
    setServicesList((prevServicesList) => {
      const updatedServicesList = [...prevServicesList];
      updatedServicesList[index].serviceId = value;
      validateService(updatedServicesList[index], index);
      return updatedServicesList;
    });
  };
  const handleEmployeeChange = (value, index) => {
    setServicesList((prevServicesList) => {
      const updatedServicesList = [...prevServicesList];
      updatedServicesList[index].employeeId = value;

      validateService(updatedServicesList[index], index);
      return updatedServicesList;
    });
  };

  const handleFrequencyChange = (selectedOption, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].frequency = selectedOption;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const handleTimesPerDayChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].numberOfService = e.target.value;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const handleCallOutChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].callOutService = e.target.value;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const handleStartDateChange = (e, index) => {
    const nextYearDate = new Date(e.target?.value);
    nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

    const nextDateString = nextYearDate.toISOString().split("T")[0];

    const updatedServices = [...servicesList];
    updatedServices[index].startDate = e.target.value;
    updatedServices[index].endDate = nextDateString;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const handleEndDateChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].endDate = e.target.value;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };
  const handleSupervisorUserIdChange = async (selectedOption, index) => {
    setServicesList((prevServicesList) => {
      const updatedServicesList = [...prevServicesList];
      updatedServicesList[index].employeeData = employee?.users?.filter(
        (user) => {
          return user.supervisorId === selectedOption?.value?.userId;
        }
      );
      updatedServicesList[index].userId = {
        value: selectedOption?.value?.userId,
        email: selectedOption?.value?.email,
        label: selectedOption?.label,
      };

      updatedServicesList[index].employeeId = [];
      validateService(updatedServicesList[index], index);
      return updatedServicesList;
    });
  };
  const validateService = (service, index) => {
    const newErrors = [...customErrors];
    const currentErrors = {};

    if (!service.serviceId.length === 0) {
      currentErrors.serviceId = "Service is required";
    }

    if (service.userId.length === 0) {
      currentErrors.userId = "Supervisor is required";
    }
    if (service.employeeId.length === 0) {
      currentErrors.employeeId = "Employee is required";
    }
    if (service.isAMC) {
      if (!service.startDate) {
        currentErrors.startDate = "Start date is required";
      }
      if (!service.endDate) {
        currentErrors.endDate = "End date is required";
      }
      if (!service.numberOfService) {
        currentErrors.numberOfService = "Number Of Service is required";
      }
      if (!service.callOutService) {
        currentErrors.callOutService = "Free Call Out Service is required";
      }
      if (service.callOutService && service.callOutService < 0) {
        currentErrors.callOutService = "can be positive value";
      }
    }

    newErrors[index] = currentErrors;
    setCustomErrors(newErrors);
  };

  const [values, setInitialData] = useState({
    firstname: "",
    prefixName: "",
    prefix: "Mr.",
    lastname: "",
    organization: "IT",
    title: "",
    leadsStatus: "",
    leadSource: "",
    leadamenities: "",
    leadpropertytype: "",
    email: "",
    phone: "",
    webSite: "",
    tags: [],
    description: "",
    city: "",
    stateOrProvince: "",
    area: "",
    mailingAddress: "",
    country: "UAE",
    permitDetails: "",
    permitRequired: "",
    permitImg: "",
    TRN: "",
  });

  const handlePostLead = async (values) => {
    try {
      values.servicesList = JSON.stringify(values.servicesList);

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (Array.isArray(values[key])) {
          values[key].forEach((item, index) => {
            formData.append(`${key}[${index}]`, item);
          });
        } else {
          formData.append(key, values[key]);
        }
      });

      const result = await postLead(formData);

      notifySuccess(result?.data?.message);
      navigate(`/${user?.role?.toLowerCase()}/lead`);
    } catch (error) {
      notifyError(error.message);
    }
  };

  useEffect(() => {
    if (selectedCustomer) {
      setInitialData({
        firstname: selectedCustomer?.firstname || "",
        prefixName: selectedCustomer?.prefixName || "",
        prefix: "Mr.",
        lastname: selectedCustomer?.lastname || "",
        organization: selectedCustomer?.organization || "IT",
        title: selectedCustomer?.title || "",
        email: selectedCustomer?.email || "",
        phone: selectedCustomer?.phone || "",
        webSite: selectedCustomer?.webSite || "",
        tags: selectedCustomer?.tags || [],
        description: selectedCustomer?.description || "",
        city: selectedCustomer?.city || "",
        stateOrProvince: selectedCustomer?.stateOrProvince || "",
        area: selectedCustomer?.area || "",
        mailingAddress: selectedCustomer?.mailingAddress || "",
        country: selectedCustomer?.country || "",
        permitDetails: selectedCustomer?.permitDetails || "",
        permitRequired: selectedCustomer?.permitRequired || "",
        permitImg: selectedCustomer?.permitImg || "",
        TRN: selectedCustomer?.TRN || "",
      });
    }
  }, [selectedCustomer]);
  const validateForm = () => {
    const formErrors = [];
    servicesList.forEach((service, index) => {
      const currentErrors = {};
      if (!service.serviceId.length === 0) {
        currentErrors.serviceId = "Service is required";
      }
      if (service.userId.length === 0) {
        currentErrors.userId = "Supervisor is required";
      }
      if (service.employeeId.length === 0) {
        currentErrors.employeeId = "Employee is required";
      }
      if (service.isAMC) {
        if (!service.startDate) {
          currentErrors.startDate = "Start date is required";
        }
        if (!service.endDate) {
          currentErrors.endDate = "End date is required";
        }
        if (!service.numberOfService) {
          currentErrors.numberOfService = "Number Of Service is required";
        }
        if (!service.callOutService) {
          currentErrors.callOutService = "Free Call Out Service is required";
        }
        // if (service.startDate && service.endDate) {
        //   const startDate = new Date(service.startDate);
        //   const endDate = new Date(service.endDate);
        //   if (startDate >= endDate) {
        //     currentErrors.startDate = "Start date must be before end date";
        //     currentErrors.endDate = "End date must be after start date";
        //   }
        // }
      }

      formErrors[index] = currentErrors;
    });

    setCustomErrors(formErrors);
    return formErrors.every((error) => Object.keys(error).length === 0);
  };
  const ValidationSchema = Yup.object().shape({
    prefix: Yup.string(),
    firstname: Yup.string().optional("First Name is required"),
    lastname: Yup.string().optional("Last Name is required"),
    organization: Yup.string().optional("organizationis required"),
    title: Yup.string().optional("Company Name is required"),
    leadsStatus: Yup.string().optional("Lead Status is required"),
    leadSource: Yup.string().optional("Lead Source is required"),
    leadamenities: Yup.string().optional("Lead Amenity is required"),
    leadpropertytype: Yup.string().optional("Lead property type is required"),

    email: Yup.string().email("Invalid email").optional("Email is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^\+?\d{10,15}$/, "Invalid phone number"),
    webSite: Yup.string(),
    description: Yup.string(),
    mailingAddress: Yup.string()
      .required("address is required")
      .max(240, "Must be less than 240 letters"),
    city: Yup.string().optional("city is required"),
    stateOrProvince: Yup.string().optional("stateOrProvince is required"),
    postalCode: Yup.string().max(50, "Must be less than 50 letters"),
    country: Yup.string().optional("country is required"),
    customerTRN: Yup.string().optional(),

    area: Yup.string().optional("area is required"),
    // callOutService: Yup.optional().number().positive("should be positive"),
  });

  const handleSelectChange = (option) => {
    setSelectedOption(option);

    const selectedCustomerData = customersList?.customers.find(
      (customer) => customer.customerId === option.value
    );
    setSelectedCustomer(selectedCustomerData);
  };
  const emirates = [
    { label: "Abu Dhabi", value: "Abu Dhabi" },
    { label: "Dubai", value: "Dubai" },
    { label: "Sharjah", value: "Sharjah" },
    { label: "Umm Al Qaiwain", value: "Umm Al Qaiwain" },
    { label: "Fujairah", value: "Fujairah" },
    { label: "Ajman", value: "Ajman" },
    { label: "Ras Al Khaimah", value: "Ras Al Khaimah" },
  ];

  const serviceLabels2 = [
    { value: "Abu Hail", label: "Abu Hail" },
    { value: "Al Aweer", label: "Al Aweer" },
    { value: "Al Baraha", label: "Al Baraha" },
    { value: "Al Barari", label: "Al Barari" },
    { value: "Al Barsha", label: "Al Barsha" },
    { value: "Al Barsha South", label: "Al Barsha South" },
    { value: "Al Furjan", label: "Al Furjan" },
    { value: "Al Garhoud", label: "Al Garhoud" },
    { value: "Al Hamriya", label: "Al Hamriya" },
    { value: "Al Jaddaf", label: "Al Jaddaf" },
    { value: "Al Jafiliya", label: "Al Jafiliya" },
    { value: "Al Karama", label: "Al Karama" },
    { value: "Al Khail Gate", label: "Al Khail Gate" },
    { value: "Al Khail Heights", label: "Al Khail Heights" },
    { value: "Al Khawaneej", label: "Al Khawaneej" },
    { value: "Al Mamzar", label: "Al Mamzar" },
    { value: "Al Manara", label: "Al Manara" },
    { value: "Al Mankhool", label: "Al Mankhool" },
    { value: "Al Muraqqabat", label: "Al Muraqqabat" },
    { value: "Al Muteena", label: "Al Muteena" },
    { value: "Al Nahda", label: "Al Nahda" },
    { value: "Al Qudra", label: "Al Qudra" },
    { value: "Al Quoz", label: "Al Quoz" },
    { value: "Al Qusais", label: "Al Qusais" },
    { value: "Al Raffa", label: "Al Raffa" },
    { value: "Al Ras", label: "Al Ras" },
    { value: "Al Rashidiya", label: "Al Rashidiya" },
    { value: "Al Rigga", label: "Al Rigga" },
    { value: "Al Safa", label: "Al Safa" },
    { value: "Al Satwa", label: "Al Satwa" },
    { value: "Al Thammam", label: "Al Thammam" },
    { value: "Al Thanyah", label: "Al Thanyah" },
    { value: "Al Twar", label: "Al Twar" },
    { value: "Al Warqa", label: "Al Warqa" },
    { value: "Al Wasl", label: "Al Wasl" },
    { value: "Arabian Ranches - I", label: "Arabian Ranches - I" },
    { value: "Arabian Ranches - II", label: "Arabian Ranches - II" },
    { value: "Arabian Ranches - III", label: "Arabian Ranches - III" },
    { value: "Arjan", label: "Arjan" },
    { value: "Barsha Heights", label: "Barsha Heights" },
    { value: "Bur Dubai", label: "Bur Dubai" },
    { value: "Business Bay", label: "Business Bay" },
    { value: "Business Village", label: "Business Village" },
    { value: "City of Arabia", label: "City of Arabia" },
    { value: "City Walk", label: "City Walk" },
    { value: "Coral Boutique Villas", label: "Coral Boutique Villas" },
    { value: "Deira", label: "Deira" },
    { value: "Discovery Gardens", label: "Discovery Gardens" },
    { value: "Downtown", label: "Downtown" },

    {
      value: "Dubai Autodrome Business Park",
      label: "Dubai Autodrome Business Park",
    },
    { value: "Dubai Biotechnology Park", label: "Dubai Biotechnology Park" },
    { value: "Dubai Creek Harbour", label: "Dubai Creek Harbour" },
    { value: "Dubai Festival City (DFC)", label: "Dubai Festival City (DFC)" },
    {
      value: "Dubai Healthcare City (DHCC)",
      label: "Dubai Healthcare City (DHCC)",
    },
    { value: "Dubai Hills", label: "Dubai Hills" },

    { value: "Dubai Internet City", label: "Dubai Internet City" },
    {
      value: "Dubai Investment Park (DIP)",
      label: "Dubai Investment Park (DIP)",
    },

    { value: "Dubai Lifestyle City", label: "Dubai Lifestyle City" },
    { value: "Dubai Logistics City", label: "Dubai Logistics City" },
    { value: "Dubai Marina", label: "Dubai Marina" },
    { value: "Dubai Maritime City", label: "Dubai Maritime City" },
    { value: "Dubai Media City", label: "Dubai Media City" },
    { value: "Dubai Outsource Zone", label: "Dubai Outsource Zone" },
    { value: "Dubai Silicon Oasis (DSO)", label: "Dubai Silicon Oasis (DSO)" },
    { value: "Dubai Studio City", label: "Dubai Studio City" },
    { value: "Dubai Textile City", label: "Dubai Textile City" },
    { value: "Dubai Waterfront", label: "Dubai Waterfront" },
    { value: "Dubai World Central", label: "Dubai World Central" },
    { value: "Dubailand", label: "Dubailand" },
    { value: "Emaar South", label: "Emaar South" },
    { value: "Emirates Hills", label: "Emirates Hills" },
    { value: "Emirates Living", label: "Emirates Living" },
    { value: "External", label: "External" },
    { value: "Falcon City of Wonders", label: "Falcon City of Wonders" },
    { value: "Ghoroob", label: "Ghoroob" },
    { value: "Hor Al Anz", label: "Hor Al Anz" },
    { value: "International City", label: "International City" },
    {
      value: "International Humanitarian City",
      label: "International Humanitarian City",
    },

    { value: "Jebel Ali", label: "Jebel Ali" },
    { value: "Jebel Ali Village", label: "Jebel Ali Village" },
    { value: "Jumeirah", label: "Jumeirah" },
    { value: "Jumeirah Bay Island", label: "Jumeirah Bay Island" },
    {
      value: "Jumeirah Beach Residences (JBR)",
      label: "Jumeirah Beach Residences (JBR)",
    },
    {
      value: "Jumeirah Golf Estates (JGE)",
      label: "Jumeirah Golf Estates (JGE)",
    },
    { value: "Jumeirah Heights (JH)", label: "Jumeirah Heights (JH)" },
    { value: "Jumeirah Islands (JI)", label: "Jumeirah Islands (JI)" },
    {
      value: "Jumeirah Lakes Towers (JLT)",
      label: "Jumeirah Lakes Towers (JLT)",
    },
    { value: "Jumeirah Parks (JP)", label: "Jumeirah Parks (JP)" },
    {
      value: "Jumeirah Village Circle (JVC)",
      label: "Jumeirah Village Circle (JVC)",
    },
    {
      value: "Jumeirah Village Triangle (JVT)",
      label: "Jumeirah Village Triangle (JVT)",
    },
    { value: "Knowledge Village", label: "Knowledge Village" },
    { value: "La Mer", label: "La Mer" },
    { value: "Masakin", label: "Masakin" },
    { value: "Meydan", label: "Meydan" },
    { value: "Mira", label: "Mira" },
    { value: "Mirdiff", label: "Mirdiff" },
    { value: "Mudon", label: "Mudon" },
    { value: "Mushrif", label: "Mushrif" },
    { value: "Nad Al Sheba", label: "Nad Al Sheba" },
    { value: "Nadd Al Hamar", label: "Nadd Al Hamar" },
    { value: "Nshama", label: "Nshama" },
    { value: "Palm Jumeirah", label: "Palm Jumeirah" },
    { value: "Polo Residences", label: "Polo Residences" },
    { value: "Ras Al Khor", label: "Ras Al Khor" },
    { value: "Remraam", label: "Remraam" },
    { value: "Sheikh Zayed Road", label: "Sheikh Zayed Road" },
    { value: "The Gardens", label: "The Gardens" },
    { value: "The Greens & Views", label: "The Greens & Views" },
    { value: "The Villa", label: "The Villa" },
    { value: "Trade Centre", label: "Trade Centre" },
    { value: "Umm Ramool", label: "Umm Ramool" },
    { value: "Umm Suqeim", label: "Umm Suqeim" },
    { value: "Villanova", label: "Villanova" },
    { value: "Warsan", label: "Warsan" },
    { value: "Zaabeel", label: "Zaabeel" },
  ];
  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      validationSchema={ValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (validateForm()) {
          values.servicesList = servicesList.map((service) => ({
            employeeId:
              service.employeeId && service.employeeId.length > 0
                ? service.employeeId
                : null,
            serviceId: service?.serviceId,

            userId: service?.userId,
            isAMC: service?.isAMC,
            frequency: service?.frequency,
            numberOfService: service?.numberOfService,
            callOutService: service?.callOutService,
            startDate: service?.startDate,
            endDate: service?.endDate,
          }));

          handlePostLead(values);
          setSubmitting(false);
        } else {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue, // Added setFieldValue for updating form field values
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Fragment>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12 col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-1">
                          <h4 className="card-title">Add Lead</h4>
                        </div>
                        {hashValue && (
                          <Select
                            defaultValue={selectedOption}
                            onChange={handleSelectChange}
                            options={options}
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                lineHeight: "initial", // Use 'initial' to maintain the default height
                                paddingLeft: "0px",
                              }),
                              control: (provided) => ({
                                ...provided,
                                height: "40px", // Set the desired height for the control
                                color: "#7e7e7e",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 1000,
                                lineHeight: "initial", // Use 'initial' to maintain the default height
                              }),
                            }}
                            className="mb-2"
                          />
                        )}
                        <Alert variant="primary">
                          <strong>
                            {goSteps === 0
                              ? "Lead Information"
                              : goSteps === 1
                              ? "Additional Information"
                              : goSteps === 2
                              ? "Address Information"
                              : ""}
                          </strong>
                        </Alert>

                        <div className="form-wizard "></div>
                        <section>
                          {/* Name Input Fields */}
                          <div className="row">
                            {["prefix", "firstname", "lastname"].map(
                              (fieldName, index) => (
                                <div
                                  key={index}
                                  className={`form-group mb-3 col-xl-4 ${
                                    index !== 0 ? "" : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    {index === 0
                                      ? "Status"
                                      : index === 1
                                      ? "First Name"
                                      : "Last Name"}
                                  </label>
                                  {fieldName === "prefix" && (
                                    <Select
                                      id="val-prefix"
                                      name="prefix"
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "prefix",
                                          selectedOption.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={{
                                        value: values?.prefix,
                                        label: values?.prefix,
                                      }}
                                      options={[
                                        { value: "Mr.", label: "Mr." },
                                        { value: "Ms.", label: "Ms." },
                                        { value: "Mrs.", label: "Mrs." },
                                        { value: "Other", label: "Other" },
                                      ]}
                                      isDisabled={isView}
                                    />
                                  )}

                                  {(fieldName === "firstname" ||
                                    fieldName === "lastname") && (
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`val-${fieldName}`}
                                        placeholder={
                                          fieldName === "firstname"
                                            ? "First Name"
                                            : fieldName === "lastname"
                                            ? "Last Name"
                                            : ""
                                        }
                                        name={fieldName}
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values[fieldName]}
                                        disabled={isView}
                                      />
                                      <div
                                        id={`val-${fieldName}-error`}
                                        className="invalid-feedback animated fadeInUp"
                                        style={{
                                          display:
                                            touched[fieldName] &&
                                            errors[fieldName]
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        <ErrorMessage name={fieldName} />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>

                          <div className="row">
                            {["organization", "title"].map(
                              (fieldName, index) => (
                                <div
                                  key={index}
                                  className="form-group mb-3 col-xl-4"
                                >
                                  <label className="text-label">
                                    {fieldName === "title"
                                      ? "Company Name"
                                      : capitalizeFirstLetter(fieldName)}
                                  </label>
                                  {fieldName === "organization" ? (
                                    <Select
                                      // className="form-select form-control"
                                      id={`val-${fieldName}`}
                                      name={fieldName}
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          fieldName,
                                          selectedOption.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={{
                                        value: values?.organization,
                                        label: values?.organization,
                                      }}
                                      options={[
                                        { value: "IT", label: "IT" },
                                        { value: "Finance", label: "Finance" },
                                        {
                                          value: "Healthcare",
                                          label: "Healthcare",
                                        },
                                        {
                                          value: "Education",
                                          label: "Education",
                                        },
                                        {
                                          value: "Manufacturing",
                                          label: "Manufacturing",
                                        },
                                        { value: "Retail", label: "Retail" },
                                        { value: "Energy", label: "Energy" },
                                        {
                                          value: "Transportation",
                                          label: "Transportation",
                                        },
                                        {
                                          value: "Real Estate",
                                          label: "Real Estate",
                                        },
                                        {
                                          value: "Hospitality",
                                          label: "Hospitality",
                                        },
                                        {
                                          value: "Agriculture",
                                          label: "Agriculture",
                                        },
                                        {
                                          value: "Entertainment",
                                          label: "Entertainment",
                                        },
                                        {
                                          value: "Telecommunications",
                                          label: "Telecommunications",
                                        },
                                        {
                                          value: "Pharmaceuticals",
                                          label: "Pharmaceuticals",
                                        },
                                        {
                                          value: "Automotive",
                                          label: "Automotive",
                                        },
                                        {
                                          value: "Aerospace and Defense",
                                          label: "Aerospace and Defense",
                                        },
                                        {
                                          value: "Construction",
                                          label: "Construction",
                                        },
                                        {
                                          value: "Consumer Goods",
                                          label: "Consumer Goods",
                                        },
                                        {
                                          value: "Media and Communications",
                                          label: "Media and Communications",
                                        },
                                        {
                                          value: "Insurance",
                                          label: "Insurance",
                                        },
                                        {
                                          value: "Nonprofit/NGO",
                                          label: "Nonprofit/NGO",
                                        },
                                        {
                                          value: "Government",
                                          label: "Government",
                                        },
                                        { value: "Legal", label: "Legal" },
                                        {
                                          value: "Consulting",
                                          label: "Consulting",
                                        },
                                        {
                                          value: "Research and Development",
                                          label: "Research and Development",
                                        },
                                        { value: "Others", label: "Others" },
                                      ]}
                                      isDisabled={isView}
                                    />
                                  ) : (
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`val-${fieldName}`}
                                        placeholder={"Company Name"}
                                        name={fieldName}
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values[fieldName]}
                                        disabled={isView}
                                      />
                                      <div
                                        id={`val-${fieldName}-error`}
                                        className="invalid-feedback animated fadeInUp"
                                        style={{
                                          display:
                                            touched[fieldName] &&
                                            errors[fieldName]
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        <ErrorMessage name={fieldName} />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                            <div className="form-group mb-3 col-xl-4">
                              <label className="text-label">Lead Status</label>

                              <Select
                                // className="form-select form-control"
                                id="val-lead-status"
                                name="leadsStatus"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "leadsStatus",
                                    selectedOption.value
                                  );
                                }}
                                onBlur={handleBlur}
                                value={{
                                  value: values?.leadsStatus,
                                  label: values?.leadsStatus,
                                }}
                                options={[
                                  {
                                    value: "Not Started",
                                    label: "Not Started",
                                  },
                                  {
                                    value: "In Progress",
                                    label: "In Progress",
                                  },
                                  { value: "Pending", label: "Pending" },
                                  { value: "Cancel", label: "Cancel" },
                                  { value: "Accepted", label: "Accepted" },
                                  // { value: "Completed", label: "Completed" },
                                ]}
                                isDisabled={isView}
                              />
                              {errors.leadsStatus && touched.leadsStatus && (
                                <div
                                  id="val-phone-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.leadsStatus}
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Lead Status, Services, Lead Source, Supervisor Responsible */}
                          <div className="row">
                            {values?.leadsStatus === "Pending" && (
                              <div className="form-group mb-3 col-xl-4">
                                <label className="text-label">
                                  Follow up date
                                </label>
                                <input
                                  type="date"
                                  id="backlogDate"
                                  name="backlogDate"
                                  value={
                                    values?.backlogDate
                                      ? new Date(values?.backlogDate)
                                          ?.toISOString()
                                          ?.slice(0, 10)
                                      : new Date(
                                          Date.now() + 7 * 24 * 60 * 60 * 1000
                                        )
                                          .toISOString()
                                          .slice(0, 10)
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "backlogDate",
                                      new Date(e.target.value)
                                    );
                                  }}
                                  className="form-control"
                                  style={{ marginLeft: "0px" }}
                                />
                                <div
                                  id="val-backlogDate-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.backlogDate && errors.backlogDate}
                                </div>
                              </div>
                            )}

                            <div className="form-group mb-3 col-xl-4">
                              <label className="text-label">Lead Source</label>
                              <Select
                                id="val-lead-propertytype"
                                name="leadSource"
                                onChange={(selectedOption) => {
                                  handleChange({
                                    target: {
                                      name: "leadSource",
                                      value: selectedOption.value,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                value={{
                                  value: values.leadSource,
                                  label: values.leadSource,
                                }}
                                options={(SourcesData?.sources || []).map(
                                  (data) => ({
                                    value: data.name || "",
                                    label: data.name || "",
                                  })
                                )}
                                isDisabled={isView}
                              />
                              {errors.leadSource && touched.leadSource && (
                                <div
                                  id="val-phone-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.leadSource}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-xl-4">
                              <label className="text-label">
                                Lead Amenities{" "}
                              </label>
                              <Select
                                // className="form-select"
                                id="val-lead-"
                                name="leadamenities"
                                onChange={(selectedOption) => {
                                  handleChange({
                                    target: {
                                      name: "leadamenities",
                                      value: selectedOption.value,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                value={{
                                  value: values.leadamenities || "",
                                  label: values.leadamenities || "",
                                }}
                                options={(AmenitiesData?.amenities || []).map(
                                  (data) => ({
                                    value: data.name,
                                    label: data.name,
                                  })
                                )}
                                isDisabled={isView}
                              />
                              {errors.leadamenities &&
                                touched.leadamenities && (
                                  <div
                                    id="val-phone-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.leadamenities}
                                  </div>
                                )}
                            </div>

                            <div className="form-group mb-3 col-xl-4">
                              <label className="text-label">
                                Lead PropertyType
                              </label>
                              <Select
                                // className="form-select"
                                id="val-lead-propertytype"
                                name="leadpropertytype"
                                onChange={(selectedOption) => {
                                  handleChange({
                                    target: {
                                      name: "leadpropertytype",
                                      value: selectedOption.value,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                value={{
                                  value: values.leadpropertytype,
                                  label: values.leadpropertytype,
                                }}
                                options={(PropertyData?.propertyType || []).map(
                                  (data) => ({
                                    value: data.name || "",
                                    label: data.name || "",
                                  })
                                )}
                                isDisabled={isView}
                              />
                              {errors.leadpropertytype &&
                                touched.leadpropertytype && (
                                  <div
                                    id="val-phone-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.leadpropertytype}
                                  </div>
                                )}
                            </div>

                            <div className="form-group col-xl-12">
                              {servicesList?.map((Service, index) => (
                                <div className=" mt-2" key={index}>
                                  <div className="row">
                                    <div className="form-group mb-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={Service?.isAMC || false}
                                        onChange={(e) => {
                                          handleAMCChange(e, index);
                                        }}
                                        disabled={isView}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="send-email"
                                      >
                                        Is AMC?
                                      </label>
                                    </div>

                                    <div className="form-group mb-3  col-xl-4">
                                      <label className="text-label">
                                        Services
                                      </label>
                                      <CustomClearIndicator
                                        data={services?.services || []}
                                        type={"services"}
                                        onChange={(value) => {
                                          handleserviceChange(value, index);
                                        }}
                                        value={Service?.serviceId}
                                        isView={isView}
                                      />

                                      {customErrors[index]?.serviceId && (
                                        <div
                                          id="val-phone-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {customErrors[index].serviceId}
                                        </div>
                                      )}
                                    </div>

                                    <div className="form-group mb-3 col-xl-4">
                                      <label className="text-label">
                                        Supervisor Responsible
                                      </label>
                                      <Select
                                        id="val-service"
                                        name="userId"
                                        onChange={(selectedOption) => {
                                          handleSupervisorUserIdChange(
                                            selectedOption,
                                            index
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={{
                                          value: Service?.userId?.value || "",
                                          label: Service?.userId?.label || "",
                                        }}
                                        options={supervisers?.users?.map(
                                          (supervisor) => ({
                                            value: supervisor,
                                            label: `${supervisor.firstName} ${supervisor.lastName}`,
                                          })
                                        )}
                                        isDisabled={isView}
                                      />
                                      {customErrors[index]?.userId && (
                                        <div
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {customErrors[index].userId}
                                        </div>
                                      )}
                                    </div>

                                    <div className="form-group mb-3 col-xl-4">
                                      <label className="text-label">
                                        Employee
                                      </label>
                                      <CustomClearIndicator
                                        data={Service?.employeeData || []}
                                        type={"employeeId"}
                                        onChange={(value) => {
                                          handleEmployeeChange(value, index);
                                        }}
                                        value={Service?.employeeId}
                                        isView={isView}
                                      />

                                      {customErrors[index]?.employeeId && (
                                        <div
                                          id="val-phone-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {customErrors[index].employeeId}
                                        </div>
                                      )}
                                    </div>

                                    {Service?.isAMC && (
                                      <>
                                        <div className="form-group mb-3 col-xl-2">
                                          <label className="text-label">
                                            Frequency
                                          </label>
                                          <Select
                                            id="val-frequency"
                                            name="frequency"
                                            onChange={(selectedOption) => {
                                              handleFrequencyChange(
                                                selectedOption,
                                                index
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            value={{
                                              value:
                                                Service?.frequency?.value || "",
                                              label:
                                                Service?.frequency?.label || "",
                                            }}
                                            options={[
                                              {
                                                value: "yearly",
                                                label: "Yearly",
                                              },
                                            ]}
                                            isDisabled={isView}
                                          />
                                          {customErrors[index]?.frequency && (
                                            <div
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {customErrors[index].frequency}
                                            </div>
                                          )}
                                        </div>

                                        <div className="form-group mb-3 col-xl-2">
                                          <label className="text-label">
                                            Number of Services
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              Service?.numberOfService || ""
                                            }
                                            onChange={(e) => {
                                              handleTimesPerDayChange(e, index);
                                            }}
                                            disabled={isView}
                                          />

                                          {customErrors[index]
                                            ?.numberOfService && (
                                            <div
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {
                                                customErrors[index]
                                                  .numberOfService
                                              }
                                            </div>
                                          )}
                                        </div>

                                        <div className="form-group mb-3 col-xl-2">
                                          <label className="text-label">
                                            Call Out Service
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              Service?.callOutService || ""
                                            }
                                            onChange={(e) => {
                                              handleCallOutChange(e, index);
                                            }}
                                            disabled={isView}
                                          />

                                          {customErrors[index]
                                            ?.callOutService && (
                                            <div
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {
                                                customErrors[index]
                                                  .callOutService
                                              }
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-md-3">
                                          <div className={`form-group mb-3`}>
                                            <label className="text-label">
                                              AMC Start Date
                                            </label>
                                            <input
                                              type="Date"
                                              className="form-control"
                                              id="val-username1"
                                              placeholder="Enter a Start Date"
                                              name="startDate"
                                              onChange={(event) => {
                                                handleStartDateChange(
                                                  event,
                                                  index
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              value={values.startDate}
                                              disabled={isView}
                                            />
                                            {customErrors[index]?.startDate && (
                                              <div
                                                className="invalid-feedback animated fadeInUp"
                                                style={{ display: "block" }}
                                              >
                                                {customErrors[index].startDate}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className={`form-group mb-3`}>
                                            <label className="text-label">
                                              AMC Expiry Date{" "}
                                            </label>
                                            <input
                                              type="Date"
                                              className="form-control"
                                              id="val-username1"
                                              placeholder="Customer Phone Name"
                                              name="endDate"
                                              onChange={(event) => {
                                                handleEndDateChange(
                                                  event,
                                                  index
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              value={servicesList[0].endDate}
                                              disabled={isView}
                                            />
                                            {customErrors[index]?.endDate && (
                                              <div
                                                className="invalid-feedback animated fadeInUp"
                                                style={{ display: "block" }}
                                              >
                                                {customErrors[index].endDate}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <Alert variant="primary">
                            <strong>{"Additional Information"}</strong>
                          </Alert>
                          <section>
                            {/* Your form fields here */}
                            <div className="row">
                              <div className="form-group mb-3 col-xl-6">
                                <label className="text-label">Email</label>
                                <div className="input-group">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="val-email"
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    disabled={isView}
                                  />
                                  <div
                                    id="val-email-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{
                                      display:
                                        touched.email && errors.email
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {errors.email}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-3 col-xl-6">
                                <label className="text-label">Phone</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-phone"
                                    placeholder="Phone"
                                    name="phone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    disabled={isView || selectedOption}
                                  />
                                  <div
                                    id="val-phone-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.phone && errors.phone}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-3 col-xl-6">
                                <label className="text-label">Website</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-email"
                                    placeholder="website"
                                    name="webSite"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.webSite}
                                    disabled={isView}
                                  />
                                  <div
                                    id="val-email-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.webSite && errors.webSite}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-3 col-xl-6">
                                <label className="text-label">Lead Notes</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-prefix"
                                    placeholder="Lead Notes"
                                    name="description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    disabled={isView}
                                  />
                                  <div
                                    id="val-prefix-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.description && errors.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </section>

                        <Alert variant="primary">
                          <strong>{"Address Information"}</strong>
                        </Alert>

                        <section>
                          <div className="row">
                            <div className="form-group mb-3 col-xl-12">
                              <label className="text-label">Address</label>
                              <div className="input-group">
                                <textarea
                                  as="textarea"
                                  className={`form-control`}
                                  placeholder="Address"
                                  name="mailingAddress"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.mailingAddress}
                                  disabled={isView || selectedOption}
                                />
                                <div
                                  id="val-email-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{
                                    display:
                                      touched.mailingAddress &&
                                      errors.mailingAddress
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  {errors.mailingAddress}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group mb-3  col-xl-6">
                                <label className="text-label">City</label>
                                <div className="input-group">
                                  <Select
                                    maxMenuHeight={240}
                                    classNamePrefix="form-control"
                                    name="city"
                                    onChange={(selectedOption) =>
                                      handleChange({
                                        target: {
                                          name: "city",
                                          value: selectedOption.value,
                                        },
                                      })
                                    }
                                    onBlur={handleBlur}
                                    value={emirates.find(
                                      (option) => option.value === values.city
                                    )}
                                    isDisabled={isView}
                                    options={emirates}
                                    menuPlacement="bottom"
                                    placeholder="Select City"
                                    styles={{
                                      container: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                      }),
                                      control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                      }),
                                    }}
                                  />
                                  <div
                                    id="val-email-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{
                                      display:
                                        touched.city && errors.city
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {errors.city}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-3  col-xl-6">
                                <label className="text-label">
                                  State/Province
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className={`form-control`}
                                    placeholder="State/Province"
                                    name="stateOrProvince"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.stateOrProvince}
                                    disabled={isView}
                                  />
                                  <div
                                    id="val-email-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{
                                      display:
                                        touched.stateOrProvince &&
                                        errors.stateOrProvince
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {errors.stateOrProvince}
                                  </div>
                                </div>
                              </div>
                              <div className="form-group mb-3 col-xl-6">
                                <label className="text-label">Area</label>
                                <div className="input-group">
                                  <Select
                                    maxMenuHeight={240}
                                    classNamePrefix="form-control"
                                    name="area"
                                    onChange={(selectedOption) =>
                                      handleChange({
                                        target: {
                                          name: "area",
                                          value: selectedOption.value,
                                        },
                                      })
                                    }
                                    onBlur={handleBlur}
                                    value={serviceLabels2.find(
                                      (option) => option.value === values.area
                                    )}
                                    isDisabled={isView}
                                    options={serviceLabels2}
                                    menuPlacement="bottom"
                                    placeholder="Select Area"
                                    styles={{
                                      container: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                      }),
                                      control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                      }),
                                    }}
                                  />
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.area}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-3  col-xl-6">
                                <label className="text-label">Country</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className={`form-control`}
                                    placeholder="Country"
                                    name="country"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.country}
                                    disabled={isView}
                                  />
                                  <div
                                    id="val-email-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{
                                      display:
                                        touched.country && errors.country
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {errors.country}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-3">
                                <div className="row">
                                  <div className="col-xl-6">
                                    <label className="text-label">
                                      Customer TRN
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className={`form-control`}
                                        placeholder="Customer TRN"
                                        name="TRN"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.TRN}
                                        // disabled={isView || selectedOption}
                                      />
                                      <div
                                        id="val-email-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{
                                          display:
                                            touched.TRN && errors.TRN
                                              ? "block"
                                              : "none",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="col-xl-3">
                                    <label className="text-label">
                                      Appointment Date
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="date"
                                        className={`form-control`}
                                        placeholder="Appointment Date"
                                        name="appointmentDate"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.appointmentDate}
                                      />
                                      <div
                                        id="val-email-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{
                                          display:
                                            touched.appointmentDate &&
                                            errors.appointmentDate
                                              ? "block"
                                              : "none",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="col-xl-3">
                                    <label className="text-label">
                                      Appointment Time
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="time"
                                        className={`form-control`}
                                        placeholder="Appointment Time"
                                        name="appointmentTime"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.appointmentTime}
                                      />
                                      <div
                                        id="val-email-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{
                                          display:
                                            touched.appointmentTime &&
                                            errors.appointmentTime
                                              ? "block"
                                              : "none",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-3 mt-2 col-xl-12">
                                <div className="form-check">
                                  <Field
                                    type="checkbox"
                                    className="form-check-input"
                                    id="permitRequired"
                                    name="permitRequired"
                                    checked={values?.permitRequired} // Set the checked attribute based on the value of permitRequired field
                                    onChange={(e) => {
                                      handleChange(e); // Handle change event
                                    }}
                                  />

                                  <label
                                    className="form-check-label"
                                    htmlFor="permitRequired"
                                  >
                                    Permit Required
                                  </label>
                                </div>
                                {/* Conditionally render the textarea if permit is required */}
                                {values?.permitRequired && (
                                  <>
                                    <div className="mt-2">
                                      <label className="text-label">
                                        Permit Details
                                      </label>
                                      <textarea
                                        className={`form-control ${
                                          touched.permitDetails &&
                                          errors.permitDetails
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        rows="4"
                                        placeholder="Enter permit details"
                                        name="permitDetails"
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.permitDetails}
                                        disabled={isView}
                                      />
                                      <div className="invalid-feedback animated fadeInUp">
                                        <ErrorMessage name="permitDetails" />
                                      </div>
                                    </div>

                                    <div className="mt-2">
                                      {" "}
                                      <label className="text-label">
                                        Permit Image
                                      </label>{" "}
                                      <input
                                        type="file"
                                        className={`form-control ${
                                          touched.permitImg && errors.permitImg
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        name="permitImg"
                                        onChange={(e) => {
                                          const file = e.target.files[0];
                                          setFieldValue("permitImg", file);
                                          e.target.value = null;
                                        }}
                                        onBlur={handleBlur}
                                        disabled={isView}
                                      />{" "}
                                      {values?.permitImg && (
                                        <div
                                          className="mt-2"
                                          style={{
                                            maxWidth: "350px",
                                            marginBottom: "10px",
                                            position: "relative",
                                          }}
                                        >
                                          {" "}
                                          {values.permitImg.type.startsWith(
                                            "image/"
                                          ) ? (
                                            <img
                                              src={URL.createObjectURL(
                                                values?.permitImg
                                              )}
                                              alt="Permit Preview"
                                              className="mx-1 img-thumbnail"
                                            />
                                          ) : (
                                            <div className="file-name">
                                              {" "}
                                              <span>
                                                {values?.permitImg.name}
                                              </span>{" "}
                                            </div>
                                          )}{" "}
                                          <span
                                            onClick={() =>
                                              setFieldValue("permitImg", "")
                                            }
                                            style={{
                                              position: "absolute",
                                              right: "5px",
                                              top: "10px",
                                            }}
                                            className="btn btn-danger"
                                          >
                                            {" "}
                                            <i
                                              className="fas fa-trash"
                                              title="Delete"
                                            ></i>{" "}
                                          </span>{" "}
                                        </div>
                                      )}{" "}
                                      <div className="invalid-feedback animated fadeInUp">
                                        {" "}
                                        <ErrorMessage name="permitImg" />{" "}
                                      </div>{" "}
                                    </div>
                                    {/* <div className="mt-2">
                                      <label className="text-label">
                                        Permit Image
                                      </label>
                                      <input
                                        type="file"
                                        className={`form-control ${
                                          touched.permitImg && errors.permitImg
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        name="permitImg"
                                        onChange={(e) => {
                                          const file = e.target.files[0];
                                          setFieldValue("permitImg", file);
                                          e.target.value = null;
                                        }}
                                        onBlur={handleBlur}
                                        disabled={isView}
                                      />
                                      {values?.permitImg && (
                                        <div
                                          className="mt-2"
                                          style={{
                                            maxWidth: "350px",
                                            // height: "300px",
                                            marginBottom: "10px",
                                            position: "relative",
                                          }}
                                        >
                                          <img
                                            src={URL.createObjectURL(
                                              values?.permitImg
                                            )}
                                            alt="Permit Preview"
                                            className="mx-1 img-thumbnail"
                                          />
                                          <span
                                            onClick={() =>
                                              setFieldValue("permitImg", "")
                                            }
                                            style={{
                                              position: "absolute",
                                              right: "5px",
                                              top: "10px",
                                            }}
                                            className="btn btn-danger"
                                          >
                                            <i
                                              className="fas fa-trash"
                                              title="Delete"
                                            ></i>
                                          </span>
                                        </div>
                                      )}

                                      <div className="invalid-feedback animated fadeInUp">
                                        <ErrorMessage name="permitImg" />
                                      </div>
                                    </div> */}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </section>

                        {/* Navigation Buttons */}

                        {!isView && (
                          <div className="text-end toolbar toolbar-bottom p-2">
                            {/* <button
                              className="btn btn-primary sw-btn-next ms-1"
                              type="submit"
                              disabled={isSubmitting || isLoading}
                            >
                              Save
                            </button> */}
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          </Form>
        );
      }}
    </Formik>
  );
};
export default Create;
