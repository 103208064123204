import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import rainbow from "../../images/rainbow.png";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { logout, setCreadentials } from "../../features/auth/authSlice";
import { notifyError, notifySuccess } from "../../helpers/Notify";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const hostname = window.location.hostname;
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const loginTime = localStorage.getItem("loginTime");

  useEffect(() => {
    if (token && user && Object.entries(user)?.length > 0) {
      if (loginTime < new Date() === false) {
        dispatch(logout());
      }
      dispatch(setCreadentials({ user, token }));
      if (user?.role === "Admin") {
        navigate("/admin");
      } else if (user?.role === "Supervisor") {
        navigate("/supervisor");
      } else if (user?.role === "Finance") {
        navigate("/finance");
      } else if (user?.role === "Employee") {
        navigate("/employee");
      } else if (user?.role?.toLowerCase()) {
        navigate("/" + user?.role?.toLowerCase());
      } else {
        dispatch(logout());
      }
    }
  }, [dispatch, navigate]);

  const [email, setEmail] = useState(
    (hostname == "localhost" && "admin@beyondaio.com") || ""
  );
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState(
    (hostname == "localhost" && "admin@beyondaio") || ""
  );

  const [login, { isLoading, isError, isSuccess }] = useLoginMutation();

  async function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    try {
      const response = await login({ email, password }).unwrap();
      dispatch(setCreadentials({ ...response }));
      notifySuccess("Login Successful");
      navigate("/" + response?.user?.role?.toLowerCase());
      if (response?.user?.role === "Admin") {
        navigate("/admin");
      } else if (response?.user?.role === "Supervisor") {
        navigate("/supervisor");
      } else if (response?.user?.role === "Finance") {
        navigate("/finance");
      } else if (response?.user?.role === "Employee") {
        navigate("/employee");
      } else if (response?.user?.role?.toLowerCase()) {
        navigate("/" + response?.user?.role?.toLowerCase());
      } else {
        dispatch(logout());
      }
    } catch (error) {
      return notifyError(error?.data?.message || "An error occurred");
    }
  }

  return (
    <div className="page-wraper">
      <div className="login-account">
        <div className="row h-100">
          <div className="col-lg-6 align-self-start">
            <div
              className="account-info-area"
              style={{ backgroundImage: "url(" + rainbow + ")" }}
            >
              <div className="login-content">
                <p className="sub-title">
                  Log in to your ERP System with your credentials
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
            <div className="login-form">
              <div className="login-head">
                <h3 className="title">Welcome Back</h3>
                <p>
                  Login page allows users to enter login credentials for
                  authentication.
                </p>
              </div>
              <h6 className="login-title">
                <span>Login</span>
              </h6>
              <form onSubmit={onLogin}>
                <div className="mb-4">
                  <label className="mb-1 text-dark">Email</label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <div className="mb-4">
                  <label className="mb-1 text-dark">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                  <div className="mb-4">
                    <div className="form-check custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckBox1"
                        required=""
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckBox1"
                      >
                        Remember my preference
                      </label>
                    </div>
                  </div>
                </div> */}
                <div className="text-center mb-4">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    {isLoading ? "Loading..." : "Sign Me In"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
