// Create.jsx
import React, { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-tagsinput/react-tagsinput.css";
import Select from "react-select";
import {
  useGetHolidayByIdQuery,
  useUpdateHolidayMutation,
} from "../../../../features/hrm/holiday/holidayApiSlice";

const validationSchema = Yup.object().shape({
  holidaysNumber: Yup.string().required("Holiday Number is required"),
  holidaysName: Yup.string().required("Holiday Name is required"),
  holidaysDate: Yup.date().required("Holiday Date is required"),
  holidaysLocation: Yup.string().required("Holiday Location is required"),
  shift: Yup.object().required("Shift is required"),
  details: Yup.string(),
});

const EditHoliday = () => {
  const navigate = useNavigate();

  const { holidayIds } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  const { data: singleHoliday, refetch } = useGetHolidayByIdQuery(holidayIds);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [updateholiday, { isLoading }] = useUpdateHolidayMutation();

  const handleSubmitted = async (values) => {
    const updatedHoliday = { ...values };

    updatedHoliday.shift = updatedHoliday?.shift?.value;

    try {
      const result = await updateholiday({
        holidayId: holidayIds,
        updatedHoliday,
      });

      notifySuccess(result?.data?.message);
      // navigate("holidays");
      navigate("/" + user?.role?.toLowerCase() + "/holidays");
    } catch (error) {
      notifyError(error.message);
      // Handle error, access error.data
    }
  };

  function convertISOToSimpleDate(isoDate) {
    try {
      const date = new Date(isoDate);
      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date");
      }
      return date.toISOString().split("T")[0];
    } catch (error) {
      console.error("Error converting ISO date:", error);
      return null; // Or handle the error as needed
    }
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Edit Holiday</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      holidaysNumber: singleHoliday?.holidaysNumber || "",
                      holidaysName: singleHoliday?.holidaysName || "",
                      holidaysDate:
                        convertISOToSimpleDate(singleHoliday?.holidaysDate) ||
                        "",
                      holidaysLocation: singleHoliday?.holidaysLocation || "",
                      shift:
                        {
                          value: singleHoliday?.shift,
                          label: singleHoliday?.shift,
                        } || {},
                      details: singleHoliday?.details || "",
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.holidaysNumber
                                  ? errors.holidaysNumber
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Holiday Number
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter number"
                                name="holidaysNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.holidaysNumber}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.holidaysNumber && errors.holidaysNumber}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.holidaysName
                                  ? errors.holidaysName
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Holiday Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter holiday name"
                                name="holidaysName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.holidaysName}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.holidaysName && errors.holidaysName}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.holidaysDate
                                  ? errors.holidaysDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Holiday Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="val-username1"
                                // placeholder="Enter number"
                                name="holidaysDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.holidaysDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.holidaysDate && errors.holidaysDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.holidaysLocation
                                  ? errors.holidaysLocation
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Holiday Location
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter location"
                                name="holidaysLocation"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.holidaysLocation}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.holidaysLocation &&
                                  errors.holidaysLocation}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.shift
                                  ? errors.shift
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Shift</label>
                              <Select
                                options={[
                                  { value: "Morning", label: "Morning" },
                                  { value: "Afternoon", label: "Afternoon" },
                                  { value: "Evening", label: "Evening" },
                                ]}
                                name="status"
                                onChange={(selectedOption) =>
                                  setFieldValue("shift", selectedOption)
                                }
                                onBlur={handleBlur}
                                value={values?.shift}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.shift && errors.shift}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group mb-3 col-md-12">
                            <label className="text-label">Detail</label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="val-prefix"
                              placeholder="detail"
                              name="details"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.details}
                            />
                            <div
                              id="val-prefix-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.details && errors.details}
                            </div>
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditHoliday;
