import axios from "axios";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Modal = ({ show, date, closeModal }) => {
  const [existingTimeSlots, setExistingTimeSlots] = useState([]);
  const [newTimeSlots, setNewTimeSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (show) {
      fetchTimeSlots();
    }
  }, [show]);

  const fetchTimeSlots = async () => {
    try {
      setLoading(true);

      const formattedDate = date.toLocaleDateString("en-CA");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}chat-specific-availability`,
        {
          params: { date: formattedDate },
        }
      );

      const filteredSlots = response.data.availabilities;
      setExistingTimeSlots(
        filteredSlots.map((slot) => ({
          id: slot.availabilityId,
          start: slot.from,
          end: slot.to,
        }))
      );
    } catch (err) {
      console.error("Error fetching time slots:", err);
      setError("Failed to fetch time slots.");
    } finally {
      setLoading(false);
    }
  };

  const saveTimeSlots = async () => {
    try {
      setLoading(true);
      await Promise.all(
        newTimeSlots.map((slot) =>
          axios.post(
            `${process.env.REACT_APP_BASE_URL}chat-specific-availability`,
            {
              date: date.toLocaleDateString("en-CA"),
              from: slot.start,
              to: slot.end,
            }
          )
        )
      );
      resetNewSlots();
      closeModal();
    } catch (err) {
      console.error("Error saving time slots:", err);
      setError("Failed to save time slots.");
    } finally {
      setLoading(false);
    }
  };

  const addTimeSlot = () => {
    setNewTimeSlots([...newTimeSlots, { start: "00:00", end: "01:00" }]);
  };

  const updateTimeSlot = (index, field, value) => {
    const updatedSlots = [...newTimeSlots];
    updatedSlots[index][field] = value;
    setNewTimeSlots(updatedSlots);
  };

  const removeTimeSlot = async (index, isExisting = false) => {
    const slotToRemove = isExisting
      ? existingTimeSlots[index]
      : newTimeSlots[index];

    if (slotToRemove.id) {
      try {
        setLoading(true);
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}chat-specific-availability/delete/${slotToRemove.id}`
        );
      } catch (err) {
        setError("Failed to delete time slot.");
      } finally {
        setLoading(false);
      }
    }

    if (isExisting) {
      const updatedSlots = existingTimeSlots.filter((_, i) => i !== index);
      setExistingTimeSlots(updatedSlots);
    } else {
      const updatedSlots = newTimeSlots.filter((_, i) => i !== index);
      setNewTimeSlots(updatedSlots);
    }
  };

  const resetNewSlots = () => {
    setNewTimeSlots([]);
  };

  if (!show) return null;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="modal-overlay"
      onClick={closeModal}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>
          Date: <span style={{ color: "gray" }}>{date.toDateString()}</span>
        </h3>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {existingTimeSlots.length > 0 && (
              <div>
                <h5>Existing Time Slots:</h5>
                {existingTimeSlots.map((slot, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <input
                      type="time"
                      className="form-control form-control-sm me-2"
                      value={slot.start}
                      disabled
                    />
                    <span className="me-2">to</span>
                    <input
                      type="time"
                      className="form-control form-control-sm me-2"
                      value={slot.end}
                      disabled
                    />
                    <button
                      className="btn btn-sm btn-danger ms-2"
                      onClick={() => removeTimeSlot(index, true)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            )}

            {newTimeSlots.length === 0 && existingTimeSlots.length === 0 ? (
              <p>No time slots added for this date.</p>
            ) : (
              <>
                <h5>New Time Slots:</h5>
                {newTimeSlots.map((slot, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <input
                      type="time"
                      className="form-control form-control-sm me-2"
                      value={slot.start}
                      onChange={(e) =>
                        updateTimeSlot(index, "start", e.target.value)
                      }
                    />
                    <span className="me-2">to</span>
                    <input
                      type="time"
                      className="form-control form-control-sm me-2"
                      value={slot.end}
                      onChange={(e) =>
                        updateTimeSlot(index, "end", e.target.value)
                      }
                    />
                    <button
                      className="btn btn-sm btn-danger ms-2"
                      onClick={() => removeTimeSlot(index, false)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </>
            )}
          </>
        )}

        <button className="btn btn-sm btn-primary mt-2" onClick={addTimeSlot}>
          Add Time Slot
        </button>

        <div className="mt-3">
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button className="btn btn-success me-2" onClick={saveTimeSlots}>
            Save
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              resetNewSlots();
              closeModal();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

function CalendarComponent() {
  const [value, onChange] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <Calendar
        onChange={onChange}
        value={value}
        onClickDay={handleDateClick}
      />

      <Modal show={isModalOpen} date={selectedDate} closeModal={closeModal} />
    </div>
  );
}

export default CalendarComponent;
