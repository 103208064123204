import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  selectCurrentToken,
  selectCurrentUser,
  setCreadentials,
} from "./auth/authSlice";

const AuthRequired = ({ allowedRoles }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  const location = useLocation();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (!token && !user) {
      dispatch(setCreadentials({ user: storedUser, token: storedToken }));
    }
  }, []);

  // Check if token and user exist either in Redux state or localStorage
  const isTokenAvailable = token || localStorage.getItem("token");
  const isUserAvailable = user || JSON.parse(localStorage.getItem("user"));
  

  

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (!allowedRoles.includes(capitalizeFirstLetter(isUserAvailable?.role))) {
    return <Navigate to="/page-error-403" />;
  }

  return isTokenAvailable && isUserAvailable ? (
    // return token && user ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default AuthRequired;
