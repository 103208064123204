import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";
import { useGetExpenseByFromAndTwoQuery } from "../../../../../features/financialMangement/expense/expense/expenseApiSlice";
import { Formik } from "formik";

const ExpenseCategory = () => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [triggerFetch, setTriggerFetch] = useState(false);

  // Call the query with conditional fetching
  const {
    data: expenses,
    isLoading,
    isError,
  } = useGetExpenseByFromAndTwoQuery(
    { from, to },
    { skip: !from || !to } // Skip fetching unless triggerFetch is true
  );
  const categories = [
    {
      name: "General & Administrative",
      style: { color: "#009688" },
      items: [],
    },
    {
      name: "HR",
      style: { color: "#009688", fontWeight: "bold" },
      items: [
        {
          expense: "HR Expense",
          budget: "$500",
          startDate: "03/19/2021",
          endDate: "N/A",
          recurrence: "Monthly",
        },
      ],
    },
    { name: "Research & Development", style: { color: "#009688" }, items: [] },
    {
      name: "Quality Assurance",
      style: { color: "#009688", fontWeight: "bold" },
      items: [
        {
          expense: "Expense",
          budget: "$1,000",
          startDate: "06/02/2021",
          endDate: "N/A",
          recurrence: "One-Time",
        },
      ],
    },
    { name: "Sales & Marketing", style: { color: "#009688" }, items: [] },
    {
      name: "Marketing",
      style: { color: "#009688", fontWeight: "bold" },
      items: [
        {
          expense: "Facebook Ads",
          budget: "$1,000",
          startDate: "01/01/2021",
          endDate: "N/A",
          recurrence: "Monthly",
        },
        {
          expense: "Google Ads",
          budget: "$1,000",
          startDate: "06/01/2021",
          endDate: "N/A",
          recurrence: "Monthly",
        },
        {
          expense: "Outbound Emails",
          budget: "$1,000",
          startDate: "05/01/2021",
          endDate: "N/A",
          recurrence: "One-Time",
        },
        {
          expense: "Podcast Ad",
          budget: "$200",
          startDate: "04/08/2021",
          endDate: "N/A",
          recurrence: "Monthly",
        },
      ],
    },
  ];

  // Function to generate the summary report
  const generateExpenseSummary = (data) => {
    // Group by account name and calculate totals
    const summary = data?.reduce((acc, entry) => {
      const accountName = entry.Account.name;
      const debitAmount = parseFloat(entry.debits) || 0;

      if (!acc[accountName]) {
        acc[accountName] = { totalDebits: 0, transactionCount: 0 };
      }

      acc[accountName].totalDebits += debitAmount;
      acc[accountName].transactionCount += 1;

      return acc;
    }, {});

    // Convert to an array format for easier reporting
    const summaryArray = Object.entries(summary)?.map(
      ([accountName, { totalDebits, transactionCount }]) => ({
        accountName,
        totalDebits: totalDebits.toFixed(2),
        transactionCount,
      })
    );

    // Calculate overall total expenses
    const totalExpenses = summaryArray?.reduce(
      (sum, item) => sum + parseFloat(item.totalDebits),
      0
    );

    // Add percentage contribution to each account
    return summaryArray?.map((item) => ({
      ...item,
      percentage: (
        (parseFloat(item.totalDebits) / totalExpenses) *
        100
      ).toFixed(2),
    }));
  };

  //   useEffect
  const report = expenses && generateExpenseSummary(expenses);
  // Calculate total number of transactions and total amount for the footer
  const totalTransactions =
    expenses && report?.reduce((sum, item) => sum + item.transactionCount, 0);
  const totalAmount =
    expenses &&
    report
      ?.reduce((sum, item) => sum + parseFloat(item.totalDebits), 0)
      .toFixed(2);
  const totalPercentage = "100"; // The total percentage of total will always be 100%
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `ExpenseByCategory_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="text-end" style={{ marginBottom: "20px" }}>
                <button
                  onClick={downloadPDF}
                  style={{
                    background: "transparent",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <MdOutlineFileDownload
                    style={{
                      color: "inherit",
                      fontSize: "20px",
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                  />
                </button>
              </div>
              <div id="report-content">
                <div className="row align-items-center">
                  {" "}
                  {/* Added align-items-center for vertical alignment */}
                  <div
                    className="col-6 text-start"
                    style={{ marginTop: "10px" }}
                  >
                    {" "}
                    {/* Added marginTop for spacing */}
                    <h1 style={{ color: "#009688", marginLeft: "10px" }}>
                      Aifa Services
                    </h1>
                  </div>
                  <div className="col-6 text-end" style={{ marginTop: "10px" }}>
                    {" "}
                    {/* Added marginTop for spacing */}
                    <img
                      src={aifaLogo}
                      alt="logo"
                      style={{
                        width: "130px",
                      }}
                    />
                  </div>
                </div>

                <div className="card-header">
                  <div className="container">
                    <div className="card-body">
                      <div className="basic-form">
                        <Formik
                          initialValues={{
                            from: "",
                            to: "",
                          }}
                          enableReinitialize={true}
                          // validationSchema={loginSchema}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group mb-3">
                                    <label className="text-label">
                                      Date From
                                    </label>
                                    <input
                                      type="date"
                                      name="from"
                                      className="form-control"
                                      onChange={(e) => {
                                        setFieldValue("from", e.target.value);
                                        setFrom(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      max={new Date()
                                        ?.toISOString()
                                        ?.slice(0, 10)}
                                    />
                                    {errors.fromDate && touched.fromDate && (
                                      <div className="invalid-feedback animated fadeInUp">
                                        {errors.fromDate}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group mb-3">
                                    <label className="text-label">
                                      Date To
                                    </label>
                                    <input
                                      type="date"
                                      max={new Date()
                                        ?.toISOString()
                                        ?.slice(0, 10)}
                                      name="to"
                                      className="form-control"
                                      onChange={(e) => {
                                        setFieldValue("to", e.target.value);
                                        setTo(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    {errors.toDate && touched.toDate && (
                                      <div className="invalid-feedback animated fadeInUp">
                                        {errors.toDate}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <h3
                          className="text-center mb-3"
                          style={{
                            marginBottom: "20px",
                            backgroundColor: "rgb(52, 152, 219)",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          Expense Summary
                        </h3>{" "}
                        {/* Centered heading with margin, background color, and white text */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <table className="table table-bordered">
                          {/* <thead>
                            <tr>
                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Expense Account Head
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Budget
                              </th>{" "}
\                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Start Date
                              </th>{" "}
\                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                End Date
                              </th>{" "}
                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Recurrence
                              </th>{" "}
                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Organize
                              </th>{" "}
                            </tr>
                          </thead>

                          <tbody>
                            {categories.map((category, idx) => (
                              <React.Fragment key={idx}>
                                <tr>
                                  <td colSpan="6">
                                    <h4 style={category.style}>
                                      {category.name}
                                    </h4>
                                  </td>
                                </tr>
                                {category.items.map((item, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      backgroundColor:
                                        item.expense === "HR Expense" ||
                                        item.expense === "Expense" ||
                                        item.expense === "Facebook Ads" ||
                                        item.expense === "Google Ads" ||
                                        item.expense === "Outbound Emails" ||
                                        item.expense === "Podcast Ad"
                                          ? "#d9edf7"
                                          : "transparent",
                                    }}
                                  >
                                    <td>{item.expense}</td>
                                    <td>{item.budget}</td>
                                    <td>{item.startDate}</td>
                                    <td>{item.endDate}</td>
                                    <td>{item.recurrence}</td>
                                    <td>
                                      <AiOutlineMessage
                                        style={{ fontSize: "1.2rem" }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            ))}
                          </tbody> */}

                          <thead className="thead-dark">
                            <tr>
                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Account Name
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Transaction Count
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Total Debits (USD)
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(52, 152, 219)",
                                }}
                              >
                                Percentage of Total (%)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {report?.map((item, index) => (
                              <tr key={index}>
                                <td>{item.accountName}</td>
                                <td>{item.transactionCount}</td>
                                <td>USD {item.totalDebits}</td>
                                <td>{item.percentage}%</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>Total</th>

                              <th>{totalTransactions}</th>
                              <th>USD{totalAmount}</th>
                              <th>{totalPercentage}%</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExpenseCategory;
