import React, { useEffect, useState } from "react";
import {
  Tab,
  Nav,
  Table,
  Alert,
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useGetSingleSupplierQuery } from "../../../features/supplier/supplierApiSlice";
import { useGetPurchaseByVendorIdQuery } from "../../../features/financialMangement/purchase/purchaseApiSlice";

import CustomerLedgerListing from "./supplierLedgerListing";
import TransactionHistory from "./transaction-history";

function formatDate(dateString) {
  const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    // hour12: true,
  });

  return formattedDate;
}
const SupplierLedger = () => {
  const user = localStorage.getItem("user");
  const [key, setKey] = useState("pending-invoices");
  const { supplierId } = useParams();
  const { data: supplier } = useGetSingleSupplierQuery(supplierId);
  const { data: invoices, refetch: refetchInvoices } =
    useGetPurchaseByVendorIdQuery(
      { supplierId: supplierId },
      {
        skip: !supplierId, // Skip the query if supplierId is undefined
      }
    );

  useEffect(() => {
    refetchInvoices();
  }, [invoices, refetchInvoices]);

  return (
    <div
      style={{
        margin: "20px",
        backgroundColor: "white",
        padding: "20px",
        color: "black",
      }}
      className="rounded"
    >
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Supplier Ledger</h4>

        {/* {JSON.parse(user)?.Role?.permissions?.includes(
          "receive-payment/supplier/create"
        ) && ( */}
        <Link
          to={`/${JSON.parse(
            user
          )?.role?.toLowerCase()}/add-payment/supplier/create?supplierId=${supplierId}`}
          className="btn btn-primary light btn-sm me-2"
        >
          <i className="fa-solid fa-plus me-2"></i>Add Payment
        </Link>
        {/* )} */}
      </div>

      <div
        style={{
          marginBottom: "20px",
          border: "1px solid #ddd",
          borderRadius: "5px",
        }}
      >
        <div style={{ padding: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "65%" }}>
              <p>
                <strong>Supplier Name:</strong> {supplier?.suppliers?.name}
              </p>
              <p>
                <strong>Supplier Phone:</strong> {supplier?.suppliers?.phone}
              </p>
              <p>
                <strong>Email: </strong>
                {supplier?.suppliers?.email}
              </p>
              <p>
                <strong>Supplier TRN:</strong> {supplier?.suppliers?.TRN}
              </p>
            </div>
            <div style={{ width: "30%", textAlign: "right" }}>
              <p>
                <strong>Account Payable :</strong>
              </p>
              <h4 style={{ color: "red" }}>
                {invoices
                  ?.filter((item) => item?.paidAmount !== item?.total)
                  .reduce(
                    (total, item) =>
                      total + (item?.total - (item?.paidAmount || 0)),
                    0
                  )}{" "}
                USD
              </h4>
            </div>
          </div>
        </div>
      </div>

      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <Nav variant="tabs" style={{ marginBottom: "20px" }}>
          <Nav.Item>
            <Nav.Link eventKey="pending-invoices">Pending PO</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="transaction-history">
              Transaction History
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="customer-ledger">Supplier Ledger</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content style={{ marginTop: "20px" }}>
          <Tab.Pane eventKey="pending-invoices">
            <div className="table-responsive active-projects task-table">
              <div
                id="task-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="empoloyeestbl2"
                  className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                >
                  <thead>
                    <tr>
                      <th>SR No</th>
                      <th>Purchase ID</th>
                      <th>Warehouse Name</th>
                      <th>Purchase Category</th>
                      <th>Total Amount (USD)</th>
                      <th>Paid Amount (USD)</th>
                      <th>Remaining (USD)</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices
                      ?.filter((item) => item?.paidAmount !== item?.total)
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {new Date(item.createdAt)?.getTime()}</td>
                          <td>{item?.purchaseOrder?.warehouse?.name}</td>
                          <td>{item?.purchaseOrder?.category?.name}</td>
                          <td>{item?.total}</td>
                          <td>{item?.paidAmount || 0}</td>
                          <td>{item?.total - (item?.paidAmount || 0)}</td>
                          <td>
                            {new Date(item?.createdAt).toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            })}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="transaction-history">
            {/* <Alert variant="primary">Transaction History</Alert> */}

            <TransactionHistory supplierId={supplierId} />
            {/* Add Transaction History content here */}
          </Tab.Pane>
          <Tab.Pane eventKey="customer-sale-history">
            <Alert variant="primary">Supplier Sale History</Alert>
            {/* Add Customer Sale History content here */}
          </Tab.Pane>
          <Tab.Pane eventKey="customer-sale-return">
            <Alert variant="primary">Supplier Sale Return</Alert>
            {/* Add Customer Sale Return content here */}
          </Tab.Pane>
          <Tab.Pane eventKey="customer-ledger">
            <div className="table-responsive active-projects task-table">
              <div
                id="task-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="empoloyeestbl2"
                  className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                >
                  <thead>
                    <tr>
                      <th>SR No</th>
                      <th>Purchase ID</th>
                      <th>Warehouse Name</th>
                      <th>Purchase Category</th>
                      <th>Total Amount (USD)</th>
                      <th>Paid Amount (USD)</th>
                      <th>Remaining (USD)</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td> {new Date(item.createdAt)?.getTime()}</td>
                        <td>{item?.purchaseOrder?.warehouse?.name}</td>
                        <td>{item?.purchaseOrder?.category?.name}</td>
                        <td>{item?.total}</td>
                        <td>{item?.paidAmount || 0}</td>
                        <td>{item?.total - (item?.paidAmount || 0)}</td>
                        <td>
                          {new Date(item?.createdAt).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default SupplierLedger;
