// Edit.jsx
import React, { useState, useEffect, Fragment } from "react";
import { useGetCategoriesQuery } from "../../../../features/Inventory/category/categoryApiSlice";
import Select from "react-select";
import { useGetBrandsQuery } from "../../../../features/Inventory/brand/brandApiSlice";
import {
  usePutProductMutation,
  useGetSingleProductQuery,
} from "../../../../features/Inventory/product/productApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
const loginSchema = Yup.object().shape({
  name: Yup.string().required("Product Name is required"),
  description: Yup.string(),
  categoryId: Yup.object().shape({
    value: Yup.string().required("Category is required"),
    label: Yup.string().required("Category is required"),
  }),
  brandId: Yup.object().shape({
    value: Yup.string().required("Brand is required"),
    label: Yup.string().required("Brand is required"),
  }),
  price: Yup.number()
    .required("Price is required")
    .positive("Price must be positive"),
  quantity: Yup.number()
    .required("Quantity is required")
    .integer("Quantity must be an integer")
    .positive("Quantity must be positive"),
  unitOfMeasurement: Yup.string(),
  taxes: Yup.number().positive("Taxes must be positive"),
  discountes: Yup.number().positive("Discount must be positive"),
});

const Edit = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isView, setIsView] = useState(false);
  const { data: products = [], refetch: refetchProducts } =
    useGetSingleProductQuery(productId);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    if (containsView) {
      setIsView(true);
    }
    refetchProducts();
  }, []);

  const { data: brands = [], refetch: refetchBrands } = useGetBrandsQuery();
  const { data: categories = [], refetch: refetchCategories } =
    useGetCategoriesQuery();

  const [putProduct, { isLoading }] = usePutProductMutation();
  const handleSubmitted = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("categoryId", values.categoryId?.value);
      formData.append("brandId", values.brandId?.value);
      formData.append("price", values.price);
      formData.append("quantity", values.quantity);
      formData.append("unitOfMeasurement", values.unitOfMeasurement);
      formData.append("taxes", values.taxes);
      formData.append("discountes", values.discountes);
      if (selectedFile) {
        formData.append("image", selectedFile);
        formData.append("oldPicture", products?.products?.image);
      }

      putProduct({ id: productId, updatedProduct: formData })
        .unwrap()
        .then((response) => {
          notifySuccess("Product updated successfully");
          navigate("product");
        })
        .catch((error) => {
          console.error("Error updating product", error);

          notifyError(error.message);
        });
    } catch (error) {
      notifyError(error.message);
      // Handle error, access error.data
    }
  };
  const fileBaseUrl = process.env.REACT_APP_File_URL;

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {isView ? "View" : "Edit"} Product
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: products?.products?.name,
                      description: products?.products?.description,
                      categoryId: {
                        value: products?.products?.category?.categoryId || "",
                        label: products?.products?.category?.name || "",
                      },
                      brandId: {
                        value: products?.products?.brand?.brandId || "",
                        label: products?.products?.brand?.name || "",
                      },
                      price: products?.products?.price,
                      quantity: products?.products?.quantity,
                      unitOfMeasurement: products?.products?.unitOfMeasurement,
                      taxes: products?.products?.taxes,
                      discountes: products?.products?.discountes,
                      imageUrl: products?.products?.image,
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Product Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter product name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Price</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter price"
                                name="price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.price && errors.price}
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Category</label>
                              <Select
                                options={categories?.categories?.map(
                                  (category) => ({
                                    value: category.categoryId,
                                    label: category.name,
                                  })
                                )}
                                name="categoryId"
                                onChange={(selectedOption) =>
                                  setFieldValue("categoryId", selectedOption)
                                }
                                onBlur={handleBlur}
                                value={values?.categoryId}
                                isDisabled={isView}
                              />
                              {errors.categoryId && touched.categoryId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.categoryId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Brand</label>
                              <Select
                                value={values?.brandId}
                                options={brands?.brands?.map((brand) => ({
                                  value: brand.brandId,
                                  label: brand.name,
                                }))}
                                name="brandId"
                                onChange={(selectedOption) =>
                                  setFieldValue("brandId", selectedOption)
                                }
                                onBlur={handleBlur}
                                // value={brandOptions.find(
                                //   (option) => option.value === values.brandId
                                // )}
                                isDisabled={isView}
                              />
                              {errors.brandId && touched.brandId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.brandId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Quantity</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter quantity"
                                name="quantity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quantity}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.quantity}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Unit of Measurement
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter unit of measurement"
                                name="unitOfMeasurement"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.unitOfMeasurement}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.unitOfMeasurement &&
                                  errors.unitOfMeasurement}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Discount %</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter discount"
                                name="discountes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.discountes}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountes && errors.discountes}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Taxes</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter taxes"
                                name="taxes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.taxes}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.taxes && errors.taxes}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Product Image
                              </label>
                              {!isView && (
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter image URL"
                                  name="imageUrl"
                                  onChange={(event) => {
                                    // Store the selected file in state
                                    setSelectedFile(event.target.files[0]);
                                    // Generate a preview URL for the selected image
                                    setPreviewImage(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                    handleChange(event);
                                  }}
                                  onBlur={handleBlur}
                                  isDisabled={isView}
                                  // value={values.imageUrl}
                                  accept="image/*"
                                />
                              )}

                              {(previewImage || values?.imageUrl) && (
                                <div className="mt-2">
                                  <img
                                    src={
                                      previewImage ||
                                      fileBaseUrl + "" + values?.imageUrl
                                    }
                                    alt="Preview"
                                    style={{ maxWidth: "200px" }}
                                  />
                                  {errors.imageUrl && touched.imageUrl && (
                                    <div className="invalid-feedback animated fadeInUp">
                                      {errors.imageUrl}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Description</label>
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="Enter description"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.description && errors.description}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!isView && (
                          <div className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting}
                            >
                              Update
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
