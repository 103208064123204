import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useGetPurchasePaymentQuery } from "../../../../../features/financialMangement/purchasePayment/purchasePaymentApiSlice";

const Payments = (props) => {
  const { data: payments, refetch: refetchPayments } =
    useGetPurchasePaymentQuery();

  const [filterPayments, setFilterPayments] = useState([]);

  useEffect(() => {
    refetchPayments();
  }, [payments, filterPayments, refetchPayments]);

  useEffect(() => {
    const filterPayments = payments?.filter((entry) => {
      return entry.purchaseId == props?.purchaseId;
    });

    setFilterPayments(filterPayments);
  }, [payments, props]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Payment History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="task-tbl_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="empoloyeestbl2"
            className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
          >
            {filterPayments?.length > 0 ? (
              <thead>
                <tr>
                  <th>#</th>
                  <th>Payment Date</th>
                  <th>Amount</th>
                  <th>Method</th>
                  <th>reference</th>
                </tr>
              </thead>
            ) : null}

            <tbody>
              {filterPayments?.length > 0 ? (
                filterPayments?.map((entry, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {entry?.paymentDate
                        ? new Date(entry?.paymentDate).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            // hour: "2-digit",
                            // minute: "2-digit",
                            // second: "2-digit",
                            // hour12: true, // This will format the time in AM/PM format
                          })
                        : "-"}
                    </td>
                    <td>{entry?.amount}</td>
                    <td>{entry?.paymentType}</td>
                    <td>{entry?.reference}</td>
                  </tr>
                ))
              ) : (
                <p>No Record Found</p>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Payments;
