import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import {
  useGetPayrollByIdQuery,
  useUpdatePayrollMutation,
  useUpdatePayrollDetailByPayroleIdMutation,
} from "../../../../features/hrm/payroll/payroll/payRollApiSlice";
import { useParams, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const PayrollDetailPage = () => {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const { payrollId } = useParams();
  const navigate = useNavigate();

  const {
    data: payroll = [],
    error,
    isLoading,
    refetch,
  } = useGetPayrollByIdQuery(payrollId);
  const [updatePayroll, { isLoading: isUpdating }] = useUpdatePayrollMutation();

  const handleSlip = (user) => {
    navigate(
      `/${userInfo?.role?.toLowerCase()}/salary-slip/` + user?.payrollDetailId
    );
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const handleAction = async (payrollId, status, payroleData) => {
    try {
      const updatedPayroll = { ...payroleData };
      updatedPayroll.status = status;

      await updatePayroll({ payrollId, updatedPayroll });
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = (employee) => {
    setSelectedEmployee(employee);
    setShowModal(true);
  };
  const [updatePayrollDetailByPayroleId] =
    useUpdatePayrollDetailByPayroleIdMutation();

  const handleUpdateEmployee = async () => {
    try {
      // Ensure that the selectedEmployee object contains the necessary properties
      const payload = {
        payrollDetailId: selectedEmployee?.payrollDetailId?.toString(), // Payroll detail ID
        updatedData: selectedEmployee, // The updated employee data
      };

      // Call the mutation and pass the payload correctly
      await updatePayrollDetailByPayroleId(payload);

      setShowModal(false); // Close the modal after update
    } catch (error) {
      console.error("Failed to update employee details:", error);
    }
  };

  return (
    <div className="mt-5 rounded p-3 m-3" style={{ backgroundColor: "white" }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>{payroll?.payrolls?.payPeriod}</h4>
        <div>
          {/* Status: <span className="badge badge-danger">Open</span> */}

          <Dropdown className="task-dropdown-2">
            <Dropdown.Toggle as="div" className="btn btn-danger">
              {payroll?.payrolls?.status}
            </Dropdown.Toggle>
            {payroll?.payrolls?.status == "Draft" && (
              <Dropdown.Menu className="task-drop-menu">
                <Dropdown.Item
                  onClick={() =>
                    handleAction(
                      payroll?.payrolls?.payrollId,
                      "Pending",
                      payroll?.payrolls
                    )
                  }
                >
                  Pending
                </Dropdown.Item>
              </Dropdown.Menu>
            )}

            {payroll?.payrolls?.status == "Pending" && (
              <Dropdown.Menu className="task-drop-menu">
                <Dropdown.Item
                  onClick={() =>
                    handleAction(
                      payroll?.payrolls?.payrollId,
                      "Approved",
                      payroll?.payrolls
                    )
                  }
                >
                  Approved
                </Dropdown.Item>
              </Dropdown.Menu>
            )}

            {payroll?.payrolls?.status == "Approved" && (
              <Dropdown.Menu className="task-drop-menu">
                <Dropdown.Item
                  onClick={() =>
                    handleAction(
                      payroll?.payrolls?.payrollId,
                      "Finalized",
                      payroll?.payrolls
                    )
                  }
                >
                  Finalized
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
      </div>
      <div className="table-responsive active-projects task-table">
        <div id="task-tbl_wrapper" className="dataTables_wrapper no-footer">
          <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
            <thead>
              <tr>
                <th>Select</th>
                <th>Employee Name</th>
                <th>Basic Pay</th>
                <th>Allowance</th>
                <th>Contributions</th>
                <th>Gross Salary</th>
                <th>Tax</th>
                <th>Deduction</th>
                <th>leaves</th>
                <th>Loan</th>
                <th>Total Working Hours</th>
                <th>Net Salary</th>

                <th className="text-center pr-4">Action</th>
              </tr>
            </thead>
            <tbody>
              {payroll?.payrollDetail?.map((user, index) => (
                <tr key={index}>
                  <td>
                    <Form.Check type="checkbox" />
                  </td>
                  <td>
                    {user?.user?.firstName} {user?.user?.lastName}
                  </td>
                  <td>{user?.companyBasicSalary} USD</td>
                  <td>{user?.allowances} USD</td>
                  <td>{user?.contributions} USD</td>
                  <td>{user?.baseSalary} USD</td>
                  <td>{user?.tax} USD</td>

                  <td>{user?.deduction} USD</td>
                  <td>{user?.leaves}</td>
                  <td>{user?.loan} USD</td>
                  <td>{`${Math.floor(user?.totalWorkingHours)}:${String(
                    Math.round((user?.totalWorkingHours % 1) * 60)
                  ).padStart(2, "0")}`}</td>

                  <td>{user?.netSalary} USD</td>
                  <td className="text-end">
                    {/* <Button
                      variant="info"
                      className="btn btn-sm"
                      onClick={() => handleEditClick(user)}
                    >
                      Edit
                    </Button> */}
                    <Button
                      className="btn btn-success"
                      onClick={() => {
                        handleSlip(user);
                      }}
                    >
                      Print
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Employee Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEmployee && (
            <Form>
              {/* Employee Name (Read-Only) */}
              <Form.Group controlId="employeeName">
                <Form.Label>Employee Name</Form.Label>
                <Form.Control
                  type="text"
                  value={`${selectedEmployee.user.firstName} ${selectedEmployee.user.lastName}`}
                  readOnly
                />
              </Form.Group>

              {/* Basic Salary */}
              <Form.Group controlId="basicSalary">
                <Form.Label>Basic Pay</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.companyBasicSalary}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      companyBasicSalary: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Allowances */}
              <Form.Group controlId="allowances">
                <Form.Label>Allowances</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.allowances}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      allowances: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Contributions */}
              <Form.Group controlId="contributions">
                <Form.Label>Contributions</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.contributions}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      contributions: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Gross Salary (Read-Only) */}
              <Form.Group controlId="grossSalary">
                <Form.Label>Gross Salary</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.baseSalary}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      baseSalary: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Tax */}
              <Form.Group controlId="tax">
                <Form.Label>Tax</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.tax}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      tax: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Deductions */}
              <Form.Group controlId="deductions">
                <Form.Label>Deductions</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.deduction}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      deduction: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Leaves */}
              <Form.Group controlId="leaves">
                <Form.Label>Leaves</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.leaves}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      leaves: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Loan */}
              <Form.Group controlId="loan">
                <Form.Label>Loan</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.loan}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      loan: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Total Working Hours */}
              <Form.Group controlId="totalWorkingHours">
                <Form.Label>Total Working Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedEmployee.totalWorkingHours}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      totalWorkingHours: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* Net Salary (Read-Only) */}
              <Form.Group controlId="netSalary">
                <Form.Label>Net Salary</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedEmployee.netSalary}
                  onChange={(e) =>
                    setSelectedEmployee({
                      ...selectedEmployee,
                      netSalary: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateEmployee}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PayrollDetailPage;
