import React, { useState } from "react";
import axios from "axios";
import Calendar from "react-calendar";
import Swal from "sweetalert2";
import "react-calendar/dist/Calendar.css";

const Appointment = ({ msg }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [showSlots, setShowSlots] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAppointed, setIsAppointed] = useState(true);
  const styles = {
    message: { position: "relative" },
    buttonYes: {
      backgroundColor: "dodgerblue",
      border: "none",
      color: "white",
      padding: "8px 16px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      margin: "4px 2px",
      cursor: "pointer",
      borderRadius: "4px",
    },
    button: {
      backgroundColor: "gray",
      border: "none",
      color: "white",
      padding: "8px 16px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      margin: "4px 2px",
      cursor: "pointer",
      borderRadius: "4px",
    },
  };

  const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "80%",
    maxWidth: "500px",
  };

  const handleConfirm = async (slot) => {
    if (!selectedDateTime) {
      setError("Please select a date and time.");
      return;
    }

    const chatUser = JSON.parse(localStorage.getItem("chatUser"));
    const formattedDate = selectedDateTime?.toLocaleDateString("en-CA");
    const formattedTime = `${convertTo12HourFormat(
      slot.from
    )} - ${convertTo12HourFormat(slot.to)}`;

    const payload = {
      chatUserId: chatUser?.user?.userId,
      date: formattedDate,
      time: formattedTime,
      email: chatUser?.user?.email,
    };

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chat-appointment`,
        payload
      );

      if (response.status !== 201) {
        throw new Error(
          "Failed to schedule the appointment. Please try again."
        );
      }

      Swal.fire({
        icon: "success",
        title: "Appointment Scheduled",
        text: response.data.message,
        confirmButtonColor: "#3085d6",
      });
      setIsAppointed(false);
      setShowModal(false);
      setShowSlots(false);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text:
          err.response?.data?.message || err.message || "An error occurred.",
        confirmButtonColor: "#d33",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDateClick = async (date) => {
    setSelectedDateTime(date);
    setShowSlots(true);
    await fetchAppointments();

    const formattedDate = date?.toLocaleDateString("en-CA");
    const dayOfWeek = date?.toLocaleDateString(undefined, { weekday: "long" });

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}combined-availability`,
        {
          params: { date: formattedDate, day: dayOfWeek },
        }
      );

      const availabilities = response.data.availabilities || [];
      const slots = availabilities.flatMap((availability) =>
        generate30MinuteSlots(availability.from, availability.to)
      );

      const disabledSlots = getDisabledSlots(formattedDate, slots);

      setAvailableSlots(
        slots.map((slot) => ({
          ...slot,
          isDisabled: disabledSlots.some(
            (disabledSlot) =>
              disabledSlot.from === slot.from && disabledSlot.to === slot.to
          ),
        }))
      );
    } catch (err) {
      setError("Failed to fetch available slots.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const generate30MinuteSlots = (from, to) => {
    const slots = [];
    let currentTime = new Date(`1970-01-01T${from}`);
    const endTime = new Date(`1970-01-01T${to}`);

    while (currentTime < endTime) {
      const start = new Date(currentTime);
      currentTime.setMinutes(currentTime.getMinutes() + 30);
      const end = new Date(currentTime);

      slots.push({
        from: start.toTimeString().substring(0, 5),
        to: end.toTimeString().substring(0, 5),
      });
    }

    return slots;
  };

  const fetchAppointments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}chat-appointment`
      );
      setAppointments(response.data.appointments || []);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const getDisabledSlots = (formattedDate, availabilities) => {
    return availabilities.filter((slot) =>
      appointments.some(
        (appointment) =>
          appointment.date === formattedDate &&
          appointment.time ===
            `${convertTo12HourFormat(slot.from)} - ${convertTo12HourFormat(
              slot.to
            )}`
      )
    );
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":").map(Number);
    const suffix = hour >= 12 ? "PM" : "AM";
    const adjustedHour = hour % 12 || 12;
    return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${suffix}`;
  };

  const formatDate = (date) => {
    return date?.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <p
      style={{
        ...styles.message,
        padding: "5px 5px 40px 5px",
        backgroundColor: "#f3f3f3",
      }}
    >
      {msg.message}
      <div
        style={{
          padding: "3px",
          position: "absolute",
          bottom: "3px",
          right: "12px",
        }}
      >
        {isAppointed ? (
          <>
            <button
              style={{
                ...styles.buttonYes,
                padding: "2px 6px",
                fontSize: "12px",
              }}
              onClick={() => setShowModal(true)}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#197bb3")}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = "dodgerblue")
              }
            >
              Get Appointment
            </button>
          </>
        ) : (
          <span style={{ color: "gray" }}>Scheduled Appointment</span>
        )}
      </div>
      {showModal && (
        <div style={{ ...modalStyle }}>
          <div style={{ ...modalContentStyle }}>
            <h2>Select Date and Time</h2>
            <h4>Available Slots for {formatDate(selectedDateTime)}</h4>
            {!showSlots ? (
              <Calendar
                onChange={setSelectedDateTime}
                value={selectedDateTime}
                onClickDay={handleDateClick}
              />
            ) : (
              <div
                style={{
                  marginTop: "20px",
                  maxHeight: "70vh",
                  overflow: "scroll",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <p>Loading available slots...</p>
                ) : availableSlots.length > 0 ? (
                  availableSlots.map((slot, index) => (
                    <button
                      key={index}
                      style={{
                        ...styles.buttonYes,
                        width: "200px",
                        margin: "5px 0",
                        padding: "10px 0",
                        backgroundColor: slot.isDisabled
                          ? "#88d9df"
                          : "dodgerblue",
                        cursor: slot.isDisabled ? "not-allowed" : "pointer",
                      }}
                      onClick={() => handleConfirm(slot)}
                      disabled={slot.isDisabled}
                    >
                      {convertTo12HourFormat(slot.from)} -{" "}
                      {convertTo12HourFormat(slot.to)}
                    </button>
                  ))
                ) : (
                  <p>No available slots for this day.</p>
                )}
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            )}
            <div style={{ marginTop: "20px" }}>
              {showSlots && (
                <button
                  style={{ marginRight: "10px", ...styles.button }}
                  onClick={() => setShowSlots(false)}
                >
                  Back to Calendar
                </button>
              )}
              <button
                style={styles.button}
                onClick={() => {
                  setIsAppointed(true);
                  setShowModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </p>
  );
};

export default Appointment;
