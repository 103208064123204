import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const WeeklyHours = ({ onUpdate, disabled }) => {
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    const fetchAvailabilities = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chat-availability`
        );
        const availabilities = response.data.availabilities;

        const groupedSchedule = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ].map((day) => {
          const timeSlots = availabilities
            .filter((availability) => availability.day === day)
            .map((availability) => ({
              start: availability.from,
              end: availability.to,
              id: availability.availabilityId,
              isNew: false,
              isModified: false,
            }));

          return { day, timeSlots };
        });

        setSchedule(groupedSchedule);
      } catch (error) {
        Swal.fire("Error", "Failed to fetch availabilities.", "error");
        console.error("Error fetching availabilities:", error);
      }
    };

    fetchAvailabilities();
  }, []);

  const handleTimeSlotChange = (dayIndex, index, field, value) => {
    const newSchedule = [...schedule];
    newSchedule[dayIndex].timeSlots[index] = {
      ...newSchedule[dayIndex].timeSlots[index],
      [field]: value,
      isModified: true,
    };
    setSchedule(newSchedule);
    onUpdate(newSchedule);
  };

  const addTimeSlot = (dayIndex) => {
    const newSchedule = [...schedule];
    newSchedule[dayIndex].timeSlots.push({
      start: "09:00",
      end: "09:30",
      id: null,
      isNew: true,
      isModified: false,
    });
    setSchedule(newSchedule);
    onUpdate(newSchedule);
  };

  const saveTimeSlot = async (dayIndex, index) => {
    const slot = schedule[dayIndex].timeSlots[index];
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chat-availability`,
        {
          day: schedule[dayIndex].day,
          from: slot.start,
          to: slot.end,
        }
      );

      const newSchedule = [...schedule];
      newSchedule[dayIndex].timeSlots[index] = {
        ...slot,
        id: response.data.availabilityId,
        isNew: false,
        isModified: false,
      };

      setSchedule(newSchedule);
      onUpdate(newSchedule);
    } catch (error) {
      const errorMsg =
        error.response?.data?.message ||
        "An error occurred while saving the availability.";
      Swal.fire("Error", errorMsg, "error");
      console.error("Error saving availability:", error);
    }
  };

  const updateTimeSlot = async (dayIndex, index) => {
    const slot = schedule[dayIndex].timeSlots[index];

    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}chat-availability/update/${slot.id}`,
        {
          day: schedule[dayIndex].day,
          from: slot.start,
          to: slot.end,
        }
      );

      const newSchedule = [...schedule];
      newSchedule[dayIndex].timeSlots[index] = {
        ...slot,
        isModified: false,
      };

      setSchedule(newSchedule);
      onUpdate(newSchedule);
    } catch (error) {
      const errorMsg =
        error.response?.data?.message ||
        "An error occurred while updating the availability.";
      Swal.fire("Error", errorMsg, "error");
      console.error("Error updating availability:", error);
    }
  };

  const removeTimeSlot = async (dayIndex, index) => {
    const slot = schedule[dayIndex].timeSlots[index];

    if (slot.isNew) {
      const newSchedule = [...schedule];
      newSchedule[dayIndex].timeSlots.splice(index, 1);
      setSchedule(newSchedule);
      onUpdate(newSchedule);
    } else {
      try {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}chat-availability/delete/${slot.id}`
        );
        const newSchedule = [...schedule];
        newSchedule[dayIndex].timeSlots.splice(index, 1);
        setSchedule(newSchedule);
        onUpdate(newSchedule);
      } catch (error) {
        const errorMsg =
          error.response?.data?.message ||
          "An error occurred while deleting the availability.";
        Swal.fire("Error", errorMsg, "error");
        console.error("Failed to delete availability:", error);
      }
    }
  };

  return (
    <div className="container-fluid mt-4" style={{ padding: "0" }}>
      <div className="card w-100">
        <div className="card-body">
          <h5 className="card-title">Set Weekly Hours</h5>
          <hr />
          {schedule.map((day, dayIndex) => (
            <div key={dayIndex} className="d-flex align-items-center mb-4">
              <h6 className="me-3">{day.day}</h6>
              <div
                className="d-flex align-items-center overflow-auto"
                style={{ maxWidth: "100%", scrollbarWidth: "thin" }}
              >
                {day.timeSlots.length > 0 ? (
                  day.timeSlots.map((slot, index) => (
                    <div key={index} className="d-flex align-items-center me-3">
                      <input
                        type="time"
                        value={slot.start}
                        onChange={(e) =>
                          handleTimeSlotChange(
                            dayIndex,
                            index,
                            "start",
                            e.target.value
                          )
                        }
                        disabled={disabled}
                        className="form-control form-control-sm me-2"
                      />
                      <span className="me-2">to</span>
                      <input
                        type="time"
                        value={slot.end}
                        onChange={(e) =>
                          handleTimeSlotChange(
                            dayIndex,
                            index,
                            "end",
                            e.target.value
                          )
                        }
                        disabled={disabled}
                        className="form-control form-control-sm me-2"
                      />
                      {slot.isNew ? (
                        <button
                          onClick={() => saveTimeSlot(dayIndex, index)}
                          disabled={disabled}
                          className="btn btn-sm btn-success ms-2"
                        >
                          Save
                        </button>
                      ) : slot.isModified ? (
                        <button
                          onClick={() => updateTimeSlot(dayIndex, index)}
                          disabled={disabled}
                          className="btn btn-sm btn-warning ms-2"
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          onClick={() => removeTimeSlot(dayIndex, index)}
                          disabled={disabled}
                          className="btn btn-sm btn-danger ms-2"
                        >
                          &times;
                        </button>
                      )}
                    </div>
                  ))
                ) : (
                  <span>Not available</span>
                )}
              </div>
              <button
                onClick={() => addTimeSlot(dayIndex)}
                disabled={disabled}
                className="btn btn-sm btn-primary ms-2"
              >
                +
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeeklyHours;
