import images from "../../../../../../src/images/images.png";
import aifaLogo from "../../../financial management/billings/Invoice/AIFA-Logo-2.png";
import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGetInvoiceByYearQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { useGetExpenseByYearQuery } from "../../../../../features/financialMangement/expense/expense/expenseApiSlice";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const loginSchema = Yup.object().shape({
  year: Yup.string().required("Year is required"),
});
const ProfitandLoss = () => {
  const [year, setYear] = useState("");

  const [filterRecords, setFilterRecords] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [currentYearInvoicesTotal, setCurrentYearInvoicesTotal] = useState(0);
  const [noneExpense, setNoneExpense] = useState([]);
  const [noneYearExpense, setNoneYearExpense] = useState(0);
  const [pastYearInvoicesTotal, setPastYearInvoicesTotal] = useState(0);
  const [selectedYearExpense, setSelectedYearExpense] = useState(0);
  const [selectedYearIncome, setSelectedYearIncome] = useState(0);
  const [incomeAccount, setIncomeAccount] = useState([]);
  const [pastYearExpense, setPastYearExpense] = useState(0);
  const [selectedYearTax, setSelectedYearTax] = useState(0);
  const [pastYearTax, setPastYearTax] = useState(0);
  const [combinedExpenses, setCombinedExpenses] = useState([]);
  const [totalProductPriceBeforeVat, setTotalProductPriceBeforeVat] =
    useState(0);

  const costOfGoodsSold = [
    {
      label: "Cost of Goods Sold",
      values: [`USD ${totalProductPriceBeforeVat}`, `USD ${0}`],
    },

    {
      label: "Total Net Cost of Goods Sold ",
      values: [`USD ${totalProductPriceBeforeVat}`, `USD ${0}`],
    },
  ];
  const assets = [
    {
      label: "Sales",
      values: [
        `USD ${currentYearInvoicesTotal}`,
        `USD ${pastYearInvoicesTotal}`,
      ],
    },

    {
      label: "Total for Operating Income ",
      values: [
        `USD ${currentYearInvoicesTotal}`,
        `USD ${pastYearInvoicesTotal}`,
      ],
    },
  ];

  const processExpenses = (expenses) => {
    return expenses.reduce((acc, expense) => {
      const name = expense.Account.name;
      const amount = parseFloat(expense.debits.replace(/[^0-9.-]+/g, ""));

      acc[name] = `USD ${amount.toFixed(2)}`;
      return acc;
    }, {});
  };

  const processIncome = (incomes) => {
    return incomes.reduce((acc, income) => {
      const name = income.Account.name;
      const amount = parseFloat(income.credits.replace(/[^0-9.-]+/g, ""));

      acc[name] = `USD ${amount.toFixed(2)}`;
      return acc;
    }, {});
  };

  const renderList = (items) => (
    <div className="table-responsive">
      <table className="table table-bordered table-striped">
        <tbody>
          {items.map((item, index) => (
            <tr
              key={index}
              className={item.label.includes("Total") ? "fw-bold" : ""}
              style={{
                ...(item.label.includes("Total")
                  ? {
                      color:
                        item.label === "Total Net sale" ? "#009688" : "#009688",
                      backgroundColor: "white",
                    }
                  : { backgroundColor: "#e6f3ff" }),
                ...(item.style || {}), // Apply any additional styles passed in the item
              }}
            >
              <td colSpan={7}>{item.label}</td>
              <td
                style={{
                  color: item.label.includes("Total")
                    ? item.label === "Total Net sale"
                      ? "#009688"
                      : "#009688"
                    : "inherit",
                  backgroundColor: item.label.includes("Total")
                    ? "white"
                    : "#e6f3ff",
                }}
                className="text-end" // Align text to the right
              >
                {item.values[0]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  function mergeEntries(entries) {
    const mergedEntries = {};

    entries.forEach((entry) => {
      const { accountId, debits, credits } = entry;

      if (!mergedEntries[accountId]) {
        mergedEntries[accountId] = { ...entry }; // Clone the entry
      } else {
        // Sum debits and credits
        mergedEntries[accountId].debits = (
          parseFloat(mergedEntries[accountId].debits || 0) +
          parseFloat(debits || 0)
        ).toString();
        mergedEntries[accountId].credits = (
          parseFloat(mergedEntries[accountId].credits || 0) +
          parseFloat(credits || 0)
        ).toString();
      }
    });

    return Object.values(mergedEntries); // Convert the object back to an array
  }

  const { data: allInvoices, refetch } = useGetInvoiceByYearQuery(
    { year: year },
    { skip: !year }
  );

  const { data: allExpenses, refetch: refetchExpense } =
    useGetExpenseByYearQuery({ year: year }, { skip: !year });

  useEffect(() => {
    if (year) {
      refetch();
      refetchExpense();
    }
  }, [year, refetch, refetchExpense]);

  useEffect(() => {
    if (allInvoices && allExpenses) {
      const paidInvoices = allInvoices?.invoices?.filter(
        (invoice) => invoice?.paymentStatus === "Paid"
      );
      
      const sum = paidInvoices?.reduce((total, invoice) => {
        // Check if subServiceList exists and has valid items
        const subServiceTotal = invoice?.subSeriviceList?.length
          ? invoice.subSeriviceList.reduce((subtotal, subService) => {
              const priceAfterDiscount = parseFloat(subService?.priceAfterDiscount) || 0; // Ensure it's a valid number
              return subtotal + priceAfterDiscount; // Safely add priceAfterDiscount
            }, 0)
          : parseFloat(invoice?.subTotal) || 0; // Default to 0 if subSeriviceList is null or empty
      
        return total + subServiceTotal; // Add subtotal to total
      }, 0); // Start total at 0
      
      const totalPriceBeforeVat = paidInvoices?.reduce((total, invoice) => {
        const productPriceBeforeVat = invoice?.productIds?.reduce((sum, productData) => {
          const product = JSON.parse(productData); // Parse the stringified product data
          return sum + (product.priceBeforeVat * product?.productQuantity || 0); // Add priceBeforeVat or 0 if undefined
        }, 0);
        return total + productPriceBeforeVat;
      }, 0);
      
      setTotalProductPriceBeforeVat(totalPriceBeforeVat);

      setCurrentYearInvoicesTotal(sum);

      const sumPast = allInvoices?.pastYearinvoices?.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item?.totalAmount);
      }, 0);

      setPastYearInvoicesTotal(sumPast);

      const taxSum = paidInvoices?.invoices?.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item?.estimatedTax);
      }, 0);

      setSelectedYearTax(taxSum);

      const taxPast = allInvoices?.pastYearinvoices?.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item?.estimatedTax);
      }, 0);

      setPastYearTax(taxPast);

      const pastData = mergeEntries(allExpenses?.pastYearExpenses);

      const PresentData = mergeEntries(allExpenses?.expenses);

      const selectYearExpenseSum = PresentData?.filter(
        (data) => data?.Account?.type === "Operating expenses"
      ).reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.debits || 0);
      }, 0);

      const selectYearNoneExpenseSum = PresentData?.filter(
        (data) =>
          data?.Account?.type === "expense" ||
          data?.Account?.type === "Other expenses"
      ).reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.debits || 0);
      }, 0);

      const selectYearIncomeSum = PresentData?.filter(
        (data) => data?.Account?.type === "Other income"
      ).reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.credits || 0);
      }, 0);

      const pastYearExpenseSum = pastData?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.debits);
      }, 0);

      setSelectedYearExpense(selectYearExpenseSum);
      setPastYearExpense(pastYearExpenseSum);

      const processedExpenses2024 = processExpenses(
        PresentData?.filter(
          (data) => data?.Account?.type === "Operating expenses"
        )
      );

      const processedNoneExpenses2024 = processExpenses(
        PresentData?.filter(
          (data) =>
            data?.Account?.type === "expense" ||
            data?.Account?.type === "Other expenses"
        )
      );

      const processedIncome2024 = processIncome(
        PresentData?.filter((data) => data?.Account?.type === "Other income")
      );

      const processedExpenses2023 = processExpenses(pastData);
      const allIncomeLabels = new Set([...Object.keys(processedIncome2024)]);

      const allExpenseLabels = new Set([
        ...Object.keys(processedExpenses2024),
        ...Object.keys(processedExpenses2023),
      ]);

      const allNoneExpenseLabels = new Set([
        ...Object.keys(processedNoneExpenses2024),
      ]);

      const combinedIncome = Array.from(allIncomeLabels).map((label) => ({
        label,
        values: [
          processedIncome2024[label] || "", // Show value for 2024 or an empty string
        ],
      }));

      const combinedExpenses = Array.from(allExpenseLabels).map((label) => ({
        label,
        values: [
          processedExpenses2024[label] || "", // Show value for 2024 or an empty string
          processedExpenses2023[label] || "", // Show value for 2023 or an empty string
        ],
      }));

      const combinedNoneExpenses = Array.from(allNoneExpenseLabels).map(
        (label) => ({
          label,
          values: [
            processedNoneExpenses2024[label] || "", // Show value for 2024 or an empty string
          ],
        })
      );

      combinedNoneExpenses.push({
        label: "Total for Non Operating Expense",
        values: [
          `USD ${selectYearNoneExpenseSum.toFixed(2)}`, // Total Expense for 2024
        ],
      });
      setNoneYearExpense(selectYearNoneExpenseSum);
      setNoneExpense(combinedNoneExpenses);
      combinedIncome.push({
        label: "Total for Non Operating Income",
        values: [
          `USD ${selectYearIncomeSum}`, // Total Expense for 2024
        ],
      });

      setSelectedYearIncome(selectYearIncomeSum);

      combinedExpenses.push({
        label: "Total for Operating Expense",
        values: [
          `USD ${selectYearExpenseSum}`, // Total Expense for 2024
          `USD ${pastYearExpenseSum}`, // Total Expense for 2023
        ],
      });

      setCombinedExpenses(combinedExpenses);
      setIncomeAccount(combinedIncome);
    }
  }, [allInvoices, refetch, setYear, allExpenses]);

  const handleSubmit = (values, { setSubmitting }) => {
    // Reset formSubmitted to false before setting it to true to trigger re-render
    setFormSubmitted(false);
    setTimeout(() => {
      setFormSubmitted(true);
      setSubmitting(false);
    }, 0);
  };
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Profit&Loss_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ color: "#009688" }}>
                  Profit And Loss Statement
                </h4>
                <button
                  onClick={downloadPDF}
                  style={{
                    marginBottom: "20px",
                    background: "transparent",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <MdOutlineFileDownload
                    style={{ color: "inherit", fontSize: "20px" }}
                  />
                </button>
              </div>

              <div className="basic-form">
                <Formik
                  initialValues={{
                    supplierId: "",
                    year: "",
                  }}
                  enableReinitialize={true}
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="form-group mb-3"
                            style={{ marginLeft: "15px" }}
                          >
                            <label className="text-label">Select Year</label>
                            <Select
                              options={[...Array(4)].map((_, i) => {
                                const year = new Date().getFullYear() - i;
                                return {
                                  value: year,
                                  label: year.toString(),
                                };
                              })}
                              name="year"
                              onChange={(selectedOption) => {
                                setFieldValue("year", selectedOption.value);
                                setYear(selectedOption.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.year && touched.year && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.year}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>

                {year && allExpenses && allInvoices && (
                  <div id="report-content">
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          className="d-flex justify-content-between align-items-center mb-3"
                          style={{ marginLeft: "15px" }}
                        >
                          <h2
                            className="mb-0 f-bold"
                            style={{ color: "#009688" }}
                          >
                            Aifa Services
                          </h2>
                          <img
                            src={aifaLogo}
                            alt="logo"
                            style={{
                              width: "130px",
                              marginRight: "20px",
                            }}
                          />
                        </div>
                        <div className="card ">
                          <div
                            className="card-header"
                            style={{
                              backgroundColor: "rgb(52 152 219)",
                              padding: "15px",
                            }}
                          >
                            <h4
                              className="card-title text-center"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52 152 219)",
                                marginBottom: "20px",
                              }}
                            >
                              Profit & Loss Statement
                            </h4>

                            <div
                              className="row"
                              style={{
                                backgroundColor: "rgb(52 152 219)",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              <div className="col-6 text-center">
                                <h4
                                  style={{
                                    color: "white",
                                    marginRight: "20px",
                                    display: "inline-block",
                                  }}
                                >
                                  {year}
                                </h4>
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            <section>
                              <h6
                                style={{ color: "#009688", fontSize: "1.1rem" }}
                                className="fw-bold"
                              >
                                Operating Income
                              </h6>
                              {renderList(
                                assets.map((item) => ({
                                  ...item,
                                  style:
                                    item.label === "Sales" ||
                                    item.label === "Total for Operating Income"
                                      ? {
                                          backgroundColor: "#d9edf7",
                                          color: "black",
                                        }
                                      : {},
                                }))
                              )}
                            </section>

                            <section>
                              <h6
                                style={{ color: "#009688", fontSize: "1.1rem" }}
                                className="fw-bold"
                              >
                                Cost of Goods Sold
                              </h6>
                              {renderList(
                                costOfGoodsSold.map((item) => ({
                                  ...item,
                                  style:
                                    item.label === "Cost of Goods Sold" ||
                                    item.label ===
                                      "Total Net Cost of Goods Sold"
                                      ? {
                                          backgroundColor: "#d9edf7",
                                          color: "black",
                                          fontSize: "1.1rem",
                                        }
                                      : {},
                                }))
                              )}

                              <div
                                className="d-flex align-items-center"
                                style={{
                                  fontSize: "1.1rem",
                                  color: "#009688",
                                  position: "relative",
                                }}
                              >
                                <h6
                                  className="fw-bold position-absolute start-50 translate-middle-x"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.1rem",
                                    color: "#009688",
                                  }}
                                >
                                  Gross Profit
                                </h6>
                                <h6
                                  className="fw-bold ms-auto"
                                  style={{
                                    textAlign: "right",
                                    margin: 0,
                                    fontSize: "1.1rem",
                                    color: "#009688",
                                  }}
                                >
                                  USD{" "}
                                  {(
                                    currentYearInvoicesTotal -
                                    totalProductPriceBeforeVat
                                  ).toFixed(2)}
                                </h6>
                              </div>

                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                }}
                              />
                            </section>

                            <section>
                              <h6
                                style={{ color: "#009688", fontSize: "1.1rem" }}
                                className="fw-bold"
                              >
                                Operating Expense
                              </h6>
                              {renderList(
                                combinedExpenses.map((item) => ({
                                  ...item,
                                  style:
                                    item.label === "Total Expense"
                                      ? {
                                          color: "#009688",
                                          backgroundColor: "white",
                                          fontSize: "1.1rem",
                                        }
                                      : { backgroundColor: "#d9edf7" },
                                }))
                              )}
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  fontSize: "1.1rem",
                                  color: "#009688",
                                  position: "relative",
                                }}
                              >
                                <h6
                                  className="fw-bold position-absolute start-50 translate-middle-x"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.1rem",
                                    color: "#009688",
                                  }}
                                >
                                  Operating Profit
                                </h6>
                                <h6
                                  className="fw-bold ms-auto"
                                  style={{
                                    textAlign: "right",
                                    margin: 0,
                                    fontSize: "1.1rem",
                                    color: "#009688",
                                  }}
                                >
                                  USD{" "}
                                  {(
                                    currentYearInvoicesTotal -
                                    totalProductPriceBeforeVat -
                                    selectedYearExpense
                                  ).toFixed(2)}
                                </h6>
                              </div>
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                }}
                              />
                            </section>

                            <section>
                              <h6
                                style={{ color: "#009688", fontSize: "1.1rem" }}
                                className="fw-bold"
                              >
                                Non Operating Income
                              </h6>
                              {renderList(
                                incomeAccount.map((item) => ({
                                  ...item,
                                  style:
                                    item.label ===
                                    "Total for Non Operating Income"
                                      ? {
                                          color: "#009688",
                                          backgroundColor: "white",
                                          fontSize: "1.1rem",
                                        }
                                      : { backgroundColor: "#d9edf7" },
                                }))
                              )}
                              <h6
                                style={{ color: "#009688", fontSize: "1.1rem" }}
                                className="fw-bold"
                              >
                                Non Operating Expense
                              </h6>
                              {renderList(
                                noneExpense.map((item) => ({
                                  ...item,
                                  style:
                                    item.label ===
                                    "Total for Non Operating Expense"
                                      ? {
                                          color: "#009688",
                                          backgroundColor: "white",
                                          fontSize: "1.1rem",
                                        }
                                      : { backgroundColor: "#d9edf7" },
                                }))
                              )}
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  fontSize: "1.1rem",
                                  color: "#009688",
                                  position: "relative",
                                }}
                              >
                                <h6
                                  className="fw-bold position-absolute start-50 translate-middle-x"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.1rem",
                                    color: "#009688",
                                  }}
                                >
                                  Net Profit/Loss
                                </h6>
                                <h6
                                  className="fw-bold ms-auto"
                                  style={{
                                    textAlign: "right",
                                    margin: 0,
                                    fontSize: "1.1rem",
                                    color: "#009688",
                                  }}
                                >
                                  USD{" "}
                                  {(
                                    currentYearInvoicesTotal -
                                    totalProductPriceBeforeVat -
                                    selectedYearExpense -
                                    noneYearExpense +
                                    selectedYearIncome
                                  ).toFixed(2)}
                                </h6>
                              </div>
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                }}
                              />
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfitandLoss;
