import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useGetAllGeneralEntryDataByDatePeriodQuery } from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";
import Select from "react-select";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";

const GeneralLedger = () => {
  const [record, setRecord] = useState([]);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");

  const { data: accounts = [], refetch } = useGetAllAccountsQuery();

  function groupEntries(entries) {
    const groupedEntries = {};

    entries.forEach((entry) => {
      // Extract accountId and name from the nested Account object
      const { accountId } = entry;
      const name = entry.Account?.name;
      const type = entry.Account?.type;

      if (!groupedEntries[accountId]) {
        // Initialize a new object with the name and an empty entries array
        groupedEntries[accountId] = {
          name: name,
          type: type,
          entries: [],
        };
      }

      // Push the current entry into the entries array
      groupedEntries[accountId].entries.push(entry);
    });

    // Convert the groupedEntries object back to an array
    return Object.values(groupedEntries);
  }

  const { data: allRecords } = useGetAllGeneralEntryDataByDatePeriodQuery(
    {
      dateTo: dateTo,
      dateFrom: dateFrom,
    },
    { skip: !dateTo || !dateFrom }
  );

  useEffect(() => {
    if (allRecords) {
      const data = groupEntries(allRecords);

      if (data?.length > 0 && selectedAccount) {
        const filterAccountData = data?.filter((account) => {
          return account?.name == selectedAccount;
        });
        setRecord(filterAccountData);
      } else if (data && !selectedAccount) {
        setRecord(data);
      }
    }
  }, [allRecords, selectedAccount]);

  // useEffect(() => {
  //   if (dateTo && dateFrom && allRecords?.length > 0 && selectedAccount) {
  //     const filterAccountData = record?.filter((account) => {
  //       return account?.name == selectedAccount;
  //     });

  //     setRecord(filterAccountData);
  //   }
  // }, [dateFrom, dateTo, selectedAccount]);
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `General_Ledger_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ color: "#009688" }}>
                General Ledger
              </h4>
              <button
                onClick={downloadPDF}
                style={{
                  marginBottom: "20px",
                  background: "transparent",
                  border: "none",
                  padding: 0,
                }}
              >
                <MdOutlineFileDownload
                  style={{ color: "inherit", fontSize: "20px" }}
                />
              </button>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{
                    fromDate: "",
                    toDate: "",
                  }}
                  enableReinitialize={true}
                  // validationSchema={loginSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group mb-3">
                            <label className="text-label">Select Account</label>
                            <Select
                              classNamePrefix="react-select"
                              name="accountname"
                              onChange={(selectedOption) => {
                                setSelectedAccount(selectedOption?.label);
                              }}
                              options={accounts?.map((account) => ({
                                label: account?.name,
                                value: account?.name,
                              }))}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group mb-3">
                            <label className="text-label">Date From</label>
                            <input
                              type="date"
                              name="fromDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("fromDate", e.target.value);
                                setdateFrom(e.target.value);
                              }}
                              onBlur={handleBlur}
                              max={new Date()?.toISOString()?.slice(0, 10)}
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.fromDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group mb-3">
                            <label className="text-label">Date To</label>
                            <input
                              type="date"
                              max={new Date()?.toISOString()?.slice(0, 10)}
                              name="toDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("toDate", e.target.value);
                                setDateTo(e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.toDate}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              <div id="report-content">
                {dateTo && dateFrom && (
                  <div className="row mb-4 align-items-center">
                    <div className="col-6 text-start">
                      <h1 style={{ color: "#009688" }}>Aifa Services</h1>
                    </div>
                    <div className="col-6 text-end">
                      <img
                        src={aifaLogo}
                        alt="logo"
                        style={{
                          width: "130px",
                        }}
                      />
                    </div>
                  </div>
                )}
                {record?.map((account, index) => (
                  <div className="row mb-4" key={index}>
                    <div className="col-lg-12">
                      <div
                        className="card shadow-sm"
                        style={{ backgroundColor: "white" }}
                      >
                        <div
                          className="card-header d-flex justify-content-between align-items-center"
                          style={{ backgroundColor: "#E0E0E0" }}
                        >
                          <h5 className="card-title mb-0">
                            Account: {account.name} ({account?.type})
                          </h5>
                          {/* <span className="text-muted">
                          Account No. {account.accountNumber}
                        </span> */}
                        </div>
                        <div className="card-body">
                          <table className="table table-bordered ">
                            <thead
                              className=""
                              style={{ backgroundColor: "rgb(52 152 219)" }}
                            >
                              <tr>
                                <th
                                  style={{
                                    color: "white",
                                    borderRight: "2px solid white",
                                  }}
                                >
                                  Date
                                </th>
                                <th
                                  style={{
                                    color: "white",
                                    borderRight: "2px solid white",
                                  }}
                                >
                                  Description
                                </th>
                                <th
                                  style={{
                                    color: "white",
                                    borderRight: "2px solid white",
                                  }}
                                >
                                  Debit
                                </th>
                                <th
                                  style={{
                                    color: "white",
                                    borderRight: "2px solid white",
                                  }}
                                >
                                  Credit
                                </th>
                                <th
                                  style={{
                                    color: "white",
                                    borderRight: "2px solid white",
                                  }}
                                >
                                  Balance
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "#d9edf7" }}>
                              {account.entries.map((entry, idx) => (
                                <tr key={idx}>
                                  <td>
                                    {new Date(entry.date).toLocaleDateString(
                                      "en-GB"
                                    )}
                                  </td>
                                  <td>{entry.description}</td>
                                  <td>{entry.debits}</td>
                                  <td>{entry.credits}</td>
                                  <td>{entry.afterBalance}</td>
                                </tr>
                              ))}
                              <tr style={{ fontWeight: "bolder" }}>
                                <td></td>

                                <td>Total</td>
                                <td>
                                  {account.entries
                                    ?.reduce((sum, row) => {
                                      return sum + parseFloat(row?.debits || 0);
                                    }, 0)
                                    ?.toFixed()}
                                </td>
                                <td>
                                  {account.entries
                                    ?.reduce((sum, row) => {
                                      return (
                                        sum + parseFloat(row?.credits || 0)
                                      );
                                    }, 0)
                                    ?.toFixed()}
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralLedger;
