import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import {
  useGetLeavesQuery,
  useUpdateLeaveMutation,
} from "../../../../../features/hrm/leaves/leaveApiSlice";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { Modal, Button } from "react-bootstrap";

import { IMAGES } from "../../../../constant/theme";
import LeaveModal from "./LeaveModal";
import LeaveDetailsModal from "./LeaveDetailsModal";
import EditLeaveModal from "./EditLeaveModal";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";
const AllLeave = () => {
  const { data: leaves = [], refetch } = useGetLeavesQuery();

  const user = useSelector(selectCurrentUser);
  const [updateLeave] = useUpdateLeaveMutation();
  const [show, setShow] = useState(false);
  const [selectedLeave, setSelecetedLeave] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [search, setSearch] = useState(""); // State for search input
  const [filteredLeaves, setFilteredLeaves] = useState([]); // State for filtered leaves

  const handleShow = (leave) => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setShowDetailModal(false);
    setShowEditModal(false);
    setSelecetedLeave(null);
  };

  const handleAccept = (leave) => {
    refetch();
    setShow(false);
    selectedLeave(null);
  };
  const handleReject = (leave) => {
    refetch();
    setShow(false);
    selectedLeave(null);
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // Filter leaves based on the search query
    setFilteredLeaves(
      leaves?.filter((leave) => {
        const firstName = leave?.user?.firstName?.toLowerCase() || "";
        const lastName = leave?.user?.lastName?.toLowerCase() || "";

        const searchTerm = search.toLowerCase();

        return firstName.includes(searchTerm) || lastName.includes(searchTerm);
      })
    );
  }, [leaves, search]);

  const [modal, setModal] = useState(false);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = filteredLeaves?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredLeaves?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = async (leaves) => {
    // Create a copy of the service object
    let updatedLeave = { ...leaves };

    // Update the servicesStatus property
    updatedLeave.isDeleted = true;
    updatedLeave.deletedBy = user?.userId;
    updatedLeave.deleteAt = new Date();

    try {
      const result = await updateLeave({
        leaveId: updatedLeave.leaveId,
        updatedLeave: updatedLeave,
      });
      notifySuccess(result?.data?.message);
      handleClose();
      handleAccept();
    } catch (err) {
      console.error("Failed to update leave: ", err);
    }
  };
  const handleEdit = (leaves) => {
    setShowEditModal(true);
    setSelecetedLeave(leaves);
  };
  const handleView = (leaves) => {
    setShowDetailModal(true);
    setSelecetedLeave(leaves);
  };

  const handleChange = (field, value) => {
    setSelecetedLeave({ ...selectedLeave, [field]: value });
  };

  const handleSave = async () => {
    try {
      const result = await updateLeave({
        leaveId: selectedLeave.leaveId,
        updatedLeave: selectedLeave,
      });
      notifySuccess(result?.data?.message);
      handleClose();
      handleAccept();
    } catch (err) {
      console.error("Failed to update leave: ", err);
    }
  };
  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (leave) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(leave);
      } else {
        swal("Your data is safe!");
      }
    });
  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Name", key: "name" },
    { label: "Type", key: "type" },
    { label: "Reason For Leave", key: "reason" },
    { label: "Leave From", key: "leaveFrom" },
    { label: "Leave To", key: "leaveTo" },
    { label: "Number of Days", key: "numberOfDays" },
    { label: "Status", key: "status" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredLeaves.map((leave, index) => ({
      index: index + 1,
      name: `${leave?.user?.firstName} ${leave?.user?.lastName}`,
      type: leave?.LeaveType?.leaveName,
      reason: leave?.reason,
      leaveFrom: leave?.fromDate
        ? new Date(leave?.fromDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        : "",
      leaveTo: leave?.toDate
        ? new Date(leave?.toDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        : "",
      numberOfDays: leave?.numberOfDays,
      status: leave?.status,
    })),
    filename: "All_Leaves.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">All Leave</h4>
                    <div className="d-flex align-items-center">
                      {filteredLeaves?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <div>
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search"
                          className="form-control me-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Reason For Leave</th>
                          <th>Leave From</th>
                          <th>Leave To</th>
                          <th>Number of Days</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records
                          ?.filter((leave) => {
                            // Assuming `userRole` is available and contains the user's role
                            if (user?.role?.toLowerCase() === "admin") {
                              return true; // Admin sees all records
                            }
                            // For non-admin users, show only their records
                            return leave.userId === user.userId;
                          })
                          ?.map((leave, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {leave?.user?.firstName +
                                  " " +
                                  leave?.user?.lastName}
                              </td>
                              <td>{leave?.LeaveType?.leaveName}</td>
                              <td>{truncateText(leave?.reason, 30)}</td>
                              <td>
                                {leave?.fromDate
                                  ? new Date(
                                      leave?.fromDate
                                    ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })
                                  : null}
                              </td>
                              <td>
                                {leave?.toDate
                                  ? new Date(leave?.toDate).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      }
                                    )
                                  : null}
                              </td>
                              <td>{leave?.numberOfDays}</td>
                              <td>
                                {user?.role == "Admin" &&
                                leave?.status === "Accepted" ? (
                                  <button
                                    type="button"
                                    class="btn btn-success "
                                    disabled
                                  >
                                    Accepted
                                  </button>
                                ) : user?.role == "Admin" &&
                                  leave?.status === "Rejected" ? (
                                  <button
                                    type="button"
                                    class="btn btn-warning "
                                    disabled
                                  >
                                    Rejected
                                  </button>
                                ) : user?.role == "Admin" ? (
                                  <Button
                                    variant={
                                      leave?.status == "Accepted"
                                        ? "primary"
                                        : leave?.status == "Rejected"
                                        ? "danger"
                                        : "danger"
                                    }
                                    onClick={() => {
                                      handleShow(leave);
                                      setSelecetedLeave(leave);
                                    }}
                                  >
                                    {leave?.status}
                                  </Button>
                                ) : (
                                  <>{leave?.status}</>
                                )}
                              </td>

                              <td>
                                {/* View icon */}
                                <span
                                  onClick={() => handleView(leave)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>

                                {/* Edit icon */}
                                {/* {leave?.status == "Pending" && (
                                <span
                                  onClick={() => handleEdit(leave)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )} */}
                                {/* Delete icon */}
                                {/* {user?.role == "Admin" && (
                                <span
                                  onClick={() => SweetAlert(leave)}
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Delete"
                                  ></i>
                                </span>
                              )} */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredLeaves?.length < lastIndex
                          ? filteredLeaves?.length
                          : lastIndex}{" "}
                        of {filteredLeaves?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LeaveModal
        show={show}
        handleClose={handleClose}
        handleAccept={handleAccept}
        handleReject={handleReject}
        data={selectedLeave}
      />
      {showDetailModal && (
        <LeaveDetailsModal
          show={showDetailModal}
          handleClose={handleClose}
          leaveData={selectedLeave}
        />
      )}
      {showEditModal && (
        <EditLeaveModal
          show={showEditModal}
          handleClose={handleClose}
          leaveData={selectedLeave}
          handleChange={handleChange}
          handleSave={handleSave}
        />
      )}
    </>
  );
};

export default AllLeave;
