import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useGetQoutaionQuery } from "../../../../../features/quotaion/quotaionApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { usePostServiceReportMutation } from "../../../../../features/reports/serviceReportApiSlice";
import { useGetAllInvoicesQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import CustomClearIndicator from "../../../Comman/MultiSelect";

const loginSchema = Yup.object().shape({
  leadId: Yup.string().required("Lead ID is required"),
  invoiceNo: Yup.string().required("Invoice No. is required"),
  invoiceDate: Yup.date().required("Invoice Date is required"),
  invoiceDue: Yup.date().required("Invoice Due is required"),
  paymentStatus: Yup.object().required("Payment Status is required"),
  phone: Yup.string().required("Phone is required"),
  discription: Yup.string(),
  email: Yup.string().email("Invalid email").required("email is required"),
  address: Yup.string(),
});

const Create = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectLead, setSelectedLead] = useState(null);
  const [initialValues, setInitialValues] = useState({
    reportNo: generateReportNumber(),
    reportDate: "",
    refNo: "",
    customerId: "",
    phone: "",
    email: "",
    address: "",
    sendMail: false,
    beforeFiles: [],
    afterFiles: [],
    subSeriviceList: [],
    serviceTitle: "",
    serviceType: "",
    unitsServiced: "",
  });

  const { data: allInvoices } = useGetAllInvoicesQuery();

  useEffect(() => {
    const serviceTitle =
      selectLead?.subSeriviceList?.length === 1
        ? selectLead?.subSeriviceList[0]?.subService?.parentServiceName
        : Array.from(
            new Set(
              selectLead?.subSeriviceList?.map(
                (service) => service.subService.parentServiceName
              )
            )
          ).map((uniqueName) => {
            const service = selectLead?.subSeriviceList.find(
              (s) => s.subService.parentServiceName === uniqueName
            );
            return {
              label: uniqueName,
            };
          }).length === 1
        ? Array.from(
            new Set(
              selectLead?.subSeriviceList?.map(
                (service) => service.subService.parentServiceName
              )
            )
          ).map((uniqueName) => {
            const service = selectLead?.subSeriviceList.find(
              (s) => s.subService.parentServiceName === uniqueName
            );
            return uniqueName;
          })
        : "";

    setInitialValues((prevValues) => ({
      ...prevValues,
      refNo: selectLead?.invoiceNo || "",
      customerId: selectLead?.customerId || "",
      phone: selectLead?.customer?.phone || "",
      email: selectLead?.customer?.email || "",
      address: selectLead?.customer?.mailingAddress || "",
      subSeriviceList: selectLead?.subSeriviceList || [],
      serviceTitle:
        Array.isArray(serviceTitle) && serviceTitle.length == 1
          ? serviceTitle[0]
          : serviceTitle || "",
      serviceType:
        Array.from(
          new Set(
            selectLead?.subSeriviceList?.map(
              (service) => service.subService.parentServiceName
            )
          )
        ).join(", ") || "",
      unitsServiced: "",
    }));
  }, [selectLead]);

  const paidInvoices = allInvoices?.invoices.filter(
    (invoice) => invoice?.paymentStatus == "Paid"
  );

  const [postServiceReport, { isLoading }] = usePostServiceReportMutation();

  const navigate = useNavigate();

  const { data: qoutaions = [] } = useGetQoutaionQuery();
  const [content, setContent] = useState(`
  <head>
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 20px;
          }
          .container {
              width: 80%;
              margin: auto;
              padding: 20px;
          }
          .section {
              margin-bottom: 20px;
          }
          .section h2 {
              padding-bottom: 5px;
          }
          .customer-details,
          .scope-of-work,
          .exclude-scope,
          .payment,
          .terms-conditions,
          .bank-details,
          .thank-you-note {
              margin-bottom: 20px;
          }
          .scope-table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
          }
          .scope-table th,
          .scope-table td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
          }
      </style>
  </head>
  <body>
      <div class="container">
          <div class="section scope-of-work">
              <h2>Scope of Work</h2>
              <table class="scope-table"></table>
          </div>
          <div class="section exclude-scope">
              <h2>Exclude Scope</h2>
              <p>[Details]</p>
          </div>
          <div class="section payment">
              <h2>Payment</h2>
              <p>[Payment Terms]</p>
          </div>
          <div class="section terms-conditions">
              <h2>General Terms & Conditions</h2>
              <p>[Terms & Conditions]</p>
          </div>
          <div class="section bank-details">
              <h2>Bank Details</h2>
              <p>[Bank Name]</p>
              <p>[Account Number]</p>
              <p>[IFSC Code]</p>
          </div>
          <div class="section thank-you-note">
              <h2>Thank You Note</h2>
              <p>[Thank You Message]</p>
          </div>
      </div>
  </body>
  </html>
  `);

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleSubmitted = async (values) => {
    const formData = new FormData();

    formData.append("reportNo", values.reportNo);
    formData.append("reportDate", values.reportDate);
    formData.append("invoiceId", selectLead?.invoiceId);
    formData.append("customerFirstName", selectLead?.customer?.firstname);
    formData.append("customerLastName", selectLead?.customer?.lastname);
    formData.append("ref", selectLead?.invoiceNo);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("address", values.address);
    formData.append("sendMail", values.sendMail);
    formData.append("notes", content);
    formData.append("serviceTitle", values.serviceTitle);
    formData.append("serviceType", values.serviceType);
    formData.append("unitsServiced", values.unitsServiced);
    // Append beforeFiles
    values.beforeFiles.forEach((file) => {
      formData.append("beforeFiles", file);
    });

    // Append afterFiles
    values.afterFiles.forEach((file) => {
      formData.append("afterFiles", file);
    });

    try {
      const result = await postServiceReport(formData);
      notifySuccess(result?.data?.message);
      navigate(`/${user?.role?.toLowerCase()}/service-report`);
    } catch (error) {
      notifyError("An error occurred while creating service report");
    }
  };

  function generateReportNumber() {
    const randomNumber = Math.floor(Math.random() * 100);
    const currentDate = new Date()
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "");
    const reportNumber = `#${"PW"}${currentDate}${randomNumber}`;
    return reportNumber;
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Create Service Report</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Report No.</label>
                              <input
                                type="text"
                                className="form-control"
                                name="reportNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.reportNo}
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Report Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="reportDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.reportDate}
                                required
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Ref No</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Enter Ref No"
                                name="refNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.refNo}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.refNo && errors.refNo}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="text-label">Customer</label>
                            <Select
                              id="val-service"
                              name="quotaionId"
                              onChange={(selectedOption) => {
                                setSelectedLead(selectedOption.value);

                                // handleChange({
                                //   target: {
                                //     name: "quotaionId",
                                //     value: selectedOption,
                                //   },
                                // });
                              }}
                              onBlur={handleBlur}
                              // value={{
                              //   value: values.quotaionId,
                              //   label: selectLead
                              //     ? `${selectLead?.customer?.firstname} ${selectLead?.customer?.lastname}`
                              //     : "",
                              // }}
                              options={paidInvoices?.map((invoice) => ({
                                value: invoice,
                                label: `${invoice?.customer?.firstname} ${invoice?.customer?.lastname}`,
                              }))}
                              placeholder="Select Customer"
                            />
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.quotaionId && errors.quotaionId}
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Phone</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="phone"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Email</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.email && errors.email}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Address</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Services</label>
                              <CustomClearIndicator
                                data={
                                  values?.subSeriviceList?.map((service) => ({
                                    value: service.subService.serviceId,
                                    label: service.subService.parentServiceName,
                                  })) || []
                                }
                                type={"services"}
                                value={values?.subSeriviceList?.map(
                                  (service) => ({
                                    value: service.subService.serviceId,
                                    label: service.subService.name,
                                  })
                                )}
                                isView={true}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Parent Services Name
                              </label>
                              <CustomClearIndicator
                                data={
                                  values?.subSeriviceList?.map((service) => ({
                                    value: service.subService.serviceId,
                                    label: service.subService.parentServiceName,
                                  })) || []
                                }
                                type={"services"}
                                value={Array.from(
                                  new Set(
                                    values?.subSeriviceList?.map(
                                      (service) =>
                                        service.subService.parentServiceName
                                    )
                                  )
                                ).map((uniqueName) => {
                                  const service = values?.subSeriviceList.find(
                                    (s) =>
                                      s.subService.parentServiceName ===
                                      uniqueName
                                  );
                                  return {
                                    value: service.subService.serviceId,
                                    label: uniqueName,
                                  };
                                })}
                                isView={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Service Title
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Enter Service Title"
                                name="serviceTitle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.serviceTitle}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.serviceTitle && errors.serviceTitle}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Service Type</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Enter Service Type"
                                name="serviceType"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.serviceType}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.serviceType && errors.ServiceType}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Units Serviced
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Enter Units Serviced"
                                name="unitsServiced"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.unitsServiced}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.unitsServiced && errors.unitsServiced}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <Field
                                type="checkbox"
                                className="form-check-input"
                                name="sendMail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.sendMail}
                              />
                              <label className="form-check-label">
                                Are you sure to send mail ?
                              </label>

                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.sendMail && errors.sendMail}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <ReactQuill
                              value={content}
                              onChange={handleContentChange}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <div className="col">
                            <label className="text-label">
                              Upload Before Images
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="beforeFiles"
                              onChange={(event) => {
                                const files = event.target.files;
                                let myFiles = Array.from(files);
                                setFieldValue("beforeFiles", [
                                  ...values.beforeFiles,
                                  ...myFiles,
                                ]);
                                event.target.value = null;
                              }}
                              multiple
                            />
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.beforeFiles && errors.beforeFiles}
                            </div>
                            <div className="row mt-2">
                              {values.beforeFiles?.map((file, index) => (
                                <div
                                  key={index}
                                  className="col-3 position-relative"
                                >
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`Before ${index}`}
                                    className="img-thumbnail"
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-danger position-absolute top-0 end-0"
                                    onClick={() => {
                                      const newFiles =
                                        values.beforeFiles.filter(
                                          (_, i) => i !== index
                                        );
                                      setFieldValue("beforeFiles", newFiles);
                                    }}
                                  >
                                    &times;
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col">
                            <label className="text-label">
                              Upload After Images
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="afterFiles"
                              onChange={(event) => {
                                const files = event.target.files;
                                let myFiles = Array.from(files);
                                setFieldValue("afterFiles", [
                                  ...values.afterFiles,
                                  ...myFiles,
                                ]);
                                event.target.value = null;
                              }}
                              multiple
                            />
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.afterFiles && errors.afterFiles}
                            </div>
                            <div className="row mt-2">
                              {values.afterFiles?.map((file, index) => (
                                <div
                                  key={index}
                                  className="col-3 position-relative"
                                >
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`After ${index}`}
                                    className="img-thumbnail"
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-danger position-absolute top-0 end-0"
                                    onClick={() => {
                                      const newFiles = values.afterFiles.filter(
                                        (_, i) => i !== index
                                      );
                                      setFieldValue("afterFiles", newFiles);
                                    }}
                                  >
                                    &times;
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
