import React, { useEffect, useState } from "react";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { styles } from "./style";
import axios from "axios";
import { dateFormat } from "../../../helpers/DateFormat";
import { BsFillChatRightFill } from "react-icons/bs";
import { io } from "socket.io-client";
import { IoIosFlash } from "react-icons/io";
import { CgSearch } from "react-icons/cg";
const socket = io("https://api.beyondaio.com");
// const socket = io("http://localhost:8080");

function ChatSidebar({
  setActiveConversation,
  activeConversation,
  setActiveTab,
}) {
  const [conversations, setConversations] = useState([]);
  const [groupedConversations, setGroupedConversations] = useState([]);
  const [activeUserId, setActiveUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    socket.emit("adminJoin");

    socket.on("newChat", (data) => {
      setConversations((prevConversations) => [
        ...prevConversations,
        data.data.conversation,
      ]);

      if (data) {
        fetchConversations();
        localStorage.setItem("newChat", JSON.stringify(data.data.conversation));
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}chatbot/conversation/all`
    );
    const data = response?.data?.conversations || [];
    setConversations(data);

    const grouped = data.reduce((acc, conversation) => {
      const userId = conversation?.members[0]?.user?.userId;
      if (!acc[userId]) {
        acc[userId] = [];
      }
      acc[userId].push(conversation);
      return acc;
    }, {});

    setGroupedConversations(Object.values(grouped));
  };

  const handleUserClick = (userId) => {
    if (activeUserId === userId) {
      setActiveUserId(null);
    } else {
      setActiveUserId(userId);
    }
  };

  const renderGroupedConversations = () => {
    const filteredConversations = groupedConversations.filter(
      (userConversations) => {
        const firstConversation = userConversations[0];
        const user = firstConversation?.members[0]?.user;
        return user?.name.toLowerCase().includes(searchQuery.toLowerCase());
      }
    );

    return filteredConversations.map((userConversations) => {
      const firstConversation = userConversations[0];
      const user = firstConversation?.members[0]?.user;
      const userId = user?.userId;

      return (
        <div key={userId}>
          <div
            onClick={() => handleUserClick(userId)}
            style={styles.listItem(activeUserId === userId)}
          >
            <img
              src={user?.profilePicture}
              alt="Avatar"
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
            <div style={{ flexGrow: 1 }}>
              <h6 style={{ margin: "0" }}>
                {user?.name.length > 30
                  ? user?.name.slice(0, 30) + "..."
                  : user?.name}
              </h6>
              <small
                style={{
                  color: "#888",
                  fontSize: "12px",
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {dateFormat(firstConversation?.createdAt)}
              </small>
              {userConversations.some(
                (conversation) => !conversation.isRead
              ) && (
                <IoIosFlash
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    fontSize: "20px",
                    color: "#0d8a6b",
                  }}
                />
              )}
            </div>
            {userConversations.length > 1 && (
              <span style={{ marginLeft: "auto" }}>
                {activeUserId === userId ? (
                  <FaChevronDown size={12} color="#888" />
                ) : (
                  <FaChevronRight size={12} color="#888" />
                )}
              </span>
            )}
          </div>
          {activeUserId === userId && (
            <div style={{ paddingLeft: "20px" }}>
              {userConversations.map((conversation, index) => (
                <div
                  key={conversation?.conversationId}
                  onClick={() => {
                    setActiveConversation(conversation);
                    setActiveTab("details");
                  }}
                  style={styles.listItem(
                    activeConversation?.conversationId ===
                      conversation.conversationId
                  )}
                >
                  <small
                    style={{
                      color: "#555",
                      fontSize: "18px",
                      fontFamily: "Plus Jakarta Sans",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BsFillChatRightFill />
                    <span style={{ fontSize: "12px", marginLeft: "4px" }}>
                      {new Date(conversation.createdAt).toLocaleString(
                        "en-US",
                        {
                          dateStyle: "medium",
                          timeStyle: "short",
                        }
                      )}
                    </span>{" "}
                    {!conversation.isRead && (
                      <IoIosFlash
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          fontSize: "14px",
                          color: "#65dd13",
                        }}
                      />
                    )}
                  </small>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div style={styles.sidebar}>
      <div
        style={{
          boxShadow: "0 0 9px 1px rgba(0,0,0,0.1)",
          padding: "1.5rem",
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <img src="/assets/images/footer-logo.png" alt="Avatar" />
        <p
          style={{
            marginBottom: "-10px",
            marginLeft: "5px",
            fontSize: "25px",
          }}
        >
          Conversations
        </p>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #ddd",
          margin: "10px",
          borderRadius: "7px",
          padding: "10px 7px",
        }}
      >
        <CgSearch style={{ fontSize: "20px", marginRight: "5px" }} />
        <input
          type="text"
          placeholder="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: "100%",

            fontSize: "14px",
            borderRadius: "5px",
            outline: "none",
            border: "none",
          }}
        />
      </div>
      <div
        style={{
          margin: "10px 0",
          height: "100%",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        {renderGroupedConversations()}
      </div>
    </div>
  );
}

export default ChatSidebar;
