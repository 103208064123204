import Index from "./jsx";
/// Style
import "./jsx/other/swiper/swiper-bundle.min.css";
import "./css/style.css";

function App(props) {
  return <Index />;
}

export default App;
