// Edit.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import CustomClearIndicator from "../../Comman/MultiSelectEdit";
import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import { useGetRoleQuery } from "../../../../features/userManagement/userManagementApiSlice";
import Select from "react-select";
import { useGetSubServicesQuery } from "../../../../features/subservice/subserviceApiSlice";
import { useGetSupervisersQuery } from "../../../../features/superviser/superviserApiSlice";
import {
  useUpdateEmployeeMutation,
  useGetSingleEmployeeQuery,
} from "../../../../features/employee/employeeApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import "react-tagsinput/react-tagsinput.css";
import { useParams } from "react-router-dom";

const Edit = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [showPassword, setShowPassword] = useState(false);
  const [subServiceData, setSubServiceData] = useState([]);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [putCustomer] = useUpdateEmployeeMutation();
  const { employeeId } = useParams();
  const storedUser = localStorage.getItem("user");
  const [selectedRole, setSelectedRole] = useState();
  const [firstInitialValue, setFirstInitialValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    bankDetails: "",
    passport: "",
    dateOfBirth: "",
    address: "",
    gender: "",
    // services: [],
    // subservices: [],
    role: {},
    profilePicture: null,
    supervisersWithServiceId: [],
  });

  const ownerRole = storedUser?.role;
  const navigate = useNavigate();
  const [profilePic, setProfilePicture] = useState(null);
  const [superviserInfo, setSuperviserInfo] = useState(null);

  const [selectServices, setSelectedServices] = useState([]);
  const [selectSubServices, setSelectedSubServices] = useState([]);
  const { data: services = [] } = useGetServicesQuery();
  const { data: roles = [] } = useGetRoleQuery();
  const { data: subservices = [] } = useGetSubServicesQuery();
  const { data: supervisers = [], refetch: refetchSupervisor } =
    useGetSupervisersQuery();
  const { data: users, refetch } = useGetSingleEmployeeQuery(employeeId);

  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [isView, setIsView] = useState(false);
  const [rendringData, setRendringData] = useState([
    "firstname",
    "lastname",
    "email",
    "password",
    "mobile",
    "dateOfBirth",
    "passport",
    "visa",
    "emiratesId",
    "passportExpireDate",
    "visaExpireDate",
    "emiratesIdExpireDate",
    "emergencyContactName",
    "emergencyContactPhoneNumber",
    "emergencyContactRelation",
    "role",
    "gender",
    "address",
  ]);
  const handleServices = () => {
    if (users?.user?.role === "Supervisor") {
      if (users?.user?.services) {
        const filteredServices = services?.services?.filter((service) =>
          users?.user?.services?.includes(service.serviceId)
        );

        if (filteredServices) {
          setSelectedServices(filteredServices);
          const serviceIds = filteredServices.map(
            (service) => service.serviceId
          );
          return serviceIds;
        }
        return [];
      }
      return [];
    }
  };

  // useEffect(() => {
  //   refetch();
  //   refetchSupervisor();
  // }, [refetch, users]);
  useEffect(() => {
    // setSelectedUserRole(userRoleObject);

    setFirstInitialValue({
      firstname: users?.user?.firstName || "",
      lastname: users?.user?.lastName || "",
      email: users?.user?.email || "",
      mobile: users?.user?.mobile || "",
      bankDetails: users?.user?.bankDetails || "",
      passport: users?.user?.passport || "",
      passportExpireDate: users?.user?.passportExpireDate || "",
      visa: users?.user?.visa || "",
      visaExpireDate: users?.user?.visaExpireDate || "",
      emiratesId: users?.user?.emiratesId,
      emiratesIdExpireDate: users?.user?.emiratesIdExpireDate
        ? new Date(users?.user?.emiratesIdExpireDate)
            .toISOString()
            .split("T")[0]
        : "",
      dateOfBirth: users?.user?.dateOfBirth
        ? new Date(users?.user?.dateOfBirth).toISOString().split("T")[0]
        : "",
      address: users?.user?.address || "",
      gender: users?.user?.gender || "",
      emergencyContactName: users?.user?.emergencyContactName || "",
      emergencyContactPhoneNumber:
        users?.user?.emergencyContactPhoneNumber || "",
      emergencyContactRelation: users?.user?.emergencyContactRelation || "",
      // services: Services,

      role: { value: users?.user?.roleId, label: users?.user?.role },
      profilePicture: null,
      // supervisersWithServiceId: [],
    });

    setSelectedRole(users?.user?.Role);

    if (users?.user?.supervisorId) {
      const userInfo = supervisers?.users?.find(
        (user) => user.userId === users?.user?.supervisorId
      );

      if (userInfo) {
        setSuperviserInfo(userInfo);
        const roleIndex = rendringData.indexOf("role");
        const updatedData = [...rendringData];
        const userServicesIds = userInfo?.services;
        setRendringData(updatedData);
      }
    }
  }, [users]);

  useEffect(() => {
    // Find the index of "role" in the array
    const roleIndex = rendringData.indexOf("role");

    // Create a copy of the array
    let updatedData = [...rendringData];

    if (!selectedRole?.supervisorRequired) {
      const stringsToRemove = ["superviser"];
      updatedData = updatedData.filter(
        (item) => !stringsToRemove.includes(item)
      );
      // if (!updatedData.includes("services")) {
      //   updatedData.splice(roleIndex + 1, 0, "services");
      // }
    } else if (selectedRole?.supervisorRequired) {
      const stringsToRemove = ["services"];
      updatedData = updatedData.filter(
        (item) => !stringsToRemove.includes(item)
      );
      if (!updatedData.includes("superviser")) {
        updatedData.splice(roleIndex + 1, 0, "superviser");
      }
    } else {
      const stringsToRemove = ["superviser", "services"];
      updatedData = updatedData.filter(
        (item) => !stringsToRemove.includes(item)
      );
    }

    // Update the rendringData state
    setRendringData(updatedData);
  }, [selectedRole, rendringData, users]);

  const loginSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("First Name is required")
      // .min(3, "First Name must be at least 3 characters")
      .max(50, "First Name must be less than 50 characters"),
    lastname: Yup.string()
      .required("Last Name is required")
      // .min(3, "Last Name must be at least 3 characters")
      .max(50, "Last Name must be less than 50 characters"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .notRequired(), // Password is optional
    mobile: Yup.string()
      .min(9, "Mobile Number must be at least 9 characters")
      .max(15, "Mobile Number must be less than 15 characters")
      .matches(/^\+?\d{9,15}$/, "Invalid Mobile Number")
      .required("Mobile Number is required"),
    bankDetails: Yup.string().notRequired(), // Bank details are optional
    passport: Yup.string()
      .required("Passport Number is required")
      .max(20, "Passport number must be less than 20 characters"),
    dateOfBirth: Yup.date().notRequired(), // Date of Birth is optional
    address: Yup.string().notRequired(), // Address is optional
    gender: Yup.string().notRequired(), // Gender is optional
    // services: Yup.array().notRequired(), // Services are optional
    role: Yup.object().required("Role is required"),
    emiratesId: Yup.string()
      .required("Emirates ID is required")
      .matches(/^\d{15}$/, "Emirates ID must be exactly 15 digits")
      .max(20, "Emirates ID Number must be less than 20 digits"),
    emiratesIdExpireDate: Yup.date().required(
      "Emirates ID Expire Date is required"
    ),
    visa: Yup.string()
      .required("Visa Number is required")
      .max(20, "Visa number must be less than 20 characters"),
    visaExpireDate: Yup.date().required("Visa Expire Date is required"),
    emergencyContactPhoneNumber: Yup.string()
      .min(9, "Emergency Contact Phone Number must be at least 9 characters")
      .max(15, "Emergency Contact Phone Number must be less than 15 characters")
      .matches(/^\+?\d{9,15}$/, "Invalid Emergency Contact Phone Number")
      .optional("Emergency Contact Phone Number is required"),

    emergencyContactRelation: Yup.string().optional(
      "Emergency Contact Relation Name is required"
    ),

    profilePicture: Yup.mixed()
      .notRequired() // Profile picture is optional
      .test(
        "fileSize",
        "File size is too large (max 5MB)",
        (value) => !value || (value && value.size <= 5 * 1024 * 1024)
      ),
  });

  firstInitialValue.supervisersWithServiceId = supervisers?.users;

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
      const updatedData = [...rendringData];

      // Remove "password" from updatedData
      const filteredData = updatedData.filter(
        (fieldName) => fieldName !== "password"
      );

      setRendringData(filteredData);
    }
  }, [isView]);

  const [postEmployee, { isLoading }] = useUpdateEmployeeMutation();

  const capitalizeFirstLetter = (str) => {
    if (str === "firstname") {
      return "First Name";
    } else if (str === "lastname") {
      return "Last Name";
    } else if (str === "lastname") {
      return "Last Name";
    } else if (str === "bankDetails") {
      return "Bank Details";
    } else if (str === "dateOfBirth") {
      return "Date Of Birth";
    } else if (str === "superviser") {
      return "Supervisor";
    } else if (str === "passportExpireDate") {
      return "Passport Expire Date";
    } else if (str === "visaExpireDate") {
      return "Visa Expire Date";
    } else if (str === "emiratesId") {
      return "Emirates ID Number";
    } else if (str === "visa") {
      return "Visa Number";
    } else if (str === "emiratesIdExpireDate") {
      return "Emirates ID Expire Date";
    } else if (str === "passport") {
      return "Passport Number";
    } else if (str === "emergencyContactName") {
      return "Emergency Contact Name";
    } else if (str === "emergencyContactPhoneNumber") {
      return "Emergency Contact  Number";
    } else if (str === "emergencyContactRelation") {
      return "Emergency Contact Relation";
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  };
  const handleSubmitted = async (values) => {
    // return
    const copyData = values;

    values.role = selectedRole?.roleName;
    values.roleId = selectedRole?.roleId;
    values.firstName = values?.firstname;
    values.lastName = values?.lastname;
    if (values?.superviser?.firstName && values?.superviser?.lastName) {
      values.supervisorId = values?.superviser?.value;
    }

    if (selectedRole?.supervisorRequired) {
      values.supervisorId = superviserInfo?.userId;
    } else {
      delete values.supervisorId;
    }

    putCustomer({
      id: employeeId,
      employeeData: values,
    })
      .unwrap()
      .then((response) => {
        notifySuccess(`User update successfully`);
        refetch();

        navigate("/" + user?.role?.toLowerCase() + "/employee");
      })
      .catch((error) => {
        notifyError(
          error?.data?.message || error?.message || "Error updating user"
        );
        console.error("Error updating user", error);
      });
  };

  const handleSuperviserChange = (value) => {
    const roleIndex = rendringData.indexOf("role");
    const updatedData = [...rendringData];

    const supperviserData = supervisers?.users?.find(
      (user) => user?.userId === value?.value
    );

    const userServicesIds = supperviserData?.services;

    setRendringData(updatedData);
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {isView ? "View " : "Edit "} Employee
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={firstInitialValue}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {rendringData?.map((fieldName, index) => (
                            <div
                              key={index}
                              className={`form-group mb-3 col-xl-4`}
                            >
                              <label className="text-label">
                                {capitalizeFirstLetter(fieldName)}
                              </label>
                              {fieldName === "role" && (
                                <>
                                  <Select
                                    name={fieldName}
                                    options={roles
                                      ?.filter(
                                        (role) => role?.roleName != "Admin"
                                      )
                                      ?.map((role) => ({
                                        value: role?.roleId,
                                        label: role?.roleName,
                                        role: role,
                                      }))}
                                    value={values?.role}
                                    onChange={(selectedOption) => {
                                      setSelectedUserRole(selectedOption); // Update the selected value
                                      setFieldValue("role", selectedOption); // Update Formik field value
                                      setSelectedRole(selectedOption?.role);
                                    }}
                                    onBlur={handleBlur}
                                    isSearchable={true}
                                    isDisabled={isView}
                                    style={{
                                      lineHeight: "40px",
                                      color: "#7e7e7e",
                                      paddingLeft: "15px",
                                    }}
                                  />
                                  <div
                                    id={`val-${fieldName}-error`}
                                    className="invalid-feedback animated fadeInUp"
                                    style={{
                                      display:
                                        touched[fieldName] && errors[fieldName]
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <ErrorMessage name={fieldName} />
                                  </div>
                                </>
                              )}

                              {fieldName === "gender" && (
                                <Select
                                  options={genderOptions}
                                  value={genderOptions.find(
                                    (option) => option.value === values.gender
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "gender",
                                      selectedOption.value
                                    )
                                  }
                                  placeholder="Select gender"
                                  isDisabled={isView}
                                />
                              )}
                              {fieldName === "address" && (
                                <div className="input-group">
                                  <textarea
                                    className="form-control"
                                    id={`val-${fieldName}`}
                                    placeholder={`${capitalizeFirstLetter(
                                      fieldName
                                    )}`}
                                    name={fieldName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values[fieldName]}
                                    disabled={isView}
                                  />
                                  <div
                                    id={`val-${fieldName}-error`}
                                    className="invalid-feedback animated fadeInUp"
                                    style={{
                                      display:
                                        touched[fieldName] && errors[fieldName]
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <ErrorMessage name={fieldName} />
                                  </div>
                                </div>
                              )}

                              {fieldName === "superviser" && (
                                <Select
                                  name={fieldName}
                                  onChange={(value) => {
                                    setFieldValue("superviser", value);
                                    handleSuperviserChange(value);

                                    setSuperviserInfo({
                                      firstName: value?.firstName,
                                      lastName: value?.lastName,
                                      userId: value?.value,
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  options={supervisers?.users
                                    ?.filter((user) => {
                                      return user?.userId != employeeId;
                                    })
                                    ?.map((user) => ({
                                      value: user.userId,
                                      label: `${user.firstName} ${user.lastName}`,
                                      firstName: user.firstName,
                                      lastName: user.lastName,
                                    }))}
                                  value={
                                    superviserInfo
                                      ? {
                                          value: superviserInfo?.userId,
                                          label: `${superviserInfo?.firstName} ${superviserInfo?.lastName}`,
                                        }
                                      : null
                                  }
                                  isDisabled={isView}
                                  style={{
                                    lineHeight: "40px",
                                    color: "#7e7e7e",
                                    paddingLeft: "15px",
                                  }}
                                />
                              )}

                              {fieldName !== "role" &&
                                // fieldName !== "services" &&
                                fieldName !== "gender" &&
                                fieldName !== "address" &&
                                fieldName !== "superviser" && (
                                  <div className="input-group">
                                    <>
                                      <input
                                        type={
                                          fieldName === "password" &&
                                          !showPassword
                                            ? "password"
                                            : fieldName === "mobile"
                                            ? "tel"
                                            : fieldName === "profilePicture"
                                            ? "file"
                                            : fieldName === "dateOfBirth"
                                            ? "date"
                                            : fieldName === "visaExpireDate"
                                            ? "date"
                                            : fieldName === "passportExpireDate"
                                            ? "date"
                                            : fieldName ===
                                              "emiratesIdExpireDate"
                                            ? "date"
                                            : "text"
                                        }
                                        className="form-control"
                                        id={`val-${fieldName}`}
                                        placeholder={`${capitalizeFirstLetter(
                                          fieldName
                                        )}`}
                                        name={fieldName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values[fieldName]}
                                        disabled={isView}
                                      />

                                      {fieldName === "password" && (
                                        <div
                                          className="input-group-append"
                                          style={{ cursor: "pointer" }}
                                          onClick={handleTogglePassword}
                                        >
                                          <span className="form-control input-group-text">
                                            {showPassword ? (
                                              <i className="fas fa-eye-slash"></i>
                                            ) : (
                                              <i className="fas fa-eye"></i>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </>
                                    <div
                                      id={`val-${fieldName}-error`}
                                      className="invalid-feedback animated fadeInUp"
                                      style={{
                                        display:
                                          touched[fieldName] &&
                                          errors[fieldName]
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <ErrorMessage name={fieldName} />
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                        {!isView && (
                          <div className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting}
                            >
                              Update
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
