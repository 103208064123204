import React from 'react';
import html2pdf from 'html2pdf.js';

class PDFGenerator extends React.Component {
    generatePDF = () => {
        const element = document.getElementById('pdf-content');
        html2pdf()
            .from(element)
            .save();
    };

    render() {
        return (
            <div>
                <div id="pdf-content">
                    <head>
                        <link
                            href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css"
                            rel="stylesheet"
                        />
                        <script src="https://cdn.tailwindcss.com"></script>
                        <style>{`
                        /* Make text selectable */
                        body {
                            -webkit-user-select: auto !important;
                            -moz-user-select: auto !important;
                            -ms-user-select: auto !important;
                            user-select: auto !important;
                        }
                    `}</style>
                    </head>
                    <body class="bg-white dark:bg-zinc-800 p-4 font-sans dark:text-white">

                        <div className="max-w-full sm:max-w-md md:max-w-lg lg:max-w-4xl mx-auto bg-white dark:bg-zinc-900 shadow-lg p-6">
                            <div className="flex sm:flex-row justify-center items-center mb-6">
                                <div className="w-full sm:w-1/3 mb-4 sm:mb-0">
                                    {/* Change image source to base64 data URL */}
                                    <img
                                        src="https://beyond-projects-files.s3.ca-central-1.amazonaws.com/AIFA/AIFA-Logo.png"
                                        alt="Company Logo"
                                        className="w-full"
                                    />
                                </div>
                            </div>
                            <div>

                                <div class="flex justify-between items-center">

                                    <div class="bg-zinc-100 dark:bg-zinc-700 p-4 rounded-lg mb-4">
                                        <h3 class="text-lg font-semibold mb-2">Customer Details</h3>
                                        <p>Name: John Doe</p>
                                        <p>Email: john.doe@example.com</p>
                                        <p>Address: 1234 Street, City</p>
                                    </div>
                                    <div class="bg-zinc-100 dark:bg-zinc-700 p-4 rounded-lg mb-4">
                                        <h3 class="text-lg font-semibold mb-2">Quotation Details</h3>
                                        <p>Ref #: 12345</p>
                                        <p>Date: 2023-12-01</p>
                                        <p>Valid Until: 2023-12-31</p>
                                        <p>Grand Total: $2000</p>
                                    </div>

                                </div>


                                <h3 class="text-lg font-semibold mb-2 text-center">Subject: Deep Cleaning</h3>
                                <h4 class="text-md font-semibold mb-2">Scope of Work</h4>
                                <table class="w-full mb-4">
                                    <thead>
                                        <tr class="bg-zinc-200 dark:bg-zinc-600">
                                            <th class="p-2">S/N</th>
                                            <th class="p-2">Frequency</th>
                                            <th class="p-2">Description</th>
                                            <th class="p-2">Qty</th>
                                            <th class="p-2">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="p-3">1</td>
                                            <td class="p-3">Monthly</td>
                                            <td class="p-3">Full House Cleaning</td>
                                            <td class="p-3">1</td>
                                            <td class="p-3">$2000</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <h4 class="text-md font-semibold mb-2">Exclude Scope:</h4>
                                    <p>Window Cleaning</p>
                                </div>
                                <div>
                                    <h4 class="text-md font-semibold mb-2">Payment:</h4>
                                    <p>Due on completion</p>
                                </div>
                                <div>
                                    <h4 class="text-md font-semibold mb-2">General Terms & Conditions:</h4>
                                    <p>Services are non-refundable</p>
                                </div>
                                <div>
                                    <h4 class="text-md font-semibold mb-2">Bank Details:</h4>
                                    <p>Bank: XYZ Bank</p>
                                    <p>Account: 123456789</p>
                                </div>
                                <div class="text-center mt-6">
                                    <p>Thank you for your business!</p>
                                </div>
                            </div>
                        </div>
                    </body>

                </div>
                <button onClick={this.generatePDF}>Generate PDF</button>
            </div>
        );
    }
}

export default PDFGenerator;
