import React, { Fragment, useState, useEffect } from "react";

import Alert from "react-bootstrap/Alert";
import { Stepper, Step } from "react-form-stepper";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import StepOne from "./StepOne";

import StepThree from "./StepThree";

import { usePostCustomerMutation } from "../../../../features/customer/customerApiSlice";

const Create = () => {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const [goSteps, setGoSteps] = useState(0);
  const [postCustomer, { isLoading }] = usePostCustomerMutation();

  const [firstStepData, setFirstStepData] = useState({
    firstname: "",
    prefix: "Mr",
    lastname: "",
    organization: "",
    title: "",
    email: "",
    phone: "",
    TRN: "",
  });

  const [therdStepData, setTherdStepData] = useState({
    city: "",
    stateOrProvince: "",
    area: "",
    mailingAddress: "",
    country: "",
    webSite: "",
  });

  const [formData, setFormData] = useState({
    serviceId: "",
    firstname: "",
    prefix: "",
    lastname: "",
    organization: "",
    title: "",
    leadsStatus: "",
    superviserId: "",
    leadSource: "",
    phone: "",
    email: "",
    description: "",
    tags: "",
    webSite: "",
    city: "",
    stateOrProvince: "",
    area: "",
    mailingAddress: "",
    country: "",
    TRN: "",
  });

  const handlePost = async (customerData) => {
    try {
      customerData.prefixName = customerData?.prefix;

      // return
      const result = await postCustomer(customerData);
      if (result && result.data.message === "Customer created successfully") {
        notifySuccess(result.data.message);
        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/customer");
      } else if (result?.error?.status == "400") {
        notifyError(result?.error?.data?.message || "An error occurred");
      }
    } catch (error) {}
  };

  const handleSendData = (inputData) => {
    const mergedData = {
      ...firstStepData,
      ...inputData,
    };

    handlePost(mergedData);
    return;
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Customer</h4>
              </div>
              <div className="card-body">
                <Alert variant="primary">
                  <strong>
                    {goSteps === 0
                      ? "Customer Information"
                      : goSteps === 1
                      ? "Address Information"
                      : ""}
                  </strong>
                </Alert>

                <div className="form-wizard ">
                  <>
                    <StepOne
                      values={firstStepData}
                      setFirstStepData={setFirstStepData}
                    />
                  </>

                  <>
                    <StepThree
                      setTherdStepData={setTherdStepData}
                      values={therdStepData}
                      handleSendData={handleSendData}
                      isLoading={isLoading}
                    />
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
