// Create.jsx
import React, { Fragment } from "react";
import { Formik } from "formik";
import Select from "react-select";

import { useGetParentServicesQuery } from "../../../../features/parentService/parentServiceApiSlice";

import { usePostServiceMutation } from "../../../../features/service/serviceApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import { useState } from "react";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Service Name is required"),
  parentServiceName: Yup.string().required("Parent Service is required"),
  startRangePrice: Yup.number()
    .positive("Price must be positive")
    .required("Price is required"),
  endRangePrice: Yup.number()
    .positive("End range price must be positive")
    .nullable(),
  totalPrice: Yup.number().positive("Final price must be positive").nullable(),
  tags: Yup.array(),
  description: Yup.string(),
});

const Create = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [postService, { isLoading }] = usePostServiceMutation();

  const { data: parentServices } = useGetParentServicesQuery();
  const [loader, setLoader] = useState(true);

  const handleSubmitted = async (values) => {
    try {
      const result = await postService(values);

      notifySuccess(result?.data?.message);
      navigate("/" + user?.role?.toLowerCase() + "/service");
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Service </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: "",
                      description: "",
                      parentServiceName: "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Service Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a service name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.parentServices
                                  ? errors.parentServices
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Parent Service Name
                              </label>
                              <Select
                                options={parentServices?.services?.map(
                                  (service) => ({
                                    label: service?.parentServiceName,
                                    value: service?.parentServiceName,
                                  })
                                )}
                                name="parentServiceName"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "parentServiceName",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.parentServiceName &&
                                  errors.parentServiceName}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.startRangePrice
                                  ? errors.startRangePrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Price</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-startRangePrice"
                                placeholder="Enter start range price"
                                name="startRangePrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.startRangePrice}
                              />
                              <div
                                id="val-startRangePrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startRangePrice &&
                                  errors.startRangePrice}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">Description</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Description"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.description && errors.description}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
