// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";

import { useGetLeadsQuery } from "../../../../../features/lead/leadApiSlice";
import {
  useGetSalesPersonQuery,
  usePostInspectionInvoiceMutation,
} from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { useGetQoutaionQuery } from "../../../../../features/quotaion/quotaionApiSlice";
import { usePostQoutaionMutation } from "../../../../../features/quotaion/quotaionApiSlice";
import { useGetStockListQuery } from "../../../../../features/Inventory/stocklist/stockListApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import * as Yup from "yup";
import { useGetInspactedLeadsQuery } from "../../../../../features/lead/leadApiSlice";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import { useGetSubServicesQuery } from "../../../../../features/subservice/subserviceApiSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";

const loginSchema = Yup.object().shape({
  leadId: Yup.string().required("Lead ID is required"),
  invoiceNo: Yup.string().required("Invoice No. is required"),
  invoiceDate: Yup.date().required("Invoice Date is required"),
  invoiceDue: Yup.date().required("Invoice Due is required"),
  // paymentStatus: Yup.object().required("Payment Status is required"),
  // serviceId: Yup.string().required("Service ID is required"),
  // customerId: Yup.string().required("Customer ID is required"),
  phone: Yup.string().optional("Phone is required"),
  discription: Yup.string(),
  email: Yup.string().email("Invalid email").required("email is reuired"),
  address: Yup.string(),
});
function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const Create = () => {
  const userInfo = useSelector(selectCurrentUser);
  const { data: users = [] } = useGetSalesPersonQuery();
  const [selectLead, setSelectedLead] = useState(null);
  const { data: accounts = [] } = useGetAllAccountsQuery();
  const [tax, setTax] = useState(0);
  const [subServicesList, setSubServicesList] = useState([]);
  const [itemss, setItemss] = useState([]);
  const [productTotalDetailPrice, setProductTotalDetailPrice] = useState({
    subtotal: 0,
    totalTax: 0,
    totalDiscount: 0,
    total: 0,
    grandTotal: 0,
  });
  const [initialValues, setInitialValues] = useState({
    leadId: "",
    invoiceNo: generateInvoiceNumber(),
    invoiceDate: "",
    invoiceDue: "",
    TRN: "",
    // paymentStatus: "",
    // serviceId: "",
    customerId: "",
    phone: "",
    description: "",
    email: "",
    address: "",
    sendMail: false,
    invoiceType: "inspection",
    salesAccountId: "",
    receiveableAccountId: "",
  });

  const { data: inspections = [] } = useGetInspactedLeadsQuery({
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();

  const [postInvoice, { isLoading }] = usePostInspectionInvoiceMutation();

  useEffect(() => {
    const updatedProductTotalDetailPrice = {
      subtotal: 0,
      totalTax: 0,
      totalDiscount: 0,
      total: 0,
      grandTotal: 0,
    };

    var calculatedDiscount = 0;
    var calculatedTax = 0;
    const discount = 0;

    const tax = selectLead
      ? (parseFloat(selectLead?.inspection?.cost) * 0.05).toFixed(2)
      : 0;

    updatedProductTotalDetailPrice.totalDiscount = calculatedDiscount;

    updatedProductTotalDetailPrice.totalTax = tax;

    updatedProductTotalDetailPrice.subtotal = parseFloat(
      selectLead?.inspection?.cost
    );

    updatedProductTotalDetailPrice.total = (
      parseFloat(updatedProductTotalDetailPrice.subtotal || 0) +
      parseFloat(tax || 0)
    ).toFixed();

    setProductTotalDetailPrice(updatedProductTotalDetailPrice);
  }, [selectLead]); // Ensure productPercentage is included as a dependency if it's used in the calculation

  const [content, setContent] = useState(`
    <head>
  
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 20px;
            }
            .container {
                width: 80%;
                margin: auto;
               
                padding: 20px;
            }
           
           
          
            .section {
                margin-bottom: 20px;
            }
            .section h2 {
              
                padding-bottom: 5px;
            }
            .customer-details,
            .scope-of-work,
            .exclude-scope,
            .payment,
            .terms-conditions,
            .bank-details,
            .thank-you-note {
                margin-bottom: 20px;
            }
            .scope-table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
            }
            .scope-table th,
            .scope-table td {
                border: 1px solid #000;
                padding: 8px;
                text-align: left;
            }
        </style>
    </head>
    <body>
        <div class="container">
          
           
            <div class="section scope-of-work">
                <h2>Scope of Work</h2>
                <table class="scope-table">
                
                </table>
            </div>
            
            <div class="section exclude-scope">
                <h2>Exclude Scope</h2>
                <p>[Details]</p>
            </div>
            
            <div class="section payment">
                <h2>Payment</h2>
                <p>[Payment Terms]</p>
            </div>
            
            <div class="section terms-conditions">
                <h2>General Terms & Conditions</h2>
                <p>[Terms & Conditions]</p>
            </div>
            
            <div class="section bank-details">
                <h2>Bank Details</h2>
                <p>[Bank Name]</p>
                <p>[Account Number]</p>
                <p>[IFSC Code]</p>
            </div>
            
            <div class="section thank-you-note">
                <h2>Thank You Note</h2>
                <p>[Thank You Message]</p>
            </div>
        </div>
    </body>
    </html>
    
  `);

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleSubmitted = async (values) => {
    values.notes = content;
    values.description = content;
    values.subTotal = productTotalDetailPrice?.subtotal;
    values.grandTotal = productTotalDetailPrice?.total;
    values.estimatedTax = productTotalDetailPrice?.totalTax;
    values.itemDiscounts = productTotalDetailPrice?.totalDiscount;
    values.totalAmount = productTotalDetailPrice?.total;
    values.receiveableAccountId = values?.receiveableAccountId?.value;
    values.salesAccountId = values.salesAccountId?.value;

    try {
      const result = await postInvoice(values);

      notifySuccess(result?.data?.message);
      navigate(`/${userInfo?.role?.toLowerCase()}/invoices`);
    } catch (error) {
      notifyError(error.message);
    }
  };

  function generateInvoiceNumber() {
    const randomNumber = Math.floor(Math.random() * 100); // Generate an 8-digit random number

    const currentDate = new Date()
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "");

    // Combine the prefix, current date, and random number
    const invoiceNumber = `#${"PW"}${currentDate}${randomNumber}`;

    return invoiceNumber;
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Inspection Invoice</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice No.</label>
                              <input
                                type="text"
                                className="form-control"
                                name="invoiceNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceNo}
                                readOnly={true}
                              />
                            </div>
                          </div>

                          {/* Invoice Date */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="invoiceDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceDate}
                                required
                              />
                            </div>
                          </div>

                          {/* Invoice Due */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice Due</label>
                              <input
                                type="date"
                                className="form-control"
                                name="invoiceDue"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceDue}
                                required
                              />
                            </div>
                          </div>

                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.TRN
                                  ? errors.TRN
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">TRN</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="TRN No"
                                name="TRN"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("TRN", data);
                                }}
                                onBlur={handleBlur}
                                value={values.TRN}
                                // disabled={true}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.TRN && errors.TRN}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label className="text-label">Customer</label>

                            <Select
                              id="val-service"
                              name="quotaionId"
                              onChange={(selectedOption) => {
                                const selectedLead = inspections?.leads.find(
                                  (lead) =>
                                    lead.inspectionId ===
                                    selectedOption?.value?.inspectionId
                                );
                                setSelectedLead(selectedLead);

                                setFieldValue(
                                  "phone",
                                  selectedLead?.customer?.phone || ""
                                );

                                setFieldValue(
                                  "email",
                                  selectedLead?.customer?.email || ""
                                );

                                setFieldValue(
                                  "address",
                                  selectedLead?.customer?.mailingAddress || ""
                                );
                                // setFieldValue(
                                //   "serviceId",
                                //   selectedLead?.service?.serviceId || ""
                                // );
                                setFieldValue(
                                  "customerId",
                                  selectedLead?.customerId || ""
                                );
                                setFieldValue(
                                  "leadId",
                                  selectedLead?.leadId || ""
                                );
                                setFieldValue(
                                  "TRN",
                                  selectedLead?.customer?.TRN || ""
                                );
                                setFieldValue(
                                  "inspectionId",
                                  selectedLead?.inspectionId || ""
                                );
                                // setFieldValue(
                                //   "subSeriviceList",
                                //   selectedLead?.subSeriviceList || []
                                // );
                                // handleChange({
                                //   target: {
                                //     name: "quotaionId",
                                //     value: selectedOption.value,
                                //   },
                                // });
                              }}
                              onBlur={handleBlur}
                              value={{
                                value: values.quotaionId,
                                label: selectLead
                                  ? `${selectLead?.customer?.firstname} ${selectLead?.customer?.lastname} - ${selectLead?.inspection?.cost}  USD`
                                  : "",
                              }}
                              options={inspections?.leads?.map((lead) => ({
                                value: lead,
                                label: `${lead?.customer?.firstname} ${lead?.customer?.lastname} - ${lead?.inspection?.cost} USD`,
                              }))}
                            ></Select>
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="quotaionId" />
                            </div>
                          </div>

                          {/* Contact Us */}
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Contact US </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Contact US"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          {/* Email */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <label className="text-label">Sales Account</label>
                            <Select
                              name="salesAccountId"
                              onChange={(selectedOption) => {
                                setFieldValue("salesAccountId", selectedOption); // Pass the whole object
                              }}
                              onBlur={handleBlur}
                              value={
                                values?.salesAccountId
                                  ? {
                                      label: values.salesAccountId?.label,
                                      value: values.salesAccountId?.value,
                                    }
                                  : null
                              }
                              options={accounts
                                ?.filter(
                                  (account) =>
                                    account?.type === "income" &&
                                    account?.detailType === "sales"
                                )
                                ?.map((account) => ({
                                  value: account?.accountId,
                                  label: account?.name,
                                }))}
                              classNamePrefix="react-select" //
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.salesAccountId && errors.salesAccountId}
                            </div>
                          </div>
                          <div className="col-4">
                            <label className="text-label">
                              Receivable Account
                            </label>
                            <Select
                              name="receiveableAccountId"
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "receiveableAccountId",
                                  selectedOption
                                ); // Pass the whole object
                              }}
                              onBlur={handleBlur}
                              value={
                                values.receiveableAccountId
                                  ? {
                                      label: values.receiveableAccountId?.label,
                                      value: values.receiveableAccountId?.value,
                                    }
                                  : null
                              } // Ensure value is properly set
                              options={accounts
                                ?.filter(
                                  (account) =>
                                    account?.type === "Account Receivable A/R"
                                )
                                ?.map((account) => ({
                                  value: account?.accountId,
                                  label: account?.name,
                                }))}
                              classNamePrefix="react-select" // Optional: For better styling
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.receiveableAccountId &&
                                errors.receiveableAccountId}
                            </div>
                          </div>
                          <div className="col-4">
                            <label className="text-label">Sales Person</label>
                            <Select
                              name="salesPersonUserId"
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "salesPersonUserId",
                                  selectedOption
                                );
                              }}
                              onBlur={handleBlur}
                              value={
                                values?.salesPersonUserId
                                  ? {
                                      label: values.salesPersonUserId?.label,
                                      value: values.salesPersonUserId?.value,
                                    }
                                  : null
                              }
                              options={users?.users?.map((user) => ({
                                value: user?.userId,
                                label: `${user?.firstName} ${user?.lastName}`,
                              }))}
                              classNamePrefix="react-select" //
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.salesPersonUserId &&
                                errors.salesPersonUserId}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">Address</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value={values.sendMail}
                                id="flexCheckChecked"
                                name="sendMail"
                                onChange={handleChange}
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Are you sure you want to send an email?
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="text-label">
                              Invoice Details
                            </label>

                            <ReactQuill
                              theme="snow"
                              value={content}
                              onChange={handleContentChange}
                            />
                          </div>
                        </div>

                        {selectLead && (
                          <React.Fragment>
                            <Alert variant="primary">
                              <strong>{"Inspection Information"}</strong>
                            </Alert>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Inspector Name</th>
                                  <th scope="col">Role</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {selectLead?.inspection?.user?.firstName}
                                  </td>
                                  <td>{selectLead?.inspection?.user?.role}</td>
                                  <td>
                                    {selectLead?.inspection?.inspectionDate
                                      ? new Date(
                                          selectLead?.inspection?.inspectionDate
                                        ).toLocaleDateString("en-GB")
                                      : ""}
                                  </td>
                                  <td>
                                    {tConvert(
                                      selectLead?.inspection?.inspectionTime
                                    )}
                                  </td>
                                  <td>
                                    {selectLead?.inspection?.inspectionType}
                                  </td>
                                  <td>
                                    {selectLead?.inspection?.cost == ""
                                      ? 0
                                      : selectLead?.inspection?.cost}{" "}
                                    USD
                                  </td>
                                </tr>
                              </tbody>

                              <tbody
                                class="before:block before:h-3"
                                id="totalAmount"
                                style={{ fontWeight: "bolder" }}
                              >
                                {selectLead !== null && (
                                  <tr className="pt-4">
                                    <td
                                      colspan="4"
                                      className="border-bottom-0"
                                    ></td>
                                    <td>Inspection Amount</td>

                                    <td>
                                      {productTotalDetailPrice?.subtotal} USD
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td
                                    colspan="4"
                                    className="border-bottom-0"
                                  ></td>
                                  <td>VAT</td>
                                  <td>
                                    {productTotalDetailPrice?.totalTax} USD{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    colspan="4"
                                    className="border-bottom-0"
                                  ></td>
                                  <td>Total Amount</td>
                                  <td>{productTotalDetailPrice?.total} USD</td>
                                </tr>
                              </tbody>
                            </table>
                          </React.Fragment>
                        )}

                        <br />

                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
