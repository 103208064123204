import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGetSupplierQuery } from "../../../../../features/supplier/supplierApiSlice";
import {
  useGetPurchaseByYearQuery,
  useGetPurchaseByVendorQuery,
} from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";

const loginSchema = Yup.object().shape({
  supplierId: Yup.string().required("Supplier is required"),
  year: Yup.string().required("Year is required"),
});
const CreateVendorWisePurchase = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const navigate = useNavigate();
  const [year, setYear] = useState("");
  const [supplierId, setSupplierId] = useState("");

  const [filterRecords, setFilterRecords] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission

  const { data: supplier } = useGetSupplierQuery();
  const { data: allPurchases, refetch } = useGetPurchaseByVendorQuery(
    { dateTo: dateTo, dateFrom: dateFrom, supplierId: supplierId },
    { skip: !dateTo || !dateFrom || !supplierId }
  );
  // const { data: allPurchases, refetch } = useGetPurchaseByYearQuery(
  //   { dateTo: dateTo, dateFrom: dateFrom, supplierId: supplierId },
  //   { skip: !dateTo || !dateFrom || !supplierId }
  // );

  useEffect(() => {
    if (dateTo && dateFrom && supplierId) {
      refetch();
    }
  }, [dateFrom, dateTo, supplierId, refetch]);

  // useEffect(() => {
  //   const date = new Date("2024-08-16T06:07:05.877Z");

  //   if (allPurchases?.length == 0) {
  //     setFilterRecords([]);
  //   }
  //   if (allPurchases) {
  //     const data = allPurchases?.filter((item) => {
  //       return (
  //         item?.purchaseOrder?.supplierId === supplierId &&
  //         new Date(item?.createdAt).getFullYear() == year
  //       );
  //     });

  //     setFilterRecords(data);
  //   }
  // }, [allPurchases, refetch, setYear]);
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Vendor_Purchase_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getMonthlyTotal = (month) => {
    const monthlyPurchases = allPurchases?.filter(
      (record) => new Date(record.createdAt).getMonth() === month
    );

    if (!monthlyPurchases?.length) {
      return { total: 0, year: null };
    }

    const total = monthlyPurchases.reduce((acc, curr) => acc + curr.total, 0);
    const year = new Date(monthlyPurchases[0].createdAt).getFullYear();

    return { total, year };
  };

  const handleSubmit = (values, { setSubmitting }) => {
    // Reset formSubmitted to false before setting it to true to trigger re-render
    setFormSubmitted(false);
    setTimeout(() => {
      setFormSubmitted(true);
      setSubmitting(false);
    }, 0);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ color: "#009688" }}>
                  Vendor Wise Purchase Order Summary
                </h4>

                {supplierId && dateFrom && dateTo && (
                  <button className="btn btn-danger" onClick={downloadPDF}>
                    Download PDF
                  </button>
                )}
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      supplierId: "",
                      year: "",
                      dateFrom,
                      dateTo,
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Supplier</label>
                              <Select
                                options={supplier?.suppliers?.map(
                                  (supplier) => ({
                                    value: supplier.supplierId,
                                    label: supplier.name,
                                  })
                                )}
                                name="supplierId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "supplierId",
                                    selectedOption.value
                                  );
                                  setSupplierId(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.supplierId && touched.supplierId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.supplierId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date From</label>
                              <input
                                type="date"
                                name="fromDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("fromDate", e.target.value);
                                  setDateFrom(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.fromDate && touched.fromDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.fromDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date To</label>
                              <input
                                type="date"
                                name="toDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("toDate", e.target.value);
                                  setDateTo(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.toDate && touched.toDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.toDate}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button> */}
                      </form>
                    )}
                  </Formik>

                  {supplierId &&
                  dateFrom &&
                  dateTo &&
                  allPurchases?.length > 0 ? (
                    <div id="report-content">
                      <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                        <h1 style={{ color: "#009688" }}>Aifa Services</h1>
                        <img
                          src={aifaLogo}
                          alt="logo"
                          style={{
                            width: "130px",
                          }}
                        />
                      </div>

                      <div
                        className="card-header "
                        style={{
                          backgroundColor: "rgb(52, 152, 219)",
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h2
                          className="card-title"
                          style={{
                            color: "#fff",
                            marginBottom: "10px",
                            fontSize: "20px",
                          }}
                        >
                          Purchase By Vendor Report
                        </h2>
                      </div>

                      <table
                        id="empoloyeestbl2"
                        className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0 mt-3"
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52, 152, 219)",
                                borderRight: "2px solid white",
                              }}
                            >
                              Year
                            </th>
                            <th
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52, 152, 219)",
                                borderRight: "2px solid white",
                              }}
                            >
                              Month
                            </th>
                            <th
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52, 152, 219)",
                              }}
                            >
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {months?.map((data, index) => {
                            const { total, year } = getMonthlyTotal(index);
                            if (total.toFixed(2) !== "0.00") {
                              return (
                                <tr key={index}>
                                  <td>{year || "N/A"}</td>
                                  <td>{data}</td>
                                  <td>{total.toFixed(2)}</td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : !supplierId || !dateFrom || !dateTo ? null : (
                    <p>No Records found </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateVendorWisePurchase;
