import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetLeaveBalanceQuery,
  useUpdateLeaveBalanceMutation,
} from "../../../../../features/hrm/leaves/leaveApiSlice";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import EditLeaveModal from "./EditLeaveModal";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const LeaveBalance = () => {
  const [selectedLeave, setSelecetedLeave] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const { data: users = [], refetch } = useGetLeaveBalanceQuery();
  const [updateLeaveBalance, { isLoading, isError, isSuccess }] =
    useUpdateLeaveBalanceMutation();
  const [search, setSearch] = useState(""); // Add state for search input
  const [filteredUsers, setFilteredUsers] = useState([]); // Add state for filtered users

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (search === "") {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users?.filter((user) =>
          `${user?.firstName} ${user?.lastName}`
            .toLowerCase()
            .includes(search.toLowerCase())
        )
      );
    }
  }, [search, users]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const loggedInuser = localStorage.getItem("user");

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = filteredUsers?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredUsers?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleEdit = (user) => {
    setShowEditModal(true);
    setSelecetedLeave(user);
  };
  const handleClose = () => {
    setShowEditModal(false);
    setSelecetedLeave(null);
  };
  const handleChange = (field, value) => {
    if (value < selectedLeave?.leavesDetails?.usedLeaves) {
      notifyError("Greater then accepted leaves");
    }
    setSelecetedLeave((prevState) => ({
      ...prevState,
      leavesDetails: {
        ...prevState.leavesDetails,
        [field]: value,
        pendingLeaves: value
          ? value - selectedLeave?.leavesDetails?.usedLeaves
          : 0,
      },
    }));
  };
  const handleSave = async () => {
    try {
      const result = await updateLeaveBalance({
        userId: selectedLeave.userId,
        leavesDetails: selectedLeave?.leavesDetails,
      });
      notifySuccess(result?.data?.message);
      refetch();
      handleClose();
    } catch (err) {
      console.error("Failed to update leave: ", err);
    }
  };
  const [statusPriority, setStatusPriority] = useState(records);

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Name", key: "name" },
    { label: "Pending Leaves", key: "pendingLeaves" },
    { label: "Current Year Leaves", key: "currentYearLeaves" },
    { label: "Total Accepted Leaves", key: "totalAcceptedLeaves" },
    { label: "Total Rejected Leaves", key: "totalRejectedLeaves" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredUsers?.map((user, index) => ({
      index: index + 1,
      name: `${user?.firstName} ${user?.lastName}`,
      pendingLeaves: user?.leavesDetails?.pendingLeaves,
      currentYearLeaves: user?.leavesDetails?.currentYearLeave,
      totalAcceptedLeaves: user?.leavesDetails?.usedLeaves,
      totalRejectedLeaves: user?.leavesDetails?.totalRejectedLeaves,
    })),
    filename: "LeaveBalance.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Leave Balance</h4>
                    <div className="d-flex align-items-center">
                      {filteredUsers?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <div>
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search"
                          className="form-control me-1"
                          style={{ marginLeft: "10px" }} // Adjust the margin as needed
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Pending Leaves</th>
                          <th>Current Year Leaves</th>
                          <th>Total Accepted Leaves</th>
                          <th style={{ textAlign: "center" }}>
                            Total Rejected Leaves
                          </th>
                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((user, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {user?.firstName}
                              {user?.lastName}
                            </td>
                            <td>{user?.leavesDetails?.pendingLeaves}</td>
                            <td>{user?.leavesDetails?.currentYearLeave}</td>
                            <td>{user?.leavesDetails?.usedLeaves}</td>
                            <td style={{ textAlign: "center" }}>
                              {user?.leavesDetails?.totalRejectedLeaves}
                            </td>
                            <td>
                              {JSON.parse(
                                loggedInuser
                              )?.Role?.permissions?.includes(
                                "leave-balance/edit/:leave_balanceId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(user)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredUsers?.length < lastIndex
                          ? filteredUsers?.length
                          : lastIndex}{" "}
                        of {filteredUsers?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditModal && (
        <EditLeaveModal
          show={showEditModal}
          handleClose={handleClose}
          leaveData={selectedLeave}
          handleChange={handleChange}
          handleSave={handleSave}
        />
      )}
    </>
  );
};

export default LeaveBalance;
