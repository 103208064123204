import { apiSlice } from "../../../app/api/apiSlice";

export const PurchasePaymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPurchasePayment: builder.query({
      query: () => "finance/purchase-payment",
      keepUnusedDataFor: 5,
    }),
    getPurchaseBySupplierId: builder.query({
      query: (supplierId) => `finance/purchase-payment/${supplierId}`,
      keepUnusedDataFor: 5,
    }),
    postPurchasePayment: builder.mutation({
      query: (newPurchasePayment) => ({
        url: "finance/purchase-payment",
        method: "POST",
        body: newPurchasePayment,
      }),
    }),
    postVatPayment: builder.mutation({
      query: (newPurchasePayment) => ({
        url: "finance/vat-payment",
        method: "POST",
        body: newPurchasePayment,
      }),
    }),
    // putPurchase: builder.mutation({
    //   query: ({ id, updatedPurchase }) => ({
    //     url: `finance/purchase/${id}`,
    //     method: "PUT",
    //     body: updatedPurchase,
    //   }),
    // }),
  }),
});

export const {
  useGetPurchasePaymentQuery,
  useGetPurchaseBySupplierIdQuery,
  // useGetSinglePurchaseQuery,
  usePostPurchasePaymentMutation,
  usePostVatPaymentMutation,
  // usePutPurchaseMutation,
} = PurchasePaymentApiSlice;
