import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import { useGetAllVatReportByDatePeriodQuery } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
const VATAuditReport = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [invoices, setInvoices] = useState({
    total: 0,
    tax: 0,
    grandTotal: 0,
  });

  const [purchases, setPurchases] = useState({
    total: 0,
    tax: 0,
    grandTotal: 0,
  });

  const { data: allRecords } = useGetAllVatReportByDatePeriodQuery(
    {
      dateTo: dateTo,
      dateFrom: dateFrom,
    },
    { skip: !dateTo || !dateFrom }
  );

  useEffect(() => {
    if (allRecords) {
      const totalPurchasevat = allRecords?.allPurchases
        ?.reduce((sum, item) => {
          return parseFloat(sum) + parseFloat(item?.vat);
        }, 0)
        .toFixed(2);

      const totalPurchaseAmount = allRecords?.allPurchases
        ?.reduce((sum, item) => {
          return parseFloat(sum) + parseFloat(item?.total);
        }, 0)
        .toFixed(2);

      const totalPurchaseAmountWithoutTax = allRecords?.allPurchases
        ?.reduce((sum, item) => {
          return parseFloat(sum) + parseFloat(item?.subTotal);
        }, 0)
        .toFixed(2);

      setPurchases({
        total: totalPurchaseAmountWithoutTax,
        tax: totalPurchasevat,
        grandTotal: totalPurchaseAmount,
      });

      const totalInvoicevat = allRecords?.allInvoices
        ?.reduce((sum, item) => {
          return parseFloat(sum) + parseFloat(item?.estimatedTax);
        }, 0)
        .toFixed(2);

      const totalInvoiceAmount = allRecords?.allInvoices
        ?.reduce((sum, item) => {
          return parseFloat(sum) + parseFloat(item?.totalAmount);
        }, 0)
        .toFixed(2);

      const totalInvoiceAmountWithoutTax = allRecords?.allInvoices
        ?.reduce((sum, item) => {
          return (
            parseFloat(sum) +
            (parseFloat(item?.totalAmount) - parseFloat(item?.estimatedTax))
          );
        }, 0)
        .toFixed(2);

      setInvoices({
        total: totalInvoiceAmountWithoutTax,
        tax: totalInvoicevat,
        grandTotal: totalInvoiceAmount,
      });
    }
  }, [allRecords]);

  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `VAT_Audit_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title" style={{ color: " #009688" }}>
                VAT Audit Report{" "}
              </h3>
              <button
                onClick={downloadPDF}
                style={{
                  background: "transparent",
                  border: "none",
                  padding: 0,
                }}
              >
                <MdOutlineFileDownload
                  style={{ color: "inherit", fontSize: "20px" }}
                />
              </button>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{
                    fromDate: "",
                    toDate: "",
                  }}
                  enableReinitialize={true}
                  // validationSchema={loginSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Date From</label>
                            <input
                              type="date"
                              name="fromDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("fromDate", e.target.value);
                                setdateFrom(e.target.value);
                              }}
                              onBlur={handleBlur}
                              max={new Date()?.toISOString()?.slice(0, 10)}
                            />
                            {errors.fromDate && touched.fromDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.fromDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="text-label">Date To</label>
                            <input
                              type="date"
                              max={new Date()?.toISOString()?.slice(0, 10)}
                              name="toDate"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("toDate", e.target.value);
                                setDateTo(e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.toDate && touched.toDate && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.toDate}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>

              {dateFrom && dateTo && (
                <div id="report-content">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h2
                      className="card-title"
                      style={{
                        color: "#fff",
                        marginBottom: "10px",
                        fontSize: "20px",
                      }}
                    >
                      VAT Audit Report
                    </h2>
                    <img
                      src={aifaLogo}
                      alt="logo"
                      style={{
                        width: "130px",
                        marginLeft: "auto",
                        marginLeft: "20px",
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card mt-3">
                        <div
                          className="card-header "
                          style={{
                            backgroundColor: "rgb(52, 152, 219)",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h2
                            className="card-title"
                            style={{
                              color: "#fff",
                              marginBottom: "10px",
                              fontSize: "20px",
                            }}
                          >
                            VAT Audit Report
                          </h2>
                          <div style={{ color: "#fff", fontSize: "16px" }}>
                            {`(From ${new Date(dateFrom).toLocaleDateString(
                              "en-GB"
                            )} To ${new Date(dateTo).toLocaleDateString(
                              "en-GB"
                            )})`}
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="basic-form">
                            <div
                              id="task-tbl_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="empoloyeestbl2"
                                className="table ItemsCheckboxSec no-footer mb-2 mb-sm-0"
                                style={{ color: "black" }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      #
                                    </th>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Date
                                    </th>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Reference
                                    </th>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Customer/Supplier
                                    </th>

                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      TRN
                                    </th>

                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Net Amount
                                    </th>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Tax Amount
                                    </th>
                                    <th
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(52, 152, 219)",
                                        borderRight: "2px solid white",
                                      }}
                                    >
                                      Gross Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan="8" className="text-left">
                                      <b style={{ color: "#009688" }}>
                                        Sales - Rate 5%
                                      </b>
                                    </td>
                                  </tr>
                                  {allRecords?.allInvoices?.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {new Date(
                                            item?.invoiceDate
                                          )?.toLocaleDateString("en-GB")}
                                        </td>

                                        <td>{item?.invoiceNo}</td>
                                        <td>
                                          {item.customer?.firstname +
                                            " " +
                                            item.customer?.lastname}
                                        </td>
                                        <td>{item.customer?.TRN}</td>
                                        <td>
                                          {parseFloat(item?.totalAmount) -
                                            parseFloat(item?.estimatedTax)}
                                        </td>
                                        <td>{item?.estimatedTax}</td>
                                        <td>{item?.totalAmount}</td>
                                      </tr>
                                    )
                                  )}
                                  <tr>
                                    <td colSpan="4" className="text-left">
                                      <b style={{ color: "#009688" }}>Total</b>
                                    </td>
                                    <td></td>
                                    <td>{`USD ${invoices?.total}`}</td>
                                    <td>{`USD ${invoices?.tax}`}</td>
                                    <td>{`USD ${invoices?.grandTotal}`}</td>
                                  </tr>
                                  <tr>
                                    <td colSpan="9" className="text-left">
                                      <b style={{ color: "#009688" }}>
                                        Purchases - Rate 5%
                                      </b>
                                    </td>
                                  </tr>
                                  {allRecords?.allPurchases?.map(
                                    (item, index) => (
                                      <tr
                                        key={index}
                                        style={{ backgroundColor: "#d9edf7" }}
                                      >
                                        <td>{index + 1}</td>
                                        <td>
                                          {new Date(
                                            item?.createdAt
                                          )?.toLocaleDateString("en-GB")}
                                        </td>

                                        <td>
                                          {new Date(
                                            item?.createdAt
                                          )?.getTime() || "Invalid date"}
                                        </td>
                                        <td>
                                          {item?.purchaseOrder?.Supplier?.name}
                                        </td>
                                        <td>
                                          {item.purchaseOrder?.Supplier?.TRN}
                                        </td>
                                        <td>{item?.subTotal}</td>
                                        <td>{item?.vat}</td>
                                        <td>{item?.total}</td>
                                      </tr>
                                    )
                                  )}
                                  <tr>
                                    <td colSpan="4" className="text-left">
                                      <b style={{ color: "#009688" }}>Total</b>
                                    </td>
                                    <td></td>
                                    <td>{`USD ${purchases?.total}`}</td>
                                    <td>{`USD ${purchases?.tax}`}</td>
                                    <td>{`USD ${purchases?.grandTotal}`}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VATAuditReport;
