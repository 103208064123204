import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; // Import the styles
const stepTwoValidationSchema = Yup.object().shape({
  // email: Yup.string().optional(),
  phone: Yup.string()
    .required("Phone is required")
    .matches(/^\+?\d{10,15}$/, "Invalid phone number"),

  // webSite: Yup.string().optional(),

  // description: Yup.string().optional(),
});

const StepTwo = ({
  nextStep,
  prevStep,

  values,
  setSecondStepData,
  formData,
  setFormData,
}) => {
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    const containsEdit = currentPath.includes("/edit/");
    if (containsView) {
      setIsView(true);
    } else if (containsEdit) {
      setIsEdit(true);
    }
  }, []);
  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      validationSchema={stepTwoValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSecondStepData(values);
        setSubmitting(false);
        nextStep();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <section>
            {/* Your form fields here */}
            <div className="row">
              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    id="val-email"
                    placeholder="Email"
                    name="email"
                    onChange={(e) => {
                      handleChange(e);
                      setFormData({ ...formData, email: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display: touched.email && errors.email ? "block" : "none",
                    }}
                  >
                    {errors.email}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Phone</label>
                <div className="input-group">
                  <input
                    type="tel"
                    className="form-control"
                    id="val-phone"
                    placeholder="Phone"
                    name="phone"
                    onChange={(e) => {
                      handleChange(e);
                      setFormData({ ...formData, phone: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.phone}
                    disabled={true}
                  />
                  <div
                    id="val-phone-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.phone && errors.phone}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Website</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="val-email"
                    placeholder="website"
                    name="webSite"
                    onChange={(e) => {
                      handleChange(e);
                      setFormData({ ...formData, webSite: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.webSite}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.webSite && errors.webSite}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Lead Notes</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="val-prefix"
                    placeholder="Lead Notes"
                    name="description"
                    onChange={(e) => {
                      handleChange(e);
                      setFormData({ ...formData, description: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.description}
                    disabled={isView}
                  />
                  <div
                    id="val-prefix-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.description && errors.description}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {isView ||
            (!isEdit && (
              <div className="text-end toolbar toolbar-bottom p-2">
                <button
                  className="btn btn-secondary sw-btn-prev me-1"
                  onClick={prevStep}
                >
                  Prev
                </button>
                <button
                  className="btn btn-primary sw-btn-next ms-1"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next
                </button>
              </div>
            ))}
        </form>
      )}
    </Formik>
  );
};

export default StepTwo;
