import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";

import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import StepOne from "./edit/StepOne";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";
import StepTwo from "./edit/StepTwo";
import StepThree from "./edit/StepThree";
import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import { useGetSupervisersQuery } from "../../../../features/superviser/superviserApiSlice";
import {
  useGetActivityQuery,
  usePostActivityMutation,
} from "../../../../features/activity/activityApiSlice";
import {
  useGetLeadsQuery,
  useGetSingleLeadQuery,
  useUpdateLeadMutation,
} from "../../../../features/lead/leadApiSlice";
import { useParams } from "react-router-dom";
import { ListGroup } from "react-bootstrap";

const Create = () => {
  const navigate = useNavigate();
  const [goSteps, setGoSteps] = useState(0);
  const user = useSelector(selectCurrentUser);

  const [isView, setIsView] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    if (containsView) {
      setIsView(true);
    }
  }, []);

  // const [postLead, { isLoading }] = usePostLeadMutation();
  // const { data: leads = [], refetch } = useGetLeadsQuery({ fetchPolicy: "network-only" });

  const { leadId } = useParams();
  const [updateLead, { isLoading, isError, error }] = useUpdateLeadMutation();

  const { data: services = [] } = useGetServicesQuery({
    fetchPolicy: "network-only",
  });
  const { data: supervisers = [] } = useGetSupervisersQuery({
    fetchPolicy: "network-only",
  });
  const { data: activity = [], refetch: activityRefetch } =
    useGetActivityQuery(leadId);
  const { data: singleLead = [], refetch } = useGetSingleLeadQuery(leadId);

  const [postActivity, { isLoading: activityLoading }] =
    usePostActivityMutation();

  const [formData, setFormData] = useState({
    serviceId: "",
    firstname: "",
    prefix: "",
    lastname: "",
    organization: "",
    title: "",
    leadsStatus: "",
    backlogDate: "",
    superviserId: "",
    leadSource: "",
    phone: "",
    email: "",
    description: "",
    tags: "",
    webSite: "",
    city: "",
    stateOrProvince: "",
    area: "",
    employeeId: [],
    mailingAddress: "",
    permitDetails: "",
    permitRequired: "",
    country: "",
    customerTRN: "",
    appointmentTime: "",
    appointmentDate: "",
    startTime: "",
    endTime: "",
  });

  const [firstStepData, setFirstStepData] = useState({
    serviceId: "",
    firstname: "",
    prefix: "",
    lastname: "",
    organization: "",
    title: "",
    backlogDate: "",
    leadsStatus: "",
    userId: "",
    userEmail: "",
    supervisorName: "",
    leadSource: "",
    leadpropertytype: "",
    leadamenities: "",
    employeeId: [],
    supervisersWithServiceId: [], // Initialize an empty array for supervisors
  });

  const [secondStepData, setSecondStepData] = useState({
    email: "",
    phone: "",
    webSite: "",
    tags: "",
    description: "",
  });

  const [therdStepData, setTherdStepData] = useState({
    city: "",
    stateOrProvince: "",
    area: "",
    mailingAddress: "",
    permitDetails: "",
    permitRequired: "",
    country: "",
    customerTRN: "",
    appointmentTime: "",
    appointmentDate: "",
    startTime: "",
    endTime: "",
  });
  useEffect(() => {
    refetch();
    activityRefetch();
  }, []);
  const [feedback, setFeedback] = useState("");
  useEffect(() => {
    // Assuming singleLead is an array with at least one element
    const leadData = singleLead?.leads || "";

    if (leadData) {
      // First Step Data
      setFirstStepData({
        serviceId:
          leadData?.serviceId?.map((item) => {
            try {
              return JSON.parse(item);
            } catch (error) {
              return item;
            }
          }) || [],
        firstname: leadData?.customer.firstname,
        prefix: leadData?.customer.prefixName || "", // Assuming prefixName is optional
        lastname: leadData?.customer.lastname,
        organization: leadData?.customer.organization,
        title: leadData?.customer.title,
        leadsStatus: leadData?.leadsStatus,
        backlogDate: leadData?.backlogDate,
        // subserviceIds: leadData?.subserviceIds?.map((item) => JSON.parse(item)),
        userId: leadData?.userId,
        // userEmail:
        leadSource: leadData?.leadSource,
        leadamenities: leadData?.leadamenities,
        leadpropertytype: leadData?.leadpropertytype,
        employeeId: leadData?.employeeId
          ? leadData?.employeeId.map((employeeId) => JSON.parse(employeeId))
          : [],
        supervisersWithServiceId: [leadData?.user], // You might need to fill this with actual data
        isAMC: leadData?.projectId || null,
        agreementId: leadData?.project?.agreementId,
        projectId: leadData?.projectId,
        frequency: leadData?.project?.frequency,
        numberOfService: leadData?.project?.numberOfService,
        callOutService: leadData?.project?.callOutService,
        startDate: leadData?.project?.startDate,
        endDate: leadData?.project?.endDate,
        startTime: leadData?.startTime,
        endTime: leadData?.endTime,
      });

      // Second Step Data
      setSecondStepData({
        email: leadData?.customer.email,
        phone: leadData?.customer.phone,
        webSite: leadData?.customer.webSite,
        tags: leadData?.tags?.split(",")?.map((tag) => tag?.trim()),
        description: leadData?.description,
      });

      // Third Step Data
      setTherdStepData({
        city: leadData?.customer.city,
        stateOrProvince: leadData?.customer.stateOrProvince,
        area: leadData?.customer.area,
        mailingAddress: leadData?.customer.mailingAddress,
        permitDetails: leadData?.permitDetails || "",
        permitRequired: leadData?.permitRequired,
        country: leadData?.customer.country,
        TRN: leadData?.customer.TRN,
        permitImg: leadData?.permitImg,
        inspectionCharge: leadData?.inspectionCharge,
        issue: leadData?.issue,
        commonRoomOption: leadData?.commonRoomOption,
        additionalCharges: leadData?.additionalCharges,
        charges: leadData?.charges,
        aCUnitInstallation: leadData?.aCUnitInstallation,
        haveACUnitOption: leadData?.haveACUnitOption,
        aCInstallationCharge: leadData?.aCInstallationCharge,
        acUnit: leadData?.acUnit,
        date: leadData?.date,
        time: leadData?.time,
        startTime: leadData?.startTime,
        endTime: leadData?.endTime,
        outsideVillaDropdown: leadData?.outsideVillaDropdown,
        appointmentTime: leadData?.appointmentTime,
        appointmentDate: leadData?.appointmentDate,
      });

      setFormData({
        serviceId:
          leadData?.serviceId?.map((item) => {
            try {
              return JSON.parse(item);
            } catch (error) {
              return item;
            }
          }) || [],
        firstname: leadData?.customer.firstname,
        prefix: leadData?.customer.prefixName || "",
        lastname: leadData?.customer.lastname,
        organization: leadData?.customer.organization,
        title: leadData?.customer.title,
        leadsStatus: leadData?.leadsStatus,
        backlogDate: leadData?.backlogDate,
        superviserId: leadData?.userId,
        leadSource: leadData?.leadSource,
        phone: leadData?.customer.phone,
        email: leadData?.customer.email,
        description: leadData?.description,
        tags: leadData?.tags,
        permitDetails: leadData?.permitDetails || "",
        permitRequired: leadData?.permitRequired,
        webSite: leadData?.customer.webSite,
        city: leadData?.customer.city,
        stateOrProvince: leadData?.customer.stateOrProvince,
        area: leadData?.customer.area,
        mailingAddress: leadData?.customer.mailingAddress,
        employeeId: leadData?.employeeId
          ? leadData?.employeeId.map((employeeId) => JSON.parse(employeeId))
          : [],
        country: leadData?.customer.country,
        customerTRN: leadData?.customer.customerTRN,
        permitImg: leadData?.permitImg,
        isAMC: leadData?.projectId,
        agreementId: leadData?.project?.agreementId,
        projectId: leadData?.projectId,
        frequency: leadData?.project?.frequency,
        numberOfService: leadData?.project?.numberOfService,
        callOutService: leadData?.project?.callOutService,
        startDate: leadData?.project?.startDate,
        endDate: leadData?.project?.endDate,
        inspectionCharge: leadData?.inspectionCharge,
        issue: leadData?.issue,
        commonRoomOption: leadData?.commonRoomOption,
        additionalCharges: leadData?.additionalCharges,
        charges: leadData?.charges,
        aCUnitInstallation: leadData?.aCUnitInstallation,
        haveACUnitOption: leadData?.haveACUnitOption,
        aCInstallationCharge: leadData?.aCInstallationCharge,
        acUnit: leadData?.acUnit,
        date: leadData?.date,
        time: leadData?.time,
        startTime: leadData?.startTime,
        endTime: leadData?.endTime,
        outsideVillaDropdown: leadData?.outsideVillaDropdown,
        appointmentTime: leadData?.appointmentTime,
        appointmentDate: leadData?.appointmentDate,
      });
    }
  }, [singleLead]);

  useEffect(() => {}, [formData]);

  const handlePostLead = async (leadData) => {
    const data = { ...formData };
    data.customerId = singleLead?.leads?.customerId;
    const formDatas = new FormData();

    // Append all data to FormData
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        // If the value is a File, append it directly
        formDatas.append(key, value);
      } else if (value && typeof value === "object") {
        // If the value is an object or array, stringify it
        formDatas.append(key, JSON.stringify(value));
      } else {
        formDatas.append(key, value);
      }
    });

    try {
      await updateLead({ id: leadId, formData: formDatas })
        .unwrap()
        .then((response) => {
          notifySuccess("Lead updated successfully");

          refetch();

          navigate(`/${user?.role?.toLowerCase()}/lead`);
        })
        .catch((error) => {
          notifyError(error?.data?.message);
        });
    } catch (error) {
      console.error("Unexpected error:", error);
      notifyError(error.message);
    }
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setGoSteps(goSteps + 1);
  };

  const handleSendData = (inputData) => {
    const mergedData = {
      ...firstStepData,
      ...secondStepData,
      ...inputData,
    };
    mergedData.customerId = singleLead?.leads?.customerId;

    handlePostLead(mergedData);
    return;
  };

  const prevStep = () => {
    setGoSteps(goSteps - 1);
  };

  const handleSubmitActivity = async (values) => {
    const userDataJSON = localStorage.getItem("user");

    const userData = JSON.parse(userDataJSON);
    const data = {
      leadId,
      actionType: "POST",
      createdBy: userData?.email || "",
      notes: values?.activity,
    };
    try {
      const result = await postActivity(data);
      notifySuccess(result?.data?.message);
      activityRefetch();
    } catch (error) {
      notifyError(error.message);
    }
  };

  function timeSince(date) {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-xl-8"
            // style={{
            //   maxHeight: "80vh",
            //   overflowY: "auto",
            //   backgroundColor: "white",
            // }}
          >
            <div className="card">
              <div className="card-header text-center">
                <h4 className="card-title">Update Lead </h4>
                <p>
                  Created At: <br />{" "}
                  {new Date(singleLead?.leads?.createdAt).toLocaleDateString(
                    "en-GB",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true, // This will format the time in AM/PM format
                    }
                  )}
                </p>
              </div>
              <div className="card-body">
                <Alert variant="primary">
                  <strong>
                    {goSteps === 0
                      ? "Lead Information"
                      : goSteps === 1
                      ? "Additional Information"
                      : goSteps === 2
                      ? "Address Information"
                      : ""}
                  </strong>
                </Alert>

                <div className="form-wizard ">
                  <StepOne
                    users={supervisers?.users}
                    services={services?.services}
                    nextStep={nextStep}
                    handleFormData={handleInputData}
                    values={firstStepData}
                    setFirstStepData={setFirstStepData}
                    formData={formData}
                    setFormData={setFormData}
                  />
                  <Alert variant="primary">
                    <strong>{"Additional Information"}</strong>
                  </Alert>
                  <StepTwo
                    setSecondStepData={setSecondStepData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleFormData={handleInputData}
                    values={secondStepData}
                    formData={formData}
                    setFormData={setFormData}
                  />
                  <Alert variant="primary">
                    <strong>{"Address Information"}</strong>
                  </Alert>
                  <StepThree
                    setTherdStepData={setTherdStepData}
                    values={therdStepData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleSendData={handleSendData}
                    handleFormData={handleInputData}
                    formData={formData}
                    setFormData={setFormData}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Lead Activity</h4>
              </div>
              <div
                className="card-body"
                // style={{
                //   maxHeight: "300vh",
                //   overflowY: "auto",
                //   backgroundColor: "white",
                // }}
              >
                <div className="basic-list-group">
                  <ListGroup>
                    {activity.map((item, index) => (
                      <ListGroup.Item
                        key={index}
                        action
                        className="flex-column"
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h5 className="mb-3 " style={{ marginRight: "30px" }}>
                            {item?.user?.email}
                          </h5>
                          <small className="text-muted text-left">
                            {timeSince(item.createdAt)}
                          </small>
                        </div>

                        <p className="mb-1"></p>
                        <small className="text-muted">{item.notes}</small>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
              {/* Feedback Form */}
              <div className="card-footer">
                <Formik
                  initialValues={{ activity: "" }}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmitActivity(values);
                    resetForm(); // Reset the form after submission
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form style={{ display: "flex", alignItems: "center" }}>
                      <Field
                        type="text"
                        name="activity"
                        placeholder="Enter activity..."
                        style={{
                          flex: "1",
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          outline: "none",
                          marginRight: "10px",
                        }}
                        required
                      />
                      {/* <button
                        type="submit"
                        className="btn btn-primary sw-btn-next ms-1"
                      >
                        Submit
                      </button> */}
                      <button
                        type="submit"
                        className="btn btn-primary me-2"
                        disabled={activityLoading}
                      >
                        {activityLoading ? (
                          <>
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
