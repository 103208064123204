import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-tagsinput/react-tagsinput.css";
import Select from "react-select";
import { useCreateAttendanceMutation } from "../../../../features/hrm/attendance/attendanceApiSlice";

import { useGetEmployeesQuery } from "../../../../features/employee/employeeApiSlice";

const stripTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
const validationSchema = Yup.object().shape({
  userId: Yup.string().required("Employee Name is required"),
  attendenceDate: Yup.date()
    .required("Date is required")
    .max(stripTime(new Date()), "Date cannot be in the future"),
  CheckInRecordtime: Yup.string().required("Check In Time is required"),
  // breakTime: Yup.string().required("Break Time is required"),
  CheckOutRecordtime: Yup.string().required("Check Out Time is required"),
});

const CreateAttendance = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [postAttendance, { isLoading }] = useCreateAttendanceMutation();

  const { data: users } = useGetEmployeesQuery();

  const handleSubmitted = async (values) => {
    try {
      const result = await postAttendance(values);
      notifySuccess(result?.data?.message);
      navigate("/" + user?.role?.toLowerCase() + "/attendence-sheet");
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Attendance</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      userId: "",
                      attendenceDate: "",
                      CheckInRecordtime: "",
                      // breakTime: "",
                      CheckOutRecordtime: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.userId
                                  ? errors.userId
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Employee Name
                              </label>
                              <Select
                                options={users?.users
                                  ?.filter(
                                    (user) =>
                                      user?.status === "Active" &&
                                      user?.OfficalInformation &&
                                      user?.OfficalInformation?.Shift
                                  )
                                  ?.map((user) => ({
                                    label: `${user?.firstName} ${user?.lastName}`,
                                    value: user?.userId,
                                  }))}
                                name="userId"
                                onChange={(selectedOption) =>
                                  setFieldValue("userId", selectedOption.value)
                                }
                                onBlur={handleBlur}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.userId && errors.userId}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.attendenceDate
                                  ? errors.attendenceDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="attendenceDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.attendenceDate}
                                max={new Date()?.toISOString()?.slice(0, 10)}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.attendenceDate && errors.attendenceDate}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.CheckInRecordtime
                                  ? errors.CheckInRecordtime
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Check In Time
                              </label>
                              <input
                                type="time"
                                className="form-control"
                                name="CheckInRecordtime"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.CheckInRecordtime}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.CheckInRecordtime &&
                                  errors.CheckInRecordtime}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.breakTime
                                  ? errors.breakTime
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Break Time</label>
                              <input
                                type="time"
                                className="form-control"
                                name="breakTime"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.breakTime}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.breakTime && errors.breakTime}
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.CheckOutRecordtime
                                  ? errors.CheckOutRecordtime
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Check Out Time
                              </label>
                              <input
                                type="time"
                                className="form-control"
                                name="CheckOutRecordtime"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.CheckOutRecordtime}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.CheckOutRecordtime &&
                                  errors.CheckOutRecordtime}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateAttendance;
