import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import "./style.css";
import { Formik } from "formik";
import { useGetTaskByTeamQuery } from "../../../../../features/taskManagement/taskManagementApiSlice";
import {
  useGetSalesPersonQuery,
  useGetSaleByTeamQuery,
} from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import { IMAGES } from "../../../../constant/theme";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

import { CSVLink } from "react-csv";
const SaleByTeamReport = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [userId, setUserId] = useState("");
  const [filterRecords, setFilterRecords] = useState([]);
  const [employeeName, setEmployeeName] = useState("");

  const { data: customers } = useGetSalesPersonQuery();

  const { data: Invoices, refetchInvoice } = useGetSaleByTeamQuery(
    {
      salesPersonUserId: userId,
      dateFrom,
      dateTo,
    },
    { skip: !userId || !dateFrom || !dateTo }
  );

  const employessData = (data) => {
    const employessName = data?.map((item) => {
      try {
        return JSON.parse(item);
      } catch (error) {
        return item;
      }
    });

    const labels = employessName?.map((employee) => employee.label);

    return labels;
  };
  const headersTitle = [
    { label: "Employee Name", key: "employeeName" },
    { label: "Job Name", key: "jobName" },
    { label: "Task Type", key: "taskType" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filterRecords.map((task) => ({
      employeeName: employeeName,
      jobName: task?.taskId
        ? task?.quotaion?.quotaion
        : task?.agreement?.quotaion?.quotaion,
      taskType:
        task?.aggrementId && task?.taskId
          ? "AMC Base Task"
          : !task?.aggrementId && task?.taskId
          ? "One Time Task"
          : "CallOut Task",
      startDate: task?.startDate
        ? new Date(task?.startDate).toLocaleDateString("en-GB")
        : "",
      endDate:
        task?.status === "Complete"
          ? new Date(task?.updatedAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })
          : "",
    })),
    filename: "csvfile.csv",
  };
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Sales_Team_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 3, // Increase the scale for better quality
        useCORS: true, // Enable cross-origin images if needed
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait", // Set orientation to portrait
      },
    };

    // Resize the content for A4
    const originalWidth = element.style.width;
    element.style.width = "794px"; // A4 width in pixels at 96 DPI

    // Generate and save the PDF
    html2pdf()
      .set(options)
      .from(element)
      .save()
      .finally(() => {
        // Reset the element's original width
        element.style.width = originalWidth;
      });
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ color: "#009688" }}>
                  Sale By Team Report
                </h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={downloadPDF}
                    className="btn btn-primary light btn-sm"
                    style={{
                      height: "38px",
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      marginRight: "10px",
                    }}
                  >
                    <MdOutlineFileDownload
                      style={{ color: "inherit", fontSize: "20px" }}
                    />{" "}
                    PDF
                  </button>
                  {filterRecords?.length > 0 && (
                    <CSVLink
                      {...csvlink}
                      className="btn btn-primary light btn-sm"
                      style={{
                        height: "38px",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <i
                        className="fa-solid fa-file-excel"
                        style={{
                          alignItems: "center",
                          marginTop: "1px",
                          marginRight: "11px",
                        }}
                      />{" "}
                      Export Report
                    </CSVLink>
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      fromDate: "",
                      toDate: "",
                    }}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault(); // Prevent default form submission
                          // Form submission now directly updates the state
                        }}
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Sale Person</label>
                              <Select
                                options={customers?.users?.map((user) => ({
                                  value: user.userId,
                                  label: `${user.firstName} ${user.lastName}`,
                                }))}
                                name="userId"
                                onChange={(selectedOption) => {
                                  setFieldValue("userId", selectedOption.value);
                                  setUserId(selectedOption.value);
                                  setEmployeeName(selectedOption.label);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.userId && touched.userId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.userId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date From</label>
                              <input
                                type="date"
                                name="fromDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("fromDate", e.target.value);
                                  setDateFrom(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.fromDate && touched.fromDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.fromDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date To</label>
                              <input
                                type="date"
                                name="toDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("toDate", e.target.value);
                                  setDateTo(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.toDate && touched.toDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.toDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12"></div>
                        </div>
                      </form>
                    )}
                  </Formik>

                  <div id="report-content">
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="text-start" style={{ color: "white" }}>
                        <h1 style={{ color: "#009688" }}>Aifa Services</h1>
                      </div>
                      <div className="text-end">
                        <img
                          src={aifaLogo}
                          alt="logo"
                          style={{
                            width: "130px",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="card-header "
                      style={{
                        backgroundColor: "rgb(52, 152, 219)",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          marginBottom: "10px",
                          fontSize: "20px",
                        }}
                      >
                        {employeeName}
                      </h2>
                      <h2
                        className="card-title"
                        style={{
                          color: "#fff",
                          marginBottom: "10px",
                          fontSize: "20px",
                        }}
                      >
                        Sales Team Report
                      </h2>
                      <div style={{ color: "#fff", fontSize: "16px" }}>
                        {`(From ${new Date(dateFrom).toLocaleDateString(
                          "en-GB"
                        )} To ${new Date(dateTo).toLocaleDateString("en-GB")})`}
                      </div>
                    </div>
                    {userId && dateFrom && dateTo ? (
                      <div
                        id="task-tbl_wrapper"
                        className="table-responsive no-footer"
                      >
                        <table
                          id="empoloyeestbl2"
                          className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0 mt-3"
                        >
                          <thead>
                            <tr
                              style={{ backgroundColor: "rgb(52, 152, 219)" }}
                            >
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Name
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Invoice Count
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Invoice Sales
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Invoice Sales with Tax
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Credit Note Count
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Credit Note Sales
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Credit Note Sales with Tax
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Total Sales
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                }}
                              >
                                Total Sales With Tax
                              </th>
                            </tr>
                          </thead>
                          <tbody style={{ backgroundColor: "#d9edf7" }}>
                            {Invoices?.map((invoice, index) => (
                              <tr key={index}>
                                <td style={{ fontSize: "14px" }}>
                                  {invoice?.customer?.firstname +
                                    " " +
                                    invoice?.customer?.lastname}
                                </td>
                                <td style={{ fontSize: "14px" }}>1</td>
                                <td>{"USD " + invoice?.totalAmount}</td>
                                <td style={{ fontSize: "14px" }}>
                                  <span>{"USD " + invoice?.totalAmount}</span>
                                </td>
                                <td style={{ fontSize: "14px" }}>{"0"}</td>
                                <td style={{ fontSize: "14px" }}>
                                  {"USD 0.00"}
                                </td>
                                <td style={{ fontSize: "14px" }}>
                                  {"USD 0.00"}
                                </td>
                                <td style={{ fontSize: "14px" }}>
                                  {"USD " + invoice?.totalAmount}
                                </td>
                                <td style={{ fontSize: "14px" }}>
                                  <span>{"USD " + invoice?.totalAmount}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <thead>
                            <tr
                              style={{ backgroundColor: "rgb(52, 152, 219)" }}
                            >
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  fontSize: "14px",
                                  padding: "10px",
                                }}
                              >
                                Total
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  fontSize: "14px",
                                  padding: "10px",
                                }}
                              >
                                {Invoices?.length}
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  fontSize: "14px",
                                  padding: "10px",
                                }}
                              >
                                {"USD " +
                                  Invoices?.reduce(
                                    (acc, invoice) =>
                                      acc +
                                      parseFloat(invoice.totalAmount || 0),
                                    0
                                  ).toFixed(2)}
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                  fontSize: "14px",
                                }}
                              >
                                {"USD " +
                                  Invoices?.reduce(
                                    (acc, invoice) =>
                                      acc +
                                      parseFloat(invoice.totalAmount || 0),
                                    0
                                  ).toFixed(2)}
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                  fontSize: "14px",
                                }}
                              >
                                0
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                  fontSize: "14px",
                                }}
                              >
                                USD 0.00
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                  fontSize: "14px",
                                }}
                              >
                                USD 0.00
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                  fontSize: "14px",
                                }}
                              >
                                {"USD " +
                                  Invoices?.reduce(
                                    (acc, invoice) =>
                                      acc +
                                      parseFloat(invoice.totalAmount || 0),
                                    0
                                  ).toFixed(2)}
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                  padding: "10px",
                                  fontSize: "14px",
                                }}
                              >
                                {"USD " +
                                  Invoices?.reduce(
                                    (acc, invoice) =>
                                      acc +
                                      parseFloat(invoice.totalAmount || 0),
                                    0
                                  ).toFixed(2)}
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    ) : (
                      <p>No Records found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SaleByTeamReport;
