// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";

import { usePostInspectionInvoiceMutation } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";

import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import * as Yup from "yup";
import { useGetInspactedLeadsQuery } from "../../../../../features/lead/leadApiSlice";
import Select from "react-select";
import "react-quill/dist/quill.snow.css";
import { useGetSingleCallOutTaskQuery } from "../../../../../features/callOutTask/callOutTaskApiSlice";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";
import ReactQuill from "react-quill";

const loginSchema = Yup.object().shape({
  // leadId: Yup.string().required("Lead ID is required"),
  invoiceNo: Yup.string().required("Invoice No. is required"),
  invoiceDate: Yup.date().required("Invoice Date is required"),
  invoiceDue: Yup.date().required("Invoice Due is required"),
  // paymentStatus: Yup.object().required("Payment Status is required"),
  // serviceId: Yup.string().required("Service ID is required"),
  // customerId: Yup.string().required("Customer ID is required"),
  phone: Yup.string().optional("Phone is required"),
  discription: Yup.string(),
  email: Yup.string().email("Invalid email").required("email is reuired"),
  address: Yup.string(),
});

const Create = () => {
  const userInfo = useSelector(selectCurrentUser);
  const { data: accounts = [] } = useGetAllAccountsQuery();
  const { callOutTaskId } = useParams();

  const { data: singleCallOut } = useGetSingleCallOutTaskQuery(callOutTaskId);

  const [selectLead, setSelectedLead] = useState(null);
  const [subServicesList, setSubServicesList] = useState([]);
  const [itemss, setItemss] = useState([]);
  const [productTotalDetailPrice, setProductTotalDetailPrice] = useState({
    subtotal: 0,
    totalTax: 0,
    totalDiscount: 0,
    total: 0,
    grandTotal: 0,
  });
  const [initialValues, setInitialValues] = useState({
    // leadId: "",
    invoiceNo: generateInvoiceNumber(),
    invoiceDate: "",
    invoiceDue: "",
    // paymentStatus: "",
    // serviceId: "",
    customerId: "",
    customerName: "",
    phone: "",
    description: "",
    email: "",
    address: "",
    sendMail: false,
    subTotal: 0,
    TRN: "",
    salesAccountId: "",
    receiveableAccountId: "",
  });
  const [content, setContent] = useState(`
    <head>
  
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 20px;
            }
            .container {
                width: 80%;
                margin: auto;
               
                padding: 20px;
            }
           
           
          
            .section {
                margin-bottom: 20px;
            }
            .section h2 {
              
                padding-bottom: 5px;
            }
            .customer-details,
            .scope-of-work,
            .exclude-scope,
            .payment,
            .terms-conditions,
            .bank-details,
            .thank-you-note {
                margin-bottom: 20px;
            }
            .scope-table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
            }
            .scope-table th,
            .scope-table td {
                border: 1px solid #000;
                padding: 8px;
                text-align: left;
            }
        </style>
    </head>
    <body>
        <div class="container">
          
           
            <div class="section scope-of-work">
                <h2>Scope of Work</h2>
                <table class="scope-table">
                
                </table>
            </div>
            
            <div class="section exclude-scope">
                <h2>Exclude Scope</h2>
                <p>[Details]</p>
            </div>
            
            <div class="section payment">
                <h2>Payment</h2>
                <p>[Payment Terms]</p>
            </div>
            
            <div class="section terms-conditions">
                <h2>General Terms & Conditions</h2>
                <p>[Terms & Conditions]</p>
            </div>
            
            <div class="section bank-details">
                <h2>Bank Details</h2>
                <p>[Bank Name]</p>
                <p>[Account Number]</p>
                <p>[IFSC Code]</p>
            </div>
            
            <div class="section thank-you-note">
                <h2>Thank You Note</h2>
                <p>[Thank You Message]</p>
            </div>
        </div>
    </body>
    </html>
    
  `);

  const handleContentChange = (value) => {
    setContent(value);
  };

  const { data: inspections = [] } = useGetInspactedLeadsQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setInitialValues({
      // leadId: "",
      invoiceNo: generateInvoiceNumber(),
      invoiceDate: "",
      invoiceDue: "",
      // paymentStatus: "",
      // serviceId: "",
      customerId: singleCallOut?.agreement?.customer?.customerId,
      customerName:
        `${singleCallOut?.agreement?.customer?.firstname} ${singleCallOut?.agreement?.customer?.lastname}` ||
        "",
      phone: singleCallOut?.agreement?.customer?.phone || "",
      description: "",
      email: singleCallOut?.agreement?.customer?.email || "",
      address: singleCallOut?.agreement?.customer?.mailingAddress || "",
      sendMail: false,
      subTotal: singleCallOut?.price,
      TRN: singleCallOut?.agreement?.customer?.TRN || "",
    });
  }, [singleCallOut]);

  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  const [postInvoice, { isLoading }] = usePostInspectionInvoiceMutation();

  const handleSubmitted = async (values) => {
    values.notes = content;
    values.description = content;
    values.estimatedTax = (values?.subTotal * 0.05).toFixed(2);
    values.totalAmount = (
      parseFloat(values.estimatedTax) + parseFloat(values?.subTotal)
    )?.toFixed();
    values.grandTotal = values?.totalAmount;
    values.callOutTaskId = callOutTaskId;

    values.receiveableAccountId = values?.receiveableAccountId?.value;
    values.salesAccountId = values.salesAccountId?.value;

    try {
      // return
      const result = await postInvoice(values);

      notifySuccess(result?.data?.message);
      navigate(`/${userInfo?.role?.toLowerCase()}/invoices`);
    } catch (error) {
      notifyError(error.message);
    }
  };

  // Function to delete an item

  function generateInvoiceNumber() {
    // Generate a random number (you can use any method to generate a unique number)
    const randomNumber = Math.floor(Math.random() * 100); // Generate an 8-digit random number

    // Get the current date and format it (e.g., YYMMDD)
    const currentDate = new Date()
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "");

    // Combine the prefix, current date, and random number
    const invoiceNumber = `#${"PW"}${currentDate}${randomNumber}`;

    return invoiceNumber;
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add callout Invoice</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice No.</label>
                              <input
                                type="text"
                                className="form-control"
                                name="invoiceNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceNo}
                                readOnly={true}
                              />
                            </div>
                          </div>

                          {/* Invoice Date */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="invoiceDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceDate}
                                required
                              />
                            </div>
                          </div>

                          {/* Invoice Due */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice Due</label>
                              <input
                                type="date"
                                className="form-control"
                                name="invoiceDue"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceDue}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label className="text-label">Customer</label>

                            <Select
                              id="val-service"
                              name="quotaionId"
                              onBlur={handleBlur}
                              value={{
                                value: values?.customerId,
                                label: values?.customerName,
                              }}
                              isDisabled={true}
                              options={inspections?.leads?.map((lead) => ({
                                value: lead,
                                label: `${lead?.customer?.firstname} ${lead?.customer?.lastname} - ${lead?.inspection?.cost} USD`,
                              }))}
                            ></Select>
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="quotaionId" />
                            </div>
                          </div>

                          {/* Contact Us */}
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Contact US </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Contact US"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          {/* Email */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                                value={values.email}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">TRN</label>
                              <input
                                type="text"
                                className="form-control"
                                name="TRN"
                                placeholder="Enter TRN No"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.TRN}
                                disabled={
                                  singleCallOut?.agreement?.customer?.TRN
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={values.sendMail}
                            id="flexCheckChecked"
                            name="sendMail"
                            onChange={handleChange}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckChecked"
                          >
                            Are you sure you want to send an email?
                          </label>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <label className="text-label">Sales Account</label>
                            <Select
                              name="salesAccountId"
                              onChange={(selectedOption) => {
                                setFieldValue("salesAccountId", selectedOption); // Pass the whole object
                              }}
                              onBlur={handleBlur}
                              value={
                                values?.salesAccountId
                                  ? {
                                      label: values.salesAccountId?.label,
                                      value: values.salesAccountId?.value,
                                    }
                                  : null
                              }
                              options={accounts
                                ?.filter(
                                  (account) =>
                                    account?.type === "income" &&
                                    account?.detailType === "sales"
                                )
                                ?.map((account) => ({
                                  value: account?.accountId,
                                  label: account?.name,
                                }))}
                              classNamePrefix="react-select" //
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.salesAccountId && errors.salesAccountId}
                            </div>
                          </div>
                          <div className="col-4">
                            <label className="text-label">
                              Receivable Account
                            </label>
                            <Select
                              name="receiveableAccountId"
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "receiveableAccountId",
                                  selectedOption
                                ); // Pass the whole object
                              }}
                              onBlur={handleBlur}
                              value={
                                values.receiveableAccountId
                                  ? {
                                      label: values.receiveableAccountId?.label,
                                      value: values.receiveableAccountId?.value,
                                    }
                                  : null
                              } // Ensure value is properly set
                              options={accounts
                                ?.filter(
                                  (account) =>
                                    account?.type === "Account Receivable A/R"
                                )
                                ?.map((account) => ({
                                  value: account?.accountId,
                                  label: account?.name,
                                }))}
                              classNamePrefix="react-select" // Optional: For better styling
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.receiveableAccountId &&
                                errors.receiveableAccountId}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">Address</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="text-label">
                              Invoice Details
                            </label>

                            <ReactQuill
                              theme="snow"
                              value={content}
                              onChange={handleContentChange}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-6">
                            <div className="">
                              <table className="table">
                                <tbody
                                  class="before:block before:h-3"
                                  id="totalAmount"
                                >
                                  {selectLead !== null && (
                                    <tr>
                                      <td
                                        // colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">Inspection Amount</td>

                                      <td class="">
                                        {selectLead?.inspection?.cost == ""
                                          ? 0
                                          : selectLead?.inspection?.cost}{" "}
                                        USD
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td
                                      // colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Price</td>
                                    <td class="">{values?.subTotal} USD</td>
                                  </tr>
                                  <tr>
                                    <td
                                      // colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">VAT</td>
                                    <td class="">
                                      {(values?.subTotal * 0.05).toFixed()} USD
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      // colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Total Amount</td>
                                    <td class="">
                                      {parseFloat(values.subTotal) +
                                        parseFloat(
                                          (values?.subTotal * 0.05).toFixed()
                                        )}{" "}
                                      USD
                                    </td>
                                  </tr>

                                  {/* {selectLead !== null && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">Grand Total</td>

                                      <td class="">
                                        {productTotalDetailPrice.grandTotal} USD
                                      </td>
                                    </tr>
                                  )} */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <br />

                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
