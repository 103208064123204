import React, { useState } from "react";
import { toast } from "react-hot-toast";
import WeeklyHours from "./WeeklyHours";
import SpecificDays from "./SpecificDays";

const WeeklySchedule = () => {
  const staticAvailabilityData = {
    availability: {
      agency_id: "12345",
      weeklySchedule: [
        { day: "Monday", start: "09:00", end: "17:00" },
        { day: "Tuesday", start: "09:00", end: "17:00" },
      ],
      specificDates: [
        { date: "2025-01-10", timeSlots: [{ start: "10:00", end: "11:00" }] },
      ],
    },
  };

  const [availabilityData, setAvailabilityData] = useState(
    staticAvailabilityData
  );
  const [updating, setUpdating] = useState(false);

  const handleWeeklyScheduleUpdate = async (weeklySchedule) => {
    if (!availabilityData?.availability || updating) return;

    setUpdating(true);
    try {
      setTimeout(() => {
        setAvailabilityData((prev) => ({
          ...prev,
          availability: {
            ...prev.availability,
            weeklySchedule,
          },
        }));
        toast.success("Schedule updated successfully");
        setUpdating(false);
      }, 500);
    } catch (error) {
      toast.error(
        `Error updating schedule: ${error.message || "Unknown error"}`
      );
      setUpdating(false);
    }
  };

  const handleSpecificDatesUpdate = async (specificDates) => {
    if (!availabilityData?.availability || updating) return;

    setUpdating(true);
    try {
      setTimeout(() => {
        setAvailabilityData((prev) => ({
          ...prev,
          availability: {
            ...prev.availability,
            specificDates,
          },
        }));
        toast.success("Specific dates updated successfully");
        setUpdating(false);
      }, 500);
    } catch (error) {
      toast.error(
        `Error updating specific dates: ${error.message || "Unknown error"}`
      );
      setUpdating(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body p-4">
              <h3>Availability</h3>
              <div className="row">
                <div className="col-lg-8 col-12">
                  <WeeklyHours
                    schedule={availabilityData.availability.weeklySchedule}
                    onUpdate={handleWeeklyScheduleUpdate}
                    disabled={updating}
                  />
                </div>
                <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                  <SpecificDays
                    dates={availabilityData.availability.specificDates}
                    onUpdate={handleSpecificDatesUpdate}
                    disabled={updating}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeeklySchedule;
