import React, { Fragment } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import images from "../../../../../../src/images/images.png";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";


const cashFlowData = {
  operatingActivity: [
    { label: "Customers", value: "$9,500.00" },
    { label: "Other operations", value: "$2,100.00" },
    { label: "General operating and admin expenses", value: "($6,900.00)", indent: true },
    { label: "Wage expenses", value: "($1,400.00)", indent: true },
    { label: "Interest", value: "($350.00)", indent: true },
    { label: "Income taxes", value: "($1,800.00)", indent: true },
  ],
  additionsToCash: [
    { label: "Depreciation", value: "$5,000.00", indent: true },
    { label: "Amortization", value: "$1,200.00", indent: true },
  ],
  subtractionsFromCash: [
    { label: "Increase in Accounts Receivable", value: "($1,900.00)", indent: true },
    { label: "Increase in Inventory", value: "($700.00)", indent: true },
  ],
  investingActivities: [
    { label: "Purchase of equipment", value: "($3,200.00)", indent: true },
    { label: "Bank loan", value: "$6,500.00", indent: true },
  ],
  financingActivities: [
    { label: "Drawing/Distribution", value: "$800.00", indent: false },
    { label: "Repayment of loans", value: "($400.00)", indent: false },
  ],
  summary: [
    { label: "Beginning Cash", value: "$15,000.00" },
    { label: "Total Change in Cash", value: "$8,450.00" },
    { label: "Ending Cash", value: "$23,450.00" },
  ]
};

const CashFlowStatement = () => {
  const renderList = (items, backgroundColor) => (
    <ul className="list-unstyled">
      {items.map((item, index) => (
        <li
          key={index}
          className={item.indent ? "ps-3" : ""}
          style={{ backgroundColor: backgroundColor }} // Set background color here
        >
          {item.label} <span className="float-end">{item.value}</span>
        </li>
      ))}
    </ul>
  );
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Cash_Flow_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };


  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex justify-content-end"> {/* Added flexbox for alignment */}
              <button
                onClick={downloadPDF}
                style={{ marginBottom: "20px", background: "transparent", border: "none", padding: 0 }}
              >
                <MdOutlineFileDownload style={{ color: "inherit", fontSize: "20px" }} />
              </button>
            </div>
            <div id="report-content">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="mb-0 f-bold" style={{color:"#009688"}}>ABC International</h2>
                <img
                  src={aifaLogo}
                  alt="logo"
                  style={{
                    width: "130px",
                  }}
                />
              </div>
              <div className="card">
                <div className="card-header" style={{ backgroundColor: 'rgb(52, 152, 219)', textAlign: 'center' }}>
                  <h4 className="card-title text-white mb-0">Cash Flow Statement</h4>
                  <small className="text-white d-block">For the year ending: 2022</small> 
                </div>

                <div className="card-body">
                  <section>
                    <h6 className="fw-bold" style={{ color: '#009688' }}>Operating Activity</h6>
                    {renderList(cashFlowData.operatingActivity, '#d9edf7')}
                    <hr className="border border-dark" />
                    <p className="fw-bold" style={{ color: '#009688' }}>
                      Net Profit from Operations 
                      <span className="float-end" style={{ color: '#009688' }}>
                        $1,150.00
                      </span>
                    </p>
                    {renderList(cashFlowData.additionsToCash, '#d9edf7')}
                    {renderList(cashFlowData.subtractionsFromCash, '#d9edf7')}
                    <hr className="border border-dark" />
                    <p className="fw-bold" style={{ color: '#009688' }}>
                      Total Cash from Operating Activities 
                      <span className="float-end" style={{ color: '#009688' }}>
                        $4,750.00
                      </span>
                    </p>
                    <hr className="border border-dark" />
                  </section>
                  <section className="mt-4">
                    <h6 className="fw-bold" style={{ color: '#009688' }}>Investing Activities</h6>
                    {renderList(cashFlowData.investingActivities, '#d9edf7')}
                    <p className="fw-bold" style={{ color: '#009688' }}>
                      Total Cash from Investing Activities 
                      <span className="float-end" style={{ color: '#009688' }}>
                        $3,300.00
                      </span>
                    </p>
                    <hr className="border border-dark" />
                  </section>
                  <section className="mt-4">
                    <h6 className="fw-bold" style={{ color: '#009688' }}>Financing Activities</h6>
                    {renderList(cashFlowData.financingActivities, '#d9edf7')}
                    <p className="fw-bold" style={{ color: '#009688' }}>
                      Total Cash from Financing Activities 
                      <span className="float-end" style={{ color: '#009688' }}>
                        $400.00
                      </span>
                    </p>
                    <hr className="border border-dark" />
                  </section>
                  <section className="mt-4">
                    {renderList(cashFlowData.summary, '#d9edf7')}
                    <hr className="border border-dark" />
                  </section>

                  <div className="text-center mt-4" style={{ backgroundColor: '#d5d8de', padding: '10px', maxWidth: '600px', margin: '0 auto' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <p className="text-success" style={{ margin: 0, marginRight: '20px' }}>
                        Open an international business account
                      </p>
                      <img
                        src={images}
                        alt="Wise Logo"
                        className="wise-logo"
                        style={{
                          width: '100px',
                          height: 'auto',
                          position: 'relative',
                          float: "right",
                          marginRight: '20px'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    
    </Fragment>
  );
};

export default CashFlowStatement;
