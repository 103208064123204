import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleAgreementQuery } from "../../../../features/agreement/agreementApiSlice";
import html2pdf from "html2pdf.js";

function convertHeadingsToParagraphs(htmlContent) {
  // Create a temporary DOM element to manipulate the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  // Select all heading tags (h1, h2, h3, etc.)
  const headings = tempDiv.querySelectorAll("h1, h2, h3, h4, h5, h6");

  headings.forEach((heading) => {
    // Create a new <p> element
    const p = document.createElement("p");

    // Copy the content of the heading into the <p> element
    p.innerHTML = heading.innerHTML;

    // Apply the bold style to the <p> tag
    p.style.fontWeight = "bold";

    // Remove extra line spacing by setting margin and padding to 0
    p.style.margin = "0";
    p.style.padding = "0";

    // Replace the heading with the <p> tag
    heading.replaceWith(p);
  });

  // Return the updated HTML as a string
  return tempDiv.innerHTML;
}
function formatDate(date) {
  const day = new Date(date)?.getDate();
  const month = new Date(date)?.toLocaleString("default", { month: "long" });
  const year = new Date(date)?.getFullYear();

  // Add ordinal suffix to the day
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";

  return `${day}${suffix} ${month} ${year}`;
}
const AgreementView = () => {
  const { agreementId } = useParams();
  const { data: agreement, refetch } = useGetSingleAgreementQuery(agreementId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleDownloadPDF = () => {
    const element = document.getElementById("agreement-content"); // Select the content to convert
    const options = {
      filename: `agreement_${new Date().toLocaleDateString()}.pdf`,
      // image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "A4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <div className=" container-fluid">
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger" onClick={handleDownloadPDF}>
          Download PDF
        </button>
      </div>
      <div
        className="mx-3 rounded mt-3 p-5 "
        id="agreement-content"
        style={{ backgroundColor: "white" }}
      >
        <div className="row  d-flex justify-content-center">
          <img src="/AIFA-Logo.png" style={{ width: "300px" }} />
        </div>
        <div style={{ color: "black" }} className="mt-3">
          <h2>
            {`AMC Agreement ${new Date(
              agreement?.project?.startDate
            )?.getFullYear()} - ${new Date(
              agreement?.project?.endDate
            )?.getFullYear()}`}
          </h2>
          <p>
            <strong>Agreement No:</strong> {agreement?.agreementNo}
          </p>

          <p>
            <strong>First Party:</strong> AIFA ENVIRONMENTAL SERVICES & PEST
            CONTROL L.L.C <br />
            P.O. Box 123112, Dubai-UAE
          </p>

          <p>
            <strong>Second Party:</strong>{" "}
            {agreement?.customer?.firstname +
              " " +
              agreement?.customer?.lastname}{" "}
            <br />
            Contact Details: {agreement?.customer?.phone}
          </p>

          <p>
            <strong>Offer Dated:</strong>{" "}
            {new Date(agreement?.createdAt)?.toLocaleDateString("en-GB")}
          </p>
          <p style={{ textAlign: "center", textDecorationLine: "underline" }}>
            <strong>
              {" "}
              <ul>Subject: {agreement?.subject}</ul>
            </strong>
          </p>
          <p>
            Dear Customer,
            <br />
            Thank you for inviting us to submit our proposal/quotation for the
            above-mentioned subject. AIFA SERVICES is grateful for this
            opportunity to offer our best services in Dubai, UAE. Our
            experienced technical team provides excellent service across various
            commercial and residential sectors.
          </p>
          <h4>Scope of Work:</h4>

          <h4 style={{ textDecorationLine: "underline" }}>Service Charges:</h4>
          <table
            className="table table-bordered   mt-3"
            style={{ color: "black" }}
          >
            <thead
              className="thead-dark"
              style={{ backgroundColor: "#A8D08D" }}
            >
              <tr>
                <th>Sr No</th>
                <th>Frequency</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {agreement?.quotaion?.subSeriviceList &&
                agreement?.quotaion?.subSeriviceList?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.oneTaskQuantity} Times</td>
                    <td>{item?.subService?.name}</td>

                    <td>{item?.quantity}</td>

                    <td>USD {item?.totalPrice}/-</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <p>
            <strong>VAT @ 5%:</strong> USD{" "}
            {agreement?.quotaion?.pricesCalculation?.EstimatedTax}/-
          </p>
          <p>
            <span style={{ backgroundColor: "yellow" }}>
              <strong>TOTAL Before Discount:</strong> USD{" "}
              {(agreement?.quotaion?.pricesCalculation?.beforeDiscountSubtotal)}
              /-
            </span>
          </p>
          <p>
            <strong>Discount:</strong> USD{" "}
            {agreement?.quotaion?.pricesCalculation?.totalDiscount}/-
          </p>

          <p>
            <span style={{ backgroundColor: "yellow" }}>
              <strong>TOTAL After Discount:</strong> USD{" "}
              {(agreement?.quotaion?.pricesCalculation?.total)}
              /-
            </span>
          </p>

          <p>
            <span style={{ backgroundColor: "yellow" }}>
              {" "}
              The contract period as set down above is limited for a period of
              one year commencing on{" "}
              <strong>{formatDate(agreement?.project?.startDate)}</strong> and
              expiring on{" "}
              <strong>{formatDate(agreement?.project?.endDate)}</strong>.
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "yellow" }}>
              <strong>Payment terms:</strong> 50% advance payment & 50% balance
              on 3<sup>rd</sup> service.
            </span>
          </p>

          <h3>TERMS AND CONDITIONS OF PURCHASE:</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: convertHeadingsToParagraphs(agreement?.remarks),
            }}
            style={{ color: "black" }}
          ></p>

          <div className="d-flex justify-content-between">
            <div style={{ width: "40%" }}>
              <p className="mb-0 pb-0">
                <strong>First Party:</strong>
              </p>
              <p className="mt-0 pt-0 mb-0 pb-0">
                <strong>
                  For: AIFA ENVIRONMENTAL SERVICES & PEST CONTROL L.L.C <br />
                  P.O. Box 123112, Dubai-UAE
                </strong>
              </p>
              <p className="mt-0 pt-0 mb-0 pb-0">
                <strong> Name:</strong> ALEX HALES
              </p>
              <p className="mt-0 pt-0 mb-0 pb-0">
                <strong> Position:</strong> Technical Engineer
              </p>
              <p className="mt-0 pt-0">
                Date:{" "}
                {new Date(agreement?.createdAt)?.toLocaleDateString("en-GB")}
              </p>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ width: "50%" }}
            >
              <img src="/stamp.jpg" style={{ maxWidth: "70%" }} />
            </div>
          </div>

          <p className="mb-0 pb-0">
            <strong>Second Party:</strong>{" "}
          </p>
          <p className="mb-0 pb-0">
            <strong>
              {" "}
              For:{" "}
              {agreement?.customer?.firstname +
                " " +
                agreement?.customer?.lastname}{" "}
              <br />{" "}
            </strong>
            Contact : <strong> {agreement?.customer?.phone} </strong>
          </p>

          <p className="mt-0 pt-0">
            Date: {new Date(agreement?.createdAt)?.toLocaleDateString("en-GB")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AgreementView;
