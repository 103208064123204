import React, { useState, useEffect, useRef } from "react";
import Header from "../../parts/Header/Header";
import Footer from "../../parts/Footer/Footer";
import SuggestedMessages from "../../parts/SuggestedMessages/SuggestedMessages";
import { BsSendFill } from "react-icons/bs";
import Loader from "../../parts/Chat/Loader";
import Message from "../../parts/Chat/Message";
import axios from "axios";
import { styles } from "./chatStyles";
import Drawer from "../../parts/Chat/Drawer";
import { io } from "socket.io-client";
import { IoMdArrowRoundBack } from "react-icons/io";

// const socket = io("http://localhost:8080");
const socket = io("https://api.beyondaio.com");
function ChatPage({ nextPage }) {
  const [messages, setMessages] = useState([]);
  const [suggestedMessages, setSuggestedMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [messageToShare, setMessageToShare] = useState("");
  const user = JSON.parse(localStorage.getItem("chatUser"));
  const conversationID = localStorage.getItem("conversationID");
  const [isHovered, setIsHovered] = useState(false);
  const notificationSoundRef = useRef(null);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chatbot/message/conversations/${conversationID}/messages`
        );
        const { messages } = response.data;

        setMessages(messages);
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    conversationID && fetchMessages();
  }, [conversationID]);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chatbot/configuration`
        );
        const configurations = response.data;

        if (configurations.configuration.faqs) {
          const questions = configurations.configuration.faqs.faqs.map(
            (faq) => faq.question
          );
          setSuggestedMessages(questions);
        }
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    fetchConfigurations();
  }, []);

  useEffect(() => {
    if (conversationID) {
      socket.emit("joinConversation", conversationID);
    }

    socket.on("newMessage", (newMessage) => {
      if (newMessage.conversationId === conversationID) {
        if (newMessage.role === "bot") {
          setMessages((prevMessages) => [...(prevMessages || []), newMessage]);
        }
      }
    });

    return () => {
      socket.off("newMessage");
    };
  }, [user]);

  useEffect(() => {
    socket.on("newMessage", (newMessage) => {
      playNotificationSound();
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === "") return;
    await sendMessage(inputMessage);
    playNotificationSound();
  };

  const onSuggestedMessageClick = async (message) => {
    setInputMessage(message);
    await sendMessage(message);
    playNotificationSound();
  };

  const sendMessage = async (messageText) => {
    try {
      const newMessage = {
        id: messages?.length + 1,
        sender: { user: { role: "user" } },
        message: messageText || response.data.data.reply,
      };

      setMessages((prevMessages) => [...(prevMessages || []), newMessage]);

      setInputMessage("");
      setLoading(true);
      setIsInputDisabled(true);
      const agent = JSON.parse(localStorage.getItem("agent"));

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/message`,
        {
          conversationId: user?.conversation?.conversationId,
          sender: user?.newSession?.sessionId,
          text: messageText,
          bot: user?.conversation?.members[1],
          agent: agent || false,
        }
      );

      if (response?.data?.reply || response?.data?.data?.reply) {
        const replyMSG = {
          text: response?.data?.data?.reply,
        };
        if (
          response?.data?.data?.reply === "Do you want to connect with agent?"
        ) {
          setMessages((prevMessages) => [
            ...(prevMessages || []),
            { ...replyMSG, isAgent: true },
          ]);
        } else {
          socket.emit("sendMessage", {
            conversationId: conversationID,
            message: messageText,
            role: "user",
          });

          socket.emit("sendMessage", {
            conversationId: conversationID,
            message: response?.data?.reply,
            role: "bot",
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setIsInputDisabled(false);
    }
  };
  const playNotificationSound = () => {
    if (notificationSoundRef.current) {
      notificationSoundRef.current.currentTime = 0;
      notificationSoundRef.current.play().catch((err) => console.error(err));
    }
  };

  return (
    <div style={styles.container}>
      <audio ref={notificationSoundRef} src="/assets/sounds/notification.wav" />

      <div style={styles.headerWrapper}>
        <Header bg={true} />
      </div>
      <IoMdArrowRoundBack
        style={{
          fontSize: "24px",
          color: isHovered ? "black" : "gray",
          cursor: "pointer",
          fontWeight: "bold",
          marginLeft: "10px",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          localStorage.removeItem("conversationID");
          localStorage.removeItem("agent");
          nextPage();
        }}
      />

      <div style={styles.chatArea}>
        <div style={styles.messagesContainer}>
          <div style={styles.robotImageContainer}>
            <img
              src="/assets/images/robot.png"
              alt="Robot Icon"
              style={styles.robotImage}
            />
          </div>
          <h1 style={styles.title}>Ask something</h1>
          <p style={styles.subtitle}>
            These are just a few examples of what I can do.
          </p>
          {suggestedMessages.map((message, index) => (
            <SuggestedMessages
              message={message}
              key={index}
              onClick={() => onSuggestedMessageClick(message)}
            />
          ))}
          {messages?.map((msg) => (
            <Message
              msg={msg}
              key={msg.id}
              setIsShare={setIsShare}
              setMessageToShare={setMessageToShare}
              setMessages={setMessages}
              setLoading={setLoading}
              loading={loading}
            />
          ))}
          {loading && (
            <div style={styles.loadingContainer}>
              <Loader />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputWrapper}>
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Ask me anything..."
              style={styles.input(isInputDisabled)}
              disabled={isInputDisabled}
              ref={inputRef}
            />
            <button
              type="submit"
              style={styles.button(isInputDisabled)}
              disabled={isInputDisabled}
            >
              <BsSendFill style={{ fontSize: "20px" }} />
            </button>
          </div>
          <div style={styles.footerContainer}>
            <Footer />
          </div>
        </form>

        {isShare && (
          <Drawer
            isShare={isShare}
            setIsShare={setIsShare}
            messageToShare={messageToShare.message || messageToShare.text}
          />
        )}
      </div>
    </div>
  );
}

export default ChatPage;
