import React, { Fragment } from 'react';
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";
const budgetData = [
  {
    title: "Mar 2018",
    headers: ["", "Actuals", "Budgets", "Variance", "YTD Actuals", "YTD Budgets", "YTD Variance"],
    rows: [
      ["Income", "", "", "", "", "", ""],
      ["Grants", "80,327", "75,273", "5,055", "722,255", "695,795", "26,459"],
      ["Fundraising- Gifts", "1,527", "1,500", "27", "14,964", "14,409", "555"],
      ["Fundraising- Contributions", "4,404", "3,967", "437", "36,394", "36,539", "(145)"],
      ["Trading/Operating Activities", "0", "0", "0", "0", "940", "(940)"],
      ["Total Income", <strong> "86,258" </strong>, <strong> "80,740" </strong>, <strong> "5,519" </strong>, <strong> "773,612" </strong>, <strong> "747,683" </strong>, <strong> "25,929" </strong>],
      ["Cost of Sales", "", "", "", "", "", ""],
      ["Cost of Sales", "636", "455", "(182)", "4,957", "4,135", "(822)"],
      ["Total Cost of Sales", <strong> "636" </strong>, <strong> "455" </strong>, <strong> "(182)" </strong>, <strong> "4,957" </strong>, <strong> "4,135" </strong>, <strong> "(822)" </strong>],
      ["Gross Profit", <strong> "85,622" </strong>, <strong> "80,285" </strong>, <strong> "5,337" </strong>, <strong> "760,655" </strong>, <strong> "743,549" </strong>, <strong> "25,107" </strong>],
      ["Expense", "", "", "", "", "", ""],
      ["Operational", "3,039", "2,358", "(681)", "16,984", "16,488", "(497)"],
      ["Finance", "1,873", "1,194", "(679)", "15,446", "13,524", "(1,922)"],
      ["Employment", "69,437", "67,713", "(1,725)", "609,971", "604,507", "(5,463)"],
      ["Occupancy", "3,927", "1,905", "(2,022)", "26,336", "17,401", "(8,934)"],
      ["Motor Vehicle Expenses", "2,442", "2,204", "[230]", "14,431", "14,417", "(14)"],
      ["Marketing", "6,725", "4,855", "(1,870)", "44,319", "40,957", "(3,362)"],
      ["Total Expense", <strong> "87,443" </strong>, <strong> "80,228" </strong>, <strong> "(7,215)" </strong>, <strong> "727,487" </strong>, <strong> "707,294" </strong>, <strong> "(20,193)" </strong>],
      ["Operating Profit", <strong> "(1,821)" </strong>, <strong> "57" </strong>, <strong> "(1,878)" </strong>, <strong> "41,169" </strong>, <strong> "36,255" </strong>, <strong> "4,914" </strong>],
      ["Net Profit", <strong> "(1,821)" </strong>, <strong> "57" </strong>, <strong> "(1,878)" </strong>, <strong> "41,169" </strong>, <strong> "36,255" </strong>, <strong> "4,914" </strong>],
    ],
  },
  {
    title: "Additional Information",
    headers: [],
    rows: [
      ["Debt Ratio", "31%", "21%", "(10)"],
      ["Working Capital Ratio", "(16%)", "413%", "429"],
      ["Net Profit Margin", "(2%)", "0%", "2"],
    ],
  },
];
const downloadPDF = () => {
  const element = document.getElementById("report-content"); // Select the content to convert
  const options = {
    filename: `Actual_Budget_Report_${new Date().toLocaleDateString()}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };
  html2pdf().set(options).from(element).save();
};

const ActualBudget = () => {
  return (
    <Fragment>
      <div className="container-fluid"></div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card" >
              <div className="text-end" style={{ marginBottom: "20px" }}>
                <button
                  onClick={downloadPDF}
                  style={{ background: "transparent", border: "none", padding: 0 }}
                >
                  <MdOutlineFileDownload style={{ color: "inherit", fontSize: "20px" , marginRight:"10px" , marginTop:"3px" }} /> 
                </button>
              </div>
            <div id="report-content">
              <div className="card-header mb-8">
              <div className="col-6 text-start mt-0">
                      <h1 style={{color:"#009688"}}>Aifa Services</h1>
                    </div>
                    <div className="col-6 text-end">
                      <img
                        src={aifaLogo}
                        alt="logo"
                        style={{
                          width: "130px",
                        }}
                      />
                    </div>
              </div>
              <div className="container mt-4">
                {/* Grouped All Headings Div */}
                <div
                  style={{
                    backgroundColor: "rgb(52, 152, 219)",
                    padding: "15px",
                    borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                  <h2 className="text-center" style={{ color: "#fff", marginBottom: "5px" }}>
                    Actual vs Budget — Not-for-Profit Zero
                  </h2>
                  <h5 className="text-center" style={{ color: "#fff", marginBottom: "10px" }}>
                    MONTH: MAR 2018
                  </h5>

                  {/* Map through each section and include the h4 title inside the same div */}
                  {budgetData.map(
                    (section, index) =>
                      section.title !== "Additional Information" && (
                        <h4
                          key={index}
                          className="text-center"
                          style={{ color: "#fff", margin: "0", paddingBottom: "10px" }}
                        >
                          {section.title}
                        </h4>
                      )
                  )}
                </div>

                {/* Rendering the Tables */}
                {budgetData.map((section, index) => (
                  <div key={index} className="mb-4">
                    {/* Additional Information Heading with Specific Color */}
                    {section.title === "Additional Information" && (
                      <h4 className="text-left" style={{ color: "#009688", margin: "15px 0" }}>
                        {section.title}
                      </h4>
                    )}

                    <table className="table table-bordered mt-2">
                      {section.headers.length > 0 && (
                        <thead className="thead-light">
                          <tr>
                            {section.headers.map((header, idx) => (
                              <th key={idx} style={{ 
                                color: ["Actuals", "Budgets", "Variance", "YTD Actuals", "YTD Budgets", "YTD Variance"].includes(header) ? "white" : "black", 
                                backgroundColor: ["Actuals", "Budgets", "Variance", "YTD Actuals", "YTD Budgets", "YTD Variance"].includes(header) ? "rgb(52, 152, 219)" : "rgb(52, 152, 219)" 
                              }}>
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                      )}
                      <tbody>
  {section.rows.map((row, idx) => (
    <tr
      key={idx}
      style={{
        backgroundColor:
          row[0] === "Income" ||
          row[0] === "Grants" ||
          row[0] === "Fundraising- Gifts" ||
          row[0] === "Fundraising- Contributions" ||
          row[0] === "Trading/Operating Activities" ||
          row[0] === "Cost of Sales" ||
          row[0] === "Expense" ||
          row[0] === "Operational" ||
          row[0] === "Finance" ||
          row[0] === "Employment" ||
          row[0] === "Occupancy" ||
          row[0] === "Motor Vehicle Expenses" ||
          row[0] === "Marketing" ||
          row[0] === "Debt Ratio" || // Added condition
          row[0] === "Working Capital Ratio" || // Added condition
          row[0] === "Net Profit Margin" // Added condition
            ? "#d9edf7"
            : "transparent", // Set the default background color
      }}
    >
      {row.map((cell, cellIdx) => (
        <td
          key={cellIdx}
          style={{
            fontWeight:
              row[0] === "Total Income" ||
              row[0] === "Total Cost of Sales" ||
              row[0] === "Gross Profit" ||
              row[0] === "Total Expense" ||
              row[0] === "Operating Profit" ||
              row[0] === "Net Profit"
                ? "bold"
                : "normal",
            color:
              row[0] === "Total Income" ||
              row[0] === "Total Cost of Sales" ||
              row[0] === "Gross Profit" ||
              row[0] === "Total Expense" ||
              row[0] === "Operating Profit" ||
              row[0] === "Net Profit"
                ? "#009688"
                : "black",
          }}
        >
          {cell}
        </td>
      ))}
    </tr>
  ))}
</tbody>

                    </table>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        </div>
      
    </Fragment>
  );
};

export default ActualBudget;
