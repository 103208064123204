import React, { Fragment, useState, useEffect } from "react";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";
import { Formik } from "formik";
import Select from "react-select";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";
import { useGetAllBankReconciliationByDatePeriodQuery } from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";
const data = [
  {
    description: "Cash balance as per bank statement, February 28, 2018",
    value: "$300,000",
    backgroundColor: "#d9edf7",
  },
  { description: "Add: Deposit in transit", value: "20,000" },
  { description: "", value: "$320,000" },
  { description: "Deduct: Outstanding checks", value: "50,000" },
  {
    description: "Adjusted cash balance",
    value: "$270,000",
    bold: true,
    underline: true,
    color: "#009688",
  },
  {
    description: "Balance as per depositor's record, February 28, 2018",
    value: "$260,900",
  },
  { description: "Add: Receivable collected by bank", value: "$9,800" },
  { description: "Interest earned", value: "20" },
  { description: "", value: "9,820" },
  { description: "", value: "$270,720" },
  { description: "Deduction: NSF check", value: "520" },
  { description: "Service charges", value: "100" },
  { description: "Error on check", value: "100", underline: true },
  { description: "", value: "720" },
  {
    description: "Adjusted cash balance",
    value: "$270,000",
    bold: true,
    underline: true,
    color: "#009688",
  },
];

const BankReconciliationStatement = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const { data: accounts = [] } = useGetAllAccountsQuery();
  const [records, setRecords] = useState([]);

  const boldStyle = { fontWeight: "bold", color: "black" };
  const underlineStyle = { textDecoration: "underline" };
  const textRightStyle = { textAlign: "right" };
  const blackTextStyle = { color: "black" };
  const greenBackgroundStyle = {
    backgroundColor: "rgb(52 152 219)",
    color: "white",
  };

  const { data: allRecords = [], isSuccess } =
    useGetAllBankReconciliationByDatePeriodQuery(
      {
        dateTo: dateTo,
        dateFrom: dateFrom,
        accountId: selectedAccount,
      },
      { skip: !dateTo || !dateFrom || !selectedAccount }
    );

  useEffect(() => {
    if (isSuccess) {
      setRecords(allRecords);
    }
  }, [allRecords]);

  const greenTextStyle = { color: "#053e05" };
  const lightBlueBackgroundStyle = { backgroundColor: "#d9edf7" };

  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Bank_Reconcialliation_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="basic-form card-body">
              <Formik
                initialValues={{
                  fromDate: "",
                  toDate: "",
                }}
                enableReinitialize={true}
                // validationSchema={loginSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label className="text-label">Select Account</label>
                          <Select
                            classNamePrefix="react-select"
                            name="accountname"
                            onChange={(selectedOption) => {
                              setSelectedAccount(selectedOption?.value);
                              setSelectedAccountName(selectedOption?.label);
                            }}
                            options={accounts
                              ?.filter((bank) => {
                                return bank?.type == "Bank Account";
                              })
                              ?.map((account) => ({
                                label: account?.name,
                                value: account?.accountId,
                              }))}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label className="text-label">Date From</label>
                          <input
                            type="date"
                            name="fromDate"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("fromDate", e.target.value);
                              setdateFrom(e.target.value);
                            }}
                            onBlur={handleBlur}
                            max={new Date()?.toISOString()?.slice(0, 10)}
                          />
                          {errors.fromDate && touched.fromDate && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.fromDate}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label className="text-label">Date To</label>
                          <input
                            type="date"
                            max={new Date()?.toISOString()?.slice(0, 10)}
                            name="toDate"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("toDate", e.target.value);
                              setDateTo(e.target.value);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.toDate && touched.toDate && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.toDate}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          {dateFrom && dateTo && selectedAccount && (
            <div className="card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  // alignItems: "end",
                }}
                className="p-4"
              >
                <button
                  onClick={downloadPDF}
                  style={{
                    marginBottom: "20px",

                    background: "transparent",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <MdOutlineFileDownload
                    style={{ color: "inherit", fontSize: "20px" }}
                  />
                </button>
              </div>

              <div id="report-content" className="px-2">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 className="text-left mb-3" style={{ color: "#009688" }}>
                      {selectedAccountName}
                    </h2>
                  </div>
                  <img
                    src={aifaLogo}
                    alt="logo"
                    style={{
                      width: "130px",
                      alignSelf: "flex-start",
                    }}
                  />
                </div>
                <div
                  style={greenBackgroundStyle}
                  className="text-center mb-4 p-2 mt-3"
                >
                  <h3 className="mb-0 text-white">
                    Bank Reconciliation Statement
                  </h3>
                  <h4 className="mb-0 text-white">
                    {`From ${new Date(dateFrom).toLocaleDateString(
                      "en-GB"
                    )} To ${new Date(dateTo).toLocaleDateString("en-GB")} `}
                  </h4>
                </div>
                <table
                  className="table "
                  style={{ color: "black", fontSize: "12px" }}
                >
                  <tbody>
                    <tr>
                      <td>Opening Balance</td>
                      <td
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          textAlign: "end",
                        }}
                      >
                        {records?.length > 0 ? records[0]?.beforeBalance : "0"}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#d9edf7" }}>
                      <td
                        colSpan="2"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Add
                      </td>
                    </tr>
                    {records
                      ?.filter((row) => {
                        return row?.debits > 0;
                      })
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              width: "80%",
                            }}
                          >
                            {item?.description}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                            }}
                          >
                            {item?.debits}
                          </td>
                        </tr>
                      ))}

                    <tr style={{ fontWeight: "bold" }}>
                      <td>Total Amount Deposited</td>
                      <td style={{ textAlign: "end" }}>
                        {records?.reduce((sum, row) => {
                          return sum + parseFloat(row?.debits || 0);
                        }, 0)}
                      </td>
                    </tr>

                    <tr style={{ backgroundColor: "#d9edf7" }}>
                      <td
                        colSpan="2"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Less
                      </td>
                    </tr>

                    {records
                      ?.filter((row) => {
                        return row?.credits > 0;
                      })
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              width: "80%",
                            }}
                          >
                            {item?.description}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                            }}
                          >
                            {item?.credits}
                          </td>
                        </tr>
                      ))}

                    <tr style={{ fontWeight: "bold" }}>
                      <td>Total Amount Withdraw</td>
                      <td style={{ textAlign: "end" }}>
                        {records?.reduce((sum, row) => {
                          return sum + parseFloat(row?.credits || 0);
                        }, 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default BankReconciliationStatement;
