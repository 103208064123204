import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import {
  useGetExpenseQuery,
  usePutExpenseMutation,
} from "../../../../../features/financialMangement/expense/expense/expenseApiSlice";
import {
  useGetServiceReportQuery,
  usePostServiceReportPDFMutation,
  usePostServiceReportPDFDownloadMutation,
} from "../../../../../features/reports/serviceReportApiSlice";
import aifaLogo from "../../.././financial management/AIFA-Logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const ServiceReport = () => {
  const [isPDFDownloadCreating, setIsPDFDownloadCreating] = useState({});
  const [isPDFCreating, setIsPDFCreating] = useState({});
  const [updateExpense, { isError, error }] = usePutExpenseMutation();
  const { data: reports = [], refetch } = useGetServiceReportQuery();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [search, setSearch] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const [createServiceReportPDF] = usePostServiceReportPDFMutation();

  const [createPDFDownloadReport] = usePostServiceReportPDFDownloadMutation();
  const viewPDF = async (report) => {
    setIsPDFCreating((prevState) => ({
      ...prevState,
      [report.reportNo]: true,
    }));
    const singleReport = { ...report };
    try {
      singleReport.customerFirstName =
        report?.invoice?.customer?.firstname || "";
      singleReport.customerLastName = report?.invoice?.customer?.lastname || "";
      singleReport.ref = report?.invoice?.invoiceNo || "";
      singleReport.phone = report?.invoice?.customer?.phone || "";
      singleReport.email = report?.invoice?.customer?.email || "";
      singleReport.address = report?.invoice?.customer?.mailingAddress || "";
      singleReport.sendMail = true;

      // Call the PDF generation function
      const pdfResponse = await createServiceReportPDF(singleReport).unwrap();
      if (pdfResponse) {
        const newWindow = window.open("", "_blank");
        if (newWindow) {
          newWindow.document.write(pdfResponse?.html);
          newWindow.document.close(); // Ensure all content is loaded
        } else {
          alert("Pop-up blocked! Please allow pop-ups to view the PDF.");
        }
      } else {
        console.error("PDF response is empty");
        alert("Failed to fetch the PDF data");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("An error occurred while generating the PDF");
    } finally {
      setIsPDFCreating((prevState) => ({
        ...prevState,
        [report.reportNo]: false,
      }));
    }
  };

  const filterReports = () => {
    if (!search) return reports;

    return reports.filter((report) => {
      const reportNo = report?.reportNo?.toString().toLowerCase();
      const reportDate = formatDate(report?.reportDate).toLowerCase();
      const customerName =
        `${report?.invoice?.customer?.firstname} ${report?.invoice?.customer?.lastname}`.toLowerCase();

      return (
        reportNo.includes(search.toLowerCase()) ||
        reportDate.includes(search.toLowerCase()) ||
        customerName.includes(search.toLowerCase())
      );
    });
  };

  const filteredReports = filterReports();
  const records = filteredReports.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredReports.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate.replace(",", "");
  }

  const downloadPDF = async (report) => {
    try {
      setIsPDFDownloadCreating((prevState) => ({
        ...prevState,
        [report.reportNo]: true,
      }));
      // Clone and prepare the report data
      const singleReport = {
        ...report,
        customerFirstName: report?.invoice?.customer?.firstname || "",
        customerLastName: report?.invoice?.customer?.lastname || "",
        ref: report?.invoice?.invoiceNo || "",
        phone: report?.invoice?.customer?.phone || "",
        email: report?.invoice?.customer?.email || "",
        address: report?.invoice?.customer?.mailingAddress || "",
        sendMail: true,
      };

      // Fetch PDF HTML content from the backend
      const pdfResponse = await createPDFDownloadReport(singleReport).unwrap();

      if (!pdfResponse?.html) {
        console.error("PDF response is empty");
        alert("Failed to fetch the PDF data");
        return;
      }

      // Create a hidden container for rendering the HTML
      const container = document.createElement("div");
      container.style.position = "absolute";
      container.style.left = "-9999px";
      container.innerHTML = pdfResponse.html;
      document.body.appendChild(container);

      // Generate a canvas from the container
      const canvas = await html2canvas(container, {
        scale: 2, // High resolution
        useCORS: true, // Enable cross-origin requests for images
      });

      // Convert canvas to an image and add it to the PDF
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = 190; // A4 page width (mm) minus margins
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
      pdf.save("service_report.pdf");

      // Clean up the container
      document.body.removeChild(container);
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("An error occurred while generating the PDF. Please try again.");
    } finally {
      // Reset loading state after the download is complete
      setIsPDFDownloadCreating((prevState) => ({
        ...prevState,
        [report.reportNo]: false,
      }));
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h3
                      className="heading mb-0"
                      style={{ color: "#009688", fontSize: "20px" }}
                    >
                      Service Reports
                    </h3>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      <Link
                        to={
                          "/" +
                          user?.role?.toLowerCase() +
                          "/service-report/create"
                        }
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.775rem 1.75rem",
                        }}
                      >
                        <i className="fa-solid fa-plus me-2"></i>Generate
                        Service Report
                      </Link>
                    </div>
                  </div>
                  <div id="report-content">
                    <div
                      id="task-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="empoloyeestbl2"
                        className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0 mt-2"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Report No:</th>
                            <th>Report Date</th>
                            <th>Customer Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.map((report, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{report?.reportNo}</td>
                              <td>{formatDate(report?.reportDate)}</td>
                              <td>{`${report?.invoice?.customer?.firstname} ${report?.invoice?.customer?.lastname}`}</td>
                              <td>
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "1px solid #007bff",
                                    color: "#007bff",
                                    borderRadius: "4px",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                  }}
                                  title="View PDF"
                                  onClick={() => viewPDF(report)}
                                  disabled={isPDFCreating[report.reportNo]}
                                >
                                  <>
                                    {isPDFCreating[report.reportNo] ? (
                                      <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        style={{
                                          marginRight: "8px",
                                          color: "#007bff",
                                        }}
                                      ></div>
                                    ) : (
                                      <i
                                        className="fas fa-eye"
                                        style={{
                                          marginRight: "8px",
                                          color: "#007bff",
                                        }}
                                      ></i>
                                    )}
                                    View PDF
                                  </>
                                </button>

                                {/* Download PDF Button */}
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "1px solid #007bff",
                                    color: "#007bff",
                                    borderRadius: "4px",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                  }}
                                  title="Download PDF"
                                  onClick={() => downloadPDF(report)}
                                  disabled={
                                    isPDFDownloadCreating[report.reportNo]
                                  }
                                >
                                  <>
                                    {isPDFDownloadCreating[report.reportNo] ? (
                                      <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        style={{
                                          color: "inherit",
                                          fontSize: "20px",
                                          marginRight: "5px",
                                        }}
                                      ></div>
                                    ) : (
                                      <MdOutlineFileDownload
                                        style={{
                                          color: "inherit",
                                          fontSize: "20px",
                                          marginRight: "5px",
                                        }}
                                      />
                                    )}
                                    Download PDF
                                  </>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {lastIndex - recordsPage + 1} to{" "}
                          {filteredReports.length < lastIndex
                            ? filteredReports.length
                            : lastIndex}{" "}
                          of {filteredReports.length} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers justify-content-center"
                          id="example2_paginate"
                        >
                          <Link
                            className="paginate_button previous disabled"
                            to="#"
                            onClick={prePage}
                          >
                            <i className="fa-solid fa-angle-left" />
                          </Link>
                          <span>
                            {number.map((n, i) => (
                              <Link
                                className={`paginate_button ${
                                  currentPage === n ? "current" : ""
                                } `}
                                key={i}
                                onClick={() => changeCPage(n)}
                              >
                                {n}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className="paginate_button next"
                            to="#"
                            onClick={nextPage}
                          >
                            <i className="fa-solid fa-angle-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceReport;
