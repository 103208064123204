import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const formatTime = (time) => {
  const [hours, minutes] = time?.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

const AttendanceDetailModal = ({ show, onHide, attendance }) => {
  if (!attendance) return null;

  const { records, date } = attendance;
  const formattedDate = date
    ? new Date(date).toLocaleDateString(undefined, {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : new Date().toLocaleDateString(undefined, {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });

  const totalHours = calculateTotalHours(records);
  return (
    <Modal show={show} onHide={onHide} dialogClassName="custom-modal-width">
      <Modal.Header closeButton>
        <Modal.Title>Attendance Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <h5>Timesheet {formattedDate}</h5>

            <div
              className="rounded px-2 py-1"
              style={{
                backgroundColor: "#F8F8F8",
                marginTop: "23px",
                marginBottom: "10px",
              }}
            >
              <p>
                Check In At:{" "}
                <h5>
                  {records[0]
                    ? formatTime(records[0].CheckInRecordtime)
                    : "N/A"}
                </h5>
              </p>
            </div>

            <div
              className="rounded-circle text-center mx-2"
              style={{
                backgroundColor: "#E8E8E8",
                height: "120px",
                width: "120px",
                border: "2px solid #C8C8C8",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4 className="p-1">{totalHours} hrs</h4>
            </div>

            <div
              className="rounded px-2 py-1 my-3"
              style={{ backgroundColor: "#F8F8F8" }}
            >
              <p>
                Check Out At:{" "}
                <h5>
                  {records &&
                  records[records?.length - 1]?.CheckOutRecordtime != null
                    ? formatTime(
                        records[records?.length - 1]?.CheckOutRecordtime
                      )
                    : "N/A"}{" "}
                </h5>
              </p>
            </div>
          </div>
          <div className="col">
            <h5 className="">Activity</h5>
            <div className="">
              <div
                id="DZ_W_TimeLine"
                className="widget-timeline dz-scroll height370 ps--active-y"
              >
                <ul className="timeline">
                  {records.map((record, index) => (
                    <li key={index}>
                      <div className="timeline-badge primary"></div>
                      <Link className="timeline-panel text-muted">
                        <h6 className="">Check In</h6>
                        <p>
                          {record?.CheckInRecordtime != null
                            ? formatTime(record?.CheckInRecordtime)
                            : "N/A"}
                        </p>

                        <h6 className="mb-0">Check Out</h6>
                        <p>
                          {record?.CheckOutRecordtime != null
                            ? formatTime(record?.CheckOutRecordtime)
                            : "N/A"}
                        </p>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const calculateTotalHours = (records) => {
  let totalMilliseconds = 0;

  records.forEach((record) => {
    if (record?.CheckOutRecordtime != null) {
      const checkIn = new Date(`1970-01-01T${record?.CheckInRecordtime}:00Z`);
      const checkOut = new Date(`1970-01-01T${record?.CheckOutRecordtime}:00Z`);
      totalMilliseconds += checkOut - checkIn;
    }
  });

  const totalHours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
  let totalMinutes = Math.floor(
    (totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );
  totalMinutes = totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes;

  return `${totalHours}:${totalMinutes}`;
};

export default AttendanceDetailModal;
