// Create.jsx
import React, { Fragment } from "react";

import {
  usePostSupplierMutation,
  isLoading,
} from "../../../features/supplier/supplierApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-tagsinput/react-tagsinput.css";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  TRN: Yup.string().optional("TRN is required"),
  email: Yup.string().email("Invalid email"),
  phone: Yup.string()
    .optional("Phone is required")
    .min(10, "Must be minimum 10 digits")
    .max(15, "Must be less than 15 digits")
    .matches(/^\d+$/, "Phone number must be digits only"),
});

const Create = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const [postSupplier, { isLoading }] = usePostSupplierMutation();

  const handleSubmitted = async (values) => {
    try {
      const result = await postSupplier(values);

      notifySuccess(result?.data?.message);
      // navigate("supplier");
      navigate("/" + user?.role?.toLowerCase() + "/supplier");
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Supplier</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: "",
                      detail: "",
                      email: "",
                      phone: "",
                      TRN: "",
                      address: "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Supplier Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.email
                                  ? errors.email
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter Email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.email && errors.email}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.TRN
                                  ? errors.TRN
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">TRN No:</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter TRN"
                                name="TRN"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.TRN}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.TRN && errors.TRN}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.phone
                                  ? errors.phone
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Phone No</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter phone no"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.address
                                  ? errors.address
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter Address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.detail
                                  ? errors.detail
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Detail</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter Detail"
                                name="detail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.detail}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.detail && errors.detail}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
