// Create.jsx
import React, { useState, Fragment, useEffect } from "react";
import { useGetCategoriesQuery } from "../../../../features/Inventory/category/categoryApiSlice";
import Select from "react-select";
import { useGetBrandsQuery } from "../../../../features/Inventory/brand/brandApiSlice";
import { usePostProductMutation } from "../../../../features/Inventory/product/productApiSlice";
import { useGetModelsQuery } from "../../../../features/Inventory/model/modelApiSlice";
import { useGetSupplierQuery } from "../../../../features/supplier/supplierApiSlice";
import { useNavigate } from "react-router-dom";
import { useGetWarehouseQuery } from "../../../../features/Inventory/warehouse/warehouseApiSlice";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Barcode from "react-barcode";
const loginSchema = Yup.object().shape({
  name: Yup.string()
    .required("Product Name is required")
    .max(255, "Product Name must be less than 255 characters"),

  description: Yup.string().max(
    255,
    "Description must be less than 255 characters"
  ),

  productType: Yup.string()
    .optional()
    .max(255, "Product Type must be less than 255 characters"),

  warehouseId: Yup.string().required("Warehouse is required"),
  supplierId: Yup.string().required("supplier is required"),

  brandId: Yup.string().optional(),

  modelId: Yup.string().optional(),
  categoryId: Yup.string().required("Category is required"),

  price: Yup.number()
    .required("Price is required")
    .positive("Price must be positive"),

  quantity: Yup.number()
    .required("Quantity is required")
    .integer("Quantity must be an integer")
    .min(0, "Quantity must be 0 or a positive value"),

  unitOfMeasurement: Yup.string()
    .optional("Unit is required")
    .min(1, "Unit must be at least 1 character")
    .max(255, "Unit must be less than 255 characters"),

  taxes: Yup.number().optional().positive("Taxes must be positive"),

  discountes: Yup.number().optional().positive("Discount must be positive"),

  discountType: Yup.string()
    .optional()
    .max(255, "Discount Type must be less than 255 characters"),

  weigth: Yup.string().optional(),

  color: Yup.string()
    .optional()
    .min(2, "Color must be at least 2 characters")
    .max(250, "Color must be less than 250 characters"),

  size: Yup.string()
    .optional()
    .max(100, "Size must be less than 100 characters"),
});

const Create = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [barcode, setbarcode] = useState("");
  const time = new Date();
  const barTime = time.getTime();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    setbarcode(barTime.toString());
  }, []);

  const { data: brands = [], refetch: refetchBrands } = useGetBrandsQuery({
    fetchPolicy: "network-only",
  });
  const { data: model = [], refetch: refetchModel } = useGetModelsQuery({
    fetchPolicy: "network-only",
  });
  const { data: warehouse = [], refetch: refetchWarehouse } =
    useGetWarehouseQuery({
      fetchPolicy: "network-only",
    });

  const { data: suppliers = [] } = useGetSupplierQuery();

  const { data: categories = [], refetch: refetchCategories } =
    useGetCategoriesQuery({
      fetchPolicy: "network-only",
    });
  const [postProduct, { isLoading }] = usePostProductMutation({
    fetchPolicy: "network-only",
  });
  const handleSubmitted = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description || "");
      formData.append("categoryId", values.categoryId);
      formData.append("modelId", values.modelId || null);
      formData.append("productType", values.productType || "");
      formData.append("brandId", values.brandId || null);
      formData.append("price", values.price);
      formData.append("quantity", values.quantity);
      formData.append("unitOfMeasurement", values.unitOfMeasurement || "");
      formData.append("taxes", values.taxes || "");
      formData.append("discountes", values.discountes || "");
      formData.append("discountType", values.discountType || "");
      formData.append("color", values.color || "");
      formData.append("weigth", values.weigth || "");
      formData.append("warranty", values.warranty || "");
      formData.append("size", values.size || "");
      formData.append("barcode", barcode);
      formData.append("warehouseId", values.warehouseId);
      formData.append("supplierId", values?.supplierId);
      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      const result = await postProduct(formData);
      // Handle success, access result.data
      notifySuccess(result?.data?.message);
      navigate("/" + user?.role?.toLowerCase() + "/product");
      // navigate("product");
    } catch (error) {
      notifyError(error.message);
      // Handle error, access error.data
    }
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Product</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: "",
                      description: "",
                      categoryId: "",
                      brandId: "",
                      price: "",
                      quantity: "",
                      unitOfMeasurement: "",
                      taxes: 5,
                      discountes: "",
                      imageUrl: "",
                      productType: "",
                      barcode: { barcode },
                      warehouseId: "",
                      discountType: "percentage",
                      productType: "New",
                      supplierId: "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Product Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter product name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Price</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter price"
                                name="price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.price && errors.price}
                              </div>
                            </div>{" "}
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Product Type</label>
                              <Select
                                options={[
                                  { value: "New", label: "New" },
                                  { value: "Used", label: "Used" },
                                ]}
                                name="productType"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "productType",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                value={
                                  values.productType
                                    ? {
                                        value: values.productType,
                                        label: values.productType,
                                      }
                                    : null
                                } // This line ensures the correct initial value is displayed
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.productType && errors.productType}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Product Barcode
                              </label>
                              <div className="d-flex align-items-center">
                                <Barcode
                                  value={barTime}
                                  height={25}
                                  margin={0}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Category</label>
                              <Select
                                options={categories?.categories?.map(
                                  (category) => ({
                                    value: category.categoryId,
                                    label: category.name,
                                  })
                                )}
                                name="categoryId"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "categoryId",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                // value={categoryOptions.find(
                                //   (option) => option.value === values.categoryId
                                // )}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.categoryId && errors.categoryId}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Brand</label>
                              <Select
                                options={brands?.brands?.map((brand) => ({
                                  value: brand.brandId,
                                  label: brand.name,
                                }))}
                                name="brandId"
                                onChange={(selectedOption) =>
                                  setFieldValue("brandId", selectedOption.value)
                                }
                                onBlur={handleBlur}
                                // value={brandOptions.find(
                                //   (option) => option.value === values.brandId
                                // )}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.brandId && errors.brandId}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Model</label>
                              <Select
                                options={model?.model?.map((model) => ({
                                  value: model.modelId,
                                  label: model.name,
                                }))}
                                name="modelId"
                                onChange={(selectedOption) =>
                                  setFieldValue("modelId", selectedOption.value)
                                }
                                onBlur={handleBlur}
                                // value={brandOptions.find(
                                //   (option) => option.value === values.brandId
                                // )}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.modelId && errors.modelId}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Warehouse</label>
                              <Select
                                options={warehouse?.warehouse?.map(
                                  (warehouse) => ({
                                    value: warehouse.warehouseId,
                                    label: warehouse.name,
                                  })
                                )}
                                name="warehouseId"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "warehouseId",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                // value={brandOptions.find(
                                //   (option) => option.value === values.brandId
                                // )}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.warehouseId && errors.warehouseId}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Unit of Measurement
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter unit of measurement"
                                name="unitOfMeasurement"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.unitOfMeasurement}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.unitOfMeasurement &&
                                  errors.unitOfMeasurement}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Quantity</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter quantity"
                                name="quantity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quantity}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.quantity}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Supplier</label>
                              <Select
                                options={suppliers?.suppliers?.map(
                                  (supplier) => ({
                                    value: supplier.supplierId,
                                    label: supplier.name,
                                  })
                                )}
                                name="supplierId"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "supplierId",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                // value={brandOptions.find(
                                //   (option) => option.value === values.brandId
                                // )}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.supplierId && errors.supplierId}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Discount Type
                              </label>
                              <Select
                                options={[
                                  { value: "percentage", label: "Percentage" },
                                  { value: "fix", label: "Fix Amount" },
                                ]}
                                name="discountType"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "discountType",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                value={
                                  values.discountType
                                    ? {
                                        value: values.discountType,
                                        label: values.discountType,
                                      }
                                    : null
                                } // This line ensures the correct initial value is displayed
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountType && errors.discountType}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                {values.discountType === "fix"
                                  ? "Discount Amount"
                                  : "Discount Percentage%"}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter discount"
                                name="discountes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.discountes}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountes && errors.discountes}
                              </div>
                            </div>
                          </div>
                          {/* 
                          <div className="col-md-2">
                            <div className="form-group mb-3">
                              <label className="text-label">Taxes %</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter taxes"
                                name="taxes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.taxes}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.taxes && errors.taxes}
                              </div>
                            </div>
                          </div> */}

                          <div className="col-lg-4">
                            <div
                              className={`form-group mb-3 ${
                                values.taxes
                                  ? errors.taxes
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">VAT Tax %</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a VAT Tax"
                                name="taxes"
                                disabled={true}
                                // onChange={(e) => {
                                //   const data = e.target.value;
                                //   setFieldValue("salesTaxs", data);
                                //   setSubServiceTotalDetailPrice(
                                //     (prevState) => ({
                                //       ...prevState,
                                //       totalTax: data,
                                //     })
                                //   );
                                // }}
                                onBlur={handleBlur}
                                value={values.taxes}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.salesTaxs && errors.salesTaxs}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Color</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter color"
                                name="color"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.color}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.color && errors.color}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Weight</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter weight(e.g., 70 kg / 500 g)"
                                name="weigth"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.weigth}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.weigth && errors.weigth}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Size</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter size"
                                name="size"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.size}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.size && errors.size}
                              </div>
                            </div>
                          </div>
                          {values.productType == "New" ? (
                            <div className="col-md-3">
                              <div className="form-group mb-3">
                                <label className="text-label">Warranty</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter warranty"
                                  s
                                  name="warranty"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.warranty}
                                  // required
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.warranty && errors.warranty}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Product Image
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                placeholder="Enter image URL"
                                name="imageUrl"
                                onChange={(event) => {
                                  // Store the selected file in state
                                  setSelectedFile(event.target.files[0]);
                                  // Generate a preview URL for the selected image
                                  setPreviewImage(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                  handleChange(event);
                                }}
                                onBlur={handleBlur}
                                value={values.imageUrl}
                                accept="image/*"
                              />
                              {/* {previewImage && (
                                <div className="mt-2">
                                  <img
                                    src={previewImage}
                                    alt="Preview"
                                    style={{ maxWidth: "200px" }}
                                  />
                                  {errors.imageUrl && touched.imageUrl && (
                                    <div className="invalid-feedback animated fadeInUp">
                                      {errors.imageUrl}
                                    </div>
                                  )}
                                </div>
                              )} */}
                              {previewImage && (
                                <div
                                  className="mt-2"
                                  style={{
                                    maxWidth: "350px",
                                    // height: "300px",
                                    marginBottom: "10px",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    src={previewImage}
                                    alt="product image"
                                    className="mx-1 img-thumbnail"
                                  />
                                  <button
                                    onClick={() => {
                                      setPreviewImage(null);
                                      setFieldValue("imageUrl", ""); // Corrected syntax here
                                    }}
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "10px",
                                    }}
                                    className="btn btn-danger"
                                  >
                                    <i
                                      className="fas fa-trash"
                                      title="Delete"
                                    ></i>
                                  </button>
                                </div>
                              )}
                              {errors.imageUrl && touched.imageUrl && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.imageUrl}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Product Notes
                              </label>
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="Enter notes"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                rows={4}
                              />
                              {errors.description && touched.description && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.description}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
